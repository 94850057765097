"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var flatten_1 = require("../../flatten");
exports.Validator = {
    name: "max_date",
    isImplicit: false,
    errorMessage: "This date must be before :max_date",
    validate: function (value, requirement) {
        var _a = requirement.split(","), dateField = _a[0], maxDateField = _a[1];
        // TODO: write a test and refactor
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        var dateVal = flatten_1.flatten(this.validator.input)[dateField];
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        var maxDateVal = flatten_1.flatten(this.validator.input)[maxDateField];
        return Number(new Date(dateVal)) >= Number(new Date(maxDateVal));
    },
};
