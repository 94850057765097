var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../theme";
export var Card = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 1px solid ", ";\n  border-radius: 4px;\n  background-color: ", ";\n  padding: 20px;\n  padding-top: 0px;\n  margin-bottom: 20px;\n"], ["\n  border: 1px solid ", ";\n  border-radius: 4px;\n  background-color: ", ";\n  padding: 20px;\n  padding-top: 0px;\n  margin-bottom: 20px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.gray10;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.white;
});
var templateObject_1;
