var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { InputRule } from "prosemirror-inputrules";
function getMarksBetween(start, end, state) {
    var marks = [];
    state.doc.nodesBetween(start, end, function (node, pos) {
        marks = __spreadArrays(marks, node.marks.map(function (mark) { return ({
            start: pos,
            end: pos + node.nodeSize,
            mark: mark,
        }); }));
    });
    return marks;
}
export default function (regexp, markType, getAttrs) {
    return new InputRule(regexp, function (state, match, start, end) {
        var attrs = getAttrs instanceof Function ? getAttrs(match) : getAttrs;
        var tr = state.tr;
        var m = match.length - 1;
        var markEnd = end;
        var markStart = start;
        if (match[m]) {
            var matchStart_1 = start + match[0].indexOf(match[m - 1]);
            var matchEnd = matchStart_1 + match[m - 1].length - 1;
            var textStart = matchStart_1 + match[m - 1].lastIndexOf(match[m]);
            var textEnd = textStart + match[m].length;
            var excludedMarks = getMarksBetween(start, end, state)
                .filter(function (item) { return item.mark.type.excludes(markType); })
                .filter(function (item) { return item.end > matchStart_1; });
            if (excludedMarks.length) {
                return null;
            }
            if (textEnd < matchEnd) {
                tr.delete(textEnd, matchEnd);
            }
            if (textStart > matchStart_1) {
                tr.delete(matchStart_1, textStart);
            }
            markStart = matchStart_1;
            markEnd = markStart + match[m].length;
        }
        tr.addMark(markStart, markEnd, markType.create(attrs));
        tr.removeStoredMark(markType);
        return tr;
    });
}
