import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
// based on the example at: https://prosemirror.net/examples/upload/
var uploadPlaceholder = new Plugin({
    state: {
        init: function () {
            return DecorationSet.empty;
        },
        apply: function (tr, set) {
            // Adjust decoration positions to changes made by the transaction
            set = set.map(tr.mapping, tr.doc);
            // See if the transaction adds or removes any placeholders
            var action = tr.getMeta(this);
            if (action && action.add) {
                var element = document.createElement("div");
                element.className = "image placeholder";
                var img = document.createElement("img");
                img.src = URL.createObjectURL(action.add.file);
                element.appendChild(img);
                var deco = Decoration.widget(action.add.pos, element, {
                    id: action.add.id,
                });
                set = set.add(tr.doc, [deco]);
            }
            else if (action && action.remove) {
                set = set.remove(set.find(null, null, function (spec) { return spec.id === action.remove.id; }));
            }
            return set;
        },
    },
    props: {
        decorations: function (state) {
            return this.getState(state);
        },
    },
});
export default uploadPlaceholder;
export function findPlaceholder(state, id) {
    var decos = uploadPlaceholder.getState(state);
    var found = decos.find(null, null, function (spec) { return spec.id === id; });
    return found.length ? found[0].from : null;
}
