var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Fragment } from "react";
import { css } from "@emotion/css";
import { theme } from "../theme";
export var SegmentedControl = function (_a) {
    var segments = _a.segments, onChange = _a.onChange, selectedIndex = _a.selectedIndex;
    var renderSegments = function () {
        return segments.map(function (segment, idx) { return (React.createElement(Fragment, { key: idx },
            React.createElement("li", { className: selectedIndex === idx ? "selected" : undefined, onClick: function () { return onChange(idx); } }, segment))); });
    };
    return (React.createElement("div", { className: cls.segmentedControl },
        React.createElement("ul", null, renderSegments())));
};
var cls = {
    segmentedControl: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    border: 1px solid ", ";\n    border-radius: 8px;\n    padding: 5px;\n    display: inline-block;\n\n    & > ul {\n      display: table-row;\n      display: -webkit-box;\n      display: -ms-flexbox;\n      display: flex;\n    }\n\n    & > ul > li {\n      color: ", ";\n      background: #fff !important;\n      border: 1px solid ", ";\n      border-radius: 8px;\n      font-size: 15px;\n      font-weight: 500;\n      height: 40px;\n      min-width: 138px;\n      cursor: pointer;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      margin-left: 15px;\n    }\n\n    & > ul > li.selected {\n      background: ", " !important;\n      color: #fff !important;\n      border: 1px solid ", ";\n    }\n\n    & > ul > li:first-of-type {\n      margin-left: 0 !important;\n    }\n  "], ["\n    border: 1px solid ", ";\n    border-radius: 8px;\n    padding: 5px;\n    display: inline-block;\n\n    & > ul {\n      display: table-row;\n      display: -webkit-box;\n      display: -ms-flexbox;\n      display: flex;\n    }\n\n    & > ul > li {\n      color: ", ";\n      background: #fff !important;\n      border: 1px solid ", ";\n      border-radius: 8px;\n      font-size: 15px;\n      font-weight: 500;\n      height: 40px;\n      min-width: 138px;\n      cursor: pointer;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      margin-left: 15px;\n    }\n\n    & > ul > li.selected {\n      background: ", " !important;\n      color: #fff !important;\n      border: 1px solid ", ";\n    }\n\n    & > ul > li:first-of-type {\n      margin-left: 0 !important;\n    }\n  "])), theme.color.borderLightGray, theme.color.primary, theme.color.borderLightGray, theme.color.primary, theme.color.primary),
};
var templateObject_1;
