var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { wrappingInputRule } from "prosemirror-inputrules";
import toggleList from "../commands/toggleList";
import Node from "./Node";
var BulletList = /** @class */ (function (_super) {
    __extends(BulletList, _super);
    function BulletList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(BulletList.prototype, "name", {
        get: function () {
            return "bullet_list";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BulletList.prototype, "schema", {
        get: function () {
            return {
                content: "list_item+",
                group: "block",
                parseDOM: [{ tag: "ul" }],
                toDOM: function () { return ["ul", 0]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    BulletList.prototype.commands = function (_a) {
        var type = _a.type, schema = _a.schema;
        return function () { return toggleList(type, schema.nodes.list_item); };
    };
    BulletList.prototype.keys = function (_a) {
        var type = _a.type, schema = _a.schema;
        return {
            "Shift-Ctrl-8": toggleList(type, schema.nodes.list_item),
        };
    };
    BulletList.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [wrappingInputRule(/^\s*([-+*])\s$/, type)];
    };
    BulletList.prototype.toMarkdown = function (state, node) {
        state.renderList(node, "  ", function () { return (node.attrs.bullet || "*") + " "; });
    };
    BulletList.prototype.parseMarkdown = function () {
        return { block: "bullet_list" };
    };
    return BulletList;
}(Node));
export default BulletList;
