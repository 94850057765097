import React, {useEffect} from "react";
import {atom, useRecoilValue} from "recoil";
import {isArray} from "lodash";
import {Modal, Form, Typography, Input} from "antd";

import {SourcePdfNode} from "../../../../model/schemaTypes";
import {formatOutput} from "../../../../services/FileService";
import {
  useSetAtomPropertyValue,
  useUnsetAtomPropertyValue,
} from "../../../../model/editor";
import {createPdfNode} from "../../../../model/pdf/pdfUtils";
import {usePdfMapperModalContext} from "./PdfMapperModalContext";

const {Text} = Typography;

/**
 * Empty 'prototype' pdf node to keep stored as 'edited' when no pdf is edited...
 */
const defaultPdfAtom = atom({
  key: "defaultPdfAtom",
  default: createPdfNode("default", "pdf") as SourcePdfNode,
});

type Values = {
  mappers: ReadonlyArray<object>; // json logic objects
};

export const PdfMapperModal = () => {
  const {editedPdfAtom, resetEditedPdfAtom} = usePdfMapperModalContext();
  const canEdit = !!editedPdfAtom;
  const pdfNode = useRecoilValue(editedPdfAtom ?? defaultPdfAtom);

  const removeMappers = useUnsetAtomPropertyValue(
    editedPdfAtom ?? defaultPdfAtom,
    "mappers",
  );
  const setMappers = useSetAtomPropertyValue(
    editedPdfAtom ?? defaultPdfAtom,
    "mappers",
  );

  return (
    <PdfMappersModalRenderer
      {...pdfNode}
      visible={canEdit}
      onChange={setMappers}
      onRemove={removeMappers}
      onCancel={resetEditedPdfAtom}
    />
  );
};

const PdfMappersModalRenderer = ({
  id,
  src,
  mappers,
  visible,
  onChange,
  onRemove,
  onCancel,
}: SourcePdfNode & {
  visible: boolean;
  onChange: (mappers: ReadonlyArray<object>) => void;
  onRemove: () => void;
  onCancel: () => void;
}) => {
  const [form] = Form.useForm<Values>();

  useEffect(() => form.resetFields(), [form, id]);

  return (
    <Modal
      width={1000}
      maskClosable={false}
      title={
        <>
          Edit mappers for{" "}
          <Text code strong>
            {src}
          </Text>
        </>
      }
      visible={visible}
      okText="Save"
      onOk={async () => {
        await form.validateFields();

        const isValid = form.getFieldError("mappers").length === 0;

        if (isValid) {
          const mappers = form.getFieldValue("mappers");

          /**
           * IF mappers are empty, we remove them from the atom.
           */
          mappers === "" ? onRemove() : onChange(JSON.parse(mappers));
          onCancel();
        }
      }}
      onCancel={onCancel}
    >
      <Form<Values>
        form={form}
        key={id}
        initialValues={{
          mappers: mappers ? formatOutput(mappers) : "",
        }}
      >
        <Form.Item
          name="mappers"
          rules={[
            () => ({
              validator: (_, value) => {
                if (value === "") {
                  return Promise.resolve();
                }

                try {
                  const obj = JSON.parse(value);

                  if (isArray(obj)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error("The object must be of type array."),
                    );
                  }
                } catch {
                  return Promise.reject(
                    new Error("The entered value is not a valid JSON."),
                  );
                }
              },
            }),
          ]}
        >
          <Input.TextArea
            style={{minHeight: 400}}
            placeholder={"Insert your array of json logic objects here."}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
