var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { css } from "@emotion/react";
import { styled } from "../../theme";
export var BlockSlide = function (_a) {
    var darkBackground = _a.darkBackground, verticalContentAlignment = _a.verticalContentAlignment, className = _a.className, children = _a.children;
    return (React.createElement(Wrapper, { darkBackground: darkBackground, className: className },
        verticalContentAlignment !== "top" && (React.createElement(Spacer, { verticalContentAlignment: verticalContentAlignment })),
        children,
        verticalContentAlignment !== "bottom" && (React.createElement(Spacer, { verticalContentAlignment: verticalContentAlignment }))));
};
var Spacer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ",
    "\n  ",
    "\n"])), function (_a) {
    var verticalContentAlignment = _a.verticalContentAlignment;
    return ["bottom", "center"].includes(verticalContentAlignment) && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      margin-top: auto;\n    "], ["\n      margin-top: auto;\n    "])));
}, function (_a) {
    var verticalContentAlignment = _a.verticalContentAlignment;
    return ["top", "center"].includes(verticalContentAlignment) && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin-bottom: auto;\n    "], ["\n      margin-bottom: auto;\n    "])));
});
/**
 * Use this class on slide's children text elements which should change color based on slide background.
 */
export var ADAPTABLE_TEXT_CLASSNAME = "page-appearance-adaptable-text";
var Wrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  background-color: transparent;\n  color: inherit;\n\n  & .", " {\n    color: inherit;\n    overflow-wrap: break-word;\n    word-break: break-word;\n    width: 100%;\n  }\n\n  ", "\n"], ["\n  width: 100%;\n  height: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  background-color: transparent;\n  color: inherit;\n\n  & .", " {\n    color: inherit;\n    overflow-wrap: break-word;\n    word-break: break-word;\n    width: 100%;\n  }\n\n  ",
    "\n"])), ADAPTABLE_TEXT_CLASSNAME, function (_a) {
    var darkBackground = _a.darkBackground;
    return darkBackground && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      & .", " {\n        color: white;\n      }\n    "], ["\n      & .", " {\n        color: white;\n      }\n    "])), ADAPTABLE_TEXT_CLASSNAME);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
