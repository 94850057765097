"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notEmpty = function (value) {
    if (Array.isArray(value)) {
        return value.length > 0;
    }
    if (typeof value === "string") {
        return value.length > 0;
    }
    return !!value;
};
