import React, {useEffect} from "react";
import {RecoilState, useRecoilValue} from "recoil";
import {Alert, Space} from "antd";
import {styled} from "@reside/ui";

import {SourceSlide} from "../../../model/schemaTypes";
import {
  AtomizedBranchNode,
  useActiveSlideAtom,
  useFocusedNode,
} from "../../../model/editor";
import {SlideCard} from "../../../atoms/slide-card";
import {
  CommonComponentGuard,
  ConstantCommonComponent,
} from "../../../atoms/common-component-guard";
import {PublishButton} from "../../../atoms/publish-button";
import {LogoutButton} from "../../../atoms/logout-button";
import {BranchSelect} from "../../../atoms/branch-select";
import {PreviewButton} from "../../../atoms/preview-button";
import {BranchGuardMessage} from "../../../atoms/branch-guard-message";
import {Footer} from "./Footer";
import {SlideForm} from "./SlideForm";
import {useScrollIntoView} from "../../../hooks/useScrollIntoView";
import {
  activeSlideIsConstantCommonImportSelector,
  slideIsCommonImportSelector,
} from "../../../model/library";
import {useIsFacilitySettingsTemplateValue} from "../../../model/template";
import {nodeErrorsSelector, useValidateAtom} from "../../../model/validation";
import {FocusContainer} from "../../../atoms/focus-container";
import {QueryBuilder} from "../../../atoms/query-builder";
import {EditableCtaButton} from "../../../atoms/editable-cta-button";

export const ActiveSlideCard = () => {
  const activeSlideAtom = useActiveSlideAtom();
  const isFacilitySettingsTemplate = useIsFacilitySettingsTemplateValue();
  const node = useFocusedNode();
  const activeSlide = useRecoilValue<SourceSlide & AtomizedBranchNode>(
    activeSlideAtom as any,
  );
  const slideIsCommon = useRecoilValue(slideIsCommonImportSelector);
  const slideIsConstant = useRecoilValue(
    activeSlideIsConstantCommonImportSelector,
  );

  const validateSlide = useValidateAtom(activeSlideAtom);
  const slideErrors = useRecoilValue(nodeErrorsSelector(activeSlide.id));
  const hasSlideErrors = slideErrors.length > 0;

  useScrollIntoView(node?.id);

  useEffect(() => {
    validateSlide();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlide]);

  if (!activeSlide) {
    return null;
  }

  return (
    <SlideCard
      atom={activeSlideAtom}
      infoMessage={activeSlide.infoMessage}
      backgroundUrl={activeSlide.backgroundUrl}
      darkBackground={activeSlide.darkBackground}
      verticalContentAlignment={activeSlide.verticalContentAlignment}
      title={
        <Space>
          <BranchSelect disabled />
          <BranchGuardMessage>
            <Space>
              <PreviewButton />
              <PublishButton />
            </Space>
          </BranchGuardMessage>
        </Space>
      }
      extra={<LogoutButton />}
      footer={
        activeSlideAtom ? (
          <FocusContainer atom={activeSlideAtom}>
            {({isFocused}) => (isFocused ? <QueryBuilder /> : <></>)}
          </FocusContainer>
        ) : null
      }
    >
      <>
        {slideIsConstant ? (
          <ConstantCommonComponent
            disabled
            tooltipTitle="Slide is constant; cannot be edited"
            text="Constant common slide."
          >
            <SlideForm
              parentAtom={activeSlideAtom}
              atoms={activeSlide.children}
            />
          </ConstantCommonComponent>
        ) : (
          <CommonComponentGuard
            atom={activeSlideAtom}
            disabled={slideIsCommon}
            inlineButtonText="Inline common slide"
            tooltipTitle="This slide is common (library) slide which means it is reused in multiple templates. Common slide cannot be edited directly, you must 'inline' it in your template first."
            text="Common slide"
          >
            <SlideForm
              parentAtom={activeSlideAtom}
              atoms={activeSlide.children}
            />
          </CommonComponentGuard>
        )}
        {activeSlide.ctaButton && !isFacilitySettingsTemplate && (
          <>
            <NavBarBottomWrapper>
              <Footer>
                <EditableCtaButton
                  atom={activeSlideAtom as RecoilState<SourceSlide>}
                />
              </Footer>
            </NavBarBottomWrapper>

            {hasSlideErrors &&
              slideErrors.map((error, key) => (
                <Alert
                  key={key}
                  showIcon={false}
                  type="error"
                  message={error}
                  banner
                />
              ))}
          </>
        )}
      </>
    </SlideCard>
  );
};

const NavBarBottomWrapper = styled.div`
  z-index: 2;
  width: 100%;
`;
