import React from "react";
import {Form, Radio} from "antd";
import {Icon, styled} from "@reside/ui";
import {ListNode} from "@reside/forms";
import {StopOutlined} from "@ant-design/icons";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";

// https://developer.mozilla.org/en-US/docs/Web/CSS/list-style-type
export const ListStyleTypeAttribute = () => {
  const node = useFocusedNode() as ListNode;
  const setListStyleType = useSetFocusedNodePropertyValue("listStyleType", {
    replace: true,
  });

  return (
    <Form.Item name="listStyleType" label="List Style Type">
      <Radio.Group
        onChange={event => {
          event.target.value === ""
            ? setListStyleType(undefined)
            : setListStyleType(event.target.value);
        }}
        value={node.listStyleType}
      >
        <VerticalRadio value="">
          <Icon name="editor-unordered-list" size={14} /> bullets
        </VerticalRadio>
        <VerticalRadio value="decimal">
          <Icon name="editor-ordered-list" size={14} /> decimal
        </VerticalRadio>
        <VerticalRadio value="lower-latin">
          <TextIcon>a.</TextIcon> lower-latin
        </VerticalRadio>
        <VerticalRadio value="upper-latin">
          <TextIcon>A.</TextIcon> upper-latin
        </VerticalRadio>
        <VerticalRadio value="lower-roman">
          <TextIcon>ⅰ.</TextIcon> lower-roman
        </VerticalRadio>
        <VerticalRadio value="upper-roman">
          <TextIcon>Ⅰ.</TextIcon> upper-roman
        </VerticalRadio>
        <VerticalRadio value="none">
          <StopOutlined /> none
        </VerticalRadio>
      </Radio.Group>
    </Form.Item>
  );
};

const VerticalRadio = (props: any) => (
  <Radio {...props} style={{display: "block"}} />
);

const TextIcon = styled.span`
  padding-left: 4px;
  text-align: center;
  font-family: monospace;
`;
