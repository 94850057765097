import React from "react";
import {Form, Input} from "antd";
import {v4} from "uuid";
import {get} from "lodash";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {isFieldArray, isFormControl} from "../../utils";

export const AnswerNameInput = ({propertyPath}: {propertyPath: string}) => {
  const node = useFocusedNode() as any;
  const setId = useSetFocusedNodePropertyValue(propertyPath);
  const id = get(node, propertyPath);

  if (!node || (!isFormControl(node) && !isFieldArray(node))) {
    return null;
  }

  return (
    <Form.Item name="id" label="ID" style={{margin: 0}}>
      <Input
        defaultValue={id}
        placeholder="Variable ID"
        onBlur={event => setId(event.currentTarget.value || v4())}
      />
    </Form.Item>
  );
};

AnswerNameInput.defaultProps = {
  propertyPath: "reference.id",
};
