import {atom, selector} from "recoil";

import {reservedBranchNames} from "../../config";
import {GitConfigUser} from "../../services/GitService";
import {userProfileAtom} from "../auth";

/**
 * ATOMS
 */
export const isRepoInitializedAtom = atom({
  key: "isRepoInitialized",
  default: false,
});

export const userConfigSelector = selector<GitConfigUser>({
  key: "userConfigSelector",
  get: ({get}) => {
    const user = get(userProfileAtom)!;

    return {
      name: user.name || user.login,
      email: user.email!,
    };
  },
});

/**
 * Current branch name read from the git client.
 */
export const branchNameAtom = atom({
  key: "branchName",
  default: "",
});

/**
 * SELECTORS
 */
/**
 * When the branch name is set, it means that we have existing git clone.
 */
export const isRepositoryClonedSelector = selector({
  key: "isRepositoryCloned",
  get: ({get}) => !!get(branchNameAtom),
});

/**
 * Important check to distinguish if the user has checked out feature branch.
 */
export const isFeatureBranchSelector = selector({
  key: "isFeatureBranch",
  get: ({get}) => isFeatureBranch(get(branchNameAtom)),
});

export const modifiedFilesAtom = atom<ReadonlyArray<string>>({
  key: "modifiedFiles",
  default: [],
});

export const hasModifiedFilesSelector = selector({
  key: "hasModifiedFiles",
  get: ({get}) => get(modifiedFilesAtom).length > 0,
});

export const isFeatureBranch = (branchName: string) =>
  !reservedBranchNames.includes(branchName);
