import React from "react";
import {styled} from "@reside/ui";

export const ResideLogo = () => (
  <Image alt="reside logo" src={require("../../assets/reside-logo.svg")} />
);

const Image = styled.img`
  filter: grayscale(100%);

  &:hover {
    filter: none;
  }
`;
