import React from "react";
import Editor, {AnswerType} from "@reside/markdown-editor";

export type TreeDataItem = {
  id: string;
  title: string;
  value: string;
  selectable: boolean;
  children?: TreeDataItem[];
};

export type Props = Readonly<{
  id?: string;
  defaultValue?: string;
  answers?: Record<string, AnswerType>;
  treeData: TreeDataItem[];
  onChange: (value: () => string) => void;
  hasTopMenu?: boolean;
  children?: typeof Editor.defaultProps.children;
}>;

export const MarkdownEditor = ({
  id,
  defaultValue,
  answers,
  treeData,
  onChange,
  hasTopMenu,
  children,
  ...props
}: Props) => (
  <Editor
    id={id}
    readOnlyWriteCheckboxes
    defaultValue={defaultValue}
    answers={answers}
    treeData={treeData}
    onChange={onChange}
    hasTopMenu={hasTopMenu}
    autoFocus
    children={children}
    {...props}
  />
);

MarkdownEditor.defaultProps = {
  children: Editor.defaultProps.children,
};
