import React, {
  createContext,
  useContext,
  useState,
  FunctionComponent,
} from "react";
import {RecoilState} from "recoil";
import {SourcePdfNode} from "../../../../model/schemaTypes";

type ContextValue = {
  editedPdfAtom: RecoilState<SourcePdfNode> | undefined;
  setEditedPdfAtom: (atom: RecoilState<SourcePdfNode>) => void;
  resetEditedPdfAtom: () => void;
};

const PdfMapperModalContext = createContext<ContextValue>({
  editedPdfAtom: undefined,
  setEditedPdfAtom: (atom: RecoilState<SourcePdfNode>) => {},
  resetEditedPdfAtom: () => {},
});

export const usePdfMapperModalContext = () => useContext(PdfMapperModalContext);

/**
 * TODO: Replace the context with recoil atom
 * The context was used in order to not have the atom changes in history which would open/close modal on undo/redo.
 */
export const PdfMapperModalProvider: FunctionComponent = ({children}) => {
  const [editedPdfAtom, setEditedPdfAtom] = useState<
    ContextValue["editedPdfAtom"]
  >(undefined);

  return (
    <PdfMapperModalContext.Provider
      value={{
        editedPdfAtom,
        setEditedPdfAtom,
        resetEditedPdfAtom: () => setEditedPdfAtom(undefined),
      }}
    >
      {children}
    </PdfMapperModalContext.Provider>
  );
};
