var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { styled } from "../theme";
import { Input } from "../form/atoms/TextualInput";
export var Multiselect = function (_a) {
    var options = _a.options, selected = _a.selected, onChange = _a.onChange, name = _a.name, emptyMessage = _a.emptyMessage, disabled = _a.disabled, testId = _a.testId, maxSelected = _a.maxSelected, placeholder = _a.placeholder;
    var _b = useState(""), value = _b[0], setValue = _b[1];
    var _c = useState(false), showSuggestions = _c[0], setShowSuggestions = _c[1];
    var onInputFocus = function () { return setShowSuggestions(true); };
    var clearInput = function () { return setValue(""); };
    var onRemoveClicked = function (option) {
        return onChange(selected.filter(function (s) { return s !== option.value; }));
    };
    var onSelectOption = function (option) {
        clearInput();
        onChange(__spreadArrays(selected, [option.value]));
    };
    var getAvailableOptions = function () {
        return options.filter(function (option) {
            return !selected.includes(option.value) &&
                option.label.toLowerCase().includes(value.toLowerCase());
        });
    };
    var handleOutsideClick = function () { return setShowSuggestions(false); };
    var renderSelected = function () {
        return selected
            .map(function (s) { return options.find(function (o) { return o.value === s; }); })
            .filter(function (option) { return option; })
            .map(function (option) { return (React.createElement(SelectedOption, { key: option.value },
            option.label,
            !disabled && (React.createElement(CloseTag, { onClick: function () { return onRemoveClicked(option); } }, "x")))); });
    };
    var availableOptions = getAvailableOptions();
    var isMaxSelectedNotReached = !maxSelected || selected.length < maxSelected;
    return (React.createElement(FullWidth, null,
        React.createElement(OutsideClickHandler, { onOutsideClick: handleOutsideClick },
            React.createElement(SelectedWrapper, null,
                renderSelected(),
                isMaxSelectedNotReached && !disabled && (React.createElement(StyledInput, { name: name, placeholder: placeholder, onChange: function (e) {
                        setValue(e.currentTarget.value);
                    }, onFocus: onInputFocus, value: value }))),
            showSuggestions && isMaxSelectedNotReached && (React.createElement(OptionsWrapper, { "data-test-id": name + "-drawer" },
                availableOptions.map(function (option) { return (React.createElement(Option, { key: option.value, onClick: function () { return onSelectOption(option); }, "data-test-id": testId }, option.label)); }),
                availableOptions.length === 0 && (React.createElement(EmptyMessage, { "data-test-id": testId }, emptyMessage)))))));
};
Multiselect.defaultProps = {
    emptyMessage: "No available options",
    placeholder: "Start typing here",
};
var FullWidth = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var SelectedWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  padding: 10px 0;\n  border-bottom: 1px solid ", ";\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  padding: 10px 0;\n  border-bottom: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue10;
});
var SelectedOption = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: 12px 15px;\n  border-radius: 20px;\n  color: ", ";\n  margin: 2px 10px 2px 2px;\n  display: flex;\n  align-items: center;\n"], ["\n  background-color: ", ";\n  padding: 12px 15px;\n  border-radius: 20px;\n  color: ", ";\n  margin: 2px 10px 2px 2px;\n  display: flex;\n  align-items: center;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue100;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.white;
});
var StyledInput = styled(Input)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  width: auto;\n  min-width: 250px;\n  display: flex;\n  background-color: transparent;\n  font-size: 16px;\n"], ["\n  flex: 1;\n  width: auto;\n  min-width: 250px;\n  display: flex;\n  background-color: transparent;\n  font-size: 16px;\n"])));
var OptionsWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 3px;\n  background-color: white;\n  box-shadow: 0 1px 4px 0 rgba(143, 142, 148, 0.3);\n  border-style: solid;\n  border-width: 0.5px;\n  max-height: 230px;\n  overflow: auto;\n"], ["\n  margin-top: 3px;\n  background-color: white;\n  box-shadow: 0 1px 4px 0 rgba(143, 142, 148, 0.3);\n  border-style: solid;\n  border-width: 0.5px;\n  max-height: 230px;\n  overflow: auto;\n"])));
var Option = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n  padding: 20px 20px;\n  border-bottom: 0.5px solid rgba(143, 142, 148, 0.3);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  cursor: pointer;\n  padding: 20px 20px;\n  border-bottom: 0.5px solid rgba(143, 142, 148, 0.3);\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.gray100;
});
var EmptyMessage = styled(Option)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  cursor: default;\n  &:hover {\n    background-color: transparent;\n  }\n"], ["\n  cursor: default;\n  &:hover {\n    background-color: transparent;\n  }\n"])));
var CloseTag = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  cursor: pointer;\n  margin-left: 10px;\n"], ["\n  cursor: pointer;\n  margin-left: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
