var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { DecorationSet, Decoration } from "prosemirror-view";
import { Plugin } from "prosemirror-state";
import { isColumnSelected, getCellsInRow } from "prosemirror-utils";
import Node from "./Node";
var TableHeadCell = /** @class */ (function (_super) {
    __extends(TableHeadCell, _super);
    function TableHeadCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TableHeadCell.prototype, "name", {
        get: function () {
            return "th";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableHeadCell.prototype, "schema", {
        get: function () {
            return {
                content: "paragraph+",
                tableRole: "header_cell",
                isolating: true,
                parseDOM: [{ tag: "th" }],
                toDOM: function (node) {
                    return [
                        "th",
                        node.attrs.alignment
                            ? { style: "text-align: " + node.attrs.alignment }
                            : {},
                        0,
                    ];
                },
                attrs: {
                    colspan: { default: 1 },
                    rowspan: { default: 1 },
                    alignment: { default: null },
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    TableHeadCell.prototype.toMarkdown = function (state, node) {
        state.renderContent(node);
    };
    TableHeadCell.prototype.parseMarkdown = function () {
        return {
            block: "th",
            getAttrs: function (tok) { return ({ alignment: tok.info }); },
        };
    };
    Object.defineProperty(TableHeadCell.prototype, "plugins", {
        get: function () {
            var _this = this;
            return [
                new Plugin({
                    props: {
                        decorations: function (state) {
                            var doc = state.doc, selection = state.selection;
                            var decorations = [];
                            var cells = getCellsInRow(0)(selection);
                            if (cells) {
                                cells.forEach(function (_a, index) {
                                    var pos = _a.pos;
                                    decorations.push(Decoration.widget(pos + 1, function () {
                                        var colSelected = isColumnSelected(index)(selection);
                                        var className = "grip-column";
                                        if (colSelected) {
                                            className += " selected";
                                        }
                                        if (index === 0) {
                                            className += " first";
                                        }
                                        else if (index === cells.length - 1) {
                                            className += " last";
                                        }
                                        var grip = document.createElement("a");
                                        grip.className = className;
                                        grip.addEventListener("mousedown", function (event) {
                                            event.preventDefault();
                                            _this.options.onSelectColumn(index, state);
                                        });
                                        return grip;
                                    }));
                                });
                            }
                            return DecorationSet.create(doc, decorations);
                        },
                    },
                }),
            ];
        },
        enumerable: true,
        configurable: true
    });
    return TableHeadCell;
}(Node));
export default TableHeadCell;
