import React from "react";
import {DateField, DateFieldProps} from "@reside/ui";
import {Form} from "antd";

import {LabelAttribute} from "../label-attribute";
import {DisabledAttribute} from "../disabled-attribute";
import {RequiredAttribute} from "../required-attribute";
import {AtomProp, useFocusedNode} from "../../model/editor";
import {isDateField} from "../../utils";
import {DisableClick} from "../disable-click";

export const EditableDateField = ({
  atom,
  ...props
}: DateFieldProps & {id: string} & AtomProp) => (
  <DisableClick>
    <DateField {...props} />
  </DisableClick>
);

/**
 * FormControl type-specific form to adjust attributes.
 */
export const DateFieldForm = () => {
  const node = useFocusedNode();

  if (!node || !isDateField(node)) {
    return null;
  }

  return (
    <Form
      key={node.id}
      initialValues={{
        label: node?.reference?.translationKey,
      }}
    >
      <LabelAttribute />
      <DisabledAttribute />
      <RequiredAttribute />
    </Form>
  );
};
