import {
  DropTargetMonitor,
  useDrag,
  useDrop,
  DragSourceMonitor,
} from "react-dnd";
import {RecoilState, useRecoilValue} from "recoil";

import {DragType} from "../constants";
import {
  AtomizedFormControlSourceNode,
  AtomProp,
  useSetAtomPropertyValue,
} from "../model/editor";
import {moveItem} from "../utils";
import {TemplateNodes} from "../model/schemaTypes";

type DragChoiceItem = Readonly<{
  type: DragType;
  choiceId: string;
  index: number;
  atom: RecoilState<TemplateNodes>;
  parent: TemplateNodes;
  setParentNode: (node: TemplateNodes) => void;
}>;

export const useDragAndDropChoices = ({
  index,
  atom,
}: {
  index: number;
} & AtomProp) => {
  const setChoices = useSetAtomPropertyValue(atom, "reference.choices");
  const parent = useRecoilValue(atom) as AtomizedFormControlSourceNode;

  const [, dropRef] = useDrop({
    accept: [DragType.MOVE_CHOICES],
    drop: (item: DragChoiceItem, monitor: DropTargetMonitor) => {
      if (monitor.didDrop()) {
        return;
      }

      if (atom.key === item.atom.key) {
        // Only handle moving of choices inside of the same node

        setChoices(
          moveItem(parent.reference.choices as any, item.index, index),
        );
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver(),
    }),
  });

  const [{isDragging}, dragRef] = useDrag({
    item: {
      type: DragType.MOVE_CHOICES,
      index,
      atom,
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return {dropRef, dragRef, isDragging};
};
