var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { css } from "@emotion/css";
import { Text } from "../text";
import { Icon } from "../icon";
import { ThemeConsumer } from "../theme";
import { Box } from "../box";
export var CompletionChecklist = function (_a) {
    var completionChecklist = _a.completionChecklist, renderText = _a.renderText;
    return (React.createElement("div", null, completionChecklist.map(function (checklist) { return (React.createElement(Box, { key: checklist.id, flexDirection: "row", alignItems: "center", className: "completionchecklist-0-56" },
        React.createElement(ThemeConsumer, null, function (theme) { return (React.createElement("i", { className: sIcon, style: {
                border: "1px solid " + theme.color.primary,
                backgroundColor: "" + (checklist.complete ? theme.color.primary : "transparent"),
            } }, checklist.complete && (React.createElement(Icon, { name: "check", color: "white", size: "0.8em" })))); }),
        React.createElement(Text, { fontSize: "1.25em", lineHeight: "1.75em", className: "completionchecklist-0-57" }, renderText(checklist.text)))); })));
};
var sIcon = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: 1.5em;\n  width: 1.5em;\n  min-height: 1.5em;\n  height: 1.5em;\n  border-radius: 50%;\n  margin-right: 1.125em;\n  padding-top: 0.2em;\n  display: flex;\n  justify-content: center;\n"], ["\n  min-width: 1.5em;\n  width: 1.5em;\n  min-height: 1.5em;\n  height: 1.5em;\n  border-radius: 50%;\n  margin-right: 1.125em;\n  padding-top: 0.2em;\n  display: flex;\n  justify-content: center;\n"])));
var templateObject_1;
