import React, {ReactNode, memo} from "react";
import {styled} from "@reside/ui";
import {Card, Typography} from "antd";
import {useDrag, DragSourceMonitor} from "react-dnd";
import {blue} from "@ant-design/colors";

import {NodeName} from "../../node-name";
import {DragType} from "../../../constants";
import {SourceNodes} from "../../../model/schemaTypes";

type Props = Readonly<{
  index: number;
  title: ReactNode;
  create: () => SourceNodes | ReadonlyArray<SourceNodes>;
}>;

export const BlockPaletteItem = memo(({title, index, create}: Props) => {
  const nodeOrNodes = create();

  const node = Array.isArray(nodeOrNodes) ? nodeOrNodes[0] : nodeOrNodes;

  const [{isDragging}, dragRef] = useDrag({
    item: {type: DragType.CREATE, index},
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <PaletteItem ref={dragRef}>
      <Typography.Text type="secondary" style={{marginBottom: 8}}>
        <NodeName node={node} />
      </Typography.Text>
      <StyledCard hoverable isDragging={isDragging}>
        <Card.Meta title={title} />
      </StyledCard>
    </PaletteItem>
  );
});

const StyledCard = styled(Card, {
  shouldForwardProp: props => props !== "isDragging",
})<{
  isDragging: boolean;
}>`
  margin-top: 8px;
  padding: 4px;
  cursor: move;
  opacity: ${({isDragging}) => (isDragging ? 0.5 : 1)};

  div {
    margin-left: 3px;
    margin-right: 3px;
    padding: 0;
  }

  h1 {
    margin: 0;
  }
`;

const PaletteItem = styled.div`
  margin: 20px;

  &:hover {
    .ant-typography-secondary {
      color: ${blue.primary};
    }
  }
`;
