import React from "react";
import {Form} from "antd";
import {SnapshotControlProps, SnapshotControlField} from "@reside/ui";

import {DisabledAttribute} from "../disabled-attribute";
import {RequiredAttribute} from "../required-attribute";
import {AtomProp, useFocusedNode} from "../../model/editor";
import {
  getInitialMaxFileSizeFormValue,
  MaxFileSizeAttribute,
} from "../max-file-size-attribute";
import {DisableClick} from "../disable-click";
import {FormControlSourceNode} from "../../model/schemaTypes";
import {EditableLabelAttribute} from "../editable-label-attribute";

export const EditableImageField = ({
  atom,
  ...props
}: AtomProp & SnapshotControlProps & {id: string}) => (
  <>
    <EditableLabelAttribute atom={atom} />
    <DisableClick>
      <SnapshotControlField {...props} label={undefined} />
    </DisableClick>
  </>
);

/**
 * FormControl type-specific form to adjust attributes.
 */
export const ImageFieldForm = () => {
  const node = useFocusedNode() as FormControlSourceNode;

  return (
    <Form
      key={node.id}
      initialValues={{
        ...getInitialMaxFileSizeFormValue(node),
      }}
    >
      <DisabledAttribute />
      <RequiredAttribute />
      <MaxFileSizeAttribute />
    </Form>
  );
};
