var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../../../theme";
export var ItemLabel = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  flex-direction: row;\n  padding-top: 5px;\n  padding-left: 5px;\n  margin-bottom: 0.625em;\n  border: 1px solid transparent;\n  cursor: pointer;\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n\n  &:focus {\n    border-color: ", ";\n    outline: none;\n  }\n\n  ", "\n"], ["\n  display: inline-flex;\n  flex-direction: row;\n  padding-top: 5px;\n  padding-left: 5px;\n  margin-bottom: 0.625em;\n  border: 1px solid transparent;\n  cursor: pointer;\n\n  &:last-of-type {\n    margin-bottom: 0;\n  }\n\n  &:focus {\n    border-color: ", ";\n    outline: none;\n  }\n\n  ",
    "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.lightBlue;
}, function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "\n    cursor: not-allowed;\n\n    &[disabled] span {\n      opacity: 0.5;\n\n      span {\n        opacity: 1;\n      }\n    }\n\n    &[disabled] * {\n      cursor: not-allowed;\n    }\n  ";
});
var templateObject_1;
