import React from "react";
import { format, isValid, parseISO } from "date-fns";
import { jsonLogic } from "@reside/forms";
import { DATE_FORMAT } from "../../date-picker/date";
import { prop } from "../template";
import { Text } from "../../text";
export var DateRenderer = function (_a) {
    var values = _a.values, value = _a.value, _b = _a.format, dateFormat = _b === void 0 ? DATE_FORMAT.FULL_DATE : _b;
    try {
        var date = parseISO(prop(values, value, value));
        var parsed = isValid(date)
            ? date
            : jsonLogic.apply(JSON.parse(value.replace(/`/gi, "")), values);
        // The `` is a work-around, without it, the value would be passed as object and broken into different properties
        // or as invalid string
        return (React.createElement("span", null, isValid(parsed)
            ? format(parsed, dateFormat)
            : "ERR_INVALID_DATE_LOGIC"));
    }
    catch (_c) {
        return React.createElement(Text, { color: "deepOrange100" },
            "ERR_INVALID_DATE_VALUE - ",
            value);
    }
};
