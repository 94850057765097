import {readEnvVariable} from "./utils";

export const readRepositoryConfig = () => {
  const gitUrl = readEnvVariable("CONTENT_GIT_URL");
  const [repo, owner] = gitUrl
    .substr(0, gitUrl.length - ".git".length)
    .split("/")
    .reverse();

  return {repo, owner};
};

export const repositoryConfig = readRepositoryConfig();

export const isEnv = (env: string) => window.location.origin.includes(env);

export const readBranchConfig = () => {
  const isProd = () =>
    !process.env.REACT_APP_CONTENT_GIT_URL?.includes("content-test");
  return isProd()
    ? {
        previewEnvName: "staging",
        previewAppUrl: "https://staging.resideadmissions.com/admin",
        publishEnvName: "production",
        publishAppUrl: "https://app.resideadmissions.com/admin",
      }
    : {
        previewEnvName: "develop",
        previewAppUrl: "https://develop.resideadmissions.com/admin",
        publishEnvName: "test",
        publishAppUrl: "https://test.resideadmissions.com/admin",
      };
};

export const branchConfig = readBranchConfig();

export const previewBranchName = "develop";
export const publishBranchName = "master";

export const reservedBranchNames = [previewBranchName, publishBranchName];

// eslint-disable-next-line no-console
console.table({...branchConfig, ...repositoryConfig});
