import React from "react";
import {Icon, styled} from "@reside/ui";
import {GroupNodeType, NodeType, PdfNode, QuestionType} from "@reside/forms";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DollarCircleOutlined,
  EditOutlined,
  FieldStringOutlined,
  FileImageOutlined,
  FlagOutlined,
  FolderOutlined,
  FormOutlined,
  PhoneOutlined,
  QuestionOutlined,
  SaveOutlined,
  UploadOutlined,
  ExpandAltOutlined,
  AppstoreOutlined,
  UserOutlined,
  BorderOutlined,
  MailOutlined,
  DatabaseOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import {TemplateNodes} from "../../model/schemaTypes";

/**
 * Given block type, will render the block specific icon.
 */
export const NodeIcon = ({node}: {node: TemplateNodes | PdfNode}) => {
  const renderIcon = () => {
    switch (node.type) {
      case NodeType.TABLE_OF_CONTENT:
        return <Icon name="index" size={14} />;
      case NodeType.TITLE:
        return <Icon name="editor-headline-main" size={14} />;
      case NodeType.SUBTITLE:
        return <Icon name="editor-headline-secondary" size={14} />;
      case NodeType.CONTINUE_BUTTON:
        return <SaveOutlined />;
      case NodeType.PARAGRAPH:
        return <Icon name="options-paragraph" size={14} />;
      case NodeType.IMAGE:
        return <Icon name="editor-image" size={14} />;
      case NodeType.COMPLETION_CHECKLIST:
        return <CheckCircleOutlined />;
      case NodeType.CARD:
        return <BorderOutlined />;
      case NodeType.COLLAPSIBLE:
        return <ExpandAltOutlined />;
      case NodeType.FRAGMENT:
        return <AppstoreOutlined />;
      case NodeType.FIELD_ARRAY:
        return <DatabaseOutlined />;
      case NodeType.FORM_CONTROL:
        switch (node.reference.type) {
          case QuestionType.TEXT:
            switch (node.format) {
              case "ssn":
                return <UserOutlined />;
              case "zip-code":
                return <Icon name="town-hall" size={14} />;
              case "us-phone":
                return <PhoneOutlined />;
              default:
                switch (node.keyboardType) {
                  case "email":
                    return <MailOutlined />;
                  default:
                    return <FieldStringOutlined />;
                }
            }
          case QuestionType.CHECKBOX:
            return <Icon name="options-checkboxes" size={14} />;
          case QuestionType.RADIO:
            return <Icon name="options-multiple-choice" size={14} />;
          case QuestionType.CURRENCY:
            return <DollarCircleOutlined />;
          case QuestionType.SELECT:
            return <Icon name="options-dropdowns" size={14} />;
          case QuestionType.STATE_SELECT:
            return <FlagOutlined />;
          case QuestionType.TIMEZONE_SELECT:
            return <ClockCircleOutlined />;
          case QuestionType.DATE:
            return <Icon name="calendar" size={14} />;
          case QuestionType.TEXTAREA:
            return <Icon name="options-short-answer" size={14} />;
          case QuestionType.IMAGE:
            return <FileImageOutlined />;
          case QuestionType.SIGNATURE:
            return <EditOutlined />;
          case QuestionType.FILE:
            return <UploadOutlined />;
          default:
            return <QuestionOutlined style={{color: "red"}} />;
        }
      case NodeType.LIST:
        return node.ordered === true || node.listStyleType ? (
          <Icon name="editor-ordered-list" size={14} />
        ) : (
          <Icon name="editor-unordered-list" size={14} />
        );
      case NodeType.COLUMN:
        return <Icon name="grid" />;
      case GroupNodeType.SLIDE:
        return <FormOutlined />;
      case GroupNodeType.SECTION:
        return (
          <div style={{position: "relative", left: -6}}>
            <Icon name="reports" size={14} />
          </div>
        );
      case GroupNodeType.SECTION_GROUP:
        return <FolderOutlined />;
      case "pdf":
        return <FilePdfOutlined />;
      default:
        return <QuestionOutlined style={{color: "red"}} />;
    }
  };

  return <Container>{renderIcon()}</Container>;
};

const Container = styled.div`
  display: inline-block;
  text-align: center;
  width: 24px;
  margin-right: 8px;
`;
