import {useMutation} from "react-query";
import {useSetRecoilState} from "recoil";
import {message} from "antd";

import {
  createCommit,
  listModifiedFiles,
  pushBranch,
  stageAll,
} from "../../services/GitService";
import {logError} from "../../logging";
import {modifiedFilesAtom} from "./RepositoryModel";

export const usePushCommitMutation = () => {
  const setModifiedFiles = useSetRecoilState(modifiedFilesAtom);

  return useMutation(
    async () => {
      await stageAll();
      await createCommit();
      await pushBranch();
    },
    {
      onSuccess: async (_, {onSuccess}: any) => {
        onSuccess();
        setModifiedFiles(await listModifiedFiles());
        message.info("Feature branch updated.");
      },
      onError: (error: any) => {
        logError(error);
        message.error(error?.message ?? "Failed to create & push commit.");
      },
    },
  );
};
