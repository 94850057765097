import React, {Component} from "react";
import {useRecoilValue} from "recoil";
import {Select} from "antd";

import {
  availableTemplatesAtom,
  templateNameAtom,
  useSwitchTemplate,
} from "../../model/template";

const {Option} = Select;

export const TemplatePicker = () => (
  <ErrorBoundary>
    <TemplatePickerSelect />
  </ErrorBoundary>
);

const TemplatePickerSelect = () => {
  const templateName = useRecoilValue(templateNameAtom);
  const availableTemplates = useRecoilValue(availableTemplatesAtom);
  const switchTemplate = useSwitchTemplate();

  return (
    <Select
      size="large"
      style={{width: "100%", fontWeight: "bold"}}
      value={templateName}
      onChange={templateName => switchTemplate(templateName)}
    >
      {availableTemplates.map(value => (
        <Option key={value} value={value}>
          {value}
        </Option>
      ))}
    </Select>
  );
};

class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return <h1>Failed to load template versions.</h1>;
    }

    return this.props.children;
  }
}
