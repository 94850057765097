import React from "react";
import {styled} from "@reside/ui";
import {Tabs} from "antd";

import {BlockPalette} from "../../../atoms/block-palette";
import {ComponentLibraryPanel} from "../../../atoms/component-library-panel";

export const ComponentTabs = () => (
  <StyledTabs defaultActiveKey="block-palette">
    <Tabs.TabPane tab="Components" key="block-palette">
      <BlockPalette />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Library components" key="component-library">
      <ComponentLibraryPanel />
    </Tabs.TabPane>
  </StyledTabs>
);

const StyledTabs = styled(Tabs)`
  overflow: hidden;
  flex: 1;

  .ant-tabs-nav {
    padding: 0 20px;
  }

  .ant-tabs-content-holder {
    display: flex;
  }
  .ant-tabs-tabpane {
    overflow-y: scroll;
  }
`;
