import React from "react";
import {useState} from "react";

import {styled} from "@reside/ui";
import {Input} from "antd";

import {BlockPaletteItem} from "./block-palette-item";
import {blocks} from "./constants";

export const BlockPalette = () => {
  const [text, setText] = useState("");

  return (
    <Wrapper>
      <SearchBarWrapper>
        <BlockPaletteSearch
          allowClear
          placeholder="Search component"
          onChange={e => setText(e.target.value)}
        />
      </SearchBarWrapper>
      {blocks
        .filter(block =>
          block.titleText.toUpperCase().includes(text.toUpperCase()),
        )
        .map(block => {
          const index = blocks.indexOf(block);
          return <BlockPaletteItem key={index} {...block} index={index} />;
        })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: inherit;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: ${({theme}) => theme.color.white};
`;

const BlockPaletteSearch = styled(Input.Search)`
  margin: 0 20px 10px;
`;
