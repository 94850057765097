import React, {useEffect, HTMLAttributes} from "react";

export const useScrollIntoView = (elementId?: string) => {
  useEffect(() => {
    if (elementId) {
      const element = document.getElementById(`${elementId}-scrollTarget`);

      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [elementId]);
};

export const ScrollTarget = ({
  children,
  id,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div {...props} id={`${id}-scrollTarget`}>
    {children}
  </div>
);
