import React, {FunctionComponent} from "react";
import {Tooltip, Typography} from "antd";
import {useRecoilValue} from "recoil";

import {branchNameAtom, isFeatureBranchSelector} from "../../model/repository";

const {Text} = Typography;

/**
 * The guard prevents the user to preview/publish changes in one of the reserved branch names.
 */
export const BranchGuardMessage: FunctionComponent = ({children}) => {
  const branchName = useRecoilValue(branchNameAtom);
  const isFeatureBranch = useRecoilValue(isFeatureBranchSelector);

  return isFeatureBranch ? (
    <>{children}</>
  ) : (
    <Tooltip title="You must first create a branch in order to publish changes.">
      <Text type="secondary">
        Can't publish to branch <Text code>{branchName}</Text>.
      </Text>
    </Tooltip>
  );
};
