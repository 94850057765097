import React from "react";
import { Icon } from "../../icon";
import { theme } from "../../theme";
// TODO(drop): probably can be dropped as it's not used
export var IconRenderer = function (_a) {
    var name = _a.name, color = _a.color;
    return (React.createElement(Icon, { name: name, color: color }));
};
IconRenderer.defaultProps = {
    color: theme.color.primary,
};
