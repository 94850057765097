import React, {FunctionComponent} from "react";
import {useRecoilState, useRecoilCallback, RecoilState} from "recoil";
import {Space, Tooltip, Button} from "antd";
import {StopOutlined} from "@ant-design/icons";
import {styled} from "@reside/ui";

import {TemplateNodes} from "../../model/schemaTypes";
import {atomizedTemplateAtom} from "../../model/template";
import {activeSlidePathAtom, AtomProp} from "../../model/editor";
import {AlertWindow} from "../alert-window";
import {isSlideNode} from "../../utils";

export const CommonComponentGuard: FunctionComponent<
  {
    tooltipTitle: string;
    text: string;
    inlineButtonText: string;
    disabled: boolean;
  } & AtomProp
> = ({children, atom, tooltipTitle, text, inlineButtonText, disabled}) => {
  const [node, setNode] = useRecoilState(atom);

  const handleInlineClick = () => {
    if (isSlideNode(node)) {
      inlineSlide();
    } else {
      const {importedFrom, ...inlineNode} = node;
      setNode(inlineNode);
    }
  };

  const inlineSlide = useRecoilCallback(({set, snapshot}) => async () => {
    const template = await snapshot.getPromise(atomizedTemplateAtom);
    const [
      sectionGroupIndex,
      sectionIndex,
      slideIndex,
    ] = await snapshot.getPromise(activeSlidePathAtom);

    const inlineAtom = (
      atomValue: TemplateNodes,
      atom: RecoilState<TemplateNodes>,
    ) => {
      if (atomValue.importedFrom) {
        set(atom, {
          ...atomValue,
          importedFrom: {...atomValue.importedFrom, inherit: true},
        });
      }
    };

    const sectionGroupAtom = template[sectionGroupIndex];
    const sectionGroupAtomValue = await snapshot.getPromise(sectionGroupAtom);
    inlineAtom(sectionGroupAtomValue, sectionGroupAtom);

    const sectionAtom = sectionGroupAtomValue.children[sectionIndex];
    const sectionAtomValue = await snapshot.getPromise<TemplateNodes>(
      sectionAtom,
    );
    inlineAtom(sectionAtomValue, sectionAtom);

    const slideAtom = sectionAtomValue.children[slideIndex];
    const slideAtomValue = await snapshot.getPromise<TemplateNodes>(slideAtom);
    inlineAtom(slideAtomValue, slideAtom);
  });

  return (
    <AlertWindow
      message={
        <FlexRow>
          <Space style={{marginLeft: "10px"}}>
            <Tooltip placement="topLeft" title={tooltipTitle}>
              <StopOutlined style={{marginRight: "5px"}} />
              <span>{text}</span>
            </Tooltip>
          </Space>
        </FlexRow>
      }
      action={<Button onClick={handleInlineClick}>{inlineButtonText}</Button>}
      disabled={disabled}
    >
      {children}
    </AlertWindow>
  );
};

export const ConstantCommonComponent: FunctionComponent<{
  tooltipTitle: string;
  text: string;
  disabled: boolean;
}> = ({children, tooltipTitle, text, disabled}) => (
  <AlertWindow
    message={
      <FlexRow>
        <Space style={{marginLeft: "10px"}}>
          <Tooltip placement="topLeft" title={tooltipTitle}>
            <StopOutlined style={{marginRight: "5px"}} />
            <span>{text}</span>
          </Tooltip>
        </Space>
      </FlexRow>
    }
    disabled={disabled}
  >
    {children}
  </AlertWindow>
);

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
