import {
  atom,
  selector,
  useRecoilCallback,
  useRecoilValue,
  waitForAll,
} from "recoil";
import {groupBy, sortBy, pickBy} from "lodash";
import {QuestionType} from "@reside/forms";

import {
  ExtendedReference,
  ExtendedReferences,
  TreeSelectFields,
} from "../../atoms/query-builder/config";
import {
  getTemplateNestedRefsCount,
  getTemplateAnswerReferences,
  getTreeSelectDataStructure,
} from "./answersUtils";
import {SourceSectionGroup} from "../schemaTypes";
import {
  atomizedTemplateAtom,
  deatomizeNodes,
  templateTypeAtom,
} from "../template";
import {TemplateType} from "../../constants";
import {readTemplate} from "../../services/FileService";

/**
 * ATOMS
 */

/**
 * Invalidating selectors, providing information for displaying loader that all priorTemplates were loaded
 */
export const priorTemplatesUpdatedAtAtom = atom<string>({
  key: "priorTemplatesUpdatedAtAtom",
  default: "",
});

const priorTemplatesAtom = atom<
  ReadonlyArray<ReadonlyArray<SourceSectionGroup>>
>({
  key: "priorTemplatesAtom",
  default: [],
});

/**
 * SELECTORS
 */
export const priorTemplateTypesSelector = selector<ReadonlyArray<TemplateType>>(
  {
    key: "priorTemplateTypesSelector",
    get: ({get}) => {
      const templateType = get(templateTypeAtom);

      return getPriorTemplateTypes(templateType);
    },
  },
);

export const priorTemplatesSelector = selector<
  ReadonlyArray<ReadonlyArray<SourceSectionGroup>>
>({
  key: "priorTemplatesSelector",
  get: ({get}) => {
    return get(priorTemplatesAtom);
  },
});

export const priorTemplatesRefsSelector = selector<ExtendedReferences>({
  key: "priorTemplatesRefsSelector",
  get: ({get}) => {
    const [priorTemplateTypes, priorTemplates] = get(
      waitForAll([priorTemplateTypesSelector, priorTemplatesSelector]),
    );

    return priorTemplates.reduce(
      (references, template, index) => ({
        ...references,
        ...getTemplateAnswerReferences(template, priorTemplateTypes[index]),
      }),
      {},
    );
  },
});

const priorTemplatesRefsCountSelector = selector<Record<string, number>>({
  key: "priorTemplatesRefsCountSelector",
  get: ({get}) => {
    const [priorTemplateTypes, priorTemplates] = get(
      waitForAll([priorTemplateTypesSelector, priorTemplatesSelector]),
    );

    return priorTemplates.reduce(
      (references, template, index) => ({
        ...references,
        ...getTemplateNestedRefsCount(template, priorTemplateTypes[index]),
      }),
      {},
    );
  },
});

export const currentTemplateRefsSelector = selector<ExtendedReferences>({
  key: "currentTemplateRefs",
  get: ({get}) => {
    const [atomizedTemplate, templateType] = get(
      waitForAll([atomizedTemplateAtom, templateTypeAtom]),
    );

    return getTemplateAnswerReferences(
      deatomizeNodes(atomizedTemplate, get),
      templateType,
    );
  },
});

const currentTemplateRefsCountSelector = selector<Record<string, number>>({
  key: "currentTemplateRefsCountSelector",
  get: ({get}) => {
    const [atomizedTemplate, templateType] = get(
      waitForAll([atomizedTemplateAtom, templateTypeAtom]),
    );

    return getTemplateNestedRefsCount(
      deatomizeNodes(atomizedTemplate, get),
      templateType,
    );
  },
});

export const priorFieldArraysSelector = selector({
  key: "priorFieldArraysSelector",
  get: ({get}) => {
    const references = get(priorTemplatesRefsSelector);

    return pickBy(
      references,
      reference => reference.type === QuestionType.LIST,
    );
  },
});

const currentFieldArraysSelector = selector({
  key: "currentFieldArraysSelector",
  get: ({get}) => {
    const references = get(currentTemplateRefsSelector);

    return pickBy(
      references,
      reference => reference.type === QuestionType.LIST,
    );
  },
});

export const fieldArraysSelector = selector({
  key: "fieldArraysSelector",
  get: ({get}) => {
    const [current, prior] = get(
      waitForAll([currentFieldArraysSelector, priorFieldArraysSelector]),
    );

    return {...current, ...prior};
  },
});

export const priorFieldArraysByTemplateSelector = selector<
  Record<string, ReadonlyArray<ExtendedReference>>
>({
  key: "priorFieldArraysByTemplateSelector",
  get: ({get}) => {
    const fieldArrays = get(priorFieldArraysSelector);

    return groupBy(
      sortBy(Object.values(fieldArrays), "translationKey"),
      fieldArray => fieldArray.templateType,
    );
  },
});

export const currentTemplateTreeSelectFieldsSelector = selector<
  TreeSelectFields
>({
  key: "currentTemplateTreeSelectFields",
  get: ({get}) => {
    const [
      priorTemplateTypes,
      priorTemplates,
      atomizedTemplate,
      templateType,
    ] = get(
      waitForAll([
        priorTemplateTypesSelector,
        priorTemplatesSelector,
        atomizedTemplateAtom,
        templateTypeAtom,
      ]),
    );

    const currentTemplate = deatomizeNodes(atomizedTemplate, get);

    return [
      ...priorTemplates.map((template, index) => ({
        data: getTreeSelectDataStructure(template),
        templateType: priorTemplateTypes[index],
      })),
      {
        data: getTreeSelectDataStructure(currentTemplate),
        templateType,
      },
    ];
  },
});

export const templatesRefsSelector = selector<ExtendedReferences>({
  key: "templatesRefs",
  get: ({get}) => {
    const [current, prior] = get(
      waitForAll([currentTemplateRefsSelector, priorTemplatesRefsSelector]),
    );

    return {...current, ...prior};
  },
});

export const templatesRefsCountSelector = selector<Record<string, number>>({
  key: "templatesRefsCountSelector",
  get: ({get}) => {
    const [current, prior] = get(
      waitForAll([
        currentTemplateRefsCountSelector,
        priorTemplatesRefsCountSelector,
      ]),
    );

    const mergedRefsCount = {...prior, ...current};

    Object.keys(current).forEach(key => {
      if (prior[key]) {
        mergedRefsCount[key] = prior[key] + current[key];
      }
    });
    return {...mergedRefsCount};
  },
});

export const templatesRefsOrderedSelector = selector<ExtendedReference[]>({
  key: "templatesRefsOrdered",
  get: ({get}) => {
    const refs = get(templatesRefsSelector);

    return sortBy(Object.values(refs), "translationKey");
  },
});

/**
 * HOOKS
 */
export const useSetPriorTemplates = () =>
  useRecoilCallback(
    ({set, reset}) => async (
      templateName: string,
      templateType: TemplateType = TemplateType.RESIDENT_EXPERIENCE,
    ) => {
      /**
       * Important to reset the loading state of prior templates, so no stalled answer references will be passed to markdown editor.
       */
      reset(priorTemplatesUpdatedAtAtom);
      const priorTemplateTypes = getPriorTemplateTypes(templateType);

      const templates = await Promise.all(
        priorTemplateTypes.map(priorTemplateType =>
          readTemplate(templateName, priorTemplateType),
        ),
      );

      set(priorTemplatesAtom, templates);
      set(priorTemplatesUpdatedAtAtom, new Date().toISOString());
    },
  );

export const useCurrentTemplateTreeSelectFieldsSelector = () =>
  useRecoilValue(currentTemplateTreeSelectFieldsSelector);

/**
 * Prior template types are templates that can source values for provided template type
 * The sequence of steps determine that only previous steps can affect current template type
 * note: order of the templates is important, the first mention of the answer should take precedence
 */
const getPriorTemplateTypes = (templateType: TemplateType): TemplateType[] => {
  switch (templateType) {
    case TemplateType.RESIDENT_EXPERIENCE: {
      return [
        TemplateType.FACILITY_SETTINGS,
        TemplateType.PREFLIGHT,
        TemplateType.PRELUDE,
      ];
    }
    case TemplateType.PRELUDE: {
      return [TemplateType.FACILITY_SETTINGS, TemplateType.PREFLIGHT];
    }
    case TemplateType.PREFLIGHT: {
      return [TemplateType.FACILITY_SETTINGS];
    }
    case TemplateType.FACILITY_SETTINGS:
    default: {
      return [];
    }
  }
};
