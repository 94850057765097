"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Return map of raw admission answer values without the metadata.
 */
exports.extractAnswerValues = function (_a) {
    var answers = _a.answers;
    var rawAnswers = {};
    for (var key in answers) {
        rawAnswers[key] = answers[key].value;
    }
    return rawAnswers;
};
exports.extractPreflightedAnswers = function (_a) {
    var answers = _a.answers;
    var preflightedAnswers = {};
    for (var key in answers) {
        var answer = answers[key];
        if (answer.preFlight) {
            preflightedAnswers[key] = answer;
        }
    }
    return preflightedAnswers;
};
