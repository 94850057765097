import markdownit from "markdown-it";
// import markPlugin from "./mark";
// import checkboxPlugin from "./checkboxes";
// import embedsPlugin from "./embeds";
import breakPlugin from "./breaks";
// import tablesPlugin from "./tables";
// import noticesPlugin from "./notices";
// import underlinesPlugin from "./underlines";
import markdownAnswers from "./answer";
export default function rules(_a) {
    var embeds = _a.embeds, answers = _a.answers;
    return (markdownit("default", {
        breaks: false,
        html: false,
    })
        // .use(embedsPlugin(embeds))
        .use(breakPlugin)
        .disable(["link", "image", "heading", "list", "table"])
        // .use(checkboxPlugin)
        // .use(markPlugin({ delim: "==", mark: "mark" }))
        // .use(markPlugin({ delim: "!!", mark: "placeholder" }))
        // .use(underlinesPlugin)
        // .use(tablesPlugin)
        // .use(noticesPlugin)
        .use(markdownAnswers(answers)));
}
