import React, {useState, useRef, useCallback} from "react";
import {useQuery, useMutation} from "react-query";
import {useSetRecoilState} from "recoil";
import {
  Button,
  ConfigProvider,
  Empty,
  message,
  Modal,
  Tooltip,
  TreeSelect,
  Typography,
} from "antd";
import {BranchesOutlined, LoadingOutlined} from "@ant-design/icons";
import {styled} from "@reside/ui";
import {
  getCurrentBranchName,
  checkoutBranch,
  createBranch,
  fetchBranch,
  pullBranch,
  pushBranch,
  isGitCheckoutConflictError,
  isGitNotFoundError,
} from "../../services/GitService";
import {logError} from "../../logging";
import {useInitializeRepo} from "../../model/editor";
import {branchNameAtom} from "../../model/repository";
import {publishBranchName} from "../../config";
import {getBranches} from "../../services/GithubService";

const {Text} = Typography;
const {TreeNode} = TreeSelect;

// TODO: ask before switching instead of force checkout
export const BranchSelect = ({
  disabled,
}: {
  /**
   * The select is in 'read-only' mode when we are editing template.
   */
  disabled?: boolean;
}) => {
  const setBranchName = useSetRecoilState(branchNameAtom);
  const ref = useRef(null);
  /**
   * Currently checked out branch.
   * The initial flag helps decide between automatic and user-initiated checkout.
   */
  const [branch, setBranch] = useState({name: "", isSelectedByUser: false});
  /**
   * Intermediary search value used while typing name of new branch.
   */
  const [searchBranch, setSearchBranch] = useState("");
  /**
   * Name of branch which is being created.
   */
  const [newBranch, setNewBranch] = useState("");
  /**
   * Flag for switching between branch picking & branch creating.
   */
  const [isCreateBranchModeActive, setIsCreateBranchModeActive] = useState(
    false,
  );
  const [forceCheckoutModalVisible, setForceCheckoutModalVisible] = useState(
    false,
  );

  const initializeRepo = useInitializeRepo();

  const {
    isFetching: isFetchingCurrentBranch,
    refetch: refetchCurrentBranch,
  } = useQuery("getCurrentBranchName", getCurrentBranchName, {
    onError: error => {
      logError(error);
      message.error("Failed to get current branch name.");
    },
    onSuccess: branchName => {
      if (branchName) {
        setBranch({...branch, name: branchName});
        setBranchName(branchName);
      }
    },
  });

  const {
    data: branches = [],
    isFetching: isFetchingBranches,
    refetch: refetchBranches,
  } = useQuery("getBranches", getBranches, {
    onError: error => {
      logError(error);
      message.error("Failed to list branches.");
    },
  });

  const {isFetching: isCreatingBranch} = useQuery(
    ["createBranch", newBranch],
    async ({queryKey: [, branch]}: any) => {
      await checkoutBranch(publishBranchName);
      await createBranch(branch);
      await pushBranch(branch);
      await checkoutBranch(branch);
      await initializeRepo();

      return branch;
    },
    {
      onError: (error: any) => {
        logError(error);
        message.error(error?.message ?? "Failed to create branch");
      },
      onSuccess: branch => {
        message.success(
          <>
            Branch <Text code>{branch}</Text> from
            <Text code>{publishBranchName}</Text> created. 🎉
          </>,
        );
        /**
         * If previously a branch was switched by user, we reset the state, so the 'checkoutBranch' flow won't be run after creating branch.
         */
        setBranch({...branch, isSelectedByUser: false});
        refetchCurrentBranch();
        refetchBranches();
      },
      onSettled: () => {
        setNewBranch("");
        setIsCreateBranchModeActive(false);
      },
      enabled: !!newBranch,
    },
  );

  const onCheckoutSuccess = useCallback(async () => {
    await initializeRepo();
    message.success(
      <>
        Branch <Text code>{branch.name}</Text> checked out. 😉
      </>,
    );
  }, [initializeRepo, branch]);

  const onCheckoutError = useCallback(
    async error => {
      logError({error});
      message.error(
        <>
          Failed to checkout branch <Text code>{branch.name}</Text>.😔
        </>,
      );
    },
    [branch],
  );

  const {
    mutate: forceCheckoutBranch,
    isLoading: isForceCheckoutBranchLoading,
  } = useMutation(
    async (branchName: string) => {
      await checkoutBranch(branchName);
      await pullBranch(branchName);
      await refetchCurrentBranch();
    },
    {
      onSuccess: () => {
        setForceCheckoutModalVisible(false);
        onCheckoutSuccess();
      },
      onError: onCheckoutError,
    },
  );

  const {isFetching: isPullingBranch} = useQuery(
    ["checkoutBranch", branch.name],
    async ({queryKey: [, branchName]}: any) => {
      try {
        /**
         * The branch might not exist locally. The early checkout
         * is important to decide if we want to pull existing branch
         * or fetch new one.
         */
        await checkoutBranch(branchName);
        await pullBranch(branchName);
      } catch (error) {
        if (isGitNotFoundError(error)) {
          await fetchBranch(branchName);
          await checkoutBranch(branchName);
        } else {
          throw error;
        }
      }

      await refetchCurrentBranch();
    },
    {
      /**
       * We checkout the branch only when user changed it.
       * Checkout should not happen when we render the branch select initially.
       */
      enabled: branch.isSelectedByUser,
      onSuccess: onCheckoutSuccess,
      onError: async (error: any) => {
        if (isGitCheckoutConflictError(error)) {
          setForceCheckoutModalVisible(true);
        } else {
          onCheckoutError(error);
        }
      },
    },
  );

  const isFetching =
    isFetchingBranches ||
    isFetchingCurrentBranch ||
    isPullingBranch ||
    isCreatingBranch ||
    newBranch !== ""; // if new branch is set, it means we are in progress on creating it.

  return (
    <ConfigProvider
      renderEmpty={
        isCreateBranchModeActive
          ? () => null
          : () => (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    No branches. <br />
                    Create first one.
                  </>
                }
              />
            )
      }
    >
      <TreeSelect
        ref={ref}
        key={branch.name}
        showSearch
        treeIcon={
          <BranchIcons>
            <BranchesOutlined />
          </BranchIcons>
        }
        style={{width: 300}}
        dropdownStyle={{maxHeight: 400, overflow: "auto"}}
        dropdownRender={node => <DropdownStyles>{node}</DropdownStyles>}
        suffixIcon={
          isCreatingBranch || isPullingBranch ? (
            <LoadingOutlined />
          ) : isFetchingBranches ? (
            <Tooltip title="Listing Branches, please wait.">
              <LoadingOutlined />
            </Tooltip>
          ) : isCreateBranchModeActive || disabled ? (
            <BranchesOutlined />
          ) : undefined
        }
        onBlur={() => {
          /**
           * Return to branch list mode if new branch is not being created.
           */
          if (!newBranch) {
            setSearchBranch("");
            setIsCreateBranchModeActive(false);
          }
        }}
        onKeyDown={event => {
          if (isCreateBranchModeActive && event.keyCode === 13) {
            if (searchBranch.length >= 3) {
              setNewBranch(searchBranch);
              setSearchBranch("");
              setIsCreateBranchModeActive(false);
            } else {
              message.error("Branch name must be at least 3 characters long!");
            }
          }
        }}
        onSearch={value => setSearchBranch(value.toLowerCase())}
        value={
          isCreatingBranch || isPullingBranch || isCreateBranchModeActive
            ? undefined
            : branch.name
        }
        placeholder={
          isCreateBranchModeActive
            ? "Enter branch name"
            : isCreatingBranch
            ? `Creating '${newBranch}'...`
            : isPullingBranch
            ? `Switching to '${branch.name}'...`
            : undefined
        }
        disabled={isFetching || disabled}
        onChange={name => setBranch({name, isSelectedByUser: true})}
      >
        {!isCreateBranchModeActive &&
          branches.map(branch => (
            <TreeNode key={branch} value={branch} title={branch} />
          ))}
      </TreeSelect>
      {!disabled && !isCreateBranchModeActive && !isCreatingBranch && (
        <Button
          type="link"
          disabled={isFetching || disabled}
          onClick={() => {
            (ref.current as any).focus();
            setIsCreateBranchModeActive(true);
          }}
        >
          Create Branch
        </Button>
      )}
      <Modal
        title="You have unpublished local changes."
        okType="danger"
        okText="Force Checkout"
        visible={forceCheckoutModalVisible}
        onOk={() => forceCheckoutBranch(branch.name)}
        confirmLoading={isForceCheckoutBranchLoading}
        onCancel={async () => {
          setBranch({...branch, isSelectedByUser: false});
          await refetchCurrentBranch();
          setForceCheckoutModalVisible(false);
        }}
      >
        <p>
          By switching the branch your changes will be overwritten. Please
          confirm.
        </p>
      </Modal>
    </ConfigProvider>
  );
};

const DropdownStyles = styled.div`
  .ant-select-tree {
    // remove space before tree items
    .ant-select-tree-switcher-noop {
      display: none;
    }

    // create space for multiple icons
    .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
      width: auto;
    }
  }
`;

const BranchIcons = styled.div`
  margin-right: 8px;
`;
