import {format} from "prettier";
import babelParser from "prettier/parser-babel";
import {FragmentNode, PdfNode} from "@reside/forms";
import {rootDir} from "./GitService";
import {
  GroupNode,
  SourceImport,
  SourceSection,
  SourceSectionGroup,
  SourceSlide,
  TemplateNodes,
} from "../model/schemaTypes";
import {
  commonComponentsDirName,
  TemplateType,
  templateTypesList,
} from "../constants";
import basicTemplate from "../assets/basicTemplate.json";
import {logError} from "../logging";
import {Components} from "../model/library";
import {repositoryConfig} from "../config";
import {isPdfNode} from "../utils";

type ImportNodes = ReadonlyArray<
  | SourceImport
  | {type: "slide" | "section-group" | "section"; children: ImportNodes}
  | PdfNode
>;

const LightningFS = require("@isomorphic-git/lightning-fs");

/**
 * Switching between prod/dev repo is common case, thus we initialize different FS for each repo.
 * This way the different configurations won't overwrite each other's data, enabling faster dev cycle.
 */
const FILE_SYSTEM_NAME = `${repositoryConfig.owner}-${repositoryConfig.repo}`;
/**
 * Old node callback api, reexported for the git calls, because the promisified version doesn't work.
 * TODO: try to remove
 */
export const callbackFs = new LightningFS(FILE_SYSTEM_NAME);

/**
 * Awaitable api.
 */
export const fs = callbackFs.promises;

export const wipeFs = async () => {
  await fs.init(FILE_SYSTEM_NAME, {wipe: true});
};

export const ensureDirectory = async (filepath: string) => {
  try {
    await fs.readdir(filepath);
  } catch {
    await fs.mkdir(filepath);
  }
};

export const readTemplateNames = async () => {
  const fileNames: ReadonlyArray<string> = await fs.readdir(rootDir);

  return fileNames
    .filter(name => name.endsWith(".json"))
    .map(name => name.replace(".json", ""))
    .sort();
};

/**
 * Resolves import statements in a template source file.
 * Similar to pre-processing which happens in the reside-content generator.
 */
export const readTemplate = async (
  name: string,
  type: TemplateType = TemplateType.RESIDENT_EXPERIENCE,
) => {
  const entryFile = await readJsonFile(`${rootDir}/${name}.json`);

  const templateDirName = `${rootDir}/json/${type}`;

  const inheritedDirName = `${templateDirName}/${name}`;

  const template = entryFile[type];

  return (await resolveChildrenImports(
    template.sections,
    templateDirName,
    inheritedDirName,
  )) as ReadonlyArray<SourceSectionGroup>;
};

const resolveChildrenImports = async (
  children: ImportNodes,
  templateDirName: string,
  inheritedDirName?: string,
): Promise<ReadonlyArray<TemplateNodes>> =>
  Promise.all(
    children.filter(keepOnlyJsonImports).map(async node => {
      return node.type === "import"
        ? await resolveImport(node, templateDirName, inheritedDirName)
        : node.type === "pdf"
        ? await readPdfWithMappers(node)
        : node.children
        ? {
            ...node,
            children: await resolveChildrenImports(
              node.children,
              templateDirName,
              inheritedDirName,
            ),
          }
        : node;
    }),
  );

export const readJsonFile = async (fileName: string) => {
  try {
    const file = await fs.readFile(fileName);

    return JSON.parse(new TextDecoder("utf-8").decode(file));
  } catch (error) {
    logError({error, fileName});
  }
};

const resolveImport = async (
  node: SourceImport,
  templateDirName: string,
  inheritedDirName?: string,
) => {
  const sourceDirHandle = inheritedDirName
    ? node.inherit
      ? inheritedDirName
      : templateDirName
    : templateDirName;

  try {
    const json = await readJsonFile(`${sourceDirHandle}/${node.src}`);
    return {
      ...json,
      importedFrom: node,
      ...(json.children && {
        children: await resolveChildrenImports(
          json.children,
          templateDirName,
          inheritedDirName,
        ),
      }),
    };
  } catch (error) {
    logError({error, src: node.src});
    //TODO catch error in case no inherited folder is found (eg Shortex does not have inherited Prelude folder)
  }
};

/**
 * When reading the template, check for mappers and add them into the PDF node.
 */
const readPdfWithMappers = async (node: PdfNode) => {
  const mappersJson = await readJsonFile(
    `${rootDir}/pdf/${node.src.replace(".pdf", ".json")}`,
  );

  return mappersJson ? {...node, ...mappersJson} : node;
};

/**
 * For now skips directory imports of internal variables.
 * TODO
 */
const keepOnlyJsonImports = (node: any) =>
  node.type === "import" ? node.src.endsWith(".json") : true;

export const cloneTemplate = async (
  originTemplateName: string,
  newTemplateName: string,
) => {
  await cloneMainJsonFile(originTemplateName, newTemplateName);

  /**
   * NOTE: we must write sequentially, thus we use for loop.
   */
  for (const templateType of templateTypesList) {
    const template = await readTemplate(originTemplateName, templateType);

    await saveTemplate(template, newTemplateName, templateType);
  }
};

export const saveTemplate = async (
  template: ReadonlyArray<SourceSectionGroup>,
  name: string,
  type: TemplateType = TemplateType.RESIDENT_EXPERIENCE,
) => {
  const entryFile = await readJsonFile(`${rootDir}/${name}.json`);

  const inheritedFolderDirName = `json/${type}/${name}`;

  /**
   * Writes the node and returns children collapsed back to import nodes.
   */
  const writeImportedFile = async (node: GroupNode) => {
    const {importedFrom, ...updatedNode} = node;
    let {children} = updatedNode;

    if (isPdfNode(node)) {
      const {mappers, ...pdfNode} = node;

      const mapperPath = `pdf/${node.src.replace(".pdf", ".json")}`;

      if (mappers) {
        await writeContents(mapperPath, formatOutput({mappers}));
      } else {
        try {
          await deleteFile(mapperPath);
        } catch {}
      }

      return pdfNode;
    }

    /**
     * If node is slide children (leaf element), stop recurring.
     */
    if (!children) {
      return node;
    }

    /**
     * Recursively write children and collect the import statements or plain nodes.
     * NOTE: we must write sequentially, thus we use for loop.
     */
    children = [];
    for (const child of node.children) {
      //@ts-ignore
      children.push(await writeImportedFile(child as GroupNode));
    }

    const writtenNode = {...updatedNode, children} as GroupNode;

    if (importedFrom && importedFrom.inherit) {
      /**
       * Contents from imported files has already been saved, turn back to imports.
       */
      await writeContents(
        `${inheritedFolderDirName}/${importedFrom.src}`,
        formatOutput(writtenNode),
      );
    }

    /**
     * If the node was imported, return the import statement, which will be recollected in parent children.
     */
    return importedFrom || writtenNode;
  };

  /**
   * Save section groups & its children before saving the main json.
   * NOTE: we must write sequentially, thus we use for loop.
   */
  const sections = [];
  for (const section of template) {
    sections.push(await writeImportedFile(section));
  }

  await writeContents(
    `${name}.json`,
    formatOutput({
      ...entryFile,
      [type]: {sections},
    }),
  );
};

const cloneMainJsonFile = async (
  originTemplateName: string,
  newTemplateName: string,
) => {
  const file = await fs.readFile(`${rootDir}/${originTemplateName}.json`);

  await fs.writeFile(`${rootDir}/${newTemplateName}.json`, file);
};

export const fileExists = async (dir: string) => {
  try {
    await fs.readFile(`${rootDir}/${dir}`);
    return true;
  } catch {
    return false;
  }
};

export const deleteFile = (filePath: string) =>
  fs.unlink(`${rootDir}/${filePath}`);

/**
 * WRITE ONLY SEQUENTIALLY to prevent race conditions.
 */
const writeContents = async (dirName: string, content: string) => {
  const makeDirs = async (
    [dirName, ...path]: string[],
    rootDir: string,
  ): Promise<void> => {
    if (!dirName) {
      return;
    }

    const currentDir = `${rootDir}/${dirName}`;

    try {
      await fs.readdir(currentDir);
    } catch {
      await fs.mkdir(currentDir);
    }

    await makeDirs(path, currentDir);
  };

  /**
   * We must first create the dirs, before writing the file.
   */
  const path = dirName.split("/");
  path.pop();
  if (path.length) {
    await makeDirs(path, rootDir);
  }

  await fs.writeFile(
    `${rootDir}/${dirName}`,
    new TextEncoder().encode(content),
  );
};

export const createNewTemplate = (newTemplateName: string) =>
  fs.writeFile(
    `${rootDir}/${newTemplateName}.json`,
    formatOutput(basicTemplate),
  );

export const formatOutput = (content: object) =>
  format(JSON.stringify(content), {
    parser: "json",
    trailingComma: "all",
    bracketSpacing: true,
    plugins: [babelParser],
  });

export const readLibraryComponents = async (
  dirName = commonComponentsDirName,
) => {
  const getLibraryComponents = async (templateType: TemplateType) => {
    const libraryFolderDirName = `${rootDir}/json/${templateType}/${dirName}`;

    let files: string[];

    try {
      files = await fs.readdir(libraryFolderDirName);
    } catch {
      files = [];
    }

    const components: Components = [];
    for await (const fileName of files) {
      if (fileName.endsWith(".json")) {
        const json = await readJsonFile(`${libraryFolderDirName}/${fileName}`);

        const result = (await resolveChildrenImports(
          json.children,
          libraryFolderDirName,
        )) as
          | ReadonlyArray<SourceSectionGroup>
          | ReadonlyArray<SourceSection>
          | ReadonlyArray<SourceSlide>
          | ReadonlyArray<FragmentNode>;

        components.push({
          ...json,
          children: result,
          fileName: fileName
            .replace(".json", "")
            .replace("-", " ")
            .replace(/\b\w/g, l => l.toUpperCase()),
          filePath: `${dirName}/${fileName}`,
        });
      }
    }
    return {
      templateType,
      components,
    };
  };

  return Promise.all(templateTypesList.map(getLibraryComponents));
};
