var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, } from "react";
import { Field } from "formik";
import { styled } from "../../theme";
import { ClearFieldButton } from "../atoms/clear-field-button";
import { TextArea } from "../atoms/TextualInput";
import { FieldError } from "../atoms/field-error";
import { FloatingFieldLabel } from "../atoms/FloatingFieldLabel";
import { FieldBorder } from "../atoms/FieldBorder";
import { LockIcon } from "../atoms/LockIcon";
import { Label } from "../atoms/Label";
import { isInvalid } from "../formHelpers";
export var TextAreaField = function (_a) {
    var name = _a.name, disabled = _a.disabled, label = _a.label, autoResize = _a.autoResize, placeholder = _a.placeholder, FormField = _a.FormField, props = __rest(_a, ["name", "disabled", "label", "autoResize", "placeholder", "FormField"]);
    var _b = useState(false), focused = _b[0], setFocused = _b[1];
    var resizeTextArea = function (e) {
        e.currentTarget.style.height = e.currentTarget.scrollHeight + "px";
    };
    return (React.createElement(FormField, { name: name }, function (_a) {
        var field = _a.field, form = _a.form;
        return (React.createElement(Label, null,
            React.createElement(FloatingFieldLabel, { visible: Boolean(field.value) }, label),
            React.createElement(TextAreaWrapper, null,
                React.createElement(TextArea, __assign({}, field, props, { value: field.value || "", placeholder: placeholder || label, disabled: disabled, onBlur: function (event) {
                        setFocused(false);
                        field.onBlur(event);
                    }, onFocus: function () { return setFocused(true); }, onKeyUp: autoResize ? resizeTextArea : undefined })),
                field.value && !disabled && (React.createElement(ClearButton, { onClick: function () { return form.setFieldValue(name, ""); } })),
                disabled && React.createElement(LockIcon, null)),
            React.createElement(FieldBorder, { focused: focused, invalid: isInvalid(name, form) }),
            React.createElement(FieldError, { name: name })));
    }));
};
TextAreaField.defaultProps = {
    FormField: Field,
};
var ClearButton = styled(ClearFieldButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 45%;\n  right: 1px;\n  padding: 2px;\n  cursor: pointer;\n"], ["\n  position: absolute;\n  bottom: 45%;\n  right: 1px;\n  padding: 2px;\n  cursor: pointer;\n"])));
var TextAreaWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  position: relative;\n  margin-top: 8px;\n\n  textarea {\n    padding-right: 1.2em;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  position: relative;\n  margin-top: 8px;\n\n  textarea {\n    padding-right: 1.2em;\n  }\n"])));
var templateObject_1, templateObject_2;
