import React, {useEffect} from "react";
import {useRecoilValue} from "recoil";
import {Form, Input, Radio} from "antd";
import {FormInstance} from "antd/lib/form";

import {ImageNode} from "@reside/forms";
import {Text} from "@reside/ui";
import {BlockImage} from "@reside/ui/dist/form-blocks/block-image";

import {
  AtomProp,
  focusedAtomAtom,
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {useTemplateAtomState} from "../../model/template";
import {SourceNode} from "../../model/schemaTypes";

export const EditableImage = ({
  node: {id, url, alt, horizontalAlignment, style},
}: {
  node: ImageNode;
} & AtomProp) =>
  url ? (
    <BlockImage
      id={id}
      url={url}
      alt={alt ?? ""}
      horizontalAlignment={horizontalAlignment}
      style={style}
    />
  ) : (
    <Text>The image will be render once you set the image url attribute.</Text>
  );

export const ImageForm = () => {
  const {url, id, style, horizontalAlignment} = useFocusedNode() as ImageNode;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, id]);

  return (
    <Form
      form={form}
      key={id}
      initialValues={{
        style: JSON.stringify(style),
        horizontalAlignment,
        url: url?.replace("/assets/", ""),
      }}
    >
      <StyleAttribute />
      <UrlAttribute form={form} />
      <HorizontalAlignmentAttribute />
    </Form>
  );
};

const StyleAttribute = () => {
  const setStyle = useSetFocusedNodePropertyValue("style", {replace: true});

  const validate = (rule: any, value: any, callback: any) => {
    if (value) {
      try {
        setStyle(JSON.parse(value));
      } catch {
        callback(`Style property must be json`);
      }
    }
  };

  return (
    <Form.Item
      name="style"
      label="Style"
      rules={[
        {
          validator: validate,
        },
      ]}
    >
      <Input type="text" />
    </Form.Item>
  );
};

const HorizontalAlignmentAttribute = ({
  alignments,
}: {
  alignments: ReadonlyArray<string>;
}) => {
  const setHorizontalAlignment = useSetFocusedNodePropertyValue(
    "horizontalAlignment",
  );

  return (
    <Form.Item
      name="horizontalAlignment"
      label="Horizontal Alignment"
      wrapperCol={{span: 24}}
      labelCol={{span: 24}}
    >
      <Radio.Group
        onChange={event => setHorizontalAlignment(event.target.value)}
      >
        {alignments.map((alignment, index) => (
          <Radio.Button key={index} value={alignment}>
            {alignment}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

HorizontalAlignmentAttribute.defaultProps = {
  alignments: ["left", "center", "right"],
};

const UrlAttribute = ({
  urls,
  form,
}: {
  urls: ReadonlyArray<string>;
  form: FormInstance<any>;
}) => {
  const [focusedNode, setFocusedNode] = useTemplateAtomState(
    useRecoilValue(focusedAtomAtom)!,
  );

  return (
    <Form.Item
      name="url"
      label="Image"
      wrapperCol={{span: 24}}
      labelCol={{span: 24}}
    >
      <Radio.Group
        onChange={event => {
          const imageNode = focusedNode as SourceNode<ImageNode>;

          setFocusedNode({
            ...imageNode,
            url: `/assets/${event.target.value}`,
          });
        }}
      >
        {urls.map(url => (
          <Radio value={url}>
            <img
              alt={url}
              width={64}
              height={64}
              style={{margin: 10}}
              src={`/assets/${url}`}
            />
          </Radio>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

UrlAttribute.defaultProps = {
  urls: [
    "adjustToYourNeed.svg",
    "advanceDirectives.svg",
    "aperion_logo.png",
    "aperion_logo-white.png",
    "bagWithCross.svg",
    "baloons.svg",
    "bellWithNumber.svg",
    "camera.svg",
    "financialForms.svg",
    "followTheChainOfInformation.svg",
    "help.svg",
    "howItWorks.svg",
    "howLongWillItTake.svg",
    "jumpBetweenSections.svg",
    "medicare.svg",
    "navigateUpAndDown.svg",
    "privacyPractices.svg",
    "reside_logo.svg",
    "reside_logo-white.svg",
    "reside_logo_extended.png",
    "reside_logo_extended.svg",
    "residentAndFacilityContract.svg",
    "smoking.svg",
    "toProceedTapContinue.svg",
    "trustFund.svg",
    "useTheHelpRibbon.svg",
    "vaccineInformation.svg",
    "wellDone.svg",
    "youCanGoBackToThePreviousScreen.svg",
  ],
};
