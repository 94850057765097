import React, {ReactNode} from "react";
import {RecoilState, useRecoilValue} from "recoil";
import {red} from "@ant-design/colors";
import {DeleteOutlined} from "@ant-design/icons";
import {styled, FlexRow} from "@reside/ui";

import {useDragAndDropChoices} from "../../hooks/useDragAndDropChoices";
import {AtomProp} from "../../model/editor";
import {ChildrenDroppable} from "../children-droppable";
import {useDragAndDrop} from "../../hooks/useDragAndDrop";
import {GroupFieldChildrenBlocks} from "../group-field-children-blocks";
import {TemplateNodes} from "../../model/schemaTypes";
import {EditableLabelAttribute} from "../editable-label-attribute";
import {useRemoveValidationErrors} from "../../model/validation";

export const GroupFieldOption = ({
  index,
  element,
  option,
  parentAtom,
  atom,
  reference,
  onRemove,
  isFocused,
  isArrayFieldScoped = false,
}: {
  index: number;
  element?: ReactNode;
  option: any;
  reference: any;
  isFocused?: boolean;
  onRemove: () => void;
  isArrayFieldScoped?: boolean;
  parentAtom: RecoilState<TemplateNodes>;
} & AtomProp) => {
  const {dropRef, dragRef} = useDragAndDropChoices({
    index,
    atom: parentAtom,
  });

  const {dropRef: dropChildrenRef} = useDragAndDrop({
    parentAtom: atom,
    index: 0,
  });
  const node = useRecoilValue(atom);
  const removeValidations = useRemoveValidationErrors(node as any);

  return (
    <Row>
      <IconsWrapper>
        <DeleteOutlined
          style={{color: red.primary}}
          onClick={() => {
            onRemove();
            removeValidations();
          }}
        />
      </IconsWrapper>
      <div ref={dropRef} style={{flex: 1}}>
        <div ref={dragRef}>
          <OptionItemRow>
            {element}
            <EditableLabelAttribute atom={atom} propertyPath="translationKey" />
          </OptionItemRow>

          {option.children?.length ? (
            <GroupFieldChildrenBlocks
              children={option.children}
              atom={reference.choices?.[index] as any}
              isArrayFieldScoped={isArrayFieldScoped}
            />
          ) : isFocused ? (
            <div ref={dropChildrenRef}>
              <ChildrenDroppable help="The items will be visible once you drop here any items." />
            </div>
          ) : null}
        </div>
      </div>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
`;

const OptionItemRow = styled(FlexRow)`
  justify-content: normal;

  // Makes the input look "read only"
  label {
    border: none;
    cursor: default;
    &:focus {
      border: none;
    }
  }
`;

const IconsWrapper = styled.div`
  padding: 8px;
`;
