import React from "react";
import {Form, Input} from "antd";

import {ContinueButtonNode} from "@reside/forms";
import {BlockContinueButton} from "@reside/ui";

import {
  AtomProp,
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {Override} from "../../model/schemaTypes";

export const EditableContinueButton = ({
  node: {id, translationKey},
}: {
  node: Override<ContinueButtonNode, {id: string}>;
} & AtomProp) => <BlockContinueButton translationKey={translationKey} />;

export const ContinueButtonForm = () => {
  const node = useFocusedNode() as ContinueButtonNode;
  const setValue = useSetFocusedNodePropertyValue("translationKey");

  const validate = (rule: any, value: any, callback: any) => {
    if (value === undefined || value === "") {
      callback(`Title must not be empty`);
    }
  };

  return (
    <Form
      key={node.id}
      initialValues={{
        translationKey: node.translationKey,
      }}
    >
      <Form.Item
        name="translationKey"
        label="title"
        rules={[
          {
            validator: validate,
          },
        ]}
      >
        <Input
          onChange={event => {
            event.target.value === ""
              ? setValue(undefined)
              : setValue(event.target.value);
          }}
          value={node.translationKey}
        />
      </Form.Item>
    </Form>
  );
};
