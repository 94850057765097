var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../../theme";
import { Text } from "../../text";
export var TableOfContentsHeader = function () { return (React.createElement(Wrapper, null,
    React.createElement(Text, { fontSize: "1.25em", fontWeight: "bold" }, "Table of Contents"),
    React.createElement(Text, { fontSize: "1.25em", fontWeight: "bold" }, "Status"))); };
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid ", ";\n  padding: 0 2px 2em 2px;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid ", ";\n  padding: 0 2px 2em 2px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.borderGray;
});
var templateObject_1;
