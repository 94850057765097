var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { wrappingInputRule } from "prosemirror-inputrules";
import Node from "./Node";
import toggleWrap from "../commands/toggleWrap";
var Blockquote = /** @class */ (function (_super) {
    __extends(Blockquote, _super);
    function Blockquote() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Blockquote.prototype, "name", {
        get: function () {
            return "blockquote";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Blockquote.prototype, "schema", {
        get: function () {
            return {
                content: "block+",
                group: "block",
                defining: true,
                parseDOM: [{ tag: "blockquote" }],
                toDOM: function () { return ["blockquote", 0]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    Blockquote.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [wrappingInputRule(/^\s*>\s$/, type)];
    };
    Blockquote.prototype.commands = function (_a) {
        var type = _a.type;
        return function () { return toggleWrap(type); };
    };
    Blockquote.prototype.keys = function (_a) {
        var type = _a.type;
        return {
            "Ctrl->": toggleWrap(type),
            "Mod-]": toggleWrap(type),
        };
    };
    Blockquote.prototype.toMarkdown = function (state, node) {
        state.wrapBlock("> ", null, node, function () { return state.renderContent(node); });
    };
    Blockquote.prototype.parseMarkdown = function () {
        return { block: "blockquote" };
    };
    return Blockquote;
}(Node));
export default Blockquote;
