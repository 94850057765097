import React from "react";
import {Form, InputNumber, Tooltip} from "antd";
import {MIN_ITEMS_RULE_NAME} from "@reside/forms/dist/validator/validators/minItemsValidator";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {getRuleValue, isFieldArray, updateRules} from "../../utils";
import {FormControlSourceNode} from "../../model/schemaTypes";

export const getMinItemsRuleValue = getRuleValue(MIN_ITEMS_RULE_NAME, parseInt);

export const getInitialMinItemsFormValue = (node: FormControlSourceNode) => {
  try {
    return {
      [MIN_ITEMS_RULE_NAME]: getMinItemsRuleValue(node.rules),
    };
  } catch {
    return {};
  }
};

export const MinItemsAttribute = () => {
  const node = useFocusedNode();
  const setValidationRules = useSetFocusedNodePropertyValue("rules");

  if (!node) {
    return null;
  }

  return isFieldArray(node) ? (
    <Form.Item
      name={MIN_ITEMS_RULE_NAME}
      label={
        <Tooltip title="Minimum number of items the field array will require before the form can be saved.">
          <span>Min items</span>
        </Tooltip>
      }
      rules={[{type: "number", min: 0}]}
    >
      <InputNumber
        min={0}
        onChange={value =>
          setValidationRules(
            updateRules(
              [...(node.rules ?? [])],
              `${value ?? ""}`,
              MIN_ITEMS_RULE_NAME,
            ),
          )
        }
      />
    </Form.Item>
  ) : (
    <>
      Attribute "${MIN_ITEMS_RULE_NAME}" can be used only with FIELD_ARRAY type
    </>
  );
};
