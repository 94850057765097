import React from "react";
import {Card, Dropdown} from "antd";
import {UpOutlined} from "@ant-design/icons";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {useRecoilCallback, useRecoilValue} from "recoil";
import {styled, FlexColumn} from "@reside/ui";
import * as immutable from "object-path-immutable";

import {
  atomizedTemplateAtom,
  rootAtom,
  useIsFacilitySettingsTemplateValue,
  useIsPreludeTemplateValue,
  useSetTemplateDirty,
} from "../../model/template";
import {keyToPath, moveItem, insert} from "../../utils";
import {NodeDropList} from "./atoms/node-drop-list";
import {TemplateTypeSwitch} from "../template-type-switch";
import {ComponentLibraryMenu} from "./atoms/component-library-menu";
import {
  updateActiveSlidePathForDifferentParent,
  updateActiveSlidePathForSameParent,
  updateActiveSlidePathForSectionGroups,
} from "./utils/utils";
import {nestedDropResultTypes} from "../../constants";
import {activeSlidePathAtom} from "../../model/editor";
import {TemplateNodes} from "../../model/schemaTypes";
import {PdfUploadButton} from "./atoms/pdf-upload-button";
import {PdfMapperModal} from "./atoms/pdf-mapper-modal";
import {PdfMapperModalProvider} from "./atoms/pdf-mapper-modal/PdfMapperModalContext";

export const TemplatePanel = () => {
  const atomizedTemplate = useRecoilValue(atomizedTemplateAtom);
  const activeSlidePath = useRecoilValue(activeSlidePathAtom);

  const setTemplateDirty = useSetTemplateDirty();
  const isFacilitySettingsTemplate = useIsFacilitySettingsTemplateValue();
  const isPreludeTemplate = useIsPreludeTemplateValue();

  const handleDrop = useRecoilCallback(
    ({set, snapshot}) => async (result: DropResult) => {
      const getAtomAtPath = async ([rootIndex, ...path]: number[]) => {
        let rootAtom = atomizedTemplate[rootIndex];
        let node = await snapshot.getPromise(rootAtom);
        const mutablePath = [...path];

        while (mutablePath.length) {
          rootAtom = node.children[mutablePath.shift() ?? 0];
          node = await snapshot.getPromise(rootAtom);
        }

        return {atom: rootAtom, node: node as TemplateNodes};
      };

      const {source, destination} = result;

      if (!destination) {
        // Dropped outside
        return;
      }

      if (nestedDropResultTypes.includes(result.type)) {
        const sourcePath = keyToPath(source.droppableId);
        const destinationPath = keyToPath(destination.droppableId);

        if (source.droppableId === destination.droppableId) {
          const {atom, node} = await getAtomAtPath(sourcePath);

          set(atom, {
            ...node,
            children: moveItem(
              node.children,
              source.index,
              destination.index,
            ) as any,
          });

          const newActiveSlidePath = updateActiveSlidePathForSameParent({
            sourceIndex: source.index,
            destinationIndex: destination.index,
            activeSlidePath,
            destinationPath,
          });
          set(activeSlidePathAtom, newActiveSlidePath);
        } else {
          const {atom: sourceAtom, node: sourceNode} = await getAtomAtPath(
            sourcePath,
          );

          const movedSlide = sourceNode.children[source.index];

          const {
            atom: destinationAtom,
            node: destinationNode,
          } = await getAtomAtPath(destinationPath);

          set(destinationAtom, {
            ...destinationNode,
            children: insert(
              destinationNode.children,
              destination.index,
              movedSlide,
            ),
          });

          const newActiveSlidePath = updateActiveSlidePathForDifferentParent({
            sourceIndex: source.index,
            destinationIndex: destination.index,
            activeSlidePath,
            destinationPath,
            sourcePath,
          });
          set(activeSlidePathAtom, newActiveSlidePath);

          set(sourceAtom, {
            ...sourceNode,
            children: immutable.del(sourceNode.children, `${source.index}`),
          });
        }
      } else if (result.type === "template") {
        set(
          atomizedTemplateAtom,
          moveItem(atomizedTemplate, source.index, destination.index),
        );
        const newActiveSlidePath = updateActiveSlidePathForSectionGroups({
          sourceIndex: source.index,
          destinationIndex: destination.index,
          activeSlidePath,
        });
        set(activeSlidePathAtom, newActiveSlidePath);
      }

      setTemplateDirty();
    },
  );

  const dropdownDisabled = isFacilitySettingsTemplate || isPreludeTemplate;

  return (
    <PdfMapperModalProvider>
      <DragDropContext onDragEnd={handleDrop}>
        <StyledCard
          title="Template Panel"
          bodyStyle={{padding: 0, flex: 1}}
          actions={[
            <Dropdown
              disabled={dropdownDisabled}
              arrow
              overlay={<ComponentLibraryMenu />}
            >
              <div style={{display: "flex"}}>
                <FooterButton disabled={dropdownDisabled}>
                  Add item <UpOutlined />
                </FooterButton>
              </div>
            </Dropdown>,
            <PdfUploadButton />,
          ]}
        >
          <Column>
            <TemplateTypeSwitch />
            <Scrollable>
              <NodeDropList
                atoms={atomizedTemplate}
                parentAtom={rootAtom as any}
                type="template"
                droppableId="template"
              />
            </Scrollable>
          </Column>
        </StyledCard>
        <PdfMapperModal />
      </DragDropContext>
    </PdfMapperModalProvider>
  );
};

const Scrollable = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .ant-card-body {
    overflow: hidden;
  }

  .ant-card-actions {
    padding: 0 24px;
  }
`;

const Column = styled(FlexColumn)`
  height: 100%;
`;

const FooterButton = styled.div<{disabled: boolean}>`
  color: ${({disabled, theme}) => (disabled ? theme.color.gray100 : "inherit")};
  cursor: ${({disabled}) => (disabled ? "not-allowed" : "pointer")};
`;
