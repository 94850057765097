import React from "react";
import {styled, Paragraph, ADAPTABLE_TEXT_CLASSNAME} from "@reside/ui";
import {get} from "lodash";

import {clearWhiteSpace} from "../../utils";
import {AtomProp, useSetAtomPropertyValue} from "../../model/editor";
import {useRecoilValue} from "recoil";

export const EditableLabelAttribute = ({
  propertyPath,
  atom,
}: {
  propertyPath: string;
} & AtomProp) => {
  const node = useRecoilValue(atom);
  const setLabel = useSetAtomPropertyValue(atom, propertyPath);
  const label = get(node, propertyPath);

  return (
    <Label
      className={ADAPTABLE_TEXT_CLASSNAME}
      placeholder="Enter text..."
      contentEditable
      onBlur={event => {
        const value = clearWhiteSpace(event.currentTarget.textContent ?? "");
        setLabel(value);
        event.currentTarget.textContent = value;
      }}
    >
      {label}
    </Label>
  );
};

EditableLabelAttribute.defaultProps = {
  propertyPath: "reference.translationKey",
};

const Label = styled(Paragraph)`
  font-size: 20px;
  margin: 0;
  width: 100%;

  &:empty:before {
    display: block;
    content: attr(placeholder);
    color: #555;
    cursor: text;
    pointer-events: none;
    opacity: 0.4;
    filter: saturate(25%);
  }

  &:focus {
    cursor: text;
    outline: none;
  }
`;
