export var MEDIA_TYPES = {
    PDF: "application/pdf",
    IMAGE_GIF: "image/gif",
    IMAGE_JPEG: "image/jpeg",
    IMAGE_JPG: "image/jpg",
    IMAGE_PNG: "image/png",
};
export var SUPPORTED_MEDIA_TYPE_IMAGES = [
    MEDIA_TYPES.IMAGE_JPEG,
    MEDIA_TYPES.IMAGE_JPG,
    MEDIA_TYPES.IMAGE_PNG,
    MEDIA_TYPES.IMAGE_GIF,
];
export var imageOfSupportedMimeType = function (file) {
    return file.type && SUPPORTED_MEDIA_TYPE_IMAGES.indexOf(file.type) > -1;
};
export var readFilesAsBase64 = function (files) {
    var promises = files.map(function (file) {
        return new Promise(function (resolve, reject) {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () { return resolve(reader.result); };
            reader.onerror = function (error) { return reject(error); };
        });
    });
    return Promise.all(promises);
};
