var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { toggleMark } from "prosemirror-commands";
import markInputRule from "../lib/markInputRule";
import Mark from "./Mark";
var Italic = /** @class */ (function (_super) {
    __extends(Italic, _super);
    function Italic() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Italic.prototype, "name", {
        get: function () {
            return "em";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Italic.prototype, "schema", {
        get: function () {
            return {
                parseDOM: [
                    { tag: "i" },
                    { tag: "em" },
                    { style: "font-style", getAttrs: function (value) { return value === "italic"; } },
                ],
                toDOM: function () { return ["em"]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    Italic.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [
            markInputRule(/(?:^|[^_])(_([^_]+)_)$/, type),
            markInputRule(/(?:^|[^*])(\*([^*]+)\*)$/, type),
        ];
    };
    Italic.prototype.keys = function (_a) {
        var type = _a.type;
        return {
            "Mod-i": toggleMark(type),
            "Mod-I": toggleMark(type),
        };
    };
    Object.defineProperty(Italic.prototype, "toMarkdown", {
        get: function () {
            return {
                open: "*",
                close: "*",
                mixable: true,
                expelEnclosingWhitespace: true,
            };
        },
        enumerable: true,
        configurable: true
    });
    Italic.prototype.parseMarkdown = function () {
        return { mark: "em" };
    };
    return Italic;
}(Mark));
export default Italic;
