import {isEqual, take} from "lodash";
import {GroupNodeType} from "@reside/forms";

export const isSameSection = (
  indexPath: ReadonlyArray<number>,
  slidePath: ReadonlyArray<number>,
) => isEqual(take(indexPath, 2), take(slidePath, 2));

const shouldUpdateActiveSlidePathInSameParent = ({
  sourceIndex,
  destinationIndex,
  slidePathIndex,
  activeSlidePath,
}: {
  sourceIndex: number;
  destinationIndex: number;
  slidePathIndex: number;
  activeSlidePath: ReadonlyArray<number>;
}) =>
  (sourceIndex <= activeSlidePath[slidePathIndex] &&
    destinationIndex >= activeSlidePath[slidePathIndex]) ||
  (sourceIndex >= activeSlidePath[slidePathIndex] &&
    destinationIndex <= activeSlidePath[slidePathIndex]);

const updateActiveSlidePathInSameSection = ({
  sourceIndex,
  destinationIndex,
  activeSlidePath,
}: {
  sourceIndex: number;
  destinationIndex: number;
  activeSlidePath: ReadonlyArray<number>;
}) => {
  const shouldUpdate = shouldUpdateActiveSlidePathInSameParent({
    sourceIndex,
    destinationIndex,
    slidePathIndex: 2,
    activeSlidePath,
  });
  if (shouldUpdate) {
    // Dragging the active slide
    if (sourceIndex === activeSlidePath[2]) {
      return [...take(activeSlidePath, 2), destinationIndex];
    }
    // Drag slide after the active slide
    else if (sourceIndex < activeSlidePath[2]) {
      return [...take(activeSlidePath, 2), activeSlidePath[2] - 1];
    }
    //Drag slide before the active slide
    else if (sourceIndex > activeSlidePath[2]) {
      return [...take(activeSlidePath, 2), activeSlidePath[2] + 1];
    }
  }
  return activeSlidePath;
};

const updateActiveSlidePathInSameSectionGroup = ({
  sourceIndex,
  destinationIndex,
  activeSlidePath,
}: {
  sourceIndex: number;
  destinationIndex: number;
  activeSlidePath: ReadonlyArray<number>;
}) => {
  const shouldUpdate = shouldUpdateActiveSlidePathInSameParent({
    sourceIndex,
    destinationIndex,
    slidePathIndex: 1,
    activeSlidePath,
  });

  if (shouldUpdate) {
    // Dragging section with active slide
    if (sourceIndex === activeSlidePath[1]) {
      return [activeSlidePath[0], destinationIndex, activeSlidePath[2]];
    }
    // Dragging section before the section with active slide
    else if (sourceIndex < activeSlidePath[1]) {
      return [activeSlidePath[0], activeSlidePath[1] - 1, activeSlidePath[2]];
    }
    // Dragging section after the section with active slide
    else if (sourceIndex > activeSlidePath[1]) {
      return [activeSlidePath[0], activeSlidePath[1] + 1, activeSlidePath[2]];
    }
  }
  return activeSlidePath;
};

// Handle updating of ActiveSlidePath for Drag and Drop in Template panel when having the same parent
export const updateActiveSlidePathForSameParent = ({
  sourceIndex,
  destinationIndex,
  activeSlidePath,
  destinationPath,
}: {
  sourceIndex: number;
  destinationIndex: number;
  activeSlidePath: ReadonlyArray<number>;
  destinationPath: ReadonlyArray<number>;
}) => {
  if (isEqual(destinationPath, take(activeSlidePath, destinationPath.length))) {
    if (destinationPath.length === 2) {
      return updateActiveSlidePathInSameSection({
        sourceIndex,
        destinationIndex,
        activeSlidePath,
      });
    } else if (destinationPath.length === 1) {
      return updateActiveSlidePathInSameSectionGroup({
        sourceIndex,
        destinationIndex,
        activeSlidePath,
      });
    }
  }

  return activeSlidePath;
};

const updateActiveSlideAmongDifferentSections = ({
  sourceIndex,
  destinationIndex,
  activeSlidePath,
  destinationPath,
  sourcePath,
}: {
  sourceIndex: number;
  destinationIndex: number;
  activeSlidePath: ReadonlyArray<number>;
  destinationPath: ReadonlyArray<number>;
  sourcePath: ReadonlyArray<number>;
}) => {
  if (
    !(
      destinationPath[0] !== activeSlidePath[0] &&
      sourcePath[0] !== activeSlidePath[0]
    )
  ) {
    // Dragging active slide
    if (
      isEqual(sourcePath, take(activeSlidePath, 2)) &&
      sourceIndex === activeSlidePath[2]
    ) {
      return [...destinationPath, destinationIndex];
    }
    // a slide has been dropped before the active slide
    else if (
      isEqual(destinationPath, take(activeSlidePath, 2)) &&
      destinationIndex <= activeSlidePath[2]
    ) {
      return [...destinationPath, activeSlidePath[2] + 1];
    }
    // slide from before active slide has been dragged
    else if (
      isEqual(sourcePath, take(activeSlidePath, 2)) &&
      sourceIndex <= activeSlidePath[2]
    ) {
      return [...sourcePath, activeSlidePath[2] - 1];
    }
  }
  return activeSlidePath;
};

const updateActiveSlideAmongDifferentSectionGroups = ({
  sourceIndex,
  destinationIndex,
  activeSlidePath,
  destinationPath,
  sourcePath,
}: {
  sourceIndex: number;
  destinationIndex: number;
  activeSlidePath: ReadonlyArray<number>;
  destinationPath: ReadonlyArray<number>;
  sourcePath: ReadonlyArray<number>;
}) => {
  if (
    !(
      sourcePath[0] > activeSlidePath[0] &&
      destinationPath[0] > activeSlidePath[0]
    ) ||
    !(
      sourcePath[0] < activeSlidePath[0] &&
      destinationPath[0] < activeSlidePath[0]
    )
  ) {
    // section before active slide has been dropped
    if (
      destinationPath[0] === activeSlidePath[0] &&
      destinationIndex <= activeSlidePath[1]
    ) {
      return [...destinationPath, activeSlidePath[1] + 1, activeSlidePath[2]];
    }
    // section with current active slide is dragging
    else if (
      sourceIndex === activeSlidePath[1] &&
      activeSlidePath[0] === sourcePath[0]
    ) {
      return [...destinationPath, destinationIndex, activeSlidePath[2]];
    }
    // section before active slide has been dragged
    else if (
      sourcePath[0] === activeSlidePath[0] &&
      sourceIndex <= activeSlidePath[1]
    ) {
      return [...destinationPath, activeSlidePath[1] - 1, activeSlidePath[2]];
    }
  }
  return activeSlidePath;
};

// Handle updating of ActiveSlidePath for Drag and Drop in Template panel when moving whole section-groups
export const updateActiveSlidePathForSectionGroups = ({
  sourceIndex,
  destinationIndex,
  activeSlidePath,
}: {
  sourceIndex: number;
  destinationIndex: number;
  activeSlidePath: ReadonlyArray<number>;
}) => {
  if (sourceIndex === activeSlidePath[0]) {
    return [destinationIndex, activeSlidePath[1], activeSlidePath[2]];
  } else if (
    sourceIndex <= activeSlidePath[0] &&
    destinationIndex >= activeSlidePath[0]
  ) {
    return [activeSlidePath[0] - 1, activeSlidePath[1], activeSlidePath[2]];
  } else if (
    sourceIndex >= activeSlidePath[0] &&
    destinationIndex <= activeSlidePath[0]
  ) {
    return [activeSlidePath[0] + 1, activeSlidePath[1], activeSlidePath[2]];
  }
  return activeSlidePath;
};

// Handle updating of ActiveSlidePath for Drag and Drop in Template panel when having different parent
export const updateActiveSlidePathForDifferentParent = ({
  sourceIndex,
  destinationIndex,
  activeSlidePath,
  destinationPath,
  sourcePath,
}: {
  sourceIndex: number;
  destinationIndex: number;
  activeSlidePath: ReadonlyArray<number>;
  destinationPath: ReadonlyArray<number>;
  sourcePath: ReadonlyArray<number>;
}) => {
  if (destinationPath.length === 2) {
    return updateActiveSlideAmongDifferentSections({
      sourceIndex,
      destinationIndex,
      activeSlidePath,
      destinationPath,
      sourcePath,
    });
  } else if (destinationPath.length === 1) {
    return updateActiveSlideAmongDifferentSectionGroups({
      sourceIndex,
      destinationIndex,
      activeSlidePath,
      destinationPath,
      sourcePath,
    });
  }
  return activeSlidePath;
};

export const updateActiveSlidePathOnDelete = ({
  type,
  indexPath,
  activeSlidePath,
}: {
  type: string;
  indexPath: ReadonlyArray<number>;
  activeSlidePath: ReadonlyArray<number>;
}) => {
  if (
    type === GroupNodeType.SLIDE &&
    isEqual(take(indexPath, 2), take(activeSlidePath, 2)) &&
    indexPath[2] <= activeSlidePath[2]
  ) {
    return [
      ...take(activeSlidePath, 2),
      activeSlidePath[2] === 0 ? 0 : activeSlidePath[2] - 1,
    ];
  } else if (
    type === GroupNodeType.SECTION &&
    indexPath[0] === activeSlidePath[0] &&
    indexPath[1] <= activeSlidePath[1]
  ) {
    return [
      activeSlidePath[0],
      activeSlidePath[1] === 0 ? 0 : activeSlidePath[1] - 1,
      0,
    ];
  } else if (
    type === GroupNodeType.SECTION_GROUP &&
    indexPath[0] <= activeSlidePath[0]
  ) {
    return [activeSlidePath[0] === 0 ? 0 : activeSlidePath[0] - 1, 0, 0];
  }

  return activeSlidePath;
};
