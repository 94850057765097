import React, {useState} from "react";
import {Button, message} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import {
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";

import {
  atomizeNodes,
  isTemplateDirtyAtom,
  lastTemplateSaveAtAtom,
  persistedTemplateSelector,
  templateNameAtom,
  templateSelector,
  templateTypeAtom,
} from "../../model/template";
import {saveTemplate} from "../../services/FileService";
import {listModifiedFiles} from "../../services/GitService";
import {modifiedFilesAtom} from "../../model/repository";
import {
  useResetValidations,
  validateNodes,
  nodeErrorsSelector,
  validationSlideAtomFamilySelector,
  getErrorActiveSlideWithFocusedNode,
} from "../../model/validation";
import {
  activeSlideAtomSelector,
  activeSlidePathAtom,
  focusedAtomAtom,
  focusedAtomSelector,
} from "../../model/editor";
import {
  templatesRefsSelector,
  templatesRefsCountSelector,
  fieldArraysSelector,
} from "../../model/answers";
import {SourceSectionGroup} from "../../model/schemaTypes";
import {TemplateType} from "../../constants";

export const SaveButton = () => {
  const setTemplatePersisted = useSetRecoilState(persistedTemplateSelector);
  const isTemplateDirty = useRecoilValue(isTemplateDirtyAtom);
  const resetIsTemplateDirty = useResetRecoilState(isTemplateDirtyAtom);
  const templateName = useRecoilValue(templateNameAtom);
  const setLastTemplateSaveAt = useSetRecoilState(lastTemplateSaveAtAtom);
  const setModifiedFiles = useSetRecoilState(modifiedFilesAtom);
  const resetValidations = useResetValidations();
  const [isSubmiting, setIsSubmitting] = useState(false);

  const saveCurrentTemplate = async (
    template: ReadonlyArray<SourceSectionGroup>,
    templateType: TemplateType,
  ) => {
    await saveTemplate(template, templateName!, templateType);

    setModifiedFiles(await listModifiedFiles());
    resetIsTemplateDirty();
    setTemplatePersisted(template);
    setLastTemplateSaveAt(new Date().toISOString());

    message.success("Template has been saved");
  };

  const persistTemplate = useRecoilCallback(({snapshot, set}) => async () => {
    message.info("Validation of the template in progress, please wait");
    setIsSubmitting(true);

    await resetValidations();

    const template = await snapshot.getPromise(templateSelector);
    const templateRefs = await snapshot.getPromise(templatesRefsSelector);
    const templateRefsCount = await snapshot.getPromise(
      templatesRefsCountSelector,
    );
    const templateType = await snapshot.getPromise(templateTypeAtom);
    const fieldArrays = await snapshot.getPromise(fieldArraysSelector);
    const {errors, slideErrors, isValid} = validateNodes(
      template as any,
      templateRefs,
      fieldArrays,
      templateRefsCount,
      templateType,
    );

    const updateActiveSlideAndFocusedNode = async () => {
      const [activeSlideAtom] = await snapshot.getPromise(
        activeSlideAtomSelector,
      );
      const currentActiveSlide = await snapshot.getPromise(activeSlideAtom);

      const currentActiveSlidePath = await snapshot.getPromise(
        activeSlidePathAtom,
      );
      const currentFocusNode = await snapshot.getPromise(focusedAtomSelector);

      const {
        errorActiveSlidePath,
        errorActiveNode,
      } = getErrorActiveSlideWithFocusedNode(
        currentActiveSlidePath,
        currentActiveSlide,
        currentFocusNode,
        slideErrors,
        template,
      );
      set(activeSlidePathAtom, errorActiveSlidePath);
      set(focusedAtomAtom, atomizeNodes([errorActiveNode])[0]);
    };

    const setValidationErrors = () => {
      Object.keys(errors).forEach(errorId => {
        set(nodeErrorsSelector(errorId), errors[errorId]);
      });
      Object.keys(slideErrors).forEach(slideErrorId => {
        set(
          validationSlideAtomFamilySelector(slideErrorId),
          slideErrors[slideErrorId],
        );
      });
    };

    if (isValid) {
      message.success("Validation successfull");
      await saveCurrentTemplate(template, templateType);
    } else {
      setValidationErrors();
      if (
        window.confirm(
          "The template contains validation errors. Are you sure you want to save the template with validation errors? If yes, click ok, otherwise click cancel and fix the errors.",
        )
      ) {
        await saveCurrentTemplate(template, templateType);
      } else {
        await updateActiveSlideAndFocusedNode();
      }
    }
    setIsSubmitting(false);
  });

  return (
    <Button
      icon={<SaveOutlined />}
      onClick={persistTemplate}
      disabled={!isTemplateDirty || isSubmiting}
      loading={isSubmiting}
    >
      Save
    </Button>
  );
};
