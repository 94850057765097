import React from "react";
import {ADAPTABLE_TEXT_CLASSNAME, styled, Paragraph} from "@reside/ui";
import {Typography} from "antd";
import {blue} from "@ant-design/colors";

const {Text} = Typography;

export const ChildrenDroppable = ({help}: {help: string}) => (
  <Container>
    <Paragraph className={ADAPTABLE_TEXT_CLASSNAME}>
      <Text strong >Drop children here. </Text>
      {help}
    </Paragraph>
  </Container>
);

const Container = styled.div`
  font-size: 14px;
  margin: 12px;
  padding: 12px 24px;
  line-height: 1em;
  text-align: center;
  border: 1px dashed #d9d9d9;

  &:hover {
    border-color: ${blue.primary};
  }

  p {
    margin: 0;
    line-height: 1em;
  }

  span {
    color: inherit;
  }
`;
