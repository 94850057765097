var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../../theme";
import { H1 } from "../../text";
import { useBlockComponentContext } from "../index";
import { ADAPTABLE_TEXT_CLASSNAME } from "../index";
export var BlockTitle = function (_a) {
    var id = _a.id, translationKey = _a.translationKey, horizontalAlignment = _a.horizontalAlignment, DefaultTitle = _a.Title;
    var _b = useBlockComponentContext(), _c = _b.Title, Title = _c === void 0 ? DefaultTitle : _c, Markdown = _b.Markdown, Translated = _b.Translated;
    return (React.createElement(Title, { id: id, className: ADAPTABLE_TEXT_CLASSNAME, horizontalAlignment: horizontalAlignment },
        React.createElement(Translated, { translationKey: translationKey, render: function (_a) {
                var translation = _a.translation;
                return React.createElement(Markdown, { source: translation });
            } })));
};
export var Title = styled(H1)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 2.625em;\n  line-height: 1.5em;\n  margin-top: 10px;\n  margin-bottom: 15px;\n  text-align: ", ";\n"], ["\n  font-size: 2.625em;\n  line-height: 1.5em;\n  margin-top: 10px;\n  margin-bottom: 15px;\n  text-align: ", ";\n"])), function (_a) {
    var horizontalAlignment = _a.horizontalAlignment;
    return horizontalAlignment;
});
BlockTitle.defaultProps = {
    horizontalAlignment: "left",
    Title: Title,
};
var templateObject_1;
