var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Plugin, NodeSelection } from "prosemirror-state";
import { InputRule } from "prosemirror-inputrules";
import { setTextSelection } from "prosemirror-utils";
import { styled } from "@reside/ui";
import ImageZoom from "react-medium-image-zoom";
import getDataTransferFiles from "../lib/getDataTransferFiles";
import uploadPlaceholderPlugin from "../lib/uploadPlaceholder";
import insertFiles from "../commands/insertFiles";
import Node from "./Node";
/**
 * Matches following attributes in Markdown-typed image: [, alt, src, title]
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg "Ipsum") -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */
var IMAGE_INPUT_REGEX = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;
var STYLE = {
    display: "inline-block",
    maxWidth: "100%",
    maxHeight: "75vh",
};
var uploadPlugin = function (options) {
    return new Plugin({
        props: {
            handleDOMEvents: {
                paste: function (view, event) {
                    if ((view.props.editable && !view.props.editable(view.state)) ||
                        !options.uploadImage) {
                        return false;
                    }
                    if (!event.clipboardData)
                        return false;
                    // check if we actually pasted any files
                    var files = Array.prototype.slice
                        .call(event.clipboardData.items)
                        .map(function (dt) { return dt.getAsFile(); })
                        .filter(function (file) { return file; });
                    if (files.length === 0)
                        return false;
                    var tr = view.state.tr;
                    if (!tr.selection.empty) {
                        tr.deleteSelection();
                    }
                    var pos = tr.selection.from;
                    insertFiles(view, event, pos, files, options);
                    return true;
                },
                drop: function (view, event) {
                    if ((view.props.editable && !view.props.editable(view.state)) ||
                        !options.uploadImage) {
                        return false;
                    }
                    // filter to only include image files
                    var files = getDataTransferFiles(event).filter(function (file) {
                        return /image/i.test(file.type);
                    });
                    if (files.length === 0) {
                        return false;
                    }
                    // grab the position in the document for the cursor
                    var result = view.posAtCoords({
                        left: event.clientX,
                        top: event.clientY,
                    });
                    if (result) {
                        insertFiles(view, event, result.pos, files, options);
                        return true;
                    }
                    return false;
                },
            },
        },
    });
};
var Image = /** @class */ (function (_super) {
    __extends(Image, _super);
    function Image() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleKeyDown = function (_a) {
            var node = _a.node, getPos = _a.getPos;
            return function (event) {
                var _a, _b;
                // Pressing Enter in the caption field should move the cursor/selection
                // below the image
                if (event.key === "Enter") {
                    event.preventDefault();
                    if (!((_a = _this.editor) === null || _a === void 0 ? void 0 : _a.view)) {
                        return;
                    }
                    var view = _this.editor.view;
                    var pos = getPos() + node.nodeSize;
                    view.focus();
                    view.dispatch(setTextSelection(pos)(view.state.tr));
                    return;
                }
                // Pressing Backspace in an an empty caption field should remove the entire
                // image, leaving an empty paragraph
                if (event.key === "Backspace" && event.target.innerText === "") {
                    if (!((_b = _this.editor) === null || _b === void 0 ? void 0 : _b.view)) {
                        return;
                    }
                    var view = _this.editor.view;
                    var $pos = view.state.doc.resolve(getPos());
                    var tr = view.state.tr.setSelection(new NodeSelection($pos));
                    view.dispatch(tr.deleteSelection());
                    view.focus();
                    return;
                }
            };
        };
        _this.handleBlur = function (_a) {
            var node = _a.node, getPos = _a.getPos;
            return function (event) {
                var _a;
                var alt = event.target.innerText;
                var src = node.attrs.src;
                if (alt === node.attrs.alt)
                    return;
                if (!((_a = _this.editor) === null || _a === void 0 ? void 0 : _a.view)) {
                    return;
                }
                var view = _this.editor.view;
                var tr = view.state.tr;
                // update meta on object
                var pos = getPos();
                var transaction = tr.setNodeMarkup(pos, undefined, {
                    src: src,
                    alt: alt,
                });
                view.dispatch(transaction);
            };
        };
        _this.handleSelect = function (_a) {
            var getPos = _a.getPos;
            return function (event) {
                var _a;
                event.preventDefault();
                if (!((_a = _this.editor) === null || _a === void 0 ? void 0 : _a.view)) {
                    return;
                }
                var view = _this.editor.view;
                var $pos = view.state.doc.resolve(getPos());
                var transaction = view.state.tr.setSelection(new NodeSelection($pos));
                view.dispatch(transaction);
            };
        };
        _this.component = function (props) {
            var theme = props.theme, isEditable = props.isEditable, isSelected = props.isSelected;
            var _a = props.node.attrs, alt = _a.alt, src = _a.src;
            return (React.createElement("div", { contentEditable: false, className: "image" },
                React.createElement(ImageWrapper, { className: isSelected ? "ProseMirror-selectednode" : "", onClick: isEditable ? _this.handleSelect(props) : undefined },
                    React.createElement(ImageZoom
                    // @ts-ignore
                    , { 
                        // @ts-ignore
                        image: {
                            src: src,
                            alt: alt,
                            style: STYLE,
                        }, defaultStyles: {
                            overlay: {
                                backgroundColor: theme.background,
                            },
                        }, shouldRespectMaxDimension: true })),
                (isEditable || alt) && (React.createElement(Caption, { onKeyDown: _this.handleKeyDown(props), onBlur: _this.handleBlur(props), tabIndex: -1, contentEditable: isEditable, suppressContentEditableWarning: true }, alt))));
        };
        return _this;
    }
    Object.defineProperty(Image.prototype, "name", {
        get: function () {
            return "image";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Image.prototype, "schema", {
        get: function () {
            return {
                inline: true,
                attrs: {
                    src: {},
                    alt: {
                        default: null,
                    },
                },
                content: "text*",
                marks: "",
                group: "inline",
                selectable: true,
                draggable: true,
                parseDOM: [
                    {
                        tag: "div[class=image]",
                        getAttrs: function (dom) {
                            var img = dom.getElementsByTagName("img")[0];
                            return {
                                src: img.getAttribute("src"),
                                alt: img.getAttribute("alt"),
                            };
                        },
                    },
                ],
                toDOM: function (node) {
                    return [
                        "div",
                        {
                            class: "image",
                        },
                        ["img", __assign(__assign({}, node.attrs), { contentEditable: false })],
                        ["p", { class: "caption" }, 0],
                    ];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Image.prototype.toMarkdown = function (state, node) {
        state.write("![" +
            state.esc((node.attrs.alt || "").replace("\n", "") || "") +
            "](" +
            state.esc(node.attrs.src) +
            ")");
    };
    Image.prototype.parseMarkdown = function () {
        return {
            node: "image",
            getAttrs: function (token) { return ({
                src: token.attrGet("src"),
                alt: (token.children[0] && token.children[0].content) || null,
            }); },
        };
    };
    Image.prototype.commands = function (_a) {
        var type = _a.type;
        return function (attrs) { return function (state, dispatch) {
            var selection = state.selection;
            var position = selection.$cursor
                ? selection.$cursor.pos
                : selection.$to.pos;
            var node = type.create(attrs);
            var transaction = state.tr.insert(position, node);
            dispatch(transaction);
            return true;
        }; };
    };
    Image.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [
            new InputRule(IMAGE_INPUT_REGEX, function (state, match, start, end) {
                var okay = match[0], alt = match[1], src = match[2];
                var tr = state.tr;
                if (okay) {
                    tr.replaceWith(start - 1, end, type.create({
                        src: src,
                        alt: alt,
                    }));
                }
                return tr;
            }),
        ];
    };
    Object.defineProperty(Image.prototype, "plugins", {
        get: function () {
            return [uploadPlaceholderPlugin, uploadPlugin(this.options)];
        },
        enumerable: true,
        configurable: true
    });
    return Image;
}(Node));
export default Image;
var ImageWrapper = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  line-height: 0;\n  display: inline-block;\n"], ["\n  line-height: 0;\n  display: inline-block;\n"])));
var Caption = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 0;\n  display: block;\n  font-size: 13px;\n  font-style: italic;\n  color: ", ";\n  padding: 2px 0;\n  line-height: 16px;\n  text-align: center;\n  width: 100%;\n  min-height: 1em;\n  outline: none;\n  background: none;\n  resize: none;\n\n  &:empty:before {\n    color: ", ";\n    content: \"Write a caption\";\n    pointer-events: none;\n  }\n"], ["\n  border: 0;\n  display: block;\n  font-size: 13px;\n  font-style: italic;\n  color: ", ";\n  padding: 2px 0;\n  line-height: 16px;\n  text-align: center;\n  width: 100%;\n  min-height: 1em;\n  outline: none;\n  background: none;\n  resize: none;\n\n  &:empty:before {\n    color: ", ";\n    content: \"Write a caption\";\n    pointer-events: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue100;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue100;
});
var templateObject_1, templateObject_2;
