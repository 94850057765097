import React from "react";
import {Card, styled} from "@reside/ui";
import {CardNode} from "@reside/forms";

import {DroppableField} from "../droppable-field";
import {ChildrenDroppable} from "../children-droppable";
import {AtomizedBranchNode, AtomProp} from "../../model/editor";
import {useDragAndDrop} from "../../hooks/useDragAndDrop";
import {Override} from "../../model/schemaTypes";

export const EditableCard = ({
  atom: parentAtom,
  index,
  node: {children},
  isArrayFieldScoped,
}: {
  index: number;
  isArrayFieldScoped: boolean;
  node: Override<CardNode, AtomizedBranchNode & {id: string}>;
} & AtomProp) => {
  const {dropRef, dragRef, isDragging} = useDragAndDrop({index, parentAtom});

  const hasChildren = children?.length > 0;

  return (
    <div ref={dropRef}>
      <Card style={{minHeight: "150px", backgroundColor: "inherit"}}>
        <DragWrapper
          style={{paddingTop: "25px", paddingBottom: "25px"}}
          ref={dragRef}
          isDragging={isDragging}
        >
          {hasChildren ? (
            children.map((atom, index) => (
              <DroppableField
                index={index}
                key={atom.key}
                atom={atom}
                parentAtom={parentAtom}
                isArrayFieldScoped={isArrayFieldScoped}
              />
            ))
          ) : (
            <ChildrenDroppable help="The items will be visible once you drop here any items." />
          )}
        </DragWrapper>
      </Card>
    </div>
  );
};

const DragWrapper = styled.div<{isDragging: boolean}>`
  opacity: ${({isDragging}) => (isDragging ? 0.5 : 1)};
`;
