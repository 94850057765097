import React, {FunctionComponent, ReactNode} from "react";
import {Layout} from "antd";

import {Sider} from "../sider";
import {Content} from "../content";

export const PageLayout: FunctionComponent<{
  rightSider?: ReactNode;
  leftSider?: ReactNode;
}> = ({children, rightSider, leftSider}) => (
  <Layout style={{minHeight: "100%"}}>
    <Sider>{leftSider}</Sider>
    <Content>{children}</Content>
    <Sider>{rightSider}</Sider>
  </Layout>
);
