var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { wrappingInputRule } from "prosemirror-inputrules";
import toggleList from "../commands/toggleList";
import Node from "./Node";
var OrderedList = /** @class */ (function (_super) {
    __extends(OrderedList, _super);
    function OrderedList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(OrderedList.prototype, "name", {
        get: function () {
            return "ordered_list";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrderedList.prototype, "schema", {
        get: function () {
            return {
                attrs: {
                    order: {
                        default: 1,
                    },
                },
                content: "list_item+",
                group: "block",
                parseDOM: [
                    {
                        tag: "ol",
                        getAttrs: function (dom) { return ({
                            order: dom.hasAttribute("start")
                                ? parseInt(dom.getAttribute("start") || "1", 10)
                                : 1,
                        }); },
                    },
                ],
                toDOM: function (node) {
                    return node.attrs.order === 1
                        ? ["ol", 0]
                        : ["ol", { start: node.attrs.order }, 0];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    OrderedList.prototype.commands = function (_a) {
        var type = _a.type, schema = _a.schema;
        return function () { return toggleList(type, schema.nodes.list_item); };
    };
    OrderedList.prototype.keys = function (_a) {
        var type = _a.type, schema = _a.schema;
        return {
            "Shift-Ctrl-9": toggleList(type, schema.nodes.list_item),
        };
    };
    OrderedList.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [
            wrappingInputRule(/^(\d+)\.\s$/, type, function (match) { return ({ order: +match[1] }); }, function (match, node) { return node.childCount + node.attrs.order === +match[1]; }),
        ];
    };
    OrderedList.prototype.toMarkdown = function (state, node) {
        var start = node.attrs.order || 1;
        var maxW = ("" + (start + node.childCount - 1)).length;
        var space = state.repeat(" ", maxW + 2);
        state.renderList(node, space, function (i) {
            var nStr = "" + (start + i);
            return state.repeat(" ", maxW - nStr.length) + nStr + ". ";
        });
    };
    OrderedList.prototype.parseMarkdown = function () {
        return { block: "ordered_list" };
    };
    return OrderedList;
}(Node));
export default OrderedList;
