// - Inspired by pope. Modified to support custom `regex` option. https://github.com/poppinss/pope/pull/4
// - Extended to support expressions
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
function isObj(obj) {
    return obj !== null && typeof obj === "object";
}
export function prop(obj, path, def) {
    if (def === void 0) { def = undefined; }
    return path
        .split(" ")
        .map(function (word) { return propWord(obj, word, def); })
        .join(" ");
}
/**
 * @param obj -> object containing all the admission answers
 * @param path -> path of an admission answer or a plain string
 * @param def - default value if path is accessing answers from the admission
 *              and the answer is not found
 * @returns {string | undefined}
 */
function propWord(obj, path, def) {
    if (!isObj(obj) || typeof path !== "string") {
        return obj;
    }
    var pathArr = path.split(".");
    if (pathArr.length === 1) {
        return path;
    }
    for (var i = 0; i < pathArr.length; i++) {
        var p = pathArr[i];
        obj = obj.hasOwnProperty(p) ? obj[p] : null;
        if (obj === null) {
            break;
        }
    }
    return obj !== null ? obj : def;
}
function isExpression(item) {
    return item.startsWith("IF");
}
function parseExpression(item) {
    var _a = item.split("THEN"), rawCondition = _a[0], body = _a[1];
    var _b = body.split("ELSE").map(function (val) { return val.trim(); }), truthy = _b[0], falsy = _b[1];
    var condition = rawCondition.replace("IF", "").trim();
    return { condition: condition, truthy: truthy, falsy: falsy };
}
export function evaluateExpression(data, _a) {
    var condition = _a.condition, truthy = _a.truthy, falsy = _a.falsy;
    if (condition.includes(" = ")) {
        var _b = condition.split("=").map(function (val) { return val.trim(); }), left = _b[0], right = _b[1];
        var propLeft = String(prop(data, left, left));
        var propRight = String(prop(data, right, right));
        return propLeft === propRight
            ? // First, try to find value in data, if not send back literal from incoming string
                prop(data, truthy, truthy)
            : // First, try to find value in data, if not send back literal from incoming string
                prop(data, falsy, falsy);
    }
    else {
        // workaround for conditions testing presence of a value
        return prop(data, condition, null)
            ? prop(data, truthy, truthy)
            : prop(data, falsy, falsy);
    }
}
export function parse(string, data, options, defaultValue) {
    if (defaultValue === void 0) { defaultValue = ""; }
    options = __assign({ skipUndefined: false, throwOnUndefined: false, regex: /{{2}(.+?)}{2}/g }, options);
    var regex = options.regex;
    var result;
    var formattedString = string;
    while ((result = regex.exec(string)) !== null) {
        var item = result[1].trim();
        if (item) {
            var value = void 0;
            if (isExpression(item)) {
                var expression = parseExpression(item);
                value = evaluateExpression(data, expression, options);
            }
            else {
                var nextValue = prop(data, item, defaultValue);
                value =
                    defaultValue !== "" && nextValue === "" ? defaultValue : nextValue;
            }
            if (value !== undefined && value !== null) {
                formattedString = formattedString.replace(result[0], value);
            }
            else if (options.throwOnUndefined) {
                var error = new Error("Missing value for " + result[0]);
                error.key = item;
                error.code = "E_MISSING_KEY";
                throw error;
            }
            else if (!options.skipUndefined) {
                formattedString = formattedString.replace(result[0], "");
            }
        }
    }
    return formattedString;
}
