import React, { createContext, useContext, useState, } from "react";
/**
 * The context permits to have one checkbox datepicker open at a time.
 */
var DateCheckboxContext = createContext(undefined);
export var useDateCheckboxContext = function () {
    var context = useContext(DateCheckboxContext);
    var _a = useState(""), openedCheckboxName = _a[0], setOpenedCheckboxName = _a[1];
    var setCheckboxClosed = function () {
        setOpenedCheckboxName("");
    };
    if (context) {
        return context;
    }
    return { openedCheckboxName: openedCheckboxName, setOpenedCheckboxName: setOpenedCheckboxName, setCheckboxClosed: setCheckboxClosed };
};
export var DateCheckboxContextProvider = function (_a) {
    var children = _a.children;
    var dateCheckboxContext = useDateCheckboxContext();
    return (React.createElement(DateCheckboxContext.Provider, { value: dateCheckboxContext }, children));
};
