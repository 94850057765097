import git from "isomorphic-git";
import http from "isomorphic-git/http/web";
import {message} from "antd";
import {curry} from "lodash";

import {
  callbackFs as fs,
  ensureDirectory,
  deleteFile,
  fileExists,
} from "./FileService";
import {isFeatureBranch} from "../model/repository";
import {previewBranchName} from "../config";

export const rootDir = "/admission-builder";

const ensureGitRootDirectory = () => ensureDirectory(rootDir);

type AuthConfig = Pick<
  Parameters<typeof git.clone>[0],
  "onAuth" | "onAuthFailure"
>;

export type GitConfigUser = {
  name: string;
  email: string;
};

let authConfig: AuthConfig = {
  onAuthFailure: () => {
    message.error("Git Auth Failure!");
  },
};

/**
 * Setter for configuring credentials for the git apis once after login.
 */
export const setGitAuthConfig = (config: AuthConfig) => {
  authConfig = {...authConfig, ...config};
};

const gitApiConfig = {
  http,
  url: process.env.REACT_APP_CONTENT_GIT_URL!,
  corsProxy: `${process.env.REACT_APP_ADMISSION_LOGIC_URL!}/git-proxy`,
};

export const configureGitUser = async ({name, email}: GitConfigUser) => {
  await git.setConfig({
    fs,
    dir: rootDir,
    path: "user.name",
    value: name,
  });

  await git.setConfig({
    fs,
    dir: rootDir,
    path: "user.email",
    value: email,
  });
};

export const cloneRepository = async ({ref}: {ref: string}) => {
  await ensureGitRootDirectory();

  await git.clone({
    ...gitApiConfig,
    ...authConfig,
    fs,
    dir: rootDir,
    ref,
    depth: 1,
  });
};

export const getCurrentBranchName = () =>
  git.currentBranch({
    fs,
    dir: rootDir,
    fullname: false,
  });

export const pullBranch = (ref?: string) =>
  git.pull({
    ...gitApiConfig,
    ...authConfig,
    fs,
    dir: rootDir,
    ref,
    singleBranch: true,
    fastForwardOnly: true, // Don't create merge commits
  });

export const fetchBranch = (remoteRef?: string) =>
  git.fetch({
    ...gitApiConfig,
    ...authConfig,
    fs,
    dir: rootDir,
    remoteRef,
    singleBranch: true,
    since: new Date(),
  });

export const resetHead = async () => {
  try {
    // Optionally delete branch if present locally
    await deleteBranch(previewBranchName);
  } catch {}

  await createBranch(previewBranchName);

  await pushBranch(previewBranchName, {
    force: true,
    remoteRef: previewBranchName,
  });
};

export const pushBranch = (
  ref?: string,
  params: {force?: true; remoteRef?: string} = {},
) =>
  git.push({
    ...gitApiConfig,
    ...authConfig,
    fs,
    dir: rootDir,
    remote: "origin",
    ref,
    ...params,
  });

export const mergeBranch = (params: {
  /**
   * The receiving branch. Defaults to the current branch.
   */
  ours?: string;
  theirs: string;
}) =>
  git.merge({
    ...gitApiConfig,
    ...authConfig,
    fs,
    dir: rootDir,
    fastForwardOnly: true,
    ...params,
  });

export const createBranch = async (ref: string) =>
  git.branch({fs, dir: rootDir, ref});

export const deleteBranch = (ref: string) =>
  git.deleteBranch({fs, dir: rootDir, ref});

export const resetStagingArea = async () => {
  const files = await listModifiedFiles();

  files.forEach(async file => await deleteFile(file));
};

export const checkoutBranch = async (ref: string) => {
  await git.checkout({
    fs,
    dir: rootDir,
    ref,
    force: true,
  });

  await resetStagingArea();
};

export const listBranches = async () => {
  const [, ...branches] = await git.listBranches({
    fs,
    dir: rootDir,
    remote: "origin",
  });

  /**
   * We don't want users to checkout the 'develop' as it's 'staging' branch
   * where we merge the 'feature' branches.
   */
  return branches.filter(isFeatureBranch);
};

export const listModifiedFiles = async (cache = {}) => {
  const statuses = await git.statusMatrix({fs, dir: rootDir, cache});

  /**
   * We pick only files which were created or updated.
   * NOTE: this does not handle delete of files (currently we don't delete files)
   */
  return statuses
    .filter(([, headStatus, workdirStatus]) => headStatus !== workdirStatus)
    .map(([filepath]) => filepath);
};

/**
 * Update staging area to match work dir.
 */
export const stageAll = async () => {
  const cache = {};

  /**
   * We pick only files which were created or updated.
   * NOTE: this does not handle delete of files (currently we don't delete files)
   */
  const modifiedFiles = await listModifiedFiles(cache);

  return Promise.all(
    modifiedFiles.map(async filepath => {
      const exists = await fileExists(filepath);

      /**
       * TODO(isomorphic-git): it should not be required to check if the file exists...
       * https://github.com/isomorphic-git/isomorphic-git/issues/1099
       */
      return exists
        ? git.add({fs, dir: rootDir, cache, filepath})
        : git.remove({fs, dir: rootDir, cache, filepath});
    }),
  );
};

export const createCommit = () =>
  git.commit({
    fs,
    dir: rootDir,
    message: "Admission Builder Changes",
  });

const isGitError = curry(
  (gitErrorName: string, error: any) => error?.name === gitErrorName,
);

export const isGitNotFoundError = isGitError("NotFoundError");

export const isGitCheckoutConflictError = isGitError("CheckoutConflictError");
