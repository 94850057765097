import {atom, GetRecoilValue, RecoilState, RecoilValue} from "recoil";

import {SourceNodes, TemplateNodes} from "../schemaTypes";

export const atomizeNodes = (
  children: ReadonlyArray<TemplateNodes>,
): RecoilState<TemplateNodes>[] => {
  return children.map((node: any) =>
    atom<TemplateNodes>({
      key: `node-${node.id}`,
      default: node.children
        ? {...node, children: atomizeNodes(node.children) as any}
        : node.items
        ? {...node, items: atomizeNodes(node.items)}
        : node.reference
        ? {
            ...node,
            reference: {
              ...node.reference,
              ...(node.reference.choices
                ? {
                    choices: atomizeNodes(node.reference.choices),
                  }
                : {}),
            },
          }
        : node,
    }),
  ) as any;
};

/**
 * NOTE, this is the same funcion as deatomizeNodes bellow but run asynchronously
 * accepts getValue as Promise from useRecoilCallback
 * can not be used in selector without Suspend
 */
export const deatomizeNodesAsync = async <T>(
  children: RecoilState<TemplateNodes | SourceNodes>[],
  getValue: (atom: RecoilValue<TemplateNodes>) => Promise<TemplateNodes>,
): Promise<TemplateNodes[]> =>
  (await Promise.all(
    children.map(async atom => {
      const node = (await getValue(atom)) as any;

      return node.children
        ? {
            ...node,
            children: await deatomizeNodesAsync(node.children, getValue),
          }
        : node.items
        ? {
            ...node,
            items: await deatomizeNodesAsync(node.items, getValue),
          }
        : node.reference
        ? {
            ...node,
            reference: {
              ...node.reference,
              ...(node.reference.choices
                ? {
                    choices: await deatomizeNodesAsync(
                      node.reference.choices,
                      getValue,
                    ),
                  }
                : {}),
            },
          }
        : node;
    }),
  )) as any;

export const deatomizeNodes = (
  children: RecoilState<TemplateNodes>[],
  get: GetRecoilValue,
): TemplateNodes[] =>
  children.map(atom => {
    const node = get(atom) as any;

    return node.children
      ? {
          ...node,
          children: deatomizeNodes(node.children, get),
        }
      : node.items
      ? {...node, items: deatomizeNodes(node.items, get)}
      : node.reference
      ? {
          ...node,
          reference: {
            ...node.reference,
            ...(node.reference.choices
              ? {
                  choices: deatomizeNodes(node.reference.choices, get),
                }
              : {}),
          },
        }
      : node;
  }) as any;
