var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Plugin, Selection, AllSelection } from "prosemirror-state";
import Extension from "../lib/Extension";
var Keys = /** @class */ (function (_super) {
    __extends(Keys, _super);
    function Keys() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Keys.prototype, "name", {
        get: function () {
            return "keys";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Keys.prototype, "plugins", {
        get: function () {
            var _this = this;
            return [
                new Plugin({
                    props: {
                        // we can't use the keys bindings for this as we want to preventDefault
                        // on the original keyboard event when handled
                        handleKeyDown: function (view, event) {
                            if (view.state.selection instanceof AllSelection) {
                                if (event.key === "ArrowUp") {
                                    var selection = Selection.atStart(view.state.doc);
                                    view.dispatch(view.state.tr.setSelection(selection));
                                    return true;
                                }
                                if (event.key === "ArrowDown") {
                                    var selection = Selection.atEnd(view.state.doc);
                                    view.dispatch(view.state.tr.setSelection(selection));
                                    return true;
                                }
                            }
                            if (!event.metaKey)
                                return false;
                            if (event.key === "s") {
                                event.preventDefault();
                                _this.options.onSave();
                                return true;
                            }
                            if (event.key === "Enter") {
                                event.preventDefault();
                                _this.options.onSaveAndExit();
                                return true;
                            }
                            if (event.key === "Escape") {
                                event.preventDefault();
                                _this.options.onCancel();
                                return true;
                            }
                            return false;
                        },
                    },
                }),
            ];
        },
        enumerable: true,
        configurable: true
    });
    return Keys;
}(Extension));
export default Keys;
