var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Mark from "./Mark";
import markInputRule from "../lib/markInputRule";
var Answer = /** @class */ (function (_super) {
    __extends(Answer, _super);
    function Answer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Answer.prototype, "type", {
        get: function () {
            return "answer";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Answer.prototype, "schema", {
        get: function () {
            return {
                parseDOM: [
                    { tag: "span" },
                    { tag: "span" },
                    { style: "font-style", getAttrs: function (value) { return value === "bold"; } },
                ],
                toDOM: function () { return ["strong"]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    ;
    Answer.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [markInputRule(/(?:\[\[)([^*]+)(?:\]\])$/, type)];
    };
    Object.defineProperty(Answer.prototype, "toMarkdown", {
        get: function () {
            return {
                open: "[[",
                close: "]]",
                mixable: true,
                expelEnclosingWhitespace: true,
            };
        },
        enumerable: true,
        configurable: true
    });
    Answer.prototype.parseMarkdown = function () {
        return { mark: "answer" };
    };
    return Answer;
}(Mark));
export default Answer;
