var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../../../theme";
import { FlexColumn } from "../../../box";
/**
 * OptionItem as container for Radio or Checkbox.
 */
export var OptionItem = styled(FlexColumn)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 10px;\n  /**\n   * The full-width is important for the OptionChildren to be rendered at full-width.\n   */\n  width: 100%;\n"], ["\n  margin-right: 10px;\n  /**\n   * The full-width is important for the OptionChildren to be rendered at full-width.\n   */\n  width: 100%;\n"])));
export var OptionItemChildren = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-left: 50px;\n"], ["\n  margin-left: 50px;\n"])));
var templateObject_1, templateObject_2;
