var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Node from "./Node";
import { isInTable } from "prosemirror-tables";
var HardBreak = /** @class */ (function (_super) {
    __extends(HardBreak, _super);
    function HardBreak() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(HardBreak.prototype, "name", {
        get: function () {
            return "br";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HardBreak.prototype, "schema", {
        get: function () {
            return {
                inline: true,
                group: "inline",
                selectable: false,
                parseDOM: [{ tag: "br" }],
                toDOM: function () {
                    return ["br"];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    HardBreak.prototype.commands = function (_a) {
        var type = _a.type;
        return function () { return function (state, dispatch) {
            dispatch(state.tr.replaceSelectionWith(type.create()).scrollIntoView());
            return true;
        }; };
    };
    HardBreak.prototype.keys = function (_a) {
        var type = _a.type;
        return {
            "Shift-Enter": function (state, dispatch) {
                if (!isInTable(state))
                    return false;
                dispatch(state.tr.replaceSelectionWith(type.create()).scrollIntoView());
                return true;
            },
        };
    };
    HardBreak.prototype.toMarkdown = function (state) {
        state.write(" \\n ");
    };
    HardBreak.prototype.parseMarkdown = function () {
        return { node: "br" };
    };
    return HardBreak;
}(Node));
export default HardBreak;
