"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var templateTypes_1 = require("./templateTypes");
var json_logic_1 = require("./json-logic");
/**
 * Recompute block visibility based on form answers.
 */
exports.computeChildrenBlock = function (_a, forceVisible) {
    var node = _a.node, answers = _a.answers, template = _a.template, _b = _a.computed, computed = _b === void 0 ? { readOnly: false } : _b, _c = _a.preflightedAnswers, preflightedAnswers = _c === void 0 ? {} : _c, parentNode = _a.parentNode;
    if (forceVisible === void 0) { forceVisible = false; }
    var reference = template.references[node.reference];
    var fieldName = reference ? exports.getFieldName(reference) : undefined; // Only FormControl nodes have reference
    var _d = node, children = _d.children, items = _d.items;
    var parent = isFieldArray(parentNode) ? parentNode : node;
    var visible = isFieldArray(parentNode) || exports.blockIsVisible(answers, node) || forceVisible;
    var block = __assign(__assign({}, node), { reference: reference
            ? __assign(__assign({}, reference), { choices: reference.choices
                    ? reference.choices.map(function (choice) {
                        return computeChoiceChildrenBlock({
                            question: reference,
                            choice: choice,
                            answers: answers,
                            template: template,
                            computed: computed,
                            preflightedAnswers: preflightedAnswers,
                            parentNode: parent,
                        }, forceVisible);
                    })
                    : undefined })
            : undefined, children: isBranchBlock(children)
            ? children.map(function (node) {
                return exports.computeChildrenBlock({
                    node: node,
                    answers: answers,
                    template: template,
                    computed: computed,
                    preflightedAnswers: preflightedAnswers,
                    parentNode: parent,
                }, forceVisible);
            })
            : undefined, state: {
            disabled: computed.readOnly ||
                node.disabled === true ||
                !!preflightedAnswers[fieldName],
            visible: visible,
            preFlight: !!preflightedAnswers[fieldName],
        }, items: items
            ? items.map(function (item) { return (__assign(__assign({}, item), { children: item.children
                    ? item === null || item === void 0 ? void 0 : item.children.map(function (node) {
                        return exports.computeChildrenBlock({
                            node: node,
                            answers: answers,
                            template: template,
                            computed: computed,
                            preflightedAnswers: preflightedAnswers,
                            parentNode: parent,
                        }, forceVisible);
                    }) : undefined })); })
            : undefined });
    return block;
};
/**
 * If parent is FieldArray, we make the field visible,
 * because it might be visible for some of the items
 * and we validate only visible fields.
 */
var isFieldArray = function (parentNode) {
    return (parentNode === null || parentNode === void 0 ? void 0 : parentNode.type) === templateTypes_1.NodeType.FIELD_ARRAY;
};
/**
 * Compute state for leaf fields in choice sub-form.
 * LIMITATION: Only leaf nodes are allowed here.
 */
var computeChoiceChildrenBlock = function (_a, forceVisible) {
    var question = _a.question, answers = _a.answers, choice = _a.choice, template = _a.template, _b = _a.computed, computed = _b === void 0 ? { readOnly: false } : _b, _c = _a.preflightedAnswers, preflightedAnswers = _c === void 0 ? {} : _c, parentNode = _a.parentNode;
    var children = choice.children;
    if (!children) {
        return choice;
    }
    return __assign(__assign({}, choice), { children: children.map(function (node) {
            var reference = template.references[node.reference];
            var fieldName = reference ? exports.getFieldName(reference) : undefined;
            var children = node.children;
            var visible = isFieldArray(parentNode) ||
                (exports.choiceIsActive(question, answers, choice) &&
                    exports.blockIsVisible(answers, node)) ||
                forceVisible;
            return __assign(__assign({}, node), { reference: reference
                    ? __assign(__assign({}, reference), { choices: visible && reference.choices
                            ? reference.choices.map(function (choice) {
                                return computeChoiceChildrenBlock({
                                    question: reference,
                                    choice: choice,
                                    answers: answers,
                                    template: template,
                                    computed: computed,
                                    preflightedAnswers: preflightedAnswers,
                                    parentNode: parentNode,
                                }, forceVisible);
                            })
                            : undefined })
                    : undefined, children: visible && isBranchBlock(children)
                    ? children.map(function (node) {
                        return exports.computeChildrenBlock({
                            node: node,
                            answers: answers,
                            template: template,
                            computed: computed,
                            preflightedAnswers: preflightedAnswers,
                            parentNode: parentNode,
                        }, forceVisible);
                    })
                    : undefined, state: {
                    visible: visible,
                    disabled: computed.readOnly ||
                        node.disabled === true ||
                        !!preflightedAnswers[fieldName],
                    preFlight: !!preflightedAnswers[fieldName],
                } });
        }) });
};
/**
 * Given question reference field and form values, check if the provided choice is active.
 */
exports.choiceIsActive = function (question, values, choice) {
    var activeChoices = values[exports.getFieldName(question)];
    if (question.type === templateTypes_1.QuestionType.CHECKBOX && Array.isArray(activeChoices)) {
        return activeChoices.includes(exports.getFieldName(choice));
    }
    else if (question.type === templateTypes_1.QuestionType.RADIO &&
        typeof activeChoices === "string") {
        return activeChoices === exports.getFieldName(choice);
    }
    else {
        return false;
    }
};
exports.blockIsVisible = function (values, block) {
    if (typeof block.visible === "boolean") {
        return block.visible;
    }
    if (block.visible instanceof Object) {
        return json_logic_1.jsonLogic.apply(block.visible, values);
    }
    return true;
};
var isBranchBlock = function (children) {
    return Array.isArray(children);
};
exports.getBlockName = function (block) {
    return block.reference ? exports.getFieldName(block.reference) : exports.getFieldName(block);
};
exports.getFieldName = function (item) { return item.id; };
exports.isResidentExperienceContextNode = function (node) {
    return node.type !== "pdf" &&
        (!node.visibilityContext ||
            node.visibilityContext.includes(templateTypes_1.VisibilityContextType.RE));
};
exports.isPdfContextNode = function (node) {
    var _a;
    return node.type === "pdf" || ((_a = node.visibilityContext) === null || _a === void 0 ? void 0 : _a.includes(templateTypes_1.VisibilityContextType.PDF));
};
exports.isSlideNode = function (node) {
    return node.type === templateTypes_1.GroupNodeType.SLIDE;
};
exports.isTableOfContentNode = function (node) {
    return node.type === templateTypes_1.NodeType.TABLE_OF_CONTENT;
};
exports.extractTemplateSlides = function (template, answers) {
    return template.children.flatMap(function (sectionGroup) {
        return sectionGroup.children.flatMap(function (section) {
            var isSectionVisible = exports.blockIsVisible(answers, section);
            return section.children.filter(exports.isSlideNode).flatMap(function (slide) {
                return __assign(__assign({}, slide), { children: slide.children.map(function (node) {
                        return exports.computeChildrenBlock({
                            node: node,
                            answers: answers,
                            template: template,
                        });
                    }), state: {
                        visible: isSectionVisible && exports.blockIsVisible(answers, slide),
                    } });
            });
        });
    });
};
/**
 * Get array of visible template nodes - slides or pdf elements.
 */
exports.extractVisibleTemplateNodes = function (template, values, forceVisible) {
    if (forceVisible === void 0) { forceVisible = false; }
    return template.children.flatMap(function (sectionGroup) {
        return sectionGroup.children.flatMap(function (section) {
            var sectionIsVisible = exports.blockIsVisible(values, section) || forceVisible;
            return section.children.filter(function (slide) {
                return sectionIsVisible &&
                    !slide.afterAdmission &&
                    (exports.blockIsVisible(values, slide) || forceVisible);
            });
        });
    });
};
