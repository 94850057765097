var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { NodeType } from "@reside/forms/dist/templateTypes";
import { Button } from "../../button";
import { Modal, ModalContent } from "../../modal";
import { BlockField } from "../../form-blocks";
export var ModalRenderer = function (_a) {
    var staticContentId = _a.staticContentId, staticContent = _a.staticContent, children = _a.children;
    if (!staticContent) {
        return null;
    }
    return (React.createElement(Modal, { trigger: function (ref) { return (React.createElement(Button, { ref: ref, testId: staticContentId, type: "link", underline: true }, children)); } }, function () {
        var content = staticContent.find(function (content) { return content.id === staticContentId; });
        return (React.createElement(ModalContent, null, content === null || content === void 0 ? void 0 : content.children.map(function (block) { return (React.createElement(BlockField, { key: block.id, block: 
            // TODO: pass to each block, if static content is used on more nodes besides paragraph.
            block.type === NodeType.PARAGRAPH
                ? __assign({ staticContent: staticContent }, block) : block })); })));
    }));
};
