var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Text } from "../../text";
import { Box } from "../../box";
import { useBlockComponentContext } from "../../form-blocks";
export var SectionRow = function (_a) {
    var translationKey = _a.translationKey;
    var Translated = useBlockComponentContext().Translated;
    return (jsx(Box, { flexDirection: "row", alignItems: "center", className: "sectionrow-0-44" },
        jsx(Text, { fontWeight: "bold", fontSize: "1.25em", css: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          padding-left: 45px;\n        "], ["\n          padding-left: 45px;\n        "]))) },
            jsx(Translated, { translationKey: translationKey }))));
};
var templateObject_1;
