import React from "react";
import {Tag, Typography} from "antd";
import {isEnv} from "../../config";

const {Text} = Typography;

const version = process.env.REACT_APP_VERSION;

export const EnvTag = () => {
  const isTestEnv = ["staging", "test", "dev"].find(isEnv);
  const isLocalEnv = ["localhost"].find(isEnv);

  return (
    <>
      <Tag color={isTestEnv || isLocalEnv ? "blue" : "orange"}>
        {isTestEnv ? "TEST" : isLocalEnv ? "LOCAL" : "PROD"} ENVIRONMENT
      </Tag>
      {version && <Text code>{process.env.REACT_APP_VERSION}</Text>}
    </>
  );
};
