"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_FILE_SIZE_RULE_NAME = "max_file_size";
exports.Validator = {
    name: exports.MAX_FILE_SIZE_RULE_NAME,
    errorMessage: "The file is too large. Maximum file size is :" + exports.MAX_FILE_SIZE_RULE_NAME + " MB.",
    validate: function (value, requirement) {
        var maxFileSizeInMB = parseInt(requirement, 10);
        // Handle File for FileUploadField
        if (value instanceof File) {
            return bytesToMegaBytes(value.size) <= maxFileSizeInMB;
        }
        // Handle base64 string in for SnapshotField (Image)
        if (typeof value === "string") {
            var approximateBase64SizeInBytes = 3 * (String(value).length / 4);
            return bytesToMegaBytes(approximateBase64SizeInBytes) <= maxFileSizeInMB;
        }
        // The value is a link to existing FileReference, no need to check for maximum file size.
        return true;
    },
};
var bytesToMegaBytes = function (bytes) { return bytes / 1024 / 1024; };
