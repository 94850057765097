var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Node from "./Node";
var Answer = /** @class */ (function (_super) {
    __extends(Answer, _super);
    function Answer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Answer.prototype, "name", {
        get: function () {
            return "answer";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Answer.prototype, "schema", {
        get: function () {
            return {
                attrs: {
                    id: { default: "" },
                    value: { default: "" },
                    label: { default: "" },
                },
                inline: true,
                group: "inline",
                draggable: true,
                atom: true,
                toDOM: function (node) { return [
                    "span",
                    {
                        id: node.attrs.id,
                        value: node.attrs.value,
                        label: node.attrs.label,
                        class: "ant-tag ant-tag-blue",
                        contentEditable: false,
                    },
                    node.attrs.label || node.attrs.id,
                ]; },
                parseDOM: [
                    {
                        tag: "span.ant-tag",
                        getAttrs: function (dom) {
                            var id = dom.getAttribute("id");
                            var value = dom.getAttribute("value");
                            var label = dom.getAttribute("label");
                            return { id: id, value: value, label: label };
                        },
                    },
                ],
            };
        },
        enumerable: true,
        configurable: true
    });
    Answer.prototype.commands = function (_a) {
        var type = _a.type;
        return function (attrs) { return function (state, dispatch) {
            dispatch(state.tr.replaceSelectionWith(type.create(attrs)));
            return true;
        }; };
    };
    Answer.prototype.toMarkdown = function (state, node) {
        state.write("[[" + node.attrs.id + "]]");
        state.renderInline(node);
    };
    Answer.prototype.parseMarkdown = function () {
        return {
            node: "answer",
            getAttrs: function (token) { return ({
                id: token.attrGet("id"),
                value: token.attrGet("value"),
                label: token.attrGet("label"),
            }); },
        };
    };
    return Answer;
}(Node));
export default Answer;
