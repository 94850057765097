var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../theme";
import { Icon } from "../icon";
export var InfoBar = function (_a) {
    var accent = _a.accent, icon = _a.icon, title = _a.title;
    return (React.createElement(Container, { className: accent },
        React.createElement(Icon, { name: icon, size: 24 }),
        React.createElement(React.Fragment, null, title)));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 65px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  pointer-events: none;\n  animation: fade-in 0.5s ease-in both;\n  font-size: ", "px;\n\n  .icon {\n    margin-right: 8px;\n  }\n\n  &.success {\n    color: ", ";\n    background-color: ", ";\n  }\n\n  &.info {\n    color: ", ";\n    background-color: ", ";\n  }\n"], ["\n  width: 100%;\n  height: 65px;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  pointer-events: none;\n  animation: fade-in 0.5s ease-in both;\n  font-size: ", "px;\n\n  .icon {\n    margin-right: 8px;\n  }\n\n  &.success {\n    color: ", ";\n    background-color: ", ";\n  }\n\n  &.info {\n    color: ", ";\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontSize[4];
}, function (_a) {
    var theme = _a.theme;
    return theme.color.green70;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.green10;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.lightBlue;
});
var templateObject_1;
