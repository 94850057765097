"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dive = function (currentKey, into, target) {
    for (var i in into) {
        if (into.hasOwnProperty(i)) {
            var newKey = i;
            var newVal = into[i];
            if (currentKey.length > 0) {
                newKey = currentKey + "." + i;
            }
            if (typeof newVal === "object") {
                dive(newKey, newVal, target);
            }
            else {
                target[newKey] = newVal;
            }
        }
    }
};
exports.flatten = function (obj) {
    var newObj = {};
    dive("", obj, newObj);
    return newObj;
};
