import {useCallback, useMemo} from "react";
import {atom, useRecoilState} from "recoil";
import {ImmutableTree, Utils as QbUtils} from "react-awesome-query-builder";
import {cloneDeep, debounce} from "lodash";

import {FormControlSourceNode} from "../schemaTypes";
import {useFocusedNode, useSetFocusedNodePropertyValue} from "../editor";
import {ConfigWithFlatPaths} from "../../atoms/query-builder/config";

export const treeVisibleByNodesByIdAtom = atom<Record<string, ImmutableTree>>({
  key: "treeVisibleByNodesById",
  default: {},
});

export const defaultTree = QbUtils.loadTree({
  id: QbUtils.uuid(),
  type: "group",
});

export const useTreeVisibleNode = ({
  nodeId,
  tree,
}: {
  nodeId: string;
  tree?: ImmutableTree;
}) => {
  const [trees, setTrees] = useRecoilState(treeVisibleByNodesByIdAtom);

  const setTree = (tree: ImmutableTree = defaultTree) => {
    setTrees(state => ({...state, [nodeId]: tree}));
  };

  return {
    tree: trees[nodeId] || tree || defaultTree,
    setTree,
    resetTree: () => setTree(),
  };
};

export const useFocusedNodeTree = ({config}: {config: ConfigWithFlatPaths}) => {
  const node = useFocusedNode();

  const {visible, setVisible} = useFocusedNodeVisible();

  const {flatPaths} = config;

  const visibleWithPaths = !visible
    ? visible
    : JSON.parse(
        JSON.stringify(visible, (key, value) => {
          if (key === "var") {
            return flatPaths[value] ?? value;
          }
          return value;
        }),
      );

  const {tree, setTree} = useTreeVisibleNode({
    nodeId: node!.id,
    tree: visible
      ? QbUtils.checkTree(
          QbUtils.loadFromJsonLogic(visibleWithPaths, config),
          config,
        )
      : undefined,
  });

  return useMemo(
    () => ({
      tree,
      setTree: debounce((tree: ImmutableTree) => {
        setTree(tree);

        if (config) {
          const {logic} = QbUtils.jsonLogicFormat(tree, config);

          if (logic) {
            const customLogic = JSON.parse(
              JSON.stringify(logic, (key, value) => {
                if (key === "var") {
                  const paths = value.split(".");
                  return paths[paths.length - 1];
                }
                return value;
              }),
            );
            setVisible(customLogic);
          } else {
            setVisible(undefined);
          }
        }
      }, 500),
    }),
    [config, setTree, setVisible, tree],
  );
};

export const useFocusedNodeVisible = () => {
  const node = useFocusedNode() as FormControlSourceNode;
  const visible = cloneDeep(node?.visible);

  const setPropertyVisible = useSetFocusedNodePropertyValue("visible", {
    replace: true,
    replaceArray: true,
  });

  const setVisible = useCallback(
    (visible: undefined | boolean | object) => {
      setPropertyVisible(cloneDeep(visible));
    },
    [setPropertyVisible],
  );

  return useMemo(
    () => ({
      visible,
      setVisible,
    }),
    [visible, setVisible],
  );
};
