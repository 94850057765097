var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import TooltipLite from "react-tooltip-lite";
import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { styled, theme } from "../theme";
export var Tooltip = function (props) {
    var theme = useTheme();
    return (React.createElement(StyledTooltip, __assign({ background: theme.color.gray100, arrowSize: 7, tipContentClassName: tooltipClass }, props)));
};
var StyledTooltip = styled(TooltipLite)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  white-space: nowrap;\n  border-radius: 6px;\n  cursor: pointer;\n"], ["\n  white-space: nowrap;\n  border-radius: 6px;\n  cursor: pointer;\n"])));
var tooltipClass = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n\n  span {\n    z-index: 1100 !important;\n  }\n"], ["\n  color: ", ";\n\n  span {\n    z-index: 1100 !important;\n  }\n"])), theme.color.white);
var templateObject_1, templateObject_2;
