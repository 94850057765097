var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Plugin } from "prosemirror-state";
import copy from "copy-to-clipboard";
import { Decoration, DecorationSet } from "prosemirror-view";
import { textblockTypeInputRule } from "prosemirror-inputrules";
import { setBlockType } from "prosemirror-commands";
import backspaceToParagraph from "../commands/backspaceToParagraph";
import toggleBlockType from "../commands/toggleBlockType";
import headingToSlug from "../lib/headingToSlug";
import Node from "./Node";
import { ToastType } from "../types";
var Heading = /** @class */ (function (_super) {
    __extends(Heading, _super);
    function Heading() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.className = "heading-name";
        _this.handleCopyLink = function () {
            return function (event) {
                // this is unfortunate but appears to be the best way to grab the anchor
                // as it's added directly to the dom by a decoration.
                var anchor = event.currentTarget.parentNode.previousSibling;
                if (!anchor.className.includes(_this.className)) {
                    throw new Error("Did not find anchor as previous sibling of heading");
                }
                var hash = "#" + anchor.id;
                // the existing url might contain a hash already, lets make sure to remove
                // that rather than appending another one.
                var urlWithoutHash = window.location.href.split("#")[0];
                copy(urlWithoutHash + hash);
                if (_this.options.onShowToast) {
                    _this.options.onShowToast(_this.options.dictionary.linkCopied, ToastType.Info);
                }
            };
        };
        return _this;
    }
    Object.defineProperty(Heading.prototype, "name", {
        get: function () {
            return "heading";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Heading.prototype, "defaultOptions", {
        get: function () {
            return {
                levels: [1, 2, 3, 4],
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Heading.prototype, "schema", {
        get: function () {
            var _this = this;
            return {
                attrs: {
                    level: {
                        default: 1,
                    },
                },
                content: "inline*",
                group: "block",
                defining: true,
                draggable: false,
                parseDOM: this.options.levels.map(function (level) { return ({
                    tag: "h" + level,
                    attrs: { level: level },
                    contentElement: "span",
                }); }),
                toDOM: function (node) {
                    var button = document.createElement("button");
                    button.innerText = "#";
                    button.type = "button";
                    button.className = "heading-anchor";
                    button.addEventListener("click", _this.handleCopyLink());
                    return [
                        "h" + (node.attrs.level + (_this.options.offset || 0)),
                        button,
                        ["span", 0],
                    ];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Heading.prototype.toMarkdown = function (state, node) {
        state.write(state.repeat("#", node.attrs.level) + " ");
        state.renderInline(node);
        state.closeBlock(node);
    };
    Heading.prototype.parseMarkdown = function () {
        return {
            block: "heading",
            getAttrs: function (token) { return ({
                level: +token.tag.slice(1),
            }); },
        };
    };
    Heading.prototype.commands = function (_a) {
        var type = _a.type, schema = _a.schema;
        return function (attrs) {
            return toggleBlockType(type, schema.nodes.paragraph, attrs);
        };
    };
    Heading.prototype.keys = function (_a) {
        var type = _a.type;
        var options = this.options.levels.reduce(function (items, level) {
            var _a;
            return (__assign(__assign({}, items), (_a = {},
                _a["Shift-Ctrl-" + level] = setBlockType(type, { level: level }),
                _a)));
        }, {});
        return __assign(__assign({}, options), { Backspace: backspaceToParagraph(type) });
    };
    Object.defineProperty(Heading.prototype, "plugins", {
        get: function () {
            var _this = this;
            var getAnchors = function (doc) {
                var decorations = [];
                var previouslySeen = {};
                doc.descendants(function (node, pos) {
                    if (node.type.name !== _this.name)
                        return;
                    // calculate the optimal id
                    var slug = headingToSlug(node);
                    var id = slug;
                    // check if we've already used it, and if so how many times?
                    // Make the new id based on that number ensuring that we have
                    // unique ID's even when headings are identical
                    if (previouslySeen[slug] > 0) {
                        id = headingToSlug(node, previouslySeen[slug]);
                    }
                    // record that we've seen this slug for the next loop
                    previouslySeen[slug] =
                        previouslySeen[slug] !== undefined ? previouslySeen[slug] + 1 : 1;
                    decorations.push(Decoration.widget(pos, function () {
                        var anchor = document.createElement("a");
                        anchor.id = id;
                        anchor.className = _this.className;
                        return anchor;
                    }, {
                        side: -1,
                        key: id,
                    }));
                });
                return DecorationSet.create(doc, decorations);
            };
            var plugin = new Plugin({
                state: {
                    init: function (config, state) {
                        return getAnchors(state.doc);
                    },
                    apply: function (tr, oldState) {
                        return tr.docChanged ? getAnchors(tr.doc) : oldState;
                    },
                },
                props: {
                    decorations: function (state) { return plugin.getState(state); },
                },
            });
            return [plugin];
        },
        enumerable: true,
        configurable: true
    });
    Heading.prototype.inputRules = function (_a) {
        var type = _a.type;
        return this.options.levels.map(function (level) {
            return textblockTypeInputRule(new RegExp("^(#{1," + level + "})\\s$"), type, function () { return ({
                level: level,
            }); });
        });
    };
    return Heading;
}(Node));
export default Heading;
