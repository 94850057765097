import React from "react";
import { BoldOutlined, ItalicOutlined } from "@ant-design/icons";
import isMarkActive from "../queries/isMarkActive";
export default function formattingMenuItems(state) {
    var schema = state.schema;
    return [
        {
            name: "strong",
            tooltip: "Bold",
            icon: function () { return React.createElement(BoldOutlined, null); },
            active: isMarkActive(schema.marks.strong),
        },
        {
            name: "em",
            tooltip: "Italic",
            icon: function () { return React.createElement(ItalicOutlined, null); },
            active: isMarkActive(schema.marks.em),
        },
    ];
}
