import React, {memo, FunctionComponent, useEffect} from "react";
import {useRecoilCallback, useRecoilValue} from "recoil";
import {css} from "@emotion/react";
import {Alert} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {red} from "@ant-design/colors";
import {styled} from "@reside/ui";

import {
  nodeErrorsSelector,
  useRemoveValidationErrors,
  useValidateAtom,
  touchedNodesFamilySelector,
} from "../../model/validation";
import {
  AtomizedBranchNode,
  AtomProp,
  focusedAtomAtom,
  useActiveSlideAtom,
  useFocusedNode,
} from "../../model/editor";
import {NodeName} from "../node-name";
import {QueryBuilder} from "../query-builder";
import {NodeByType} from "../NodeByType";
import {VisibilityIndicator} from "./VisibilityIndicator";
import {SourceNodes, SourceSlide} from "../../model/schemaTypes";
import {AnswerNameInput} from "../answer-name-input";
import {ScrollTarget} from "../../hooks/useScrollIntoView";
import {isFieldArray} from "../../utils";
import {UnfocusNodeButton} from "./UnfocusNodeButton";
import {deatomizedFocusedNodeSelector} from "../../model/template";

export const EditableNode: FunctionComponent<
  AtomProp & {
    index: number;
    isFocused: boolean;
    onDelete: () => void;
    isArrayFieldScoped: boolean;
  }
> = memo(({atom, isFocused, index, onDelete, isArrayFieldScoped}) => {
  const node = useRecoilValue(atom) as SourceNodes;
  const errors = useRecoilValue(nodeErrorsSelector(node.id));
  const activeSlideAtom = useActiveSlideAtom();
  const activeSlide = useRecoilValue<SourceSlide & AtomizedBranchNode>(
    activeSlideAtom as any,
  );
  const validateAtom = useValidateAtom(atom, activeSlide, isArrayFieldScoped);
  const removeValidations = useRemoveValidationErrors(node);
  const focusNode = useFocusedNode();
  const isTouched = useRecoilValue(touchedNodesFamilySelector(node.id));
  const visible = node?.visible;
  const hasVisibilityCondition = typeof visible === "object";
  const hasError = errors.length > 0;
  const deatomizedFocusedNode = useRecoilValue(deatomizedFocusedNodeSelector);

  useEffect(() => {
    if (isTouched) {
      setTimeout(() => validateAtom(), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deatomizedFocusedNode, isTouched]);

  const handleClick = useRecoilCallback(
    ({set}) => async (node: SourceNodes) => {
      set(touchedNodesFamilySelector(node.id), true);
      set(focusedAtomAtom, atom);

      if (focusNode) {
        set(touchedNodesFamilySelector(focusNode.id), true);
      }
    },
  );
  const customPropertyPath = isFieldArray(node) ? "id" : undefined;

  return (
    <ScrollTarget
      onClick={async event => {
        event.stopPropagation();
        if (isTouched) {
          validateAtom();
        }
        await handleClick(node);
      }}
      id={node.id}
    >
      {isFocused && (
        <Wrapper>
          <NodeName node={node} />
          <IconGrid>
            <AnswerNameInput propertyPath={customPropertyPath} />
            <DeleteOutlined
              style={{color: red.primary}}
              onClick={event => {
                event.stopPropagation();
                event.stopPropagation();
                onDelete();
                removeValidations();
              }}
            />
            <UnfocusNodeButton />
          </IconGrid>
        </Wrapper>
      )}
      <VisibilityIndicator
        hasVisibilityCondition={hasVisibilityCondition}
        visibilityContext={node.visibilityContext}
      >
        <Border hasBorder={isFocused} hasError={hasError}>
          <NodeByType
            index={index}
            atom={atom}
            node={node}
            isFocused={isFocused}
            isArrayFieldScoped={isArrayFieldScoped}
          />
          {isFocused && <QueryBuilder />}
          {hasError &&
            errors.map((error, key) => (
              <Alert
                key={key}
                showIcon={false}
                type="error"
                message={error}
                banner
              />
            ))}
        </Border>
      </VisibilityIndicator>
    </ScrollTarget>
  );
});

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;
`;

const IconGrid = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`;

const Border = styled.div<{
  hasBorder?: boolean;
  hasError: boolean;
}>`
  ${({hasBorder}) =>
    hasBorder &&
    css`
      border: 1px solid #a8b6ca;
    `};

  ${({hasError}) =>
    hasError &&
    css`
      border: 2px solid #ffccc7;
      margin-bottom: 10px;
    `};
`;
