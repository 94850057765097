import React from "react";
import {Form} from "antd";
import {
  SignatureContainer,
  SignatureCanvasContainer,
  EmptySignatureMessage,
} from "@reside/ui/dist/form/atoms/signature/Signature";

import {DisabledAttribute} from "../disabled-attribute";
import {RequiredAttribute} from "../required-attribute";
import {AtomProp, useFocusedNode} from "../../model/editor";
import {FormControlSourceNode} from "../../model/schemaTypes";
import {EditableLabelAttribute} from "../editable-label-attribute";

export const EditableSignatureField = ({
  id,
  atom,
  disabled,
}: {id: string; disabled: boolean} & AtomProp) => (
  <SignatureContainer disabled={disabled}>
    <EditableLabelAttribute atom={atom} />
    <SignatureCanvasContainer
      disabled={disabled}
      filled={false}
      style={{height: 330}}
    >
      <EmptySignatureMessage />
    </SignatureCanvasContainer>
  </SignatureContainer>
);

export const SignatureFieldForm = () => {
  const node = useFocusedNode() as FormControlSourceNode;

  return (
    <Form key={node.id}>
      <DisabledAttribute />
      <RequiredAttribute />
    </Form>
  );
};
