import React, {CSSProperties, useCallback} from "react";
import {RecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import * as immutable from "object-path-immutable";
import {css} from "@emotion/react";

import {styled} from "@reside/ui";

import {useDragAndDrop} from "../../hooks/useDragAndDrop";
import {isCommonImportedComponent} from "../../utils";
import {
  useIsAtomFocused,
  useActiveSlideAtom,
  AtomizedBranchNode,
  focusedAtomAtom,
} from "../../model/editor";
import {CommonComponentGuard} from "../common-component-guard";
import {EditableNode} from "../editable-node";
import {SourceSlide, TemplateNodes} from "../../model/schemaTypes";
import {useTemplateAtomState} from "../../model/template";
import {
  activeSlideIsConstantCommonImportSelector,
  slideIsCommonImportSelector,
} from "../../model/library";

type Props = Readonly<{
  index: number;
  atom: RecoilState<TemplateNodes>;
  parentAtom: RecoilState<TemplateNodes>;
  customStyle?: CSSProperties;
  isArrayFieldScoped?: boolean;
}>;

export const DroppableField = ({
  parentAtom,
  atom,
  index,
  customStyle,
  isArrayFieldScoped = false,
}: Props) => {
  const node = useRecoilValue(atom);
  const [parent, setParent] = useTemplateAtomState(parentAtom);
  const activeSlideAtom = useActiveSlideAtom();
  const activeSlide = useRecoilValue<SourceSlide & AtomizedBranchNode>(
    activeSlideAtom as any,
  );
  const isFocused = useIsAtomFocused(atom);

  const {dropRef, dragRef, isDragging} = useDragAndDrop({
    index,
    parentAtom,
    canDrag: !isFocused,
  });
  const slideIsCommon = useRecoilValue(slideIsCommonImportSelector);
  const slideIsConstant = useRecoilValue(
    activeSlideIsConstantCommonImportSelector,
  );

  const resetFocusedAtom = useResetRecoilState(focusedAtomAtom);

  const handleDelete = useCallback(() => {
    setParent({
      ...parent,
      children: immutable.del(parent.children, `${index}`),
    });
    resetFocusedAtom();
  }, [index, parent, resetFocusedAtom, setParent]);

  const isCommonImport = isCommonImportedComponent(node);

  /**
   * drag refs sets draggable attribute which prevents contenteditable cursor navigation in children.
   * Only partial solution as for deeply nested elements there are multiple draggable parents.
   * TODO: remove draggable on all parent elements of the focused item?
   */
  const hackProps = isFocused ? {} : {ref: dragRef};

  return (
    <div ref={dropRef} style={customStyle}>
      <DragWrapper
        canDrag={!isFocused}
        {...hackProps}
        isDragging={isDragging}
        darkBackground={activeSlide.darkBackground ?? false}
      >
        {slideIsConstant ? (
          <EditableNode
            isFocused={isFocused}
            atom={atom}
            onDelete={handleDelete}
            index={index}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        ) : (
          <CommonComponentGuard
            atom={atom}
            disabled={!slideIsCommon && isCommonImport}
            tooltipTitle="The is a imported component and cannot be edited. Click on inline to copy the children into the parent slide."
            text="Common component."
            inlineButtonText="Inline into parent slide"
          >
            <EditableNode
              isFocused={isFocused}
              atom={atom}
              onDelete={handleDelete}
              index={index}
              isArrayFieldScoped={isArrayFieldScoped}
            />
          </CommonComponentGuard>
        )}
      </DragWrapper>
    </div>
  );
};

const DragWrapper = styled.div<{
  isDragging: boolean;
  canDrag: boolean;
  darkBackground: boolean;
}>`
  opacity: ${({isDragging}) => (isDragging ? 0.5 : 1)};
  transition: all 0.5s ease;

  ${({canDrag}) =>
    canDrag &&
    css`
      cursor: move;
    `}

  :hover {
    ${({darkBackground}) =>
      darkBackground
        ? css`
            background: rgba(255, 255, 255, 0.15);
            // antd based shadow
            box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
              0 3px 6px 0 rgba(0, 0, 0, 0.12),
              0 5px 12px 4px rgba(0, 0, 0, 0.09);
          `
        : css`
            background: rgba(24, 144, 255, 0.05);
            box-shadow: 0 1px 2px -2px rgba(12, 72, 128, 0.16),
              0 3px 6px 0 rgba(12, 72, 128, 0.12),
              0 5px 12px 4px rgba(12, 72, 128, 0.09);
          `}
  }
`;
