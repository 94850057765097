export var previewProps = {
    slideIndex: 1,
    onSlideClick: function () { },
    showIncompleteSlidesOnly: false,
    onToggleIncomplete: function () { },
    incompleteSlides: {},
    fullTOC: {
        groups: [
            {
                children: [],
                id: "1",
                totalSlides: 1,
                completedSlides: 0,
                flaggedSlides: 1,
                title: {
                    translationKey: "First Section (flagged)",
                },
                state: {
                    totalSlides: 1,
                },
            },
            {
                children: [],
                id: "2",
                totalSlides: 2,
                completedSlides: 2,
                flaggedSlides: 0,
                title: {
                    translationKey: "Second Section (done)",
                },
                state: {
                    totalSlides: 2,
                },
            },
            {
                children: [],
                id: "3",
                totalSlides: 3,
                completedSlides: 2,
                flaggedSlides: 0,
                title: {
                    translationKey: "Third Section (default)",
                },
                state: {
                    totalSlides: 3,
                },
            },
        ],
        state: {
            totalSlides: 2,
            completedSlides: 1,
        },
    },
};
