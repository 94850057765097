var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { css } from "@emotion/css";
var MinimalPlayer = /** @class */ (function (_super) {
    __extends(MinimalPlayer, _super);
    function MinimalPlayer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.videoRef = React.createRef();
        _this.state = {
            playing: false,
        };
        _this.handleVideoPlaybackStart = function () {
            _this.setState({ playing: true });
        };
        _this.handleVideoPlaybackPaused = function () {
            _this.setState({ playing: false });
        };
        _this.handleVideoPlaybackFinished = function () {
            if (_this.props.onEnd) {
                _this.props.onEnd();
            }
        };
        _this.play = function (event) {
            event.stopPropagation();
            if (_this.videoRef.current) {
                _this.videoRef.current.play();
            }
        };
        _this.pause = function (event) {
            event.stopPropagation();
            if (_this.videoRef.current) {
                _this.videoRef.current.pause();
            }
        };
        return _this;
    }
    MinimalPlayer.prototype.componentDidMount = function () {
        if (this.videoRef.current) {
            this.videoRef.current.addEventListener("play", this.handleVideoPlaybackStart);
            this.videoRef.current.addEventListener("pause", this.handleVideoPlaybackPaused);
            this.videoRef.current.addEventListener("ended", this.handleVideoPlaybackFinished);
        }
    };
    MinimalPlayer.prototype.componentWillUnmount = function () {
        if (this.videoRef.current) {
            this.videoRef.current.removeEventListener("play", this.handleVideoPlaybackStart);
            this.videoRef.current.removeEventListener("pause", this.handleVideoPlaybackPaused);
            this.videoRef.current.removeEventListener("ended", this.handleVideoPlaybackFinished);
        }
    };
    MinimalPlayer.prototype.render = function () {
        var _a = this.props, source = _a.source, onEnd = _a.onEnd, videoElementProps = __rest(_a, ["source", "onEnd"]);
        return (React.createElement("div", { className: styles.blockContainer, style: { width: this.props.width, height: this.props.height } },
            React.createElement("video", __assign({ ref: this.videoRef }, videoElementProps),
                React.createElement("source", { src: this.props.source })),
            React.createElement("div", { className: styles.pauseButton, onClick: this.pause }, this.state.playing === false && (React.createElement("div", { className: styles.playButton, onClick: this.play, "data-test-id": "mandatory-video-block-play-button" },
                React.createElement("img", { src: require("./assets/button_play.svg"), width: "76", height: "76", alt: "" }))))));
    };
    return MinimalPlayer;
}(React.Component));
export { MinimalPlayer };
var styles = {
    blockContainer: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    background: #ccc;\n    position: relative;\n  "], ["\n    background: #ccc;\n    position: relative;\n  "]))),
    // Pause button in form of overlay layer
    pauseButton: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n  "], ["\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n  "]))),
    playButton: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    border-radius: 76px;\n    width: 76px;\n    height: 76px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  "], ["\n    border-radius: 76px;\n    width: 76px;\n    height: 76px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%);\n  "]))),
};
var templateObject_1, templateObject_2, templateObject_3;
