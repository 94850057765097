import React from "react";
import {Form, InputNumber} from "antd";
import {MIN_LENGTH_RULE_NAME} from "@reside/forms/dist/validator/validators/minLengthValidator";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {getRuleValue, isTextOrTextAreaNode, updateRules} from "../../utils";
import {FormControlSourceNode} from "../../model/schemaTypes";

export const getMinLengthRuleValue = getRuleValue(
  MIN_LENGTH_RULE_NAME,
  parseInt,
);

export const getInitialMinLengthFormValue = (node: FormControlSourceNode) => {
  try {
    return {
      [MIN_LENGTH_RULE_NAME]: getMinLengthRuleValue(node.rules),
    };
  } catch {
    return {};
  }
};

export const MinLengthAttribute = () => {
  const node = useFocusedNode();
  const setValidationRules = useSetFocusedNodePropertyValue("rules");

  if (!node) {
    return null;
  }

  return isTextOrTextAreaNode(node) ? (
    <Form.Item
      name={MIN_LENGTH_RULE_NAME}
      label="Min length"
      rules={[{type: "number", min: 0}]}
    >
      <InputNumber
        min={0}
        onChange={value =>
          setValidationRules(
            updateRules(
              [...(node.rules ?? [])],
              `${value ?? ""}`,
              MIN_LENGTH_RULE_NAME,
            ),
          )
        }
      />
    </Form.Item>
  ) : (
    <>
      Attribute "${MIN_LENGTH_RULE_NAME}" can be used only with
      FORM_CONTROL-TEXT/TEXTAREA type
    </>
  );
};
