import ReactDOM from "react-dom";
var ComponentView = /** @class */ (function () {
    // See https://prosemirror.net/docs/ref/#view.NodeView
    function ComponentView(component, _a) {
        var editor = _a.editor, extension = _a.extension, node = _a.node, view = _a.view, getPos = _a.getPos, decorations = _a.decorations;
        this.isSelected = false;
        this.component = component;
        this.editor = editor;
        this.extension = extension;
        this.getPos = getPos;
        this.decorations = decorations;
        this.node = node;
        this.view = view;
        this.dom = node.type.spec.inline
            ? document.createElement("span")
            : document.createElement("div");
        this.renderElement();
    }
    ComponentView.prototype.renderElement = function () {
        var children = this.component({
            node: this.node,
            isSelected: this.isSelected,
            isEditable: this.view.editable,
            getPos: this.getPos,
        });
        ReactDOM.render(children, this.dom);
    };
    ComponentView.prototype.update = function (node) {
        if (node.type !== this.node.type) {
            return false;
        }
        this.node = node;
        this.renderElement();
        return true;
    };
    ComponentView.prototype.selectNode = function () {
        if (this.view.editable) {
            this.isSelected = true;
            this.renderElement();
        }
    };
    ComponentView.prototype.deselectNode = function () {
        if (this.view.editable) {
            this.isSelected = false;
            this.renderElement();
        }
    };
    ComponentView.prototype.stopEvent = function () {
        return true;
    };
    ComponentView.prototype.destroy = function () {
        if (this.dom) {
            ReactDOM.unmountComponentAtNode(this.dom);
        }
        this.dom = null;
    };
    ComponentView.prototype.ignoreMutation = function () {
        return true;
    };
    return ComponentView;
}());
export default ComponentView;
