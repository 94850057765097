var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from "@emotion/react";
import { styled, theme } from "../../theme";
export var FloatingFieldLabel = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: #a8b6ca;\n  font-size: 1em;\n  font-weight: ", ";\n  opacity: 0;\n  transform: translateY(15px);\n  transition: transform 250ms;\n  ", "\n  ", "\n"], ["\n  color: #a8b6ca;\n  font-size: 1em;\n  font-weight: ", ";\n  opacity: 0;\n  transform: translateY(15px);\n  transition: transform 250ms;\n  ",
    "\n  ",
    "\n"])), theme.fontWeight.bold, function (_a) {
    var visible = _a.visible;
    return visible && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      opacity: 1;\n      transform: translateY(5px);\n    "], ["\n      opacity: 1;\n      transform: translateY(5px);\n    "])));
}, function (_a) {
    var uppercase = _a.uppercase;
    return uppercase && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      text-transform: uppercase;\n    "], ["\n      text-transform: uppercase;\n    "])));
});
FloatingFieldLabel.defaultProps = {
    uppercase: true,
};
var templateObject_1, templateObject_2, templateObject_3;
