var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { setBlockType } from "prosemirror-commands";
import Node from "./Node";
var Paragraph = /** @class */ (function (_super) {
    __extends(Paragraph, _super);
    function Paragraph() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Paragraph.prototype, "name", {
        get: function () {
            return "paragraph";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paragraph.prototype, "schema", {
        get: function () {
            return {
                content: "inline*",
                group: "block",
                parseDOM: [{ tag: "p" }],
                toDOM: function () {
                    return ["p", 0];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Paragraph.prototype.keys = function (_a) {
        var type = _a.type;
        return {
            "Shift-Ctrl-0": setBlockType(type),
        };
    };
    Paragraph.prototype.commands = function (_a) {
        var type = _a.type;
        return function () { return setBlockType(type); };
    };
    Paragraph.prototype.toMarkdown = function (state, node) {
        // render empty paragraphs as hard breaks to ensure that newlines are
        // persisted between reloads (this breaks from markdown tradition)
        if (node.textContent.trim() === "" &&
            node.childCount === 0 &&
            !state.inTable) {
            state.write("\\\n");
        }
        else {
            state.renderInline(node);
            state.closeBlock(node);
        }
    };
    Paragraph.prototype.parseMarkdown = function () {
        return { block: "paragraph" };
    };
    return Paragraph;
}(Node));
export default Paragraph;
