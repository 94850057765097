var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Plugin } from "prosemirror-state";
import { toggleMark } from "prosemirror-commands";
import Extension from "../lib/Extension";
import isUrl from "../lib/isUrl";
import isInCode from "../queries/isInCode";
var MarkdownPaste = /** @class */ (function (_super) {
    __extends(MarkdownPaste, _super);
    function MarkdownPaste() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MarkdownPaste.prototype, "name", {
        get: function () {
            return "markdown-paste";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MarkdownPaste.prototype, "plugins", {
        get: function () {
            var _this = this;
            return [
                new Plugin({
                    props: {
                        handlePaste: function (view, event) {
                            var _a, _b, _c;
                            if (!_this.editor || !view) {
                                return false;
                            }
                            if (view.props.editable && !view.props.editable(view.state)) {
                                return false;
                            }
                            if (!event.clipboardData)
                                return false;
                            var text = event.clipboardData.getData("text/plain");
                            var html = event.clipboardData.getData("text/html");
                            var state = view.state, dispatch = view.dispatch;
                            // first check if the clipboard contents can be parsed as a url
                            if (isUrl(text)) {
                                // just paste the link mark directly onto the selected text
                                if (!state.selection.empty) {
                                    if (!((_a = _this.editor) === null || _a === void 0 ? void 0 : _a.schema)) {
                                        return false;
                                    }
                                    toggleMark(_this.editor.schema.marks.link, { href: text })(state, dispatch);
                                    return true;
                                }
                                // Is this link embeddable? Create an embed!
                                var embeds = _this.editor.props.embeds;
                                if (embeds) {
                                    for (var _i = 0, embeds_1 = embeds; _i < embeds_1.length; _i++) {
                                        var embed = embeds_1[_i];
                                        var matches = embed.matcher(text);
                                        if (matches) {
                                            if (!((_b = _this.editor) === null || _b === void 0 ? void 0 : _b.commands)) {
                                                return false;
                                            }
                                            _this.editor.commands.embed({
                                                href: text,
                                                component: embed.component,
                                                matches: matches,
                                            });
                                            return true;
                                        }
                                    }
                                }
                                // well, it's not an embed and there is no text selected – so just
                                // go ahead and insert the link directly
                                var transaction_1 = view.state.tr
                                    .insertText(text, state.selection.from, state.selection.to)
                                    .addMark(state.selection.from, state.selection.to + text.length, state.schema.marks.link.create({ href: text }));
                                view.dispatch(transaction_1);
                                return true;
                            }
                            // otherwise, if we have html on the clipboard then fallback to the
                            // default HTML parser behavior that comes with Prosemirror.
                            if (text.length === 0 || html)
                                return false;
                            event.preventDefault();
                            if (isInCode(view.state)) {
                                view.dispatch(view.state.tr.insertText(text));
                                return true;
                            }
                            if (!((_c = _this.editor) === null || _c === void 0 ? void 0 : _c.parser)) {
                                return false;
                            }
                            var paste = _this.editor.parser.parse(text);
                            var slice = paste.slice(0);
                            var transaction = view.state.tr.replaceSelection(slice);
                            view.dispatch(transaction);
                            return true;
                        },
                    },
                }),
            ];
        },
        enumerable: true,
        configurable: true
    });
    return MarkdownPaste;
}(Extension));
export default MarkdownPaste;
