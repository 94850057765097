var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useMemo } from "react";
import { useFormikContext } from "formik";
import { QuestionType } from "@reside/forms/dist/templateTypes";
import { getFieldName } from "@reside/forms/dist/templateUtils";
import { STATES } from "../../form/states-field";
import { ValidationBlockBoundary } from "../validation-block-boundary";
import { BlockField, useBlockComponentContext } from "../";
import { getChoices, getCustomOptionLabel } from "./helper";
// Form Fields
import { CheckboxGroupField } from "../../form/checkbox-group-field";
import { ComputationField } from "../../form/computation-field";
import { CurrencyField } from "../../form/currency-field";
import { DateField } from "../../form/date-field";
import { FileField } from "../../form/file-field";
import { RadioGroupField } from "../../form/radio-group-field";
import { SelectField } from "../../form/select-field";
import { SignatureField } from "../../form/signature-field";
import { SnapshotControlField } from "../../form/snapshot-control-field";
import { SSNField } from "../../form/ssn-field";
import { TextAreaField } from "../../form/text-area-field";
import { TextField } from "../../form/text-field";
import { getFormFieldComponent } from "../../form";
var defaultFormComponents = {
    CheckboxGroupField: CheckboxGroupField,
    ComputationField: ComputationField,
    CurrencyField: CurrencyField,
    DateField: DateField,
    FileField: FileField,
    RadioGroupField: RadioGroupField,
    SelectField: SelectField,
    SignatureField: SignatureField,
    SnapshotControlField: SnapshotControlField,
    SSNField: SSNField,
    TextAreaField: TextAreaField,
    TextField: TextField,
};
export var BlockFormControl = function (_a) {
    var reference = _a.reference, format = _a.format, keyboardType = _a.keyboardType, signDateRef = _a.signDateRef, state = _a.state, queryVariables = _a.queryVariables, id = _a.id, props = __rest(_a, ["reference", "format", "keyboardType", "signDateRef", "state", "queryVariables", "id"]);
    var _b = __assign(__assign({}, props), useBlockComponentContext()), CheckboxGroupField = _b.CheckboxGroupField, ComputationField = _b.ComputationField, CurrencyField = _b.CurrencyField, DateField = _b.DateField, FileField = _b.FileField, RadioGroupField = _b.RadioGroupField, SelectField = _b.SelectField, SignatureField = _b.SignatureField, SnapshotControlField = _b.SnapshotControlField, SSNField = _b.SSNField, TextAreaField = _b.TextAreaField, TextField = _b.TextField, Translated = _b.Translated, translate = _b.translate, Markdown = _b.Markdown, interpolate = _b.interpolate;
    var formik = useFormikContext();
    var fieldName = getFieldName(reference);
    var placeholder = useMemo(function () { return translate(reference.translationKey); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reference.translationKey]);
    var markdownRenderer = useCallback(function (_a) {
        var translation = _a.translation;
        return (React.createElement(Markdown, { values: { query: queryVariables, values: formik.values }, source: translation }));
    }, [formik.values, queryVariables]);
    var label = useMemo(function () { return (React.createElement(Translated, { translationKey: reference.translationKey, render: markdownRenderer })); }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reference.translationKey]);
    var fieldProps = {
        id: id,
        label: label,
        placeholder: placeholder,
        disabled: state.disabled,
        name: fieldName,
    };
    var textFormatter = interpolate({
        query: queryVariables,
        values: formik.values,
    });
    var renderChildrenBlocks = useCallback(function (_a) {
        var children = _a.children;
        return (React.createElement(React.Fragment, null, children.map(function (block) { return (React.createElement(BlockField, { key: block.id, block: block, queryVariables: queryVariables })); })));
    }, [queryVariables]);
    var translatedChoices = useMemo(function () {
        return getChoices(reference, queryVariables, function (translationKey) { return (React.createElement(Translated, { translationKey: translationKey, render: markdownRenderer })); });
    }, [markdownRenderer, queryVariables, reference]);
    var FormField = getFormFieldComponent(fieldName);
    var render = function () {
        switch (reference.type) {
            case QuestionType.TEXT: {
                if (format === "ssn") {
                    return React.createElement(SSNField, __assign({}, fieldProps, { FormField: FormField }));
                }
                else if (reference.computation) {
                    return (React.createElement(ComputationField, __assign({}, fieldProps, { disabled: true, formatter: textFormatter, computation: reference.computation, key: "" + reference.id })));
                }
                return (React.createElement(TextField, __assign({}, fieldProps, { format: format, type: keyboardType })));
            }
            case QuestionType.TEXTAREA:
                return React.createElement(TextAreaField, __assign({}, fieldProps));
            case QuestionType.CURRENCY: {
                if (reference.computation) {
                    return (React.createElement(ComputationField, __assign({}, fieldProps, { disabled: true, formatter: textFormatter, computation: reference.computation, currency: reference.currency || "USD" })));
                }
                return (React.createElement(CurrencyField, __assign({}, fieldProps, { currency: reference.currency, FormField: FormField })));
            }
            case QuestionType.CHECKBOX: {
                /**
                 * Formik form.values are not in sync with template
                 * template utils computes state for blocks with delay
                 * Fields with option children can not use FastField optimizations
                 */
                var FormField_1 = getFormFieldComponent(fieldName, translatedChoices);
                return reference.query ? (React.createElement(CheckboxGroupField, __assign({}, fieldProps, { getOptionLabel: function (option) {
                        return getCustomOptionLabel(reference, option, translate);
                    }, getOptionValue: function (option) { return option.id; }, getOptionKey: function (option, name) { return name + "." + option.id; }, isChecked: function (option, fieldValue) { return fieldValue.includes(option.id); }, options: translatedChoices, FormField: FormField_1, OptionChildren: renderChildrenBlocks }))) : (React.createElement(CheckboxGroupField, __assign({}, fieldProps, { options: translatedChoices, FormField: FormField_1, OptionChildren: renderChildrenBlocks })));
            }
            case QuestionType.DATE:
                return React.createElement(DateField, __assign({}, fieldProps, { FormField: FormField }));
            case QuestionType.RADIO: {
                /**
                 * Formik form.values are not in sync with template
                 * template utils computes state for blocks with delay
                 * Fields with option children can not use FastField optimizations
                 */
                var FormField_2 = getFormFieldComponent(fieldName, translatedChoices);
                return reference.query ? (React.createElement(RadioGroupField, __assign({}, fieldProps, { getOptionLabel: function (option) {
                        return getCustomOptionLabel(reference, option, translate);
                    }, getOptionValue: function (option) { return option.id; }, getRawOptionValue: function (option) { return option.id; }, getOptionKey: function (option, name) { return name + "." + option.id; }, isChecked: function (option, fieldValue) { return fieldValue === option.id; }, options: translatedChoices, OptionChildren: renderChildrenBlocks, FormField: FormField_2 }))) : (React.createElement(RadioGroupField, __assign({}, fieldProps, { options: translatedChoices, OptionChildren: renderChildrenBlocks, FormField: FormField_2 })));
            }
            case QuestionType.SELECT:
                return (React.createElement(SelectField, __assign({}, fieldProps, { FormField: FormField, getOptionLabel: function (option) {
                        return reference.query
                            ? getCustomOptionLabel(reference, option, translate)
                            : option.label;
                    }, getOptionValue: function (option) {
                        return reference.query ? option.id : option.value;
                    }, options: getChoices(reference, queryVariables, translate) })));
            case QuestionType.STATE_SELECT:
                return (React.createElement(SelectField, __assign({}, fieldProps, { getOptionValue: function (option) { return option.id; }, options: STATES })));
            case QuestionType.TIMEZONE_SELECT:
                return React.createElement(SelectField, __assign({}, fieldProps, { options: TIME_ZONES }));
            case QuestionType.SIGNATURE:
                return React.createElement(SignatureField, __assign({}, fieldProps, { signDateRef: signDateRef }));
            case QuestionType.IMAGE:
                return React.createElement(SnapshotControlField, __assign({}, fieldProps));
            case QuestionType.FILE:
                return (React.createElement(FileField, __assign({}, fieldProps, { acceptedMediaTypes: reference.acceptedMediaTypes, FormField: FormField })));
            default: {
                throw new Error("Block.reference.type=\"" + reference.type + "\" does not have a renderer");
            }
        }
    };
    return (React.createElement(ValidationBlockBoundary, { name: fieldName }, render()));
};
BlockFormControl.defaultProps = __assign({ keyboardType: "text" }, defaultFormComponents);
export var TIME_ZONES = [
    {
        label: "New York (GMT-4)",
        value: "America/New_York",
    },
    {
        label: "Chicago (GMT-5)",
        value: "America/Chicago",
    },
    {
        label: "Denver (GMT-6)",
        value: "America/Denver",
    },
    {
        label: "Phoenix (GMT-7)",
        value: "America/Phoenix",
    },
    {
        label: "Los Angeles (GMT-7)",
        value: "America/Los_Angeles",
    },
    {
        label: "Anchorage (GMT-8)",
        value: "America/Anchorage",
    },
    {
        label: "Honolulu (GMT-10)",
        value: "Pacific/Honolulu",
    },
];
