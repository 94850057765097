import {GroupNodeType, NodeType, QuestionType} from "@reside/forms";
import {validate} from "uuid";

import {TemplateType} from "../../constants";
import {ExtendedReferences} from "../../atoms/query-builder/config";
import {isFieldArray, isFormControl, toTitleCase} from "../../utils";
import {TemplateNodes} from "../schemaTypes";

export const getTemplateAnswerReferences = (
  nodes: ReadonlyArray<TemplateNodes>,
  templateType: TemplateType,
): ExtendedReferences =>
  nodes.reduce<ExtendedReferences>((references, node) => {
    const childrenRefs = getTemplateAnswerReferences(
      node.children ?? [],
      templateType,
    );

    if (isFieldArray(node)) {
      const {id} = node;

      return {
        ...references,
        [id]: {
          id,
          translationKey: node.translationKey,
          /**
           * NOTE: this is non standard, as we dont use the question type in reality.
           * The Field array should be modelled as form:control NodeType with the LIST as reference type BUT ITS NOT.
           */
          type: QuestionType.LIST,
          templateType,
          children: childrenRefs,
        },
      };
    }

    if (isFormControl(node)) {
      const {reference} = node;
      const {id, choices = []} = reference;

      if (reference) {
        references[id!] = {
          ...reference,
          templateType,
          choices: choices.map(({id, translationKey}) => ({
            id,
            translationKey,
          })),
        };
      }

      return {
        ...references,
        ...childrenRefs,
        ...getTemplateAnswerReferences(
          choices as ReadonlyArray<TemplateNodes>,
          templateType,
        ),
      };
    }

    return {
      ...references,
      ...childrenRefs,
    };
  }, {});

export const getTemplateNestedRefsCount = (
  nodes: ReadonlyArray<TemplateNodes>,
  templateType: TemplateType,
): Record<string, number> =>
  nodes.reduce<Record<string, number>>((references, node) => {
    const childrenRefs = getTemplateNestedRefsCount(
      node.children ?? [],
      templateType,
    );

    if (isFieldArray(node)) {
      const {id} = node;

      return {
        ...references,
        [id]: 1 + (references[id] ?? 0),
      };
    }

    if (isFormControl(node)) {
      const {reference} = node;
      const {id, choices = []} = reference;

      if (reference) {
        references[id as string] =
          1 + (references[reference.id as string] ?? 0);
      }

      return {
        ...references,
        ...childrenRefs,
        ...getTemplateNestedRefsCount(
          choices as ReadonlyArray<TemplateNodes>,
          templateType,
        ),
      };
    }

    return {
      ...references,
      ...childrenRefs,
    };
  }, {});

const FilteredNodeTypes = [
  NodeType.COMPLETION_CHECKLIST,
  NodeType.TITLE,
  NodeType.SUBTITLE,
  NodeType.PARAGRAPH,
  NodeType.CONTINUE_BUTTON,
  NodeType.FIELD_ARRAY,
  NodeType.IMAGE,
  NodeType.MANDATORY_VIDEO,
  NodeType.TABLE_OF_CONTENT,
  undefined,
];

const FilteredGroupNodes = [GroupNodeType.SECTION_GROUP, GroupNodeType.SECTION];

const filterNodes = (nodes: TemplateNodes[]) =>
  nodes.filter((node: any) => !FilteredNodeTypes.includes(node.type));

export const getTreeSelectDataStructure = (
  children: ReadonlyArray<TemplateNodes>,
): TemplateNodes[] =>
  children
    .map((node: any) =>
      node.children
        ? FilteredGroupNodes.includes(node.type)
          ? getTreeSelectDataStructure(filterNodes(node.children))
          : {
              ...node,
              children: getTreeSelectDataStructure(filterNodes(node.children)),
            }
        : node.items
        ? {...node, items: getTreeSelectDataStructure(node.items)}
        : node.reference
        ? {
            ...node,
            reference: {
              ...node.reference,
              ...(node.reference.choices
                ? {
                    choices: getTreeSelectDataStructure(node.reference.choices),
                  }
                : {}),
            },
          }
        : node,
    )
    .filter(
      node =>
        (node?.children ? node.children?.length : node) &&
        !node.disabled &&
        !node?.reference?.preFlighted,
    )
    .flat() as any;

export const answerIdToTitleCase = (answerId: string) =>
  !validate(answerId) && toTitleCase(answerId);
