"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// https://github.com/bengourley/currency-symbol-map/blob/master/map.js
var CurrencySymbolMap;
(function (CurrencySymbolMap) {
    CurrencySymbolMap["AED"] = "\u062F.\u0625";
    CurrencySymbolMap["AFN"] = "\u060B";
    CurrencySymbolMap["ALL"] = "L";
    CurrencySymbolMap["AMD"] = "\u058F";
    CurrencySymbolMap["ANG"] = "\u0192";
    CurrencySymbolMap["AOA"] = "Kz";
    CurrencySymbolMap["ARS"] = "$";
    CurrencySymbolMap["AUD"] = "$";
    CurrencySymbolMap["AWG"] = "\u0192";
    CurrencySymbolMap["AZN"] = "\u20BC";
    CurrencySymbolMap["BAM"] = "KM";
    CurrencySymbolMap["BBD"] = "$";
    CurrencySymbolMap["BDT"] = "\u09F3";
    CurrencySymbolMap["BGN"] = "\u043B\u0432";
    CurrencySymbolMap["BHD"] = ".\u062F.\u0628";
    CurrencySymbolMap["BIF"] = "FBu";
    CurrencySymbolMap["BMD"] = "$";
    CurrencySymbolMap["BND"] = "$";
    CurrencySymbolMap["BOB"] = "$b";
    CurrencySymbolMap["BRL"] = "R$";
    CurrencySymbolMap["BSD"] = "$";
    CurrencySymbolMap["BTC"] = "\u0E3F";
    CurrencySymbolMap["BTN"] = "Nu.";
    CurrencySymbolMap["BWP"] = "P";
    CurrencySymbolMap["BYR"] = "Br";
    CurrencySymbolMap["BYN"] = "Br";
    CurrencySymbolMap["BZD"] = "BZ$";
    CurrencySymbolMap["CAD"] = "$";
    CurrencySymbolMap["CDF"] = "FC";
    CurrencySymbolMap["CHF"] = "CHF";
    CurrencySymbolMap["CLP"] = "$";
    CurrencySymbolMap["CNY"] = "\u00A5";
    CurrencySymbolMap["COP"] = "$";
    CurrencySymbolMap["CRC"] = "\u20A1";
    CurrencySymbolMap["CUC"] = "$";
    CurrencySymbolMap["CUP"] = "\u20B1";
    CurrencySymbolMap["CVE"] = "$";
    CurrencySymbolMap["CZK"] = "K\u010D";
    CurrencySymbolMap["DJF"] = "Fdj";
    CurrencySymbolMap["DKK"] = "kr";
    CurrencySymbolMap["DOP"] = "RD$";
    CurrencySymbolMap["DZD"] = "\u062F\u062C";
    CurrencySymbolMap["EEK"] = "kr";
    CurrencySymbolMap["EGP"] = "\u00A3";
    CurrencySymbolMap["ERN"] = "Nfk";
    CurrencySymbolMap["ETB"] = "Br";
    CurrencySymbolMap["ETH"] = "\u039E";
    CurrencySymbolMap["EUR"] = "\u20AC";
    CurrencySymbolMap["FJD"] = "$";
    CurrencySymbolMap["FKP"] = "\u00A3";
    CurrencySymbolMap["GBP"] = "\u00A3";
    CurrencySymbolMap["GEL"] = "\u20BE";
    CurrencySymbolMap["GGP"] = "\u00A3";
    CurrencySymbolMap["GHC"] = "\u20B5";
    CurrencySymbolMap["GHS"] = "GH\u20B5";
    CurrencySymbolMap["GIP"] = "\u00A3";
    CurrencySymbolMap["GMD"] = "D";
    CurrencySymbolMap["GNF"] = "FG";
    CurrencySymbolMap["GTQ"] = "Q";
    CurrencySymbolMap["GYD"] = "$";
    CurrencySymbolMap["HKD"] = "$";
    CurrencySymbolMap["HNL"] = "L";
    CurrencySymbolMap["HRK"] = "kn";
    CurrencySymbolMap["HTG"] = "G";
    CurrencySymbolMap["HUF"] = "Ft";
    CurrencySymbolMap["IDR"] = "Rp";
    CurrencySymbolMap["ILS"] = "\u20AA";
    CurrencySymbolMap["IMP"] = "\u00A3";
    CurrencySymbolMap["INR"] = "\u20B9";
    CurrencySymbolMap["IQD"] = "\u0639.\u062F";
    CurrencySymbolMap["IRR"] = "\uFDFC";
    CurrencySymbolMap["ISK"] = "kr";
    CurrencySymbolMap["JEP"] = "\u00A3";
    CurrencySymbolMap["JMD"] = "J$";
    CurrencySymbolMap["JOD"] = "JD";
    CurrencySymbolMap["JPY"] = "\u00A5";
    CurrencySymbolMap["KES"] = "KSh";
    CurrencySymbolMap["KGS"] = "\u043B\u0432";
    CurrencySymbolMap["KHR"] = "\u17DB";
    CurrencySymbolMap["KMF"] = "CF";
    CurrencySymbolMap["KPW"] = "\u20A9";
    CurrencySymbolMap["KRW"] = "\u20A9";
    CurrencySymbolMap["KWD"] = "KD";
    CurrencySymbolMap["KYD"] = "$";
    CurrencySymbolMap["KZT"] = "\u043B\u0432";
    CurrencySymbolMap["LAK"] = "\u20AD";
    CurrencySymbolMap["LBP"] = "\u00A3";
    CurrencySymbolMap["LKR"] = "\u20A8";
    CurrencySymbolMap["LRD"] = "$";
    CurrencySymbolMap["LSL"] = "M";
    CurrencySymbolMap["LTC"] = "\u0141";
    CurrencySymbolMap["LTL"] = "Lt";
    CurrencySymbolMap["LVL"] = "Ls";
    CurrencySymbolMap["LYD"] = "LD";
    CurrencySymbolMap["MAD"] = "MAD";
    CurrencySymbolMap["MDL"] = "lei";
    CurrencySymbolMap["MGA"] = "Ar";
    CurrencySymbolMap["MKD"] = "\u0434\u0435\u043D";
    CurrencySymbolMap["MMK"] = "K";
    CurrencySymbolMap["MNT"] = "\u20AE";
    CurrencySymbolMap["MOP"] = "MOP$";
    CurrencySymbolMap["MRO"] = "UM";
    CurrencySymbolMap["MRU"] = "UM";
    CurrencySymbolMap["MUR"] = "\u20A8";
    CurrencySymbolMap["MVR"] = "Rf";
    CurrencySymbolMap["MWK"] = "MK";
    CurrencySymbolMap["MXN"] = "$";
    CurrencySymbolMap["MYR"] = "RM";
    CurrencySymbolMap["MZN"] = "MT";
    CurrencySymbolMap["NAD"] = "$";
    CurrencySymbolMap["NGN"] = "\u20A6";
    CurrencySymbolMap["NIO"] = "C$";
    CurrencySymbolMap["NOK"] = "kr";
    CurrencySymbolMap["NPR"] = "\u20A8";
    CurrencySymbolMap["NZD"] = "$";
    CurrencySymbolMap["OMR"] = "\uFDFC";
    CurrencySymbolMap["PAB"] = "B/.";
    CurrencySymbolMap["PEN"] = "S/.";
    CurrencySymbolMap["PGK"] = "K";
    CurrencySymbolMap["PHP"] = "\u20B1";
    CurrencySymbolMap["PKR"] = "\u20A8";
    CurrencySymbolMap["PLN"] = "z\u0142";
    CurrencySymbolMap["PYG"] = "Gs";
    CurrencySymbolMap["QAR"] = "\uFDFC";
    CurrencySymbolMap["RMB"] = "\uFFE5";
    CurrencySymbolMap["RON"] = "lei";
    CurrencySymbolMap["RSD"] = "\u0414\u0438\u043D.";
    CurrencySymbolMap["RUB"] = "\u20BD";
    CurrencySymbolMap["RWF"] = "R\u20A3";
    CurrencySymbolMap["SAR"] = "\uFDFC";
    CurrencySymbolMap["SBD"] = "$";
    CurrencySymbolMap["SCR"] = "\u20A8";
    CurrencySymbolMap["SDG"] = "\u062C.\u0633.";
    CurrencySymbolMap["SEK"] = "kr";
    CurrencySymbolMap["SGD"] = "$";
    CurrencySymbolMap["SHP"] = "\u00A3";
    CurrencySymbolMap["SLL"] = "Le";
    CurrencySymbolMap["SOS"] = "S";
    CurrencySymbolMap["SRD"] = "$";
    CurrencySymbolMap["SSP"] = "\u00A3";
    CurrencySymbolMap["STD"] = "Db";
    CurrencySymbolMap["STN"] = "Db";
    CurrencySymbolMap["SVC"] = "$";
    CurrencySymbolMap["SYP"] = "\u00A3";
    CurrencySymbolMap["SZL"] = "E";
    CurrencySymbolMap["THB"] = "\u0E3F";
    CurrencySymbolMap["TJS"] = "SM";
    CurrencySymbolMap["TMT"] = "T";
    CurrencySymbolMap["TND"] = "\u062F.\u062A";
    CurrencySymbolMap["TOP"] = "T$";
    CurrencySymbolMap["TRL"] = "\u20A4";
    CurrencySymbolMap["TRY"] = "\u20BA";
    CurrencySymbolMap["TTD"] = "TT$";
    CurrencySymbolMap["TVD"] = "$";
    CurrencySymbolMap["TWD"] = "NT$";
    CurrencySymbolMap["TZS"] = "TSh";
    CurrencySymbolMap["UAH"] = "\u20B4";
    CurrencySymbolMap["UGX"] = "USh";
    CurrencySymbolMap["USD"] = "$";
    CurrencySymbolMap["UYU"] = "$U";
    CurrencySymbolMap["UZS"] = "\u043B\u0432";
    CurrencySymbolMap["VEF"] = "Bs";
    CurrencySymbolMap["VND"] = "\u20AB";
    CurrencySymbolMap["VUV"] = "VT";
    CurrencySymbolMap["WST"] = "WS$";
    CurrencySymbolMap["XAF"] = "FCFA";
    CurrencySymbolMap["XBT"] = "\u0243";
    CurrencySymbolMap["XCD"] = "$";
    CurrencySymbolMap["XOF"] = "CFA";
    CurrencySymbolMap["XPF"] = "\u20A3";
    CurrencySymbolMap["YER"] = "\uFDFC";
    CurrencySymbolMap["ZAR"] = "R";
    CurrencySymbolMap["ZWD"] = "Z$";
})(CurrencySymbolMap = exports.CurrencySymbolMap || (exports.CurrencySymbolMap = {}));
var QuestionType;
(function (QuestionType) {
    QuestionType["TEXT"] = "TEXT";
    QuestionType["DATE"] = "DATE";
    QuestionType["DATETIME"] = "DATETIME";
    QuestionType["CHECKBOX"] = "CHECKBOX";
    QuestionType["RADIO"] = "RADIO";
    QuestionType["SELECT"] = "SELECT";
    QuestionType["STATE_SELECT"] = "STATE_SELECT";
    QuestionType["TIMEZONE_SELECT"] = "TIMEZONE_SELECT";
    QuestionType["SIGNATURE"] = "SIGNATURE";
    QuestionType["IMAGE"] = "IMAGE";
    QuestionType["TEXTAREA"] = "TEXTAREA";
    QuestionType["OBJECT"] = "OBJECT";
    QuestionType["LIST"] = "LIST";
    QuestionType["FILE"] = "FILE";
    QuestionType["CURRENCY"] = "CURRENCY";
})(QuestionType = exports.QuestionType || (exports.QuestionType = {}));
var GroupNodeType;
(function (GroupNodeType) {
    GroupNodeType["SLIDE"] = "slide";
    GroupNodeType["SECTION"] = "section";
    GroupNodeType["SECTION_GROUP"] = "section-group";
})(GroupNodeType = exports.GroupNodeType || (exports.GroupNodeType = {}));
var NodeType;
(function (NodeType) {
    NodeType["CARD"] = "card";
    NodeType["COLLAPSIBLE"] = "collapsible";
    NodeType["COLUMN"] = "column-block";
    NodeType["COMPLETION_CHECKLIST"] = "completionChecklist";
    NodeType["CONTINUE_BUTTON"] = "continue-button";
    NodeType["FIELD_ARRAY"] = "fieldArray";
    NodeType["FORM_CONTROL"] = "form:control";
    NodeType["FRAGMENT"] = "fragment";
    NodeType["IMAGE"] = "image";
    NodeType["LIST"] = "list";
    NodeType["MANDATORY_VIDEO"] = "mandatory-video";
    NodeType["PARAGRAPH"] = "paragraph";
    NodeType["SUBTITLE"] = "subtitle";
    NodeType["TABLE_OF_CONTENT"] = "tableOfContent";
    NodeType["TITLE"] = "title";
})(NodeType = exports.NodeType || (exports.NodeType = {}));
var VisibilityContextType;
(function (VisibilityContextType) {
    VisibilityContextType["PDF"] = "pdf";
    VisibilityContextType["RE"] = "resident-experience";
})(VisibilityContextType = exports.VisibilityContextType || (exports.VisibilityContextType = {}));
var PreflightCoreVariables;
(function (PreflightCoreVariables) {
    PreflightCoreVariables["RESIDENT_FIRST_NAME"] = "resident_firstName";
    PreflightCoreVariables["RESIDENT_LAST_LANE"] = "resident_lastName";
})(PreflightCoreVariables = exports.PreflightCoreVariables || (exports.PreflightCoreVariables = {}));
