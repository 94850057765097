import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "@reside/ui/dist/styles";

import {App} from "./App";
import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));

// eslint-disable-next-line no-console
console.table(process.env);
