var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { memo } from "react";
import { styled } from "../../../theme";
import { Text } from "../../../text";
import { ItemLabel } from "../item-label";
export var RadioItem = memo(function (_a) {
    var checked = _a.checked, children = _a.children, disabled = _a.disabled, label = _a.label, name = _a.name, onChange = _a.onChange, value = _a.value, _b = _a.testId, testId = _b === void 0 ? "" : _b;
    return (React.createElement(React.Fragment, null,
        React.createElement(ItemLabel, { tabIndex: disabled ? -1 : 0, disabled: disabled },
            React.createElement("input", { type: "radio", name: name, value: value, hidden: true, disabled: disabled, onChange: disabled ? undefined : onChange, checked: checked, "data-test-id": testId }),
            React.createElement(Radio, { disabled: disabled },
                React.createElement(OuterCircle, { cx: "50%", cy: "50%", r: "0.563em", disabled: disabled }),
                React.createElement(InnerCircle, { cx: "50%", cy: "50%", r: "0.375em", disabled: disabled, "data-test-id": "fill-" + testId, checked: checked })),
            React.createElement(StyledText, { disabled: disabled }, label)),
        children));
});
var Radio = styled.svg(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 1.375em;\n  height: 1.375em;\n  // Hack: min- values are required for flexbox sizing https://github.com/philipwalton/flexbugs/issues/184\n  min-width: 1.375em;\n  max-height: 1.375em;\n\n  ", "\n"], ["\n  width: 1.375em;\n  height: 1.375em;\n  // Hack: min- values are required for flexbox sizing https://github.com/philipwalton/flexbugs/issues/184\n  min-width: 1.375em;\n  max-height: 1.375em;\n\n  ", "\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && "cursor: not-allowed;";
});
var StyledText = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 1.5em;\n  pointer-events: none;\n  font-size: 1.25em;\n  line-height: 1.5em;\n  font-weight: ", ";\n"], ["\n  padding-left: 1.5em;\n  pointer-events: none;\n  font-size: 1.25em;\n  line-height: 1.5em;\n  font-weight: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.normal;
});
var OuterCircle = styled.circle(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  stroke-width: 0.125rem;\n  stroke: ", ";\n  fill: transparent;\n"], ["\n  stroke-width: 0.125rem;\n  stroke: ",
    ";\n  fill: transparent;\n"])), function (_a) {
    var theme = _a.theme, disabled = _a.disabled;
    return disabled ? theme.color.gray100 : theme.color.primary;
});
var InnerCircle = styled.circle(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  stroke: transparent;\n  fill: ", ";\n  visibility: ", ";\n"], ["\n  stroke: transparent;\n  fill: ",
    ";\n  visibility: ", ";\n"])), function (_a) {
    var checked = _a.checked, disabled = _a.disabled, theme = _a.theme;
    return disabled
        ? theme.color.gray100
        : checked
            ? theme.color.primary
            : "transparent";
}, function (_a) {
    var checked = _a.checked;
    return (checked ? "visible" : "hidden");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
