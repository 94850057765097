import React from "react";
import {RecoilState, useRecoilCallback} from "recoil";
import * as immutable from "object-path-immutable";
import {DeleteOutlined} from "@ant-design/icons";
import {take} from "lodash";
import {styled} from "@reside/ui";

import {ActionButton} from "./ActionButton";
import {GroupNode} from "../../../../model/schemaTypes";
import {activeSlidePathAtom} from "../../../../model/editor";
import {isSameSection} from "../../utils/utils";
import {useTemplateAtomState} from "../../../../model/template";

export const DeletePdfButton = ({
  indexPath,
  parentAtom,
  disabled,
}: {
  indexPath: ReadonlyArray<number>;
  parentAtom: RecoilState<GroupNode>;
  disabled?: boolean;
}) => {
  const [, setParent] = useTemplateAtomState(parentAtom);

  const handleDelete = useRecoilCallback(({snapshot, set}) => async () => {
    const parent = await snapshot.getPromise(parentAtom);
    const activeSlidePath = await snapshot.getPromise(activeSlidePathAtom);

    if (window.confirm(`Do you want to remove this PDF?`)) {
      if (
        isSameSection(activeSlidePath, indexPath) &&
        indexPath[2] < activeSlidePath[2]
      ) {
        /**
         * If the PDF is before the active slide in the same section, we shift the index.
         */
        set(activeSlidePathAtom, [
          ...take(activeSlidePath, 2),
          activeSlidePath[2] - 1,
        ]);
      }

      setParent({
        ...parent,
        children: immutable.del(
          parent.children,
          `${[...indexPath].pop()}`,
        ) as any,
      });
    }
  });

  return (
    <DeleteButton
      data-testid="delete-pdf-button"
      disabled={disabled}
      onClick={e => {
        e.stopPropagation();

        handleDelete();
      }}
      icon={<DeleteOutlined />}
    />
  );
};

const DeleteButton = styled(ActionButton)`
  right: 50px;
`;
