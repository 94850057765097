import React from "react";
import {
  Config,
  FieldOrGroup,
  Funcs,
  Operator,
  TypedMap,
} from "react-awesome-query-builder";
import AntdConfig from "react-awesome-query-builder/lib/config/antd";
import AntdWidgets from "react-awesome-query-builder/lib/components/widgets/antd";
import {Button} from "antd";
import {red} from "@ant-design/colors";
import {DeleteOutlined} from "@ant-design/icons";

import {QuestionChoice, QuestionReference, QuestionType} from "@reside/forms";
import {STATES} from "@reside/ui/dist/form";
import {TIME_ZONES} from "@reside/ui/dist/form-blocks/block-form-control/BlockFormControl";

import {getTemplateTypeName, TemplateType} from "../../constants";
import {TemplateNodes, FormControlSourceNode} from "../../model/schemaTypes";
import {answerIdToTitleCase} from "../../model/answers/answersUtils";

const InitialConfig = AntdConfig;

export type FlatPaths = Record<string, string>;
export type ConfigWithFlatPaths = Config & {flatPaths: FlatPaths};

const {FieldTreeSelect} = AntdWidgets;

export type ExtendedReference = QuestionReference<TemplateNodes> & {
  // path: string;
  templateType: string;
  children?: ExtendedReferences;
};

export type ExtendedReferences = Record<string, ExtendedReference>;

export type TreeSelectFields = ReadonlyArray<{
  templateType: TemplateType;
  data: ReadonlyArray<TemplateNodes>;
}>;

const operators: Record<string, Operator> = {
  ...InitialConfig.operators,
  equal: {
    label: "Is",
    labelForFormat: "Is",
    reversedOp: "not_equal",
    jsonLogic: "==",
  },
  not_equal: {
    label: "Is not",
    labelForFormat: "Is not",
    reversedOp: "equal",
    jsonLogic: "!=",
  },
  less: {
    label: "Is less than",
    labelForFormat: "Is less than",
    reversedOp: "greater_or_equal",
    jsonLogic: "<",
  },
  less_or_equal: {
    label: "Is less than or equal to",
    labelForFormat: "Is less than or equal to",
    reversedOp: "greater",
    jsonLogic: "<=",
  },
  greater: {
    label: "Is greater than",
    labelForFormat: "Is greater than",
    reversedOp: "less_or_equal",
    jsonLogic: ">",
  },
  greater_or_equal: {
    label: "Is greater than or equal to",
    labelForFormat: "Is greater than or equal to",
    reversedOp: "less",
    jsonLogic: ">=",
  },
  like: {
    label: "Like",
    labelForFormat: "Like",
    reversedOp: "not_like",
    jsonLogic: "in",
    _jsonLogicIsRevArgs: true,
    valueSources: ["value"],
  },
  not_like: {
    label: "Not like",
    reversedOp: "like",
    labelForFormat: "Not Like",
    valueSources: ["value"],
  },
  is_empty: {
    label: "Is empty",
    labelForFormat: "Is empty",
    cardinality: 0,
    reversedOp: "is_not_empty",
    jsonLogic: "!",
  },
  is_not_empty: {
    label: "Is not empty",
    labelForFormat: "Is not empty",
    cardinality: 0,
    reversedOp: "is_empty",
    jsonLogic: "!!",
  },
  select_equals: {
    label: "Is",
    labelForFormat: "Is",
    reversedOp: "select_not_equals",
    jsonLogic: "==",
  },
  select_not_equals: {
    label: "Is not",
    labelForFormat: "Is not",
    reversedOp: "select_equals",
    jsonLogic: "!=",
  },
  between: {
    ...InitialConfig.operators.between,
    label: "Is between",
    labelForFormat: "Is between",
  },
  not_between: {
    ...InitialConfig.operators.not_between,
    label: "Is not between",
    labelForFormat: "Is not between",
  },
  // reside custom operators
  not_empty: {
    label: "Is not empty",
    labelForFormat: "Is not empty",
    reversedOp: "empty",
    cardinality: 0,
    jsonLogic: "not_empty",
  },
  none_in: {
    label: "Does not contain",
    labelForFormat: "Does not contain",
    reversedOp: "in",
    jsonLogic: "none_in",
  },
  some_in: {
    label: "Contains at least one of following",
    labelForFormat: "Contains any of the following",
    reversedOp: "in",
    jsonLogic: "some_in",
  },
  date_equal: {
    label: "Is",
    labelForFormat: "Is",
    reversedOp: "",
    jsonLogic: "isEqual",
  },
  date_before: {
    label: "Is before",
    labelForFormat: "Is before",
    reversedOp: "",
    jsonLogic: "isBefore",
  },
  date_after: {
    label: "Is after",
    labelForFormat: "Is after",
    reversedOp: "",
    jsonLogic: "isAfter",
  },
};

const funcs: Funcs = {
  addDays: {
    label: "Add days",
    jsonLogic: ({date, days}) => ({addDays: [date, days]}),
    returnType: "date",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      days: {
        label: "Number of days",
        type: "number",
        valueSources: ["value"],
      },
    },
  },
  addMonths: {
    label: "Add months",
    jsonLogic: ({date, months}) => ({addMonths: [date, months]}),
    returnType: "date",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      months: {
        label: "Number of months",
        type: "number",
        valueSources: ["value"],
      },
    },
  },
  addWeeks: {
    label: "Add weeks",
    jsonLogic: ({date, weeks}) => ({addWeeks: [date, weeks]}),
    returnType: "date",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      weeks: {
        label: "Number of weeks",
        type: "number",
        valueSources: ["value"],
      },
    },
  },
  addYears: {
    label: "Add years",
    jsonLogic: ({date, years}) => ({addYears: [date, years]}),
    returnType: "date",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      years: {
        label: "Number of years",
        type: "number",
        valueSources: ["value"],
      },
    },
  },
  differenceInDays: {
    label: "Difference in days",
    jsonLogic: ({dateLeft, dateRight}) => ({
      differenceInDays: [dateLeft, dateRight],
    }),
    returnType: "number",
    args: {
      dateLeft: {
        label: "Date left",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      dateRight: {
        label: "Date right",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  differenceInMonths: {
    label: "Difference in months",
    jsonLogic: ({dateLeft, dateRight}) => ({
      differenceInMonths: [dateLeft, dateRight],
    }),
    returnType: "number",
    args: {
      dateLeft: {
        label: "Date left",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      dateRight: {
        label: "Date right",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  differenceInWeeks: {
    label: "Difference in weeks",
    jsonLogic: ({dateLeft, dateRight}) => ({
      differenceInWeeks: [dateLeft, dateRight],
    }),
    returnType: "number",
    args: {
      dateLeft: {
        label: "Date left",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      dateRight: {
        label: "Date right",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  differenceInYears: {
    label: "Difference in years",
    jsonLogic: ({dateLeft, dateRight}) => ({
      differenceInYears: [dateLeft, dateRight],
    }),
    returnType: "number",
    args: {
      dateLeft: {
        label: "Date left",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      dateRight: {
        label: "Date right",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  format: {
    label: "format",
    jsonLogic: ({date, format}) => ({
      format: [date, format],
    }),
    returnType: "string",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      format: {
        label: "Format",
        type: "string",
        valueSources: ["value"],
      },
    },
  },
  getDate: {
    label: "Get date",
    jsonLogic: ({date}) => ({
      getDate: [date],
    }),
    returnType: "number",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  isAfter: {
    label: "Is after",
    jsonLogic: ({date, dateToCompare}) => ({
      isAfter: [date, dateToCompare],
    }),
    returnType: "boolean",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      dateToCompare: {
        label: "Dtae to compare",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  isBefore: {
    label: "Is before",
    jsonLogic: ({date, dateToCompare}) => ({
      isBefore: [date, dateToCompare],
    }),
    returnType: "boolean",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      dateToCompare: {
        label: "Date to compare",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  isSameDay: {
    label: "Is same day",
    jsonLogic: ({dateLeft, dateRight}) => ({
      isSameDay: [dateLeft, dateRight],
    }),
    returnType: "boolean",
    args: {
      dateLeft: {
        label: "Date left",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      dateRight: {
        label: "Date right",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  parseISO: {
    label: "Parse ISO",
    jsonLogic: ({date}) => ({
      parseISO: [date],
    }),
    returnType: "date",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  setDate: {
    label: "Set date",
    jsonLogic: ({date, dayOfMonth}) => ({
      setDate: [date, dayOfMonth],
    }),
    returnType: "date",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
      dayOfMonth: {
        label: "Day of month",
        type: "number",
        valueSources: ["value"],
      },
    },
  },
  startOfDay: {
    label: "Start of day",
    jsonLogic: ({date}) => ({
      startOfDay: [date],
    }),
    returnType: "date",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  startOfMonth: {
    label: "Start of month",
    jsonLogic: ({date}) => ({
      startOfMonth: [date],
    }),
    returnType: "date",
    args: {
      date: {
        label: "Date",
        type: "date",
        valueSources: ["value", "field", "func"],
      },
    },
  },
  startOfToday: {
    label: "Start ot today",
    jsonLogic: () => ({
      startOfToday: [],
    }),
    returnType: "date",
    args: {},
  },
};

const getLabel = (node: any, index: number) =>
  node.translationKey ||
  node.title?.translationKey ||
  `${node.type} #${index + 1}`;

const getReferenceLabel = (reference: QuestionReference, index: number) =>
  answerIdToTitleCase(reference.id!) ||
  reference.translationKey ||
  `${reference.type} #${index + 1}`;

const getCustomField = (
  reference: QuestionReference,
  type: string,
  index: number,
  listValues?: any,
): TypedMap<FieldOrGroup> => ({
  [reference.id!]: {
    type,
    label: getReferenceLabel(reference, index),
    listValues,
  },
});

const getFieldByType = (reference: QuestionReference, index: number) => {
  switch (reference.type) {
    case QuestionType.RADIO:
    case QuestionType.SELECT:
      return getCustomField(
        reference,
        reference?.query ? "existancetype" : "select",
        index,
        reference.choices?.map(({id, translationKey}) => ({
          value: id,
          title: translationKey,
        })),
      );
    case QuestionType.STATE_SELECT:
      return getCustomField(
        reference,
        "select",
        index,
        STATES.map(state => state.label),
      );
    case QuestionType.TIMEZONE_SELECT:
      return getCustomField(
        reference,
        "select",
        index,
        TIME_ZONES.map(zone => zone.label),
      );
    case QuestionType.TEXT:
    case QuestionType.TEXTAREA:
      return getCustomField(reference, "text", index);
    case QuestionType.CURRENCY:
      return getCustomField(reference, "number", index);
    case QuestionType.DATE:
      return getCustomField(reference, "date", index);
    case QuestionType.CHECKBOX:
      return getCustomField(
        reference,
        reference?.query ? "existancetype" : "multiselect",
        index,
        reference.choices?.map(({id, translationKey}) => ({
          value: id,
          title: translationKey,
        })),
      );
    case QuestionType.FILE:
    case QuestionType.IMAGE:
    case QuestionType.SIGNATURE:
      return getCustomField(reference, "existancetype", index);
    default:
      throw Error(`Not supported type : ${reference.type}`);
  }
};

const processSubfields = (node: TemplateNodes): TypedMap<FieldOrGroup> => {
  const children = [
    node?.children,
    (node as any)?.items,
    (node as FormControlSourceNode)?.reference?.choices?.filter(
      (choice: QuestionChoice) => choice.children?.length,
    ),
  ]
    .filter(Boolean)
    .flatMap(x => x);

  return children.reduce((previous: any, currentNode: any, index: number) => {
    if (
      currentNode?.reference?.choices?.some(
        (choice: QuestionChoice) => choice.children?.length,
      )
    ) {
      return {
        ...previous,
        ...getFieldByType(currentNode.reference, index),
        [currentNode.id]: {
          type: "!struct",
          label: getReferenceLabel(currentNode.reference, index),
          subfields: processSubfields(currentNode),
        },
      };
    }

    if (currentNode.reference) {
      return {...previous, ...getFieldByType(currentNode.reference, index)};
    }

    return {
      ...previous,
      [currentNode.id]: {
        type: "!struct",
        label: getLabel(currentNode, index),
        subfields: processSubfields(currentNode),
      },
    };
  }, {});
};

const getFieldsPerTemplate = (
  templateType: TemplateType,
  templateNodes: ReadonlyArray<TemplateNodes>,
): TypedMap<FieldOrGroup> => ({
  [templateType]: {
    label: getTemplateTypeName(templateType),
    type: "!struct",
    subfields: templateNodes.reduce(
      (previous, node) => ({
        ...previous,
        [node.id]: {
          type: "!struct",
          label: (node as any).title?.translationKey,
          subfields: processSubfields(node),
        },
      }),
      {},
    ),
  },
});

const getFields = (
  treeSelectFields: TreeSelectFields,
): TypedMap<FieldOrGroup> =>
  treeSelectFields.reduce(
    (previous, current) => ({
      ...previous,
      ...getFieldsPerTemplate(current.templateType, current.data),
    }),
    {},
  );

const processChildrenNodes = (
  path: string,
  node: any,
  separator = ".",
): Record<string, string> => {
  const children = [node.children, node.items, node.choices].filter(Boolean);
  return children
    .flatMap(children => children)
    .reduce((previous: any, currentNode: any) => {
      const currentPath = [path, currentNode.id].join(separator);

      return {
        ...previous,
        [currentNode.id]: currentPath,
        ...processChildrenNodes(currentPath, currentNode),
        ...(currentNode.reference
          ? {
              [currentNode.reference.id]: [path, currentNode.reference.id].join(
                separator,
              ),
              ...processChildrenNodes(
                [path, currentNode.reference.id].join(separator),
                currentNode.reference,
              ),
            }
          : {}),
      };
    }, {});
};

const getFlatPathsPerTemplate = (
  templateType: TemplateType,
  templateNodes: ReadonlyArray<TemplateNodes>,
) =>
  templateNodes.reduce((previous, node: TemplateNodes) => {
    const path = [templateType, node.id].join(".");

    return {
      ...previous,
      [node.id]: path,
      ...processChildrenNodes(path, node),
    };
  }, {});

const getFlatPaths = (treeSelectFields: TreeSelectFields) =>
  treeSelectFields.reduce(
    (previous, current) => ({
      ...previous,
      ...getFlatPathsPerTemplate(current.templateType, current.data),
    }),
    {},
  );

export const getConfig = (
  treeSelectFields: TreeSelectFields,
): ConfigWithFlatPaths => ({
  ...InitialConfig,
  fields: getFields(treeSelectFields),
  flatPaths: getFlatPaths(treeSelectFields),
  types: {
    ...InitialConfig.types,
    select: {
      ...InitialConfig.types.select,
      widgets: {
        ...InitialConfig.types.select.widgets,
        multiselect: {
          operators: ["not_empty", "none_in", "some_in"],
        },
        select: {
          operators: ["select_equals", "select_not_equals", "not_empty"],
        },
      },
    },
    multiselect: {
      ...InitialConfig.types.multiselect,
      widgets: {
        ...InitialConfig.types.multiselect.widgets,
        multiselect: {
          operators: ["not_empty", "none_in", "some_in"],
        },
        select: {
          operators: ["not_empty"],
        },
      },
    },
    date: {
      ...InitialConfig.types.date,
      widgets: {
        ...InitialConfig.types.date.widgets,
        date: {
          operators: [
            "date_equal",
            "date_before",
            "date_after",
            "is_empty",
            "is_not_empty",
          ],
        },
      },
    },
    text: {
      ...InitialConfig.types.text,
      widgets: {
        select: {
          operators: ["is_empty", "not_empty"],
        },
        text: {
          operators: ["not_equal", "equal"],
        },
      },
    },
    existancetype: {
      widgets: {
        select: {
          operators: ["not_empty", "is_empty"],
        },
      },
    },
  },
  operators,
  settings: {
    ...InitialConfig.settings,
    canReorder: false,
    canRegroup: false,
    addRuleLabel: "Add condition",
    addGroupLabel: "Add group of conditions",
    renderButton: props =>
      props?.type === ("delRule" || "delRuleGroup") ? (
        <DeleteOutlined style={{color: red.primary}} onClick={props?.onClick} />
      ) : (
        <Button onClick={props?.onClick} {...props?.config}>
          {props?.label}
        </Button>
      ),
    renderField: (props: any) => <FieldTreeSelect {...props} />,
  },
  funcs: {
    ...InitialConfig.funcs,
    ...funcs,
  },
});
