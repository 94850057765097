import React, {FunctionComponent} from "react";
import {Menu, Tooltip} from "antd";
import {useRecoilValue} from "recoil";
import {DragSourceMonitor, useDrag} from "react-dnd";
import {styled} from "@reside/ui";

import {
  componentLibraryByTypeSelector,
  isCommonComponentUsedSelector,
} from "../../model/library";
import {DragType} from "../../constants";

export const ComponentLibraryPanel = () => {
  const componentLibraryByType = useRecoilValue(componentLibraryByTypeSelector);

  return (
    <Scrollable>
      <Menu onClick={() => {}}>
        {componentLibraryByType.map((node: any, index) => (
          <DraggableMenuItem key={index} node={node} />
        ))}
      </Menu>
    </Scrollable>
  );
};

const Scrollable = styled.div`
  overflow: auto;
`;

const DraggableMenuItem: FunctionComponent<{key: number; node: any}> = ({
  key,
  node,
  ...props
}) => {
  const isCommonComponentUsed = useRecoilValue(
    isCommonComponentUsedSelector(node.id),
  );

  const [, dragRef] = useDrag({
    item: {
      type: DragType.ADD_LIBRARY_COMPONENT,
      id: node.id,
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const itemMenuText = node.title ? node.title.translationKey : node.fileName;

  return (
    <>
      {isCommonComponentUsed ? (
        <Menu.Item disabled={isCommonComponentUsed} key={key} {...props}>
          <Tooltip title="Mutliple importing of the same common component is not allowed">
            {itemMenuText}
          </Tooltip>
        </Menu.Item>
      ) : (
        <Menu.Item disabled={isCommonComponentUsed} key={key} {...props}>
          <div ref={dragRef}>{itemMenuText}</div>
        </Menu.Item>
      )}
    </>
  );
};
