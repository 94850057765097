import React from "react";
import { STATES } from "../../form/states-field";
import { prop } from "../template";
var DEFAULT_VALUE = {
    label: "",
};
export var StateNameRenderer = function (_a) {
    var values = _a.values, stateId = _a.stateId;
    var selectedStateId = prop(values, stateId, stateId);
    var state = STATES.find(function (state) { return state.id === selectedStateId; });
    return React.createElement("span", null,
        " ",
        (state || DEFAULT_VALUE).label);
};
