import React from "react";
import {FragmentNode} from "@reside/forms";

import {AtomizedBranchNode, AtomProp} from "../../model/editor";
import {DroppableField} from "../droppable-field";
import {useDragAndDrop} from "../../hooks/useDragAndDrop";
import {Override} from "../../model/schemaTypes";

export const EditableFragmentComponent = ({
  node: {children, id},
  atom: parentAtom,
  index,
  isArrayFieldScoped,
}: {
  index: number;
  isArrayFieldScoped: boolean;
  node: Override<FragmentNode, AtomizedBranchNode & {id: string}>;
} & AtomProp) => {
  const {dropRef, dragRef} = useDragAndDrop({index, parentAtom});

  return (
    <div ref={dropRef}>
      <div ref={dragRef}>
        {children.map((atom, index) => (
          <DroppableField
            index={index}
            key={atom.key}
            atom={atom}
            parentAtom={parentAtom}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        ))}
      </div>
    </div>
  );
};
