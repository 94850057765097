var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { styled } from "../../theme";
export var Input = forwardRef(function (_a, ref) {
    var props = __rest(_a, []);
    return React.createElement(BaseInput, __assign({}, props, { ref: ref }));
});
export var BaseInput = forwardRef(function (_a, ref) {
    var _b = _a.testId, testId = _b === void 0 ? "" : _b, props = __rest(_a, ["testId"]);
    return (React.createElement(TextInput, __assign({}, props, { ref: ref, "data-test-id": testId, onChange: props.disabled ? undefined : props.onChange })));
});
export var TextArea = forwardRef(function (props, ref) { return React.createElement(TextAreaInput, __assign({}, props, { ref: ref })); });
var TextInput = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 1.375em;\n  padding: 0.75em 4px;\n  border: none;\n  width: 100%;\n  min-width: 1px;\n  font-family: Gilroy, sans-serif;\n  text-overflow: ellipsis;\n  color: ", ";\n  background: transparent;\n  box-shadow: none; // Firefox: Remove default red shadow on invalid input (e.g. email)\n\n  ::placeholder {\n    color: ", ";\n    opacity: 0.75;\n  }\n\n  ::-webkit-calendar-picker-indicator {\n    display: none;\n  }\n\n  &:focus {\n    outline: none;\n    ::placeholder {\n      color: ", ";\n    }\n  }\n\n  &[disabled] {\n    opacity: 0.65;\n    cursor: not-allowed;\n    filter: saturate(25%);\n  }\n"], ["\n  font-size: 1.375em;\n  padding: 0.75em 4px;\n  border: none;\n  width: 100%;\n  min-width: 1px;\n  font-family: Gilroy, sans-serif;\n  text-overflow: ellipsis;\n  color: ", ";\n  background: transparent;\n  box-shadow: none; // Firefox: Remove default red shadow on invalid input (e.g. email)\n\n  ::placeholder {\n    color: ", ";\n    opacity: 0.75;\n  }\n\n  ::-webkit-calendar-picker-indicator {\n    display: none;\n  }\n\n  &:focus {\n    outline: none;\n    ::placeholder {\n      color: ", ";\n    }\n  }\n\n  &[disabled] {\n    opacity: 0.65;\n    cursor: not-allowed;\n    filter: saturate(25%);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue90;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue90;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue90;
});
var TextAreaInput = styled.textarea(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.2em;\n  padding: 0.75em 4px;\n  border: none;\n  width: 100%;\n  min-width: 1px;\n  font-family: Gilroy, sans-serif;\n  text-overflow: ellipsis;\n  color: ", ";\n  background: transparent;\n\n  @media (min-width: ", "px) {\n    font-size: 1.375em;\n  }\n\n  ::placeholder {\n    color: ", ";\n    opacity: 0.75;\n  }\n\n  &:focus {\n    outline: none;\n    ::placeholder {\n      color: ", ";\n    }\n  }\n\n  &[disabled] {\n    opacity: 0.65;\n    cursor: not-allowed;\n    filter: saturate(25%);\n  }\n"], ["\n  font-size: 1.2em;\n  padding: 0.75em 4px;\n  border: none;\n  width: 100%;\n  min-width: 1px;\n  font-family: Gilroy, sans-serif;\n  text-overflow: ellipsis;\n  color: ", ";\n  background: transparent;\n\n  @media (min-width: ", "px) {\n    font-size: 1.375em;\n  }\n\n  ::placeholder {\n    color: ", ";\n    opacity: 0.75;\n  }\n\n  &:focus {\n    outline: none;\n    ::placeholder {\n      color: ", ";\n    }\n  }\n\n  &[disabled] {\n    opacity: 0.65;\n    cursor: not-allowed;\n    filter: saturate(25%);\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue90;
}, function (_a) {
    var theme = _a.theme;
    return theme.breakpoint.md;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue90;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue90;
});
var templateObject_1, templateObject_2;
