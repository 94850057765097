import {useQuery} from "react-query";
import {useRecoilValue} from "recoil";
import {message} from "antd";

import {branchNameAtom} from "./RepositoryModel";
import {compareChanges} from "../../services/GithubService";

export const useCompareChanges = ({toBranch}: {toBranch: string}) => {
  const branchName = useRecoilValue(branchNameAtom);

  return useQuery(
    ["compareChanges", {toBranch, fromBranch: branchName}],
    async ({queryKey: [_, params]}: any) => {
      const {
        data: {files},
      } = await compareChanges(params);

      return files && files.length > 0;
    },
    {
      cacheTime: 0,
      enabled: !!branchName,
      onError: () =>
        message.error(
          `Failed to compare branch '${branchName}' to '${toBranch}'.`,
        ),
    },
  );
};
