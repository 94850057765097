import React, {ReactNode} from "react";

import {styled} from "@reside/ui";

type Props = Readonly<{
  children: ReactNode;
}>;

export const Footer = ({children}: Props) => <Container>{children}</Container>;

const Container = styled.footer`
  display: flex;
  bottom: 0;
  width: 100%;
  justify-content: flex-end;
  padding: ${({theme}) => theme.space[5]}px;
`;
