import uploadPlaceholderPlugin, { findPlaceholder, } from "../lib/uploadPlaceholder";
import { ToastType } from "../types";
var insertFiles = function (view, event, pos, files, options) {
    // filter to only include image files
    var images = files.filter(function (file) { return /image/i.test(file.type); });
    if (images.length === 0)
        return;
    var dictionary = options.dictionary, uploadImage = options.uploadImage, onImageUploadStart = options.onImageUploadStart, onImageUploadStop = options.onImageUploadStop, onShowToast = options.onShowToast;
    if (!uploadImage) {
        // eslint-disable-next-line no-console
        console.warn("uploadImage callback must be defined to handle image uploads.");
        return;
    }
    // okay, we have some dropped images and a handler – lets stop this
    // event going any further up the stack
    event.preventDefault();
    // let the user know we're starting to process the images
    if (onImageUploadStart)
        onImageUploadStart();
    var schema = view.state.schema;
    // we'll use this to track of how many images have succeeded or failed
    var complete = 0;
    var _loop_1 = function (file) {
        // Use an object to act as the ID for this upload, clever.
        var id = {};
        var tr = view.state.tr;
        // insert a placeholder at this position
        tr.setMeta(uploadPlaceholderPlugin, {
            add: { id: id, file: file, pos: pos },
        });
        view.dispatch(tr);
        // start uploading the image file to the server. Using "then" syntax
        // to allow all placeholders to be entered at once with the uploads
        // happening in the background in parallel.
        uploadImage(file)
            .then(function (src) {
            var pos = findPlaceholder(view.state, id);
            // if the content around the placeholder has been deleted
            // then forget about inserting this image
            if (pos === null)
                return;
            // otherwise, insert it at the placeholder's position, and remove
            // the placeholder itself
            var transaction = view.state.tr
                .replaceWith(pos, pos, schema.nodes.image.create({ src: src }))
                .setMeta(uploadPlaceholderPlugin, { remove: { id: id } });
            view.dispatch(transaction);
        })
            .catch(function (error) {
            // eslint-disable-next-line no-console
            console.error(error);
            // cleanup the placeholder if there is a failure
            var transaction = view.state.tr.setMeta(uploadPlaceholderPlugin, {
                remove: { id: id },
            });
            view.dispatch(transaction);
            // let the user know
            if (onShowToast) {
                onShowToast(dictionary.imageUploadError, ToastType.Error);
            }
        })
            // eslint-disable-next-line no-loop-func
            .finally(function () {
            complete++;
            // once everything is done, let the user know
            if (complete === images.length) {
                if (onImageUploadStop)
                    onImageUploadStop();
            }
        });
    };
    // the user might have dropped multiple images at once, we need to loop
    for (var _i = 0, images_1 = images; _i < images_1.length; _i++) {
        var file = images_1[_i];
        _loop_1(file);
    }
};
export default insertFiles;
