import React from "react";
import {Form, Input} from "antd";
import {FileField, FileFieldProps} from "@reside/ui";

import {LabelAttribute} from "../label-attribute";
import {DisabledAttribute} from "../disabled-attribute";
import {RequiredAttribute} from "../required-attribute";
import {
  AtomProp,
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {
  getInitialMaxFileSizeFormValue,
  MaxFileSizeAttribute,
} from "../max-file-size-attribute";
import {isFileField} from "../../utils";
import {DisableClick} from "../disable-click";

export const EditableFileField = ({
  atom,
  ...props
}: FileFieldProps & {id: string} & AtomProp) => (
  <DisableClick>
    <FileField {...props} />
  </DisableClick>
);

/**
 * FormControl type-specific form to adjust attributes.
 */
export const EditableFileForm = () => {
  const node = useFocusedNode();

  if (!node || !isFileField(node)) {
    return null;
  }

  return (
    <Form
      key={node.id}
      initialValues={{
        label: node?.reference?.translationKey,
        ...getInitialMaxFileSizeFormValue(node),
        acceptedMediaTypes: node?.reference?.acceptedMediaTypes?.toString(),
      }}
    >
      <LabelAttribute />
      <DisabledAttribute />
      <RequiredAttribute />
      <MaxFileSizeAttribute />
      <AcceptedMediaTypesAttribute />
    </Form>
  );
};

const AcceptedMediaTypesAttribute = () => {
  const node = useFocusedNode();
  const setAcceptedMediaTypesAttribute = useSetFocusedNodePropertyValue(
    "reference.acceptedMediaTypes",
  );

  if (!node) {
    return null;
  }

  return isFileField(node) ? (
    <Form.Item name="acceptedMediaTypes" label="Accepted Media Types">
      <Input
        onChange={event => {
          setAcceptedMediaTypesAttribute(
            event.currentTarget.value.split(",").map(v => v.trim()),
          );
        }}
      />
    </Form.Item>
  ) : (
    <>
      Attribute Accepted Media Types can be used only with FORM_CONTROL FILE
      type
    </>
  );
};
