var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { useToggle } from "react-use";
import { Collapse } from "react-collapse";
import { Text } from "../text";
import { Button } from "../button";
import { styled } from "../theme";
export var Collapsible = function (_a) {
    var children = _a.children, splitIndex = _a.splitIndex, openTitle = _a.openTitle, closeTitle = _a.closeTitle, buttonSize = _a.buttonSize, Button = _a.Button;
    var _b = useToggle(false), isOpened = _b[0], toggle = _b[1];
    var _c = splitChildrenByIndex({
        children: children,
        splitIndex: splitIndex,
    }), isCollapsible = _c.isCollapsible, visibleChildren = _c.visibleChildren, collapsedChildren = _c.collapsedChildren;
    return (React.createElement("div", null,
        visibleChildren,
        isCollapsible && collapsedChildren && (React.createElement(Collapse, { isOpened: isOpened }, collapsedChildren)),
        isCollapsible && (React.createElement(Button, { onClick: toggle, size: buttonSize, type: "link" },
            React.createElement(Text, { fontWeight: "bold" }, isOpened ? closeTitle : openTitle)))));
};
Collapsible.defaultProps = {
    splitIndex: 1,
    Button: styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    && {\n      height: auto;\n      min-width: 0;\n      margin-top: 8px;\n    }\n  "], ["\n    && {\n      height: auto;\n      min-width: 0;\n      margin-top: 8px;\n    }\n  "]))),
};
var splitChildrenByIndex = function (_a) {
    var children = _a.children, splitIndex = _a.splitIndex;
    var allChildren = React.Children.toArray(children);
    var topIndex = allChildren.length - 1;
    var isCollapsible = topIndex >= splitIndex;
    if (!isCollapsible) {
        return {
            isCollapsible: isCollapsible,
            visibleChildren: allChildren,
        };
    }
    var lastVisibleChildIndex = isCollapsible ? splitIndex : topIndex;
    var visibleChildren = allChildren.slice(0, lastVisibleChildIndex);
    var collapsedChildren = allChildren.slice(lastVisibleChildIndex);
    return {
        isCollapsible: isCollapsible,
        visibleChildren: visibleChildren,
        collapsedChildren: collapsedChildren,
    };
};
var templateObject_1;
