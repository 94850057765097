"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = {
    name: "some_in",
    errorMessage: "some_in validation failed",
    validate: function (value, requirement) {
        if (typeof value !== "string") {
            return false;
        }
        var acceptedValues = requirement.split(",");
        return value.split(":").some(function (v) { return acceptedValues.includes(v); });
    },
};
