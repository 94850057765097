var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import Token from "markdown-it/lib/token";
// The if statements are for now ignored from parser
var ANSWER_REGEX = /\[\[(?!IF).*?]]/g;
var getMatches = function (token) {
    return token.content.match(ANSWER_REGEX);
};
var getAnswerToken = function (_a) {
    var id = _a.id, value = _a.value, _b = _a.label, label = _b === void 0 ? "" : _b;
    var token = new Token("answer", "", 0);
    token.attrs = [
        ["id", id],
        ["value", value],
        ["label", label],
    ];
    token.content = "";
    token.children = [];
    return token;
};
var getTextToken = function (_a) {
    var content = _a.content;
    var text = new Token("text", "", 0);
    text.content = content;
    var token = new Token("inline", "", 0);
    token.level = 1;
    token.children = [text];
    return token;
};
var getTokens = function (matches, input, answers) {
    return matches.reduce(function (acc, match, index) {
        var _a, _b, _c, _d;
        var _e = acc.rest.split(match), before = _e[0], after = _e[1];
        var rest = after !== null && after !== void 0 ? after : "";
        var id = match.replace("[[", "").replace("]]", "");
        var isLast = index >= matches.length - 1;
        if (isLast) {
            return {
                rest: rest,
                tokens: __spreadArrays(acc.tokens, [
                    getTextToken({ content: before }),
                    getAnswerToken({
                        id: id,
                        value: (_a = answers[id]) === null || _a === void 0 ? void 0 : _a.value,
                        label: (_b = answers[id]) === null || _b === void 0 ? void 0 : _b.label,
                    }),
                    getTextToken({ content: rest }),
                ]),
            };
        }
        return {
            rest: rest,
            tokens: __spreadArrays(acc.tokens, [
                getTextToken({ content: before }),
                getAnswerToken({
                    id: id,
                    value: (_c = answers[id]) === null || _c === void 0 ? void 0 : _c.value,
                    label: (_d = answers[id]) === null || _d === void 0 ? void 0 : _d.label,
                }),
            ]),
        };
    }, { rest: input, tokens: [] }).tokens;
};
export default (function (answers) { return function (md) {
    // push a new rule after other rules are applied
    md.core.ruler.push("answer", function (state) {
        var tokens = state.tokens;
        tokens.forEach(function (token, i) {
            var _a;
            var matches = getMatches(token);
            if (matches) {
                (_a = token === null || token === void 0 ? void 0 : token.children) === null || _a === void 0 ? void 0 : _a.forEach(function (childToken, i) {
                    var _a;
                    var matches = getMatches(childToken);
                    if (matches) {
                        // Mutation because there is only mutation API
                        (_a = token === null || token === void 0 ? void 0 : token.children) === null || _a === void 0 ? void 0 : _a.splice.apply(_a, __spreadArrays([i,
                            1], getTokens(matches, childToken.content, answers)));
                    }
                });
            }
        });
        return false;
    });
}; });
