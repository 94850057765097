import React from "react";
import {Card, Space, Tag} from "antd";
import {NodeType, QuestionType} from "@reside/forms/dist/templateTypes";

import {TemplateNodes} from "../../model/schemaTypes";
import {useFocusedNode} from "../../model/editor";
import {TextFieldForm} from "../editable-text-field";
import {CurrencyFieldForm} from "../editable-currency-field";
import {StatesFieldForm} from "../editable-states-field";
import {TimeZoneFieldForm} from "../editable-time-zones-field";
import {DateFieldForm} from "../editable-date-field";
import {TextareaFieldForm} from "../editable-textarea-field";
import {ImageFieldForm} from "../editable-image-field";
import {SignatureFieldForm} from "../editable-signature-field";
import {EditableFileForm} from "../editable-file-field";
import {SSNFieldForm} from "../editable-ssn-field";
import {ZipCodeFieldForm} from "../editable-zipcode-field";
import {PhoneFieldForm} from "../editable-phone-field";
import {ImageForm} from "../editable-image";
import {ContinueButtonForm} from "../editable-continue-button";
import {NodeName} from "../node-name";
import {SelectFieldForm} from "../editable-select-field";
import {GroupFieldForm} from "../group-field-form";
import {CheckboxFieldForm} from "../checkbox-field-form";
import {SlideForm} from "../editable-slide";
import {ListFieldForm} from "../editable-list";
import {EmailFieldForm} from "../editable-email-field";
import {FieldArrayForm} from "../editable-field-array";
import {ColumnForm} from "../editable-column";

const SLIDE = "slide";

export const CustomizationSidebar = () => {
  const node = useFocusedNode() as TemplateNodes;

  const renderForm = (node: TemplateNodes) => {
    switch (node.type) {
      case SLIDE: {
        return <SlideForm />;
      }
      case NodeType.PARAGRAPH:
      case NodeType.TITLE:
      case NodeType.SUBTITLE:
        return <>Block customization not implemented</>;
      case NodeType.COLUMN:
        return <ColumnForm />;
      case NodeType.LIST:
        return <ListFieldForm />;
      case NodeType.FIELD_ARRAY:
        return <FieldArrayForm />;
      case NodeType.FORM_CONTROL:
        switch (node.reference.type) {
          case QuestionType.CURRENCY:
            return <CurrencyFieldForm />;
          case QuestionType.SELECT:
            return <SelectFieldForm />;
          case QuestionType.STATE_SELECT:
            return <StatesFieldForm />;
          case QuestionType.TIMEZONE_SELECT:
            return <TimeZoneFieldForm />;
          case QuestionType.RADIO:
            return <GroupFieldForm />;
          case QuestionType.CHECKBOX:
            return <CheckboxFieldForm />;
          case QuestionType.TEXT:
            switch (node.format) {
              case "ssn":
                return <SSNFieldForm />;
              case "zip-code":
                return <ZipCodeFieldForm />;
              case "us-phone":
                return <PhoneFieldForm />;
              default:
                switch (node.keyboardType) {
                  case "email":
                    return <EmailFieldForm />;
                  default:
                    return <TextFieldForm />;
                }
            }
          case QuestionType.DATE:
            return <DateFieldForm />;
          case QuestionType.TEXTAREA:
            return <TextareaFieldForm />;
          case QuestionType.IMAGE:
            return <ImageFieldForm />;
          case QuestionType.SIGNATURE:
            return <SignatureFieldForm />;
          case QuestionType.FILE:
            return <EditableFileForm />;
          default:
            return <>Block customization not implemented</>;
        }
      case NodeType.IMAGE:
        return <ImageForm />;
      case NodeType.CONTINUE_BUTTON:
        return <ContinueButtonForm />;
      case NodeType.FRAGMENT:
        return <>Block customization not implemented</>;
      default:
        return <>Block customization not implemented</>;
    }
  };

  return (
    <Card
      title={
        <Space>
          Customize Block
          {node ? (
            <Tag color="processing">
              <NodeName node={node} displayBlockType />
            </Tag>
          ) : null}
        </Space>
      }
    >
      {node ? renderForm(node) : "Click some element on the left to customize."}
    </Card>
  );
};
