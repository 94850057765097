var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Node from "./Node";
var TableRow = /** @class */ (function (_super) {
    __extends(TableRow, _super);
    function TableRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TableRow.prototype, "name", {
        get: function () {
            return "tr";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TableRow.prototype, "schema", {
        get: function () {
            return {
                content: "(th | td)*",
                tableRole: "row",
                parseDOM: [{ tag: "tr" }],
                toDOM: function () {
                    return ["tr", 0];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    TableRow.prototype.parseMarkdown = function () {
        return { block: "tr" };
    };
    return TableRow;
}(Node));
export default TableRow;
