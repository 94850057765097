import React from "react";
import {Form, InputNumber, Tooltip, Typography} from "antd";
import {RecoilState, useRecoilValue} from "recoil";

import {ColumnNode} from "@reside/forms";
import {styled, Grid} from "@reside/ui";

import {DroppableField} from "../droppable-field";
import {ChildrenDroppable} from "../children-droppable";
import {
  AtomizedBranchNode,
  AtomProp,
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {useDragAndDrop} from "../../hooks/useDragAndDrop";
import {Override, TemplateNodes} from "../../model/schemaTypes";

const {Link} = Typography;

export const EditableColumn = ({
  atom: parentAtom,
  node: {children},
  index,
  isArrayFieldScoped,
}: {
  node: Override<ColumnNode, AtomizedBranchNode>;
  index: number;
  isArrayFieldScoped: boolean;
} & AtomProp) => {
  const {dropRef, dragRef, isDragging} = useDragAndDrop({index, parentAtom});
  const {columns, columnStart, columnEnd} = useRecoilValue(
    parentAtom,
  ) as ColumnNode;
  const hasChildren = children?.length > 0;
  return (
    <div ref={dropRef}>
      <DragWrapper
        style={{paddingTop: "25px", paddingBottom: "25px"}}
        ref={dragRef}
        isDragging={isDragging}
      >
        <Grid columns={columns} columnStart={columnStart} columnEnd={columnEnd}>
          {hasChildren ? (
            children.map((atom, index) => (
              <ChildrenRenderer
                atom={atom}
                index={index}
                parentAtom={parentAtom}
                key={atom.key}
                isArrayFieldScoped={isArrayFieldScoped}
              />
            ))
          ) : (
            <ChildrenDroppable help="The items will be visible once you drop here any items." />
          )}
        </Grid>
      </DragWrapper>
    </div>
  );
};

const ChildrenRenderer = ({
  atom,
  index,
  parentAtom,
  isArrayFieldScoped,
}: {
  parentAtom: RecoilState<TemplateNodes>;
  index: number;
  isArrayFieldScoped: boolean;
} & AtomProp) => {
  const {columnStart, columnEnd} = useRecoilValue(atom) as any;

  return (
    <DroppableField
      index={index}
      key={atom.key}
      atom={atom}
      parentAtom={parentAtom}
      customStyle={{gridColumnStart: columnStart, gridColumnEnd: columnEnd}}
      isArrayFieldScoped={isArrayFieldScoped}
    />
  );
};

const DragWrapper = styled.div<{isDragging: boolean}>`
  opacity: ${({isDragging}) => (isDragging ? 0.5 : 1)};
`;

export const ColumnForm = () => {
  const node = useFocusedNode() as ColumnNode;

  if (!node) {
    return null;
  }

  return (
    <Form
      key={node.id}
      initialValues={{
        columns: node?.columns,
        columnStart: node?.columnStart,
        columnEnd: node?.columnEnd,
      }}
    >
      <ColumnsAttribute />
      <ColumnStartAttribute />
      <ColumnEndAttribute />
    </Form>
  );
};

export const ColumnsAttribute = () => {
  const node = useFocusedNode();
  const setColumns = useSetFocusedNodePropertyValue("columns");

  if (!node) {
    return null;
  }

  return (
    <Form.Item
      name="columns"
      label="Columns"
      rules={[{type: "number", min: 1, required: true}]}
    >
      <InputNumber min={0} onChange={value => setColumns(value)} />
    </Form.Item>
  );
};

export const ColumnStartAttribute = () => {
  const node = useFocusedNode();
  const setColumnStart = useSetFocusedNodePropertyValue("columnStart");

  if (!node) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Link
          href="https://developer.mozilla.org/en-US/docs/Web/CSS/grid-column-start"
          target="_blank"
          rel="noopener noreferrer"
        >
          MDN Documentation
        </Link>
      }
    >
      <Form.Item
        name="columnStart"
        label="Column Start"
        rules={[{type: "number"}]}
      >
        <InputNumber onChange={value => setColumnStart(value)} />
      </Form.Item>
    </Tooltip>
  );
};

export const ColumnEndAttribute = () => {
  const node = useFocusedNode();
  const setColumnEnd = useSetFocusedNodePropertyValue("columnEnd");

  if (!node) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Link
          href="https://developer.mozilla.org/en-US/docs/Web/CSS/grid-column-end"
          target="_blank"
          rel="noopener noreferrer"
        >
          MDN Documentation
        </Link>
      }
    >
      <Form.Item name="columnEnd" label="Column End" rules={[{type: "number"}]}>
        <InputNumber onChange={value => setColumnEnd(value)} />
      </Form.Item>
    </Tooltip>
  );
};
