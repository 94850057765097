import React from "react";
import {useRecoilValue} from "recoil";
import {Tooltip} from "antd";
import {EditOutlined} from "@ant-design/icons";

import {AtomProp, useSetAtomPropertyValue} from "../../../../model/editor";
import {GroupNode} from "../../../../model/schemaTypes";
import {ActionButton} from "./ActionButton";

export const RenameGroupTitleButton = ({
  atom,
  disabled,
}: {disabled: boolean} & AtomProp<GroupNode>) => {
  const renameTitle = useSetAtomPropertyValue(atom, "title.translationKey");
  const node = useRecoilValue(atom);

  return (
    <Tooltip
      title="Can not rename common component or component with common  parent"
      {...(disabled ? {} : {visible: false})}
    >
      <ActionButton
        disabled={disabled}
        onClick={e => {
          e.stopPropagation();

          const title = prompt(
            `Rename ${node.type} to:`,
            node.title.translationKey,
          );

          if (title) {
            renameTitle(title);
          }
        }}
        icon={<EditOutlined />}
      />
    </Tooltip>
  );
};
