var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { Field } from "formik";
import { styled } from "../../theme";
import { Button } from "../../button";
import { FloatingFieldLabel } from "../atoms/FloatingFieldLabel";
import { FileInput } from "../atoms/file-input";
import { FieldError } from "../atoms/field-error";
import { Label } from "../atoms/Label";
export var FileField = function (_a) {
    var name = _a.name, label = _a.label, title = _a.title, emptyMessage = _a.emptyMessage, uploadedMessage = _a.uploadedMessage, acceptedMediaTypes = _a.acceptedMediaTypes, disabled = _a.disabled, FormField = _a.FormField;
    return (React.createElement(FormField, { name: name }, function (_a) {
        var field = _a.field, form = _a.form;
        var value = field.value;
        var setFieldTouched = form.setFieldTouched, setFieldValue = form.setFieldValue;
        var onChange = function (event) {
            setFieldTouched(name, true);
            setFieldValue(name, event.currentTarget.files[0]);
        };
        var onRemove = function () {
            setFieldValue(name, undefined);
        };
        return (React.createElement(React.Fragment, null,
            label && (React.createElement(Label, { htmlFor: name },
                React.createElement(FloatingFieldLabel, { visible: true }, label))),
            React.createElement(Wrapper, null,
                React.createElement(FileInputTrigger, { disabled: disabled },
                    React.createElement("span", null, title),
                    React.createElement(FileInput, { id: name, name: name, acceptedMediaTypes: acceptedMediaTypes, disabled: disabled, onChange: onChange })),
                React.createElement(FileInputValue, { name: name, value: value, onRemove: onRemove, emptyMessage: emptyMessage, uploadedMessage: uploadedMessage, disabled: disabled })),
            React.createElement(FieldError, { name: name })));
    }));
};
FileField.defaultProps = {
    title: "Upload File",
    emptyMessage: "No file chosen",
    uploadedMessage: "Show current content",
    disabled: false,
    FormField: Field,
};
export var FileInputValue = function (_a) {
    var name = _a.name, value = _a.value, emptyMessage = _a.emptyMessage, uploadedMessage = _a.uploadedMessage, disabled = _a.disabled, onRemove = _a.onRemove;
    if (!value) {
        return React.createElement("label", { htmlFor: name }, emptyMessage);
    }
    return (React.createElement(React.Fragment, null,
        value instanceof File ? (React.createElement(OverflowEllipsis, null, value.name)) : (React.createElement("a", { rel: "noopener noreferrer", target: "_blank", href: value.url }, uploadedMessage)),
        React.createElement(ButtonWrapper, null,
            React.createElement(Button, { outline: true, color: "danger", size: "small", icon: "close", disabled: disabled, onClick: onRemove }, "Remove"))));
};
var OverflowEllipsis = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 100%;\n"], ["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  width: 100%;\n"])));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 15px;\n  margin-bottom: 5px;\n  display: inline-flex;\n  align-items: center;\n  width: 100%;\n"], ["\n  margin-top: 15px;\n  margin-bottom: 5px;\n  display: inline-flex;\n  align-items: center;\n  width: 100%;\n"])));
var ButtonWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 16px;\n"], ["\n  margin-left: 16px;\n"])));
/**
 * Note: <Button /> can't be reused here, as we are very custom.
 */
export var FileInputTrigger = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-right: 16px;\n  padding: 12px 32px;\n  cursor: pointer;\n  border-radius: 5px;\n  border: 1px solid ", ";\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  font-size: 15px;\n  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);\n  white-space: nowrap;\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n    transform: translateY(-1px);\n  }\n\n  ", "\n"], ["\n  margin-right: 16px;\n  padding: 12px 32px;\n  cursor: pointer;\n  border-radius: 5px;\n  border: 1px solid ", ";\n  background-color: ", ";\n  color: ", ";\n  font-weight: ", ";\n  font-size: 15px;\n  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);\n  white-space: nowrap;\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n    transform: translateY(-1px);\n  }\n\n  ",
    "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.medium;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.white;
}, function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "{\n    opacity: 0.6;\n    cursor: not-allowed;\n    filter: saturate(25%);\n  }";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
