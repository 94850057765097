import React from "react";
import { cx } from "@emotion/css";
import { range } from "lodash";
export var StarRating = function (_a) {
    var name = _a.name, value = _a.value, count = _a.count, onChange = _a.onChange;
    return (React.createElement("div", { "data-test-id": "star-rating" }, range(1, count + 1).map(function (option) { return (React.createElement("label", { key: option, className: cx("star-rating__item", {
            "star-rating__item--active": option <= Number(value),
        }) },
        React.createElement("input", { type: "radio", name: name, value: option, onChange: function () { return onChange(option); } }))); })));
};
StarRating.defaultProps = {
    value: 0,
    count: 5,
    onChange: function () { },
};
