var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "@reside/ui";
import { Button, Divider, Space, TreeSelect } from "antd";
import getFormattingMenuItems from "../menus/formatting";
export var TopMenu = function (_a) {
    var answers = _a.answers, treeData = _a.treeData, commands = _a.commands, state = _a.state, selection = _a.selection;
    var _b, _c;
    if (!state) {
        return null;
    }
    var items = getFormattingMenuItems(state);
    if (!items.length) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledSpace, { size: "small" },
            items
                .filter(function (item) { return item.visible !== false && item.icon; })
                .map(function (item, index) {
                var Icon = item.icon;
                var isActive = item.active ? item.active(state) : false;
                return (React.createElement(Button, { key: item.name, size: "small", icon: React.createElement(Icon, null), 
                    // disabled={selection.empty}
                    type: isActive ? "primary" : "default", onClick: function () { return item.name && commands[item.name](item.attrs); } }));
            }),
            React.createElement(Divider, { type: "vertical" }),
            React.createElement(StyledTreeSelect, { size: "small", placeholder: "Please select", showSearch /* TODO: Update ANTD to version where text search would work on title/label and not only value/id  */: true, value: (_c = (_b = selection.node) === null || _b === void 0 ? void 0 : _b.attrs) === null || _c === void 0 ? void 0 : _c.value, onChange: function (value, labelList, extra) {
                    var props = extra.triggerNode.props;
                    commands["answer"]({
                        id: props.id,
                        value: props.value,
                        label: props.title,
                    });
                }, treeData: treeData, treeDefaultExpandAll: true }))));
};
var StyledSpace = styled(Space)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  line-height: 1rem;\n  border-top: 1px solid #a8b6ca;\n  border-right: 1px solid #a8b6ca;\n  border-left: 1px solid #a8b6ca;\n  padding: 2px 2px 1px;\n  background: #f0f2f5;\n  white-space: normal;\n\n  .ant-space-item:last-of-type {\n    width: 100%;\n  }\n"], ["\n  width: 100%;\n  line-height: 1rem;\n  border-top: 1px solid #a8b6ca;\n  border-right: 1px solid #a8b6ca;\n  border-left: 1px solid #a8b6ca;\n  padding: 2px 2px 1px;\n  background: #f0f2f5;\n  white-space: normal;\n\n  .ant-space-item:last-of-type {\n    width: 100%;\n  }\n"])));
var StyledTreeSelect = styled(TreeSelect)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  min-width: 360px;\n"], ["\n  min-width: 360px;\n"])));
var templateObject_1, templateObject_2;
