import React, {useState} from "react";
import {Skeleton, Typography} from "antd";

import {Credentials, useLoginQuery} from "../../model/auth";
import {SlideCard} from "../../atoms/slide-card";
import {PageLayout} from "../../atoms/page-layout";
import {Content} from "../../atoms/content";
import {LoginForm} from "./LoginForm";

const {Title, Paragraph, Text, Link} = Typography;

export const LoginPage = () => {
  const [credentials, setCredentials] = useState<Credentials>();

  const {isFetching} = useLoginQuery({credentials});

  return (
    <PageLayout
      leftSider={
        <Content>
          <Skeleton />
        </Content>
      }
    >
      <SlideCard>
        <div style={{textAlign: "center"}}>
          <Skeleton />
          <div style={{padding: 40}}>
            <>
              <Title>Login</Title>
              <Paragraph>
                Please log in with your <Text strong>Github account</Text>.
              </Paragraph>
              <Paragraph>
                <Link
                  target="_blank"
                  href="https://docs.github.com/en/github/authenticating-to-github/creating-a-personal-access-token"
                >
                  How to get my access token.
                </Link>
              </Paragraph>
              <LoginForm onSubmit={setCredentials} isLoading={isFetching} />
            </>
          </div>
          <Skeleton />
        </div>
      </SlideCard>
    </PageLayout>
  );
};
