"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = {
    name: "exact",
    errorMessage: "This field should equal to :value.",
    validate: function (value, requirement) {
        if (typeof value !== "string") {
            return false;
        }
        return value.split(":").includes(requirement);
    },
};
