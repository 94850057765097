import React from "react";
import {TextAreaField, TextareaFieldProps} from "@reside/ui";
import {Form} from "antd";

import {LabelAttribute} from "../label-attribute";
import {RequiredAttribute} from "../required-attribute";
import {DisabledAttribute} from "../disabled-attribute";
import {
  getInitialMaxLengthFormValue,
  MaxLengthAttribute,
} from "../max-length-attribute";
import {
  getInitialMinLengthFormValue,
  MinLengthAttribute,
} from "../min-length-attribute";
import {AtomProp, useFocusedNode} from "../../model/editor";
import {DisableClick} from "../disable-click";
import {FormControlSourceNode} from "../../model/schemaTypes";

export const EditableTextareaField = ({
  atom,
  ...props
}: TextareaFieldProps & AtomProp) => (
  <DisableClick>
    <TextAreaField {...props} />
  </DisableClick>
);

/**
 * FormControl type-specific form to adjust attributes.
 */
export const TextareaFieldForm = () => {
  const node = useFocusedNode() as FormControlSourceNode;

  return (
    <Form
      key={node.id}
      initialValues={{
        label: node?.reference?.translationKey,
        ...getInitialMinLengthFormValue(node),
        ...getInitialMaxLengthFormValue(node),
      }}
    >
      <LabelAttribute />
      <MinLengthAttribute />
      <MaxLengthAttribute />
      <DisabledAttribute />
      <RequiredAttribute />
    </Form>
  );
};
