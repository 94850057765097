var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
function isHardbreak(token) {
    return (token.type === "hardbreak" ||
        (token.type === "text" && token.content === "\\"));
}
export default function markdownBreakToParagraphs(md) {
    // insert a new rule after the "inline" rules are parsed
    md.core.ruler.after("inline", "breaks", function (state) {
        var Token = state.Token;
        var tokens = state.tokens;
        // work backwards through the tokens and find text that looks like a br
        for (var i = tokens.length - 1; i > 0; i--) {
            var tokenChildren = tokens[i].children || [];
            var matches = tokenChildren.filter(isHardbreak);
            if (matches.length) {
                var token = void 0;
                var nodes = [];
                var children = tokenChildren.filter(function (child) { return !isHardbreak(child); });
                var count = matches.length;
                if (!!children.length)
                    count++;
                for (var i_1 = 0; i_1 < count; i_1++) {
                    var isLast = i_1 === count - 1;
                    token = new Token("paragraph_open", "p", 1);
                    nodes.push(token);
                    var text = new Token("text", "", 0);
                    text.content = "";
                    token = new Token("inline", "", 0);
                    token.level = 1;
                    token.children = isLast ? __spreadArrays([text], children) : [text];
                    token.content = "";
                    nodes.push(token);
                    token = new Token("paragraph_close", "p", -1);
                    nodes.push(token);
                }
                tokens.splice.apply(tokens, __spreadArrays([i - 1, 3], nodes));
            }
        }
        return false;
    });
}
