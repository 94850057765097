import React from "react";
import {Button} from "antd";

import {useOnLogout, userProfileAtom} from "../../model/auth";
import {useRecoilValue} from "recoil";

export const LogoutButton = () => {
  const onLogout = useOnLogout();
  const userProfile = useRecoilValue(userProfileAtom);

  return (
    <Button type="ghost" danger onClick={onLogout}>
      Logout {userProfile?.name || userProfile?.login}
    </Button>
  );
};
