var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { splitListItem, sinkListItem, liftListItem, } from "prosemirror-schema-list";
import Node from "./Node";
var CheckboxItem = /** @class */ (function (_super) {
    __extends(CheckboxItem, _super);
    function CheckboxItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (event) {
            var _a;
            if (!((_a = _this.editor) === null || _a === void 0 ? void 0 : _a.view)) {
                return;
            }
            var view = _this.editor.view;
            var tr = view.state.tr;
            var _b = event.target.getBoundingClientRect(), top = _b.top, left = _b.left;
            var result = view.posAtCoords({ top: top, left: left });
            if (result) {
                var transaction = tr.setNodeMarkup(result.inside, undefined, {
                    checked: event.target.checked,
                });
                view.dispatch(transaction);
            }
        };
        return _this;
    }
    Object.defineProperty(CheckboxItem.prototype, "name", {
        get: function () {
            return "checkbox_item";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckboxItem.prototype, "schema", {
        get: function () {
            var _this = this;
            return {
                attrs: {
                    checked: {
                        default: false,
                    },
                },
                content: "paragraph block*",
                defining: true,
                draggable: false,
                parseDOM: [
                    {
                        tag: "li[data-type=\"" + this.name + "\"]",
                        getAttrs: function (dom) { return ({
                            checked: dom.className.includes("checked"),
                        }); },
                    },
                ],
                toDOM: function (node) {
                    var input = document.createElement("input");
                    input.type = "checkbox";
                    input.addEventListener("change", _this.handleChange);
                    if (node.attrs.checked) {
                        input.checked = true;
                    }
                    return [
                        "li",
                        {
                            "data-type": _this.name,
                            class: node.attrs.checked ? "checked" : undefined,
                        },
                        [
                            "span",
                            {
                                contentEditable: false,
                            },
                            input,
                        ],
                        ["div", 0],
                    ];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    CheckboxItem.prototype.keys = function (_a) {
        var type = _a.type;
        return {
            Enter: splitListItem(type),
            Tab: sinkListItem(type),
            "Shift-Tab": liftListItem(type),
            "Mod-]": sinkListItem(type),
            "Mod-[": liftListItem(type),
        };
    };
    CheckboxItem.prototype.toMarkdown = function (state, node) {
        state.write(node.attrs.checked ? "[x] " : "[ ] ");
        state.renderContent(node);
    };
    CheckboxItem.prototype.parseMarkdown = function () {
        return {
            block: "checkbox_item",
            getAttrs: function (tok) { return ({
                checked: tok.attrGet("checked") ? true : undefined,
            }); },
        };
    };
    return CheckboxItem;
}(Node));
export default CheckboxItem;
