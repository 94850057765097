var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import refractor from "refractor/core";
import bash from "refractor/lang/bash";
import css from "refractor/lang/css";
import clike from "refractor/lang/clike";
import csharp from "refractor/lang/csharp";
import java from "refractor/lang/java";
import javascript from "refractor/lang/javascript";
import json from "refractor/lang/json";
import markup from "refractor/lang/markup";
import php from "refractor/lang/php";
import python from "refractor/lang/python";
import powershell from "refractor/lang/powershell";
import ruby from "refractor/lang/ruby";
import typescript from "refractor/lang/typescript";
import { setBlockType } from "prosemirror-commands";
import { textblockTypeInputRule } from "prosemirror-inputrules";
import copy from "copy-to-clipboard";
import Prism, { LANGUAGES } from "../plugins/Prism";
import Node from "./Node";
import { ToastType } from "../types";
[
    bash,
    css,
    clike,
    csharp,
    java,
    javascript,
    json,
    markup,
    php,
    python,
    powershell,
    ruby,
    typescript,
].forEach(refractor.register);
var CodeFence = /** @class */ (function (_super) {
    __extends(CodeFence, _super);
    function CodeFence() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleLanguageChange = function (event) {
            var _a;
            if (!((_a = _this.editor) === null || _a === void 0 ? void 0 : _a.view)) {
                return;
            }
            var view = _this.editor.view;
            var tr = view.state.tr;
            var element = event.target;
            var _b = element.getBoundingClientRect(), top = _b.top, left = _b.left;
            var result = view.posAtCoords({ top: top, left: left });
            if (result) {
                var transaction = tr.setNodeMarkup(result.inside, undefined, {
                    language: element.value,
                });
                view.dispatch(transaction);
            }
        };
        return _this;
    }
    Object.defineProperty(CodeFence.prototype, "languageOptions", {
        get: function () {
            return Object.entries(LANGUAGES);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CodeFence.prototype, "name", {
        get: function () {
            return "code_fence";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CodeFence.prototype, "schema", {
        get: function () {
            var _this = this;
            return {
                attrs: {
                    language: {
                        default: "javascript",
                    },
                },
                content: "text*",
                marks: "",
                group: "block",
                code: true,
                defining: true,
                draggable: false,
                parseDOM: [
                    { tag: "pre", preserveWhitespace: "full" },
                    {
                        tag: ".code-block",
                        preserveWhitespace: "full",
                        contentElement: "code",
                        getAttrs: function (dom) {
                            return {
                                language: dom.dataset.language,
                            };
                        },
                    },
                ],
                toDOM: function (node) {
                    var button = document.createElement("button");
                    button.innerText = "Copy";
                    button.type = "button";
                    button.addEventListener("click", _this.handleCopyToClipboard(node));
                    var select = document.createElement("select");
                    select.addEventListener("change", _this.handleLanguageChange);
                    _this.languageOptions.forEach(function (_a) {
                        var key = _a[0], label = _a[1];
                        var option = document.createElement("option");
                        var value = key === "none" ? "" : key;
                        option.value = value;
                        option.innerText = label;
                        option.selected = node.attrs.language === value;
                        select.appendChild(option);
                    });
                    return [
                        "div",
                        { class: "code-block", "data-language": node.attrs.language },
                        ["div", { contentEditable: false }, select, button],
                        ["pre", ["code", { spellCheck: false }, 0]],
                    ];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    CodeFence.prototype.commands = function (_a) {
        var type = _a.type;
        return function () { return setBlockType(type); };
    };
    CodeFence.prototype.keys = function (_a) {
        var type = _a.type;
        return {
            "Shift-Ctrl-\\": setBlockType(type),
        };
    };
    CodeFence.prototype.handleCopyToClipboard = function (node) {
        var _this = this;
        return function () {
            copy(node.textContent);
            if (_this.options.onShowToast) {
                _this.options.onShowToast(_this.options.dictionary.codeCopied, ToastType.Info);
            }
        };
    };
    Object.defineProperty(CodeFence.prototype, "plugins", {
        get: function () {
            return [
                Prism({
                    name: this.name,
                    deferred: !this.options.initialReadOnly,
                }),
            ];
        },
        enumerable: true,
        configurable: true
    });
    CodeFence.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [textblockTypeInputRule(/^```$/, type)];
    };
    CodeFence.prototype.toMarkdown = function (state, node) {
        state.write("```" + (node.attrs.language || "") + "\n");
        state.text(node.textContent, false);
        state.ensureNewLine();
        state.write("```");
        state.closeBlock(node);
    };
    Object.defineProperty(CodeFence.prototype, "markdownToken", {
        get: function () {
            return "fence";
        },
        enumerable: true,
        configurable: true
    });
    CodeFence.prototype.parseMarkdown = function () {
        return {
            block: "code_block",
            getAttrs: function (tok) { return ({ language: tok.info }); },
        };
    };
    return CodeFence;
}(Node));
export default CodeFence;
