var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../theme";
import { Text } from "./Text";
export var H1 = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
H1.defaultProps = {
    as: "h1",
    fontSize: [5, 6],
    marginTop: [3, 4],
    marginBottom: "1em",
    fontWeight: "bold",
};
export var H2 = styled(Text)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 1em;\n  margin-bottom: 0.5em;\n"], ["\n  margin-top: 1em;\n  margin-bottom: 0.5em;\n"])));
H2.defaultProps = {
    as: "h2",
    fontSize: [4, 5],
    fontWeight: "bold",
};
export var H3 = styled(Text)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 0.5em;\n  margin-bottom: 0.4em;\n"], ["\n  margin-top: 0.5em;\n  margin-bottom: 0.4em;\n"])));
H3.defaultProps = {
    as: "h3",
    fontSize: [3],
    fontWeight: "bold",
};
export var H4 = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 10px;\n  margin-bottom: 9px;\n"], ["\n  margin-top: 10px;\n  margin-bottom: 9px;\n"])));
H4.defaultProps = {
    as: "h4",
    fontSize: [3],
    fontWeight: "bold",
};
export var H5 = styled(Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 9px;\n  margin-bottom: 6px;\n"], ["\n  margin-top: 9px;\n  margin-bottom: 6px;\n"])));
H5.defaultProps = {
    as: "h5",
    fontSize: [2],
    fontWeight: "medium",
};
export var Caption = styled(Text)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 0.5em;\n  margin-bottom: 0.5em;\n  color: ", ";\n"], ["\n  margin-top: 0.5em;\n  margin-bottom: 0.5em;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue40;
});
Caption.defaultProps = {
    as: "div",
    fontSize: [1],
    fontWeight: "medium",
};
export var Paragraph = styled(Text)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 0.5em;\n  margin-bottom: 0.75em;\n"], ["\n  margin-top: 0.5em;\n  margin-bottom: 0.75em;\n"])));
Paragraph.defaultProps = {
    as: "p",
    lineHeight: "1.8em",
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
