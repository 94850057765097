import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {RecoilState} from "recoil";

import {DroppableField} from "../../../atoms/droppable-field";
import {TemplateNodes} from "../../../model/schemaTypes";

export const SlideForm = ({
  atoms,
  parentAtom,
}: {
  atoms: ReadonlyArray<RecoilState<TemplateNodes>>;
  parentAtom: RecoilState<TemplateNodes>;
}) => (
  <EmptyForm>
    <>
      {atoms?.map((atom, index) => (
        <DroppableField
          index={index}
          key={atom.key}
          atom={atom}
          parentAtom={parentAtom}
        />
      ))}
    </>
  </EmptyForm>
);

const EmptyForm: FunctionComponent = ({children}) => (
  <Formik initialValues={{}} onSubmit={() => {}}>
    {children}
  </Formik>
);
