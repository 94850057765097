var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Fragment } from "react";
import { Field } from "formik";
import { styled } from "../../theme";
import { FlexColumn, FlexRow } from "../../box";
import { FieldError } from "../atoms/field-error";
import { FieldLabel } from "../atoms/FieldLabel";
import { LockIcon } from "../atoms/LockIcon";
import { CheckboxItem } from "../atoms/checkbox-item";
import { OptionItem, OptionItemChildren } from "../atoms/option-item";
export var CheckboxGroupField = function (_a) {
    var name = _a.name, options = _a.options, label = _a.label, disabled = _a.disabled, OptionChildren = _a.OptionChildren, onChange = _a.onChange, getOptionLabel = _a.getOptionLabel, getOptionValue = _a.getOptionValue, getOptionKey = _a.getOptionKey, isChecked = _a.isChecked, renderOption = _a.renderOption, FormField = _a.FormField;
    return (React.createElement(FlexColumn, null,
        label && React.createElement(GroupLabel, { disabled: disabled }, label),
        React.createElement(FieldError, { name: name }),
        React.createElement(FlexRow, null,
            React.createElement(OptionItem, null, options.map(function (option, index) {
                var element = (React.createElement(FormField, { key: getOptionKey(option, name), name: name }, function (_a) {
                    var form = _a.form, field = _a.field;
                    var fieldValue = field.value || [];
                    var checked = isChecked(option, fieldValue);
                    return (React.createElement(CheckboxItem, { name: name, value: getOptionValue(option), label: getOptionLabel(option), disabled: disabled || option.disabled, checked: checked, onChange: function (event) {
                            field.onBlur(event);
                            form.setFieldValue(name, toggleArrayValue(field.value, getOptionValue(option), event.currentTarget.checked), true);
                            onChange(event);
                        }, testId: "checkbox-" + name + "-" + getOptionValue(option) }, checked && OptionChildren && option.children && (React.createElement(OptionItemChildren, null, OptionChildren({
                        children: option.children,
                        index: index,
                    })))));
                }));
                return (React.createElement(Fragment, { key: getOptionKey(option, name) }, renderOption(element, option, index)));
            })),
            disabled && React.createElement(LockIcon, null))));
};
CheckboxGroupField.defaultProps = {
    onChange: function () { },
    renderOption: function (element) { return element; },
    getOptionLabel: function (option) { return option.label; },
    getOptionValue: function (option) { return option.value; },
    getOptionKey: function (option, name) {
        return name + "." + option.value;
    },
    isChecked: function (option, fieldValue) {
        return fieldValue.includes(option.value);
    },
    FormField: Field,
};
function toggleArrayValue(arr, value, checked) {
    var set = new Set(arr);
    if (checked) {
        set.add(value);
    }
    else {
        set.delete(value);
    }
    return Array.from(set).filter(function (v) { return v; });
}
var GroupLabel = styled(FieldLabel)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-bottom: 0.625em;\n  word-break: break-word;\n"], ["\n  padding-bottom: 0.625em;\n  word-break: break-word;\n"])));
var templateObject_1;
