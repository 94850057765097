var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Field } from "formik";
import { differenceInCalendarDays, format, parseISO } from "date-fns";
import { Manager, Popper, Reference } from "react-popper";
import OutsideClickHandler from "react-outside-click-handler";
import { css } from "@emotion/react";
import { DATE_FORMAT } from "../../../date-picker/date";
import { styled } from "../../../theme";
import { DatePicker } from "../../../date-picker";
import { Text } from "../../../text";
import { Tooltip } from "../../../tooltip";
import { Checkbox } from "../checkbox";
import { useDateCheckboxContext } from "./DateCheckboxContext";
var defaultProps = {
    tooltipLength: 22,
    Checkbox: Checkbox,
    CheckboxWrapper: styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    position: relative;\n\n    margin-bottom: 0.625em;\n\n    &:last-of-type {\n      margin-bottom: 0;\n    }\n  "], ["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    position: relative;\n\n    margin-bottom: 0.625em;\n\n    &:last-of-type {\n      margin-bottom: 0;\n    }\n  "]))),
    ClickOutsideWrapper: styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""]))),
    Label: styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    cursor: pointer;\n    width: 100%;\n    align-items: center;\n    margin-bottom: 0.625em;\n\n    span {\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n      display: block;\n      line-height: 1.2em;\n    }\n\n    ", ";\n  "], ["\n    display: flex;\n    cursor: pointer;\n    width: 100%;\n    align-items: center;\n    margin-bottom: 0.625em;\n\n    span {\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n      display: block;\n      line-height: 1.2em;\n    }\n\n    ",
        ";\n  "])), function (_a) {
        var disabled = _a.disabled;
        return disabled && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        cursor: not-allowed;\n      "], ["\n        cursor: not-allowed;\n      "])));
    }),
    LabelText: styled(Text)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    font-size: 1.25em;\n    padding: 0 1.5em;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "], ["\n    font-size: 1.25em;\n    padding: 0 1.5em;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  "]))),
    DateLabel: function (_a) {
        var tooltipLength = _a.tooltipLength, label = _a.label, LabelText = _a.LabelText, disabled = _a.disabled;
        return tooltipLength < label.length ? (React.createElement(StyledTooltip, { content: label, disabled: disabled },
            React.createElement(LabelText, null, label))) : (React.createElement(LabelText, null, label));
    },
    DatePicker: styled(DatePicker)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    z-index: 1000;\n  "], ["\n    z-index: 1000;\n  "]))),
    children: function (_a) {
        var Checkbox = _a.Checkbox, Date = _a.Date, DateWrapper = _a.DateWrapper, name = _a.name;
        return (React.createElement(React.Fragment, null,
            React.createElement(Checkbox, null),
            React.createElement(Date, null),
            React.createElement(DateWrapper, null)));
    },
};
export var CheckboxDateBase = function (_a) {
    var label = _a.label, name = _a.name, CheckboxWrapper = _a.CheckboxWrapper, Checkbox = _a.Checkbox, Label = _a.Label, LabelText = _a.LabelText, ClickOutsideWrapper = _a.ClickOutsideWrapper, tooltipLength = _a.tooltipLength, DateLabel = _a.DateLabel, DatePicker = _a.DatePicker, children = _a.children, props = __rest(_a, ["label", "name", "CheckboxWrapper", "Checkbox", "Label", "LabelText", "ClickOutsideWrapper", "tooltipLength", "DateLabel", "DatePicker", "children"]);
    var _b = useDateCheckboxContext(), openedCheckboxName = _b.openedCheckboxName, setOpenedCheckboxName = _b.setOpenedCheckboxName, setCheckboxClosed = _b.setCheckboxClosed;
    return (React.createElement(Field, { name: name }, function (_a) {
        var form = _a.form, field = _a.field;
        var handleDateChange = function (date, unit) {
            if (unit !== "day") {
                return;
            }
            form.setFieldTouched(name, true, true);
            form.setFieldValue(name, format(date, DATE_FORMAT.ISO_DATE), true);
            setCheckboxClosed();
        };
        var dateValue = deserializeValue(field.value);
        return (React.createElement(Manager, null,
            React.createElement(Reference, null, function (_a) {
                var ref = _a.ref;
                return (React.createElement(CheckboxWrapper, { checked: field.value, disabled: props.disabled, ref: ref },
                    React.createElement(Label, { disabled: props.disabled }, children({
                        Checkbox: function () { return (React.createElement(Checkbox, __assign({}, field, props, { name: name, checked: field.value, onChange: function (event) {
                                field.onBlur(event);
                                form.setFieldTouched(name, true, true);
                                if (event.target.checked === true) {
                                    setOpenedCheckboxName(name);
                                }
                                else {
                                    form.setFieldValue(name, undefined, true);
                                }
                            } }))); },
                        Date: function () { return (React.createElement(DateLabel, __assign({ tooltipLength: tooltipLength, label: label, LabelText: LabelText, disabled: props.disabled }, props))); },
                        DateWrapper: function () { return (React.createElement(DateWrapper, { disabled: props.disabled, onClick: function () {
                                if (!props.disabled)
                                    setOpenedCheckboxName(name);
                            } }, field.value
                            ? format(parseISO(field.value), DATE_FORMAT.SHORT_DAY_IN_MONTH)
                            : null)); },
                        name: name,
                    }))));
            }),
            openedCheckboxName === name && (React.createElement(ClickOutsideWrapper, { className: "click-outside-wrapper" },
                React.createElement(OutsideClickHandler, { onOutsideClick: setCheckboxClosed },
                    React.createElement(Popper, { placement: "bottom" }, function (_a) {
                        var ref = _a.ref, style = _a.style;
                        return (React.createElement(DatePicker, { ref: ref, style: style, date: dateValue, range: {
                                before: [getDateRange(15), "day"],
                                after: [getDateRange(1), "day"],
                            }, onChange: handleDateChange }));
                    }))))));
    }));
};
CheckboxDateBase.defaultProps = defaultProps;
export var CheckboxDateSmall = function (props) { return (React.createElement(CheckboxDateBase, __assign({}, props))); };
CheckboxDateSmall.defaultProps = __assign(__assign({}, CheckboxDateBase.defaultProps), { size: "small", Checkbox: styled(CheckboxDateBase.defaultProps.Checkbox)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    border-width: 1px;\n  "], ["\n    border-width: 1px;\n  "]))), CheckboxWrapper: styled(CheckboxDateBase.defaultProps.CheckboxWrapper)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    margin-bottom: 0;\n\n    div {\n      margin-bottom: 0;\n    }\n  "], ["\n    margin-bottom: 0;\n\n    div {\n      margin-bottom: 0;\n    }\n  "]))), Label: styled(CheckboxDateBase.defaultProps.Label)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    line-height: 1em;\n    margin: 0.3em 0;\n  "], ["\n    line-height: 1em;\n    margin: 0.3em 0;\n  "]))), LabelText: styled(CheckboxDateBase.defaultProps.LabelText)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    padding-left: 5px;\n    font-size: 1em;\n  "], ["\n    padding-left: 5px;\n    font-size: 1em;\n  "]))) });
export var CheckboxDateFaded = function (props) { return (React.createElement(CheckboxDateBase, __assign({}, props))); };
CheckboxDateFaded.defaultProps = __assign(__assign({}, CheckboxDateBase.defaultProps), { Checkbox: styled(CheckboxDateBase.defaultProps.Checkbox)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    border-color: ", ";\n    border-width: 1px;\n\n    ", "\n\n    ", "\n  "], ["\n    border-color: ", ";\n    border-width: 1px;\n\n    ",
        "\n\n    ",
        "\n  "])), function (_a) {
        var theme = _a.theme;
        return theme.color.darkBlue100;
    }, function (_a) {
        var checked = _a.checked, theme = _a.theme;
        return checked && css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n        background-color: ", ";\n        border-color: ", ";\n      "], ["\n        background-color: ", ";\n        border-color: ", ";\n      "])), theme.color.primary, theme.color.primary);
    }, function (_a) {
        var checked = _a.checked, disabled = _a.disabled, theme = _a.theme;
        return disabled && css(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n        background-color: ", ";\n        border-color: ", ";\n        cursor: not-allowed;\n      "], ["\n        background-color: ", ";\n        border-color: ", ";\n        cursor: not-allowed;\n      "])), checked ? theme.color.gray100 : "transparent", theme.color.gray100);
    }), CheckboxWrapper: styled(CheckboxDateBase.defaultProps.CheckboxWrapper)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n    ", "\n  "], ["\n    ",
        "\n  "])), function (_a) {
        var checked = _a.checked, disabled = _a.disabled, theme = _a.theme;
        return (checked || disabled) && css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n        color: ", ";\n      "], ["\n        color: ", ";\n      "])), theme.color.gray100);
    }), Label: CheckboxDateBase.defaultProps.Label, LabelText: CheckboxDateBase.defaultProps.LabelText });
export var CheckboxDateFadedSmall = function (props) { return (React.createElement(CheckboxDateBase, __assign({}, props))); };
CheckboxDateFadedSmall.defaultProps = __assign(__assign({}, CheckboxDateFaded.defaultProps), { size: "small", Checkbox: CheckboxDateFaded.defaultProps.Checkbox, CheckboxWrapper: styled(CheckboxDateFaded.defaultProps.CheckboxWrapper)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    margin-bottom: 0;\n\n    div {\n      margin-bottom: 0;\n    }\n  "], ["\n    margin-bottom: 0;\n\n    div {\n      margin-bottom: 0;\n    }\n  "]))), Label: styled(CheckboxDateFaded.defaultProps.Label)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    line-height: 1em;\n    margin: 0.3em 0;\n  "], ["\n    line-height: 1em;\n    margin: 0.3em 0;\n  "]))), LabelText: styled(CheckboxDateFaded.defaultProps.LabelText)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    padding: 0 5px;\n    font-size: 1em;\n  "], ["\n    padding: 0 5px;\n    font-size: 1em;\n  "]))) });
var getDateRange = function (years) {
    var today = new Date();
    var nextYear = today.getFullYear() + years;
    var expectedDay = new Date(nextYear, 11, 31);
    return differenceInCalendarDays(expectedDay, today);
};
var deserializeValue = function (value) {
    return value ? parseISO(value) : new Date();
};
var DateWrapper = styled.div(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  margin-left: auto;\n  white-space: nowrap;\n\n  ", ";\n"], ["\n  margin-left: auto;\n  white-space: nowrap;\n\n  ",
    ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && css(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n      cursor: not-allowed;\n    "], ["\n      cursor: not-allowed;\n    "])));
});
var StyledTooltip = styled(Tooltip)(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  overflow: hidden;\n\n  ", ";\n"], ["\n  overflow: hidden;\n\n  ",
    ";\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && css(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n      cursor: not-allowed;\n    "], ["\n      cursor: not-allowed;\n    "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22;
