var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../../theme";
import { Text } from "../../text";
import { Box, FlexRowCenter } from "../../box";
import { Checkbox } from "../../form/atoms/checkbox";
export var ToggleCheckbox = function (_a) {
    var checked = _a.checked, onCheck = _a.onCheck;
    return (React.createElement(React.Fragment, null,
        React.createElement(Wrapper, null,
            React.createElement(Box, { flexDirection: "row" },
                React.createElement("label", null,
                    React.createElement(FlexRowCenter, null,
                        React.createElement(Checkbox, { name: "tocFilterMode", value: "yes", checked: checked, onChange: onCheck }),
                        React.createElement(Text, { fontSize: "1.25em", lineHeight: "1.5em", className: "tableofcontent-0-30" }, "Show only incomplete and flagged slides"))))),
        React.createElement("div", { className: "tableofcontent-0-31" },
            React.createElement(Text, { fontWeight: "bold", fontSize: "1.25em", lineHeight: "1.75em" }, "If this option is selected you can only move between incomplete or flagged slides. If you would like to go to already completed slides, please uncheck the box."))));
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: 0.625em;\n  margin-bottom: 1.25em;\n  margin-top: 1.25em;\n"], ["\n  margin-left: 0.625em;\n  margin-bottom: 1.25em;\n  margin-top: 1.25em;\n"])));
var templateObject_1;
