var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { splitListItem, sinkListItem, liftListItem, } from "prosemirror-schema-list";
import Node from "./Node";
var ListItem = /** @class */ (function (_super) {
    __extends(ListItem, _super);
    function ListItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ListItem.prototype, "name", {
        get: function () {
            return "list_item";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListItem.prototype, "schema", {
        get: function () {
            return {
                content: "paragraph block*",
                defining: true,
                draggable: true,
                parseDOM: [{ tag: "li" }],
                toDOM: function () { return ["li", 0]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    ListItem.prototype.keys = function (_a) {
        var type = _a.type;
        return {
            Enter: splitListItem(type),
            Tab: sinkListItem(type),
            "Shift-Tab": liftListItem(type),
            "Mod-]": sinkListItem(type),
            "Mod-[": liftListItem(type),
        };
    };
    ListItem.prototype.toMarkdown = function (state, node) {
        state.renderContent(node);
    };
    ListItem.prototype.parseMarkdown = function () {
        return { block: "list_item" };
    };
    return ListItem;
}(Node));
export default ListItem;
