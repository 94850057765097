import React from "react";
import {capitalize} from "lodash";
import {v4} from "uuid";
import {
  Button,
  CheckboxItem,
  Text,
  CompletionChecklist,
  DatePickerInput,
  Paragraph,
  Select,
  SnapshotControl,
  STATES,
  styled,
  Title,
  RadioItem,
  Subtitle,
  FileInputTrigger,
  Signature,
} from "@reside/ui";
import {TIME_ZONES} from "@reside/ui/dist/form-blocks/block-form-control";
import {
  CollapsibleNode,
  NodeType,
  QuestionType,
  VisibilityContextType,
} from "@reside/forms";

import {TextFieldType} from "../../constants";
import {
  FormControlSourceNode,
  SourceNode,
  SourceNodes,
} from "../../model/schemaTypes";
import {DisableClick} from "../disable-click";

const StyledParagraph = styled(Paragraph)`
  white-space: normal;
`;

const TinySnapshotControl = styled(DisableClick)`
  margin: 10px;

  img {
    width: 38px;
    margin: 12px;
  }

  label {
    div {
      height: auto;
    }

    span {
      display: none;
    }
  }
`;

const TinySignature = styled(DisableClick)`
  > div {
    margin: 10px 0;

    > div:last-child {
      height: 64px;
    }
  }

  div:first-child > div:first-child {
    display: none;
  }

  img {
    width: 90px;
    margin: 0 auto;
  }

  span {
    display: none;
  }
`;

const createNode = (type: NodeType) =>
  ({
    id: v4(),
    type: type as any,
  } as SourceNodes);

const createTransNode = (type: NodeType, translationKey = "") =>
  ({
    ...createNode(type),
    translationKey,
  } as SourceNodes);

const createFormControl = (questionType: QuestionType, translationKey = "") =>
  ({
    ...createNode(NodeType.FORM_CONTROL),
    rules: [],
    reference: {
      id: v4(),
      type: questionType,
      translationKey,
    },
  } as FormControlSourceNode);

export const blocks = [
  {
    titleText: "Paragraph",
    title: <StyledParagraph>Paragraph</StyledParagraph>,
    create: () => createTransNode(NodeType.PARAGRAPH),
  },
  {
    titleText: "List",
    title: <StyledParagraph>List</StyledParagraph>,
    create: () => ({
      ...createNode(NodeType.LIST),
      items: [{id: v4(), translationKey: ""}],
    }),
  },
  {
    titleText: "Subtitle",
    title: <Subtitle>Subtitle</Subtitle>,
    create: () => createTransNode(NodeType.SUBTITLE),
  },
  {
    titleText: "Title",
    title: <Title>Title</Title>,
    create: () => createTransNode(NodeType.TITLE),
  },
  {
    titleText: "Radio Field",
    title: (
      <>
        <DisableClick>
          <RadioItem label="Radio Option" />
        </DisableClick>
      </>
    ),
    create: () => createFormControl(QuestionType.RADIO, ""),
  },
  {
    titleText: "Checkbox Field",
    title: (
      <>
        <DisableClick>
          <CheckboxItem label="Checkbox Option" onChange={() => null} />
        </DisableClick>
      </>
    ),
    create: () => createFormControl(QuestionType.CHECKBOX, ""),
  },
  {
    titleText: "Select Field",
    title: (
      <DisableClick>
        <Select>
          <option>Select</option>
        </Select>
      </DisableClick>
    ),
    create: () => createFormControl(QuestionType.SELECT),
  },
  {
    titleText: "Timezone Select Field",
    title: (
      <DisableClick>
        <Select>
          {TIME_ZONES.map(state => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </Select>
      </DisableClick>
    ),
    create: () =>
      createFormControl(QuestionType.TIMEZONE_SELECT, "Timezone Select"),
  },
  {
    titleText: "State Select Field",
    title: (
      <DisableClick>
        <Select>
          {STATES.map(state => (
            <option key={state.id} value={state.id}>
              {state.label}
            </option>
          ))}
        </Select>
      </DisableClick>
    ),
    create: () => createFormControl(QuestionType.STATE_SELECT, "State Select"),
  },
  {
    titleText: "Text field",
    title: <StyledParagraph>Text field</StyledParagraph>,
    create: () => createFormControl(QuestionType.TEXT),
  },
  {
    titleText: "Textarea Field",
    title: <StyledParagraph>Textarea</StyledParagraph>,
    create: () => createFormControl(QuestionType.TEXTAREA),
  },
  {
    titleText: "Email Field",
    title: <StyledParagraph>Email field</StyledParagraph>,
    create: (): FormControlSourceNode => ({
      ...createFormControl(QuestionType.TEXT, capitalize(TextFieldType.EMAIL)),
      rules: ["email"],
      keyboardType: "email",
    }),
  },
  {
    titleText: "Phone Field",
    title: <StyledParagraph>Phone</StyledParagraph>,
    create: (): FormControlSourceNode => ({
      ...createFormControl(QuestionType.TEXT, capitalize(TextFieldType.PHONE)),
      rules: ["phone"],
      format: "us-phone",
      keyboardType: "tel",
    }),
  },
  {
    titleText: "Zip-Code Field",
    title: <StyledParagraph>Zip-Code Field</StyledParagraph>,
    create: (): FormControlSourceNode => ({
      ...createFormControl(QuestionType.TEXT, "Zip code"),
      rules: ["zip-code", "max_length:10"],
      format: "zip-code",
      keyboardType: "tel",
    }),
  },
  {
    titleText: "SSN Field",
    title: <StyledParagraph>SSN Field</StyledParagraph>,
    create: (): FormControlSourceNode => ({
      ...createFormControl(QuestionType.TEXT, TextFieldType.SSN.toUpperCase()),
      rules: ["ssn"],
      format: "ssn",
      keyboardType: "tel",
    }),
  },
  {
    titleText: "Currency field",
    title: <StyledParagraph>Currency field $</StyledParagraph>,
    create: (): FormControlSourceNode => ({
      ...createFormControl(QuestionType.CURRENCY),
      rules: ["numeric"],
    }),
  },
  {
    titleText: "Date field",
    title: (
      <DisableClick>
        <DatePickerInput
          range={{before: [0, "year"], after: [0, "year"]}}
          inputProps={{
            name: "dateField",
            placeholder: "Date",
          }}
          style={{cursor: "grab"}}
        />
      </DisableClick>
    ),
    create: () => ({...createFormControl(QuestionType.DATE), rules: ["date"]}),
  },
  {
    titleText: "Signature Field",
    title: (
      <TinySignature>
        <Signature
          value=""
          label=""
          defaultHeight={0}
          defaultWidth={0}
          transformUrl={() => ""}
          onChange={() => {}}
        />
      </TinySignature>
    ),
    create: (): ReadonlyArray<FormControlSourceNode> => {
      const signDateRef = v4();
      const dateField = createFormControl(QuestionType.DATE);

      return [
        {
          ...createFormControl(QuestionType.SIGNATURE),
          signDateRef,
          visibilityContext: [VisibilityContextType.PDF],
        },
        {
          ...dateField,
          disabled: true,
          rules: ["date"],
          reference: {
            ...dateField.reference,
            id: signDateRef,
          },
        },
      ];
    },
  },
  {
    titleText: "Image Field",
    title: (
      <TinySnapshotControl>
        <SnapshotControl name="snapshot" />
      </TinySnapshotControl>
    ),
    create: () => createFormControl(QuestionType.IMAGE),
  },
  {
    titleText: "File Field",
    title: (
      <DisableClick style={{margin: 20}}>
        <FileInputTrigger disabled={false}>
          <span>Upload File</span>
        </FileInputTrigger>
        <span>No file chosen</span>
      </DisableClick>
    ),
    create: () => createFormControl(QuestionType.FILE),
  },
  {
    titleText: "Field Array",
    title: <StyledParagraph>Field array</StyledParagraph>,
    create: () => createTransNode(NodeType.FIELD_ARRAY),
  },
  {
    titleText: "Collapsible",
    title: (
      <DisableClick>
        <Button type="link" size="large">
          <Text fontWeight="bold">Read More</Text>
        </Button>
      </DisableClick>
    ),
    create: () => {
      return {
        ...createNode(NodeType.COLLAPSIBLE),
        children: [],
      } as SourceNode<CollapsibleNode>;
    },
  },
  {
    titleText: "Column",
    title: <StyledParagraph>Column</StyledParagraph>,
    create: () => ({...createNode(NodeType.COLUMN), columns: 1}),
  },
  {
    titleText: "Card",
    title: <StyledParagraph>Card</StyledParagraph>,
    create: () => createNode(NodeType.CARD),
  },
  {
    titleText: "Image",
    title: <img style={{margin: 20}} alt="camera" src="/assets/camera.svg" />,
    create: () =>
      ({
        ...createNode(NodeType.IMAGE),
        url: `/assets/camera.svg`,
        style: {
          width: "100px",
          marginBottom: "40px",
        },
        horizontalAlignment: "left",
      } as any),
  },
  {
    titleText: "Completion Checklist",
    title: (
      <>
        <div className="blockcompletionchecklist-0-54">
          <Text
            fontSize="1.25em"
            lineHeight="1.75em"
            className="blockcompletionchecklist-0-55"
          >
            Completion Checklist
          </Text>
        </div>
        <CompletionChecklist
          completionChecklist={[
            {
              id: "1",
              complete: true,
              text: "Slide 1",
            },
            {
              id: "2",
              complete: false,
              text: "Slide 2",
            },
          ]}
          renderText={text => text}
        />
      </>
    ),
    create: () =>
      createTransNode(NodeType.COMPLETION_CHECKLIST, "Completion Checklist"),
  },
] as const;
