var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import refractor from "refractor/core";
import flattenDeep from "lodash/flattenDeep";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { findBlockNodes } from "prosemirror-utils";
export var LANGUAGES = {
    none: "None",
    bash: "Bash",
    css: "CSS",
    clike: "C",
    csharp: "C#",
    markup: "HTML",
    java: "Java",
    javascript: "JavaScript",
    json: "JSON",
    php: "PHP",
    powershell: "Powershell",
    python: "Python",
    ruby: "Ruby",
    typescript: "TypeScript",
};
function getDecorations(_a) {
    var doc = _a.doc, name = _a.name;
    var decorations = [];
    var blocks = findBlockNodes(doc).filter(function (item) { return item.node.type.name === name; });
    function parseNodes(nodes, classNames) {
        if (classNames === void 0) { classNames = []; }
        return nodes.map(function (node) {
            if (node.type === "element") {
                var classes = __spreadArrays(classNames, (node.properties.className || []));
                return parseNodes(node.children, classes);
            }
            return {
                text: node.value,
                classes: classNames,
            };
        });
    }
    blocks.forEach(function (block) {
        var startPos = block.pos + 1;
        var language = block.node.attrs.language;
        if (!language ||
            language === "none" ||
            !Object.keys(LANGUAGES).includes(language)) {
            return;
        }
        var nodes = refractor.highlight(block.node.textContent, language);
        flattenDeep(parseNodes(nodes))
            // @ts-ignore
            .map(function (node) {
            var from = startPos;
            var to = from + node.text.length;
            startPos = to;
            return __assign(__assign({}, node), { from: from,
                to: to });
        })
            .forEach(function (node) {
            var decoration = Decoration.inline(node.from, node.to, {
                class: (node.classes || []).join(" "),
            });
            decorations.push(decoration);
        });
    });
    return DecorationSet.create(doc, decorations);
}
export default function Prism(_a) {
    var name = _a.name, _b = _a.deferred, deferred = _b === void 0 ? true : _b;
    return new Plugin({
        key: new PluginKey("prism"),
        state: {
            init: function (_, _a) {
                var doc = _a.doc;
                if (deferred)
                    return;
                return getDecorations({ doc: doc, name: name });
            },
            apply: function (transaction, decorationSet, oldState, state) {
                // TODO: find way to cache decorations
                // see: https://discuss.prosemirror.net/t/how-to-update-multiple-inline-decorations-on-node-change/1493
                var deferredInit = !decorationSet;
                var nodeName = state.selection.$head.parent.type.name;
                var previousNodeName = oldState.selection.$head.parent.type.name;
                var codeBlockChanged = transaction.docChanged && [nodeName, previousNodeName].includes(name);
                if (deferredInit || codeBlockChanged) {
                    return getDecorations({ doc: transaction.doc, name: name });
                }
                return decorationSet.map(transaction.mapping, transaction.doc);
            },
        },
        props: {
            decorations: function (state) {
                return this.getState(state);
            },
        },
    });
}
