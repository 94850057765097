import React from "react";
/**
 * Dummy component for rendering translations as *elements*. By default there are no translations.
 * Custom BlockTranslated is expected to be passed to the BlockComponentContext.
 */
export var Translated = function (_a) {
    var translationKey = _a.translationKey, render = _a.render;
    return (React.createElement(React.Fragment, null, render ? render({ translation: translationKey }) : translationKey));
};
/**
 * Dummy mapper for rendering translation as *strings*.
 */
export var translate = function (translationKey) { return translationKey; };
