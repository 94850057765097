var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { undoInputRule } from "prosemirror-inputrules";
import { history, undo, redo } from "prosemirror-history";
import Extension from "../lib/Extension";
var History = /** @class */ (function (_super) {
    __extends(History, _super);
    function History() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(History.prototype, "name", {
        get: function () {
            return "history";
        },
        enumerable: true,
        configurable: true
    });
    History.prototype.keys = function () {
        return {
            "Mod-z": undo,
            "Mod-y": redo,
            "Shift-Mod-z": redo,
            Backspace: undoInputRule,
        };
    };
    Object.defineProperty(History.prototype, "plugins", {
        get: function () {
            return [history()];
        },
        enumerable: true,
        configurable: true
    });
    return History;
}(Extension));
export default History;
