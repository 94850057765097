import React from "react";
import {Form} from "antd";
import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {DisabledAttribute} from "../disabled-attribute";
import {QueryAttribute} from "../query-attribute";
import {RequiredAttribute} from "../required-attribute";
import {isSelectableField} from "../../utils";

export const GroupFieldForm = () => {
  const node = useFocusedNode();
  const setReference = useSetFocusedNodePropertyValue("reference", {
    replace: true,
  });

  if (!node) {
    return null;
  }

  if (!isSelectableField(node)) {
    return null;
  }

  return (
    <Form
      key={node.id}
      initialValues={{
        query: !!node.reference.query,
        queryPath: node?.reference.query?.path,
        optionLabel: node?.reference.optionLabel?.translationKey,
        choices: node?.reference.choices,
      }}
      onValuesChange={({query}, {choices}) => {
        if (query !== undefined) {
          if (query) {
            const {choices, ...reference} = node.reference;

            setReference({...reference, query: {path: ""}});
          } else {
            const {query, optionLabel, ...reference} = node.reference;

            setReference({...reference, choices});
          }
        }
      }}
    >
      <DisabledAttribute />
      <RequiredAttribute />
      <QueryAttribute />
    </Form>
  );
};
