import React from "react";
import {Typography} from "antd";
import {capitalize, memoize} from "lodash";
import {
  NodeType,
  QuestionType,
  TextFormControlFormat,
} from "@reside/forms/dist/templateTypes";

import {isFormControl, isGroupNode, isPdfNode} from "../../utils";
import {TemplateNodes} from "../../model/schemaTypes";
import {getPdfFileName} from "../../model/pdf/pdfUtils";

const {Text} = Typography;

type KeyboardTypes = "email";

/**
 * The component renders the most specific name of a node in human readable form, without any separators or naming convention.
 */
export const NodeName = ({
  node,
  displayBlockType,
}: {
  node: TemplateNodes;
  displayBlockType?: boolean;
}) => {
  //TODO reference in video does not have type
  if ((node as any).video) {
    return (
      <Text
        style={{
          color: "inherit",
          whiteSpace: "nowrap",
          overflow: "hidden",
          display: "block",
          textOverflow: "ellipsis",
        }}
      >
        VIDEO - NOT SUPPORTED YET
      </Text>
    );
  }
  return (
    <Text
      style={{
        color: "inherit",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "block",
        textOverflow: "ellipsis",
      }}
    >
      {isGroupNode(node) && !displayBlockType
        ? node.title.translationKey
        : isFormControl(node)
        ? `${
            node.format
              ? formatFieldFormat(node.format)
              : node.keyboardType
              ? formatFieldFormat(node.keyboardType as KeyboardTypes)
              : node.reference.type
              ? formatFieldType(node.reference.type)
              : "INVALID"
          } Field`
        : isPdfNode(node)
        ? getPdfFileName(node)
        : formatNodeType(node.type)}
    </Text>
  );
};

const formatFieldFormat = memoize(
  (format: TextFormControlFormat | KeyboardTypes) =>
    format === "ssn"
      ? "SSN"
      : format === "us-phone"
      ? "Phone"
      : format === "npi"
      ? "NPI"
      : capitalize(format),
);

const formatFieldType = memoize((type: QuestionType) =>
  type.split("_").map(capitalize).join(" "),
);

const formatNodeType = memoize(
  (type: NodeType | "slide" | "section" | "section-group") =>
    type === "tableOfContent"
      ? "Table Of Content"
      : type === "completionChecklist"
      ? "Completion Checklist"
      : type === "fieldArray"
      ? "Field Array"
      : type.split("-").map(capitalize).join(" "),
);
