import React from "react";
import {ParagraphNode} from "@reside/forms";
import {ADAPTABLE_TEXT_CLASSNAME, Paragraph, styled} from "@reside/ui";
import {debounce} from "lodash";

import {AtomProp, useSetAtomPropertyValue} from "../../model/editor";
import {BuilderMarkdownEditor} from "../../pages/editor/atoms/BuilderMarkdownEditor";
import {Override} from "../../model/schemaTypes";

type Props = Readonly<{
  isFocused?: boolean;
  node: Override<ParagraphNode, {id: string}>;
}> &
  AtomProp;

export const EditableParagraph = ({
  node: {id, translationKey},
  atom,
  isFocused,
}: Props) => {
  const setValue = useSetAtomPropertyValue(atom, "translationKey");

  const handleChange = debounce(value => {
    const text = value();
    setValue(text);
  }, 250);

  return (
    <BuilderMarkdownEditor
      id={id}
      defaultValue={translationKey}
      onChange={handleChange}
      hasTopMenu={isFocused}
    >
      {({Menu, Editor}) => (
        <>
          <Menu />
          <StyledParagraph as="span" className={ADAPTABLE_TEXT_CLASSNAME}>
            <Editor />
          </StyledParagraph>
        </>
      )}
    </BuilderMarkdownEditor>
  );
};

const StyledParagraph = styled(Paragraph)`
  font-size: 1.25em;
  line-height: 1.75em;
  padding: 0 7px;
  & .ProseMirror {
    &:focus {
      cursor: text;
    }
  }
`;
