var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
import Node from "./Node";
var Embed = /** @class */ (function (_super) {
    __extends(Embed, _super);
    function Embed() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Embed.prototype, "name", {
        get: function () {
            return "embed";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Embed.prototype, "schema", {
        get: function () {
            return {
                content: "inline*",
                group: "block",
                atom: true,
                attrs: {
                    href: {},
                    component: {},
                    matches: {},
                },
                parseDOM: [{ tag: "span" }],
                toDOM: function (node) { return [
                    "span",
                    { src: node.attrs.href, contentEditable: false },
                    0,
                ]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    Embed.prototype.component = function (_a) {
        var isEditable = _a.isEditable, isSelected = _a.isSelected, theme = _a.theme, node = _a.node;
        var Component = node.attrs.component;
        return (React.createElement(Component, { attrs: node.attrs, isEditable: isEditable, isSelected: isSelected, theme: theme }));
    };
    Embed.prototype.commands = function (_a) {
        var type = _a.type;
        return function (attrs) { return function (state, dispatch) {
            dispatch(state.tr.replaceSelectionWith(type.create(attrs)).scrollIntoView());
            return true;
        }; };
    };
    Embed.prototype.toMarkdown = function (state, node) {
        state.ensureNewLine();
        state.write("[" + state.esc(node.attrs.href) + "](" + state.esc(node.attrs.href) + ")");
        state.write("\n\n");
    };
    Embed.prototype.parseMarkdown = function () {
        return {
            node: "embed",
            getAttrs: function (token) { return ({
                href: token.attrGet("href"),
                matches: token.attrGet("matches"),
                component: token.attrGet("component"),
            }); },
        };
    };
    return Embed;
}(Node));
export default Embed;
