import React from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {useQuery} from "react-query";
import {Skeleton, Button, Typography, Result} from "antd";

import {branchNameAtom, userConfigSelector} from "../../model/repository";
import {TemplateTable} from "../../atoms/template-table";
import {SlideCard} from "../../atoms/slide-card";
import {
  cloneRepository,
  configureGitUser,
  getCurrentBranchName,
} from "../../services/GitService";
import {wipeFs} from "../../services/FileService";
import {logError} from "../../logging";
import {PageLayout} from "../../atoms/page-layout";
import {publishBranchName} from "../../config";

const {Title, Paragraph} = Typography;

export const CloneRepositoryPage = () => {
  const userConfig = useRecoilValue(userConfigSelector);
  const setBranchName = useSetRecoilState(branchNameAtom);

  const {
    isFetched: isBranchNameFetched,
    data: branchName = "",
    refetch: refetchBranchName,
  } = useQuery("getCurrentBranchName", getCurrentBranchName, {
    onSuccess: branchName => branchName && setBranchName(branchName),
  });

  const {
    isFetching: isCloningRepository,
    error: cloneError,
    refetch: refetchCloneRepository,
  } = useQuery(
    "cloneRepository",
    async () => {
      await cloneRepository({ref: publishBranchName});
      /**
       * Once the repository was initialised, we set the global git user
       * so we can continue with pulling, pushing, etc.
       */
      await configureGitUser(userConfig);
    },
    {
      /**
       * When we can't read branch name, then there is no repo, so we start cloning.
       */
      enabled: isBranchNameFetched && !branchName,
      onSuccess: refetchBranchName,
      onError: error => {
        logError(error);
      },
    },
  );

  return (
    <PageLayout leftSider={<TemplateTable />}>
      <SlideCard>
        <div style={{textAlign: "center"}}>
          <Skeleton />
          <div style={{padding: 40}}>
            {isCloningRepository ? (
              <>
                <Title level={2}>Cloning content repository</Title>
                <Paragraph>This will take several minutes.</Paragraph>
                <Paragraph>Please do not close the browser.</Paragraph>
              </>
            ) : cloneError ? (
              <Result
                status="error"
                title="Clone Repository Error"
                subTitle="Failed to clone the repository."
                extra={[
                  <Button
                    danger
                    ghost
                    type="primary"
                    onClick={async () => {
                      await wipeFs();
                      refetchCloneRepository();
                    }}
                  >
                    Clear data & clone again.
                  </Button>,
                ]}
              />
            ) : (
              <></>
            )}
          </div>
          <Skeleton />
        </div>
      </SlideCard>
    </PageLayout>
  );
};
