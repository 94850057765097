"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var templateUtils_1 = require("./templateUtils");
/**
 * Scope the field name (tag) for each field array item by it's index.
 * Compute visibility of nested fields and blocks.
 * LIMITATION: the 'visible' json condition on nested fields can refer only to answers from single field array item.
 */
exports.getIndexedBlock = function (block, id, index, answers, isChoiceActive) {
    if (isChoiceActive === void 0) { isChoiceActive = true; }
    var reference = block.reference;
    return __assign(__assign({}, block), { reference: reference
            ? __assign(__assign({}, reference), { id: id + "." + index + "." + reference.id, choices: reference.choices
                    ? reference.choices.map(function (choice) {
                        return choice.children
                            ? __assign(__assign({}, choice), { children: choice.children.map(function (block) {
                                    return exports.getIndexedBlock(block, id, index, answers, templateUtils_1.choiceIsActive(reference, answers, choice));
                                }) }) : choice;
                    })
                    : undefined }) : undefined, state: __assign(__assign({}, block.state), { visible: isChoiceActive && templateUtils_1.blockIsVisible(answers, block) }) });
};
/**
 * Get indexed blocks for each of the form values.
 */
exports.explodeFieldArray = function (field, values, allValues) {
    if (values === void 0) { values = []; }
    if (allValues === void 0) { allValues = {}; }
    return values.flatMap(function (item, index) {
        return field.children.map(function (block) {
            return exports.getIndexedBlock(block, field.id, index, __assign(__assign(__assign({}, item), values), allValues));
        });
    });
};
