import React from "react";
import {RecoilRoot} from "recoil";
import {RecoilUndoRoot} from "recoil-undo";
import {ThemeProvider} from "@reside/ui";

import {Router} from "./router";
import {ReloginPage} from "./pages/relogin-page";
import {ReactQueryClientProvider} from "./atoms/query-client-provider";

export const App = () => (
  <RecoilRoot>
    <RecoilUndoRoot>
      <ReactQueryClientProvider>
        <ThemeProvider>
          <ReloginPage>
            <Router />
          </ReloginPage>
        </ThemeProvider>
      </ReactQueryClientProvider>
    </RecoilUndoRoot>
  </RecoilRoot>
);
