import React, {ReactNode} from "react";
import {Card} from "antd";
import {CardProps} from "antd/lib/card";
import {styled, BlockSlide, InfoBar, FlexColumn} from "@reside/ui";
import {Slide} from "@reside/forms/dist/templateTypes";

import {AtomProp} from "../../model/editor";
import {EnvTag} from "../env-tag";
import {ResideLogo} from "../reside-logo";

export const SlideCard = ({
  atom,
  infoMessage,
  backgroundUrl,
  darkBackground,
  verticalContentAlignment,
  children,
  footer,
  ...props
}: CardProps & {footer?: ReactNode} & Partial<AtomProp> &
  Pick<
    Slide,
    | "backgroundUrl"
    | "verticalContentAlignment"
    | "darkBackground"
    | "infoMessage"
  >) => (
  <StyledCard {...props} actions={[<EnvTag />, <ResideLogo />]}>
    {infoMessage?.translationKey && (
      <InfoBar accent="info" icon="info" title={infoMessage.translationKey} />
    )}
    <CardBody backgroundUrl={backgroundUrl}>
      <SlideWrapper
        darkBackground={darkBackground}
        verticalContentAlignment={verticalContentAlignment}
      >
        {children}
      </SlideWrapper>
    </CardBody>
    {footer}
  </StyledCard>
);

SlideCard.defaultProps = {
  darkBackground: false,
  verticalContentAlignment: "center",
};

const StyledCard = styled(Card)`
  color: ${({theme}) => theme.color.darkBlue100};
  height: 100vh;
  display: flex;
  flex-direction: column;

  .ant-card-head {
    min-height: 64px;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: auto;
    flex: auto;
  }

  .ant-card-actions {
    padding: 0 20px;
    li[style] {
      width: 70% !important;
    }
    li:first-child[style] {
      width: auto !important;
    }
    li:not(:last-child) {
      border: none;
    }
  }
`;

const SlideWrapper = styled(BlockSlide)`
  flex-grow: 1;
`;

const CardBody = styled(FlexColumn)<Pick<Slide, "backgroundUrl">>`
  position: relative;
  padding: 20px;
  height: 100%;
  overflow: auto;
  background: url(${({backgroundUrl}) => backgroundUrl});
  background-size: cover;
`;
