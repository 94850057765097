var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../../theme";
export var FieldBorder = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: thin solid\n    ", ";\n"], ["\n  border-bottom: thin solid\n    ",
    ";\n"])), function (_a) {
    var color = _a.theme.color, focused = _a.focused, invalid = _a.invalid;
    return invalid
        ? color.deepOrange100
        : focused
            ? color.primary
            : color.borderGray;
});
var templateObject_1;
