var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { BlockField } from "../index";
import { List } from "../../list";
import { styled } from "../../theme";
import { useBlockComponentContext } from "../BlockComponentContext";
export var BlockList = function (_a) {
    var items = _a.items, listStyleType = _a.listStyleType, ordered = _a.ordered, queryVariables = _a.queryVariables;
    var _b = useBlockComponentContext(), Translated = _b.Translated, Markdown = _b.Markdown;
    return (React.createElement(Wrapper, null,
        React.createElement(List, { listStyleType: listStyleType, ordered: ordered, items: items.map(function (_a) {
                var translationKey = _a.translationKey, id = _a.id, children = _a.children;
                return (React.createElement(React.Fragment, null,
                    React.createElement(Translated, { key: id, render: function (_a) {
                            var translation = _a.translation;
                            return React.createElement(Markdown, { source: translation });
                        }, translationKey: translationKey }),
                    Array.isArray(children) &&
                        children.map(function (block) { return (React.createElement(BlockField, { block: block, key: block.id, queryVariables: queryVariables })); })));
            }) })));
};
BlockList.defaultProps = {
    ordered: false,
};
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 15px 0;\n"], ["\n  margin: 15px 0;\n"])));
var templateObject_1;
