import React from "react";
import {Button, Text, styled} from "@reside/ui";
import {CollapsibleNode} from "@reside/forms";

import {AtomizedBranchNode, AtomProp} from "../../model/editor";
import {ChildrenDroppable} from "../children-droppable";
import {DroppableField} from "../droppable-field";
import {useDragAndDrop} from "../../hooks/useDragAndDrop";
import {DisableClick} from "../disable-click";
import {Override} from "../../model/schemaTypes";

export const EditableCollapsible = ({
  node: {children},
  atom: parentAtom,
  index,
  isArrayFieldScoped,
}: {
  index: number;
  isArrayFieldScoped: boolean;
  node: Override<CollapsibleNode, AtomizedBranchNode & {id: string}>;
} & AtomProp) => {
  const {dropRef, dragRef, isDragging} = useDragAndDrop({index, parentAtom});

  return (
    <>
      <DisableClick>
        <Button type="link" size="large">
          <Text fontWeight="bold">Read More</Text>
        </Button>
      </DisableClick>
      <div ref={dropRef}>
        <DragWrapper
          style={{paddingTop: "25px", paddingBottom: "25px"}}
          ref={dragRef}
          isDragging={isDragging}
        >
          {children.length > 0 ? (
            children.map((atom, index) => (
              <DroppableField
                index={index}
                key={atom.key}
                atom={atom}
                parentAtom={parentAtom}
                isArrayFieldScoped={isArrayFieldScoped}
              />
            ))
          ) : (
            <ChildrenDroppable help="The items will be visible once after the 'Read More' is clicked." />
          )}
        </DragWrapper>
      </div>
    </>
  );
};

const DragWrapper = styled.div<{isDragging: boolean}>`
  opacity: ${({isDragging}) => (isDragging ? 0.5 : 1)};
`;
