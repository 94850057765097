import React from "react";
import {CompletionChecklistNode} from "@reside/forms";
import {CompletionChecklist, Text} from "@reside/ui";

import {AtomProp, useSetAtomPropertyValue} from "../../model/editor";
import {Override} from "../../model/schemaTypes";

export const EditableCompletionChecklist = ({
  node: {id, translationKey},
  atom,
}: {
  node: Override<CompletionChecklistNode, {id: string}>;
} & AtomProp) => {
  const setValue = useSetAtomPropertyValue(atom, "translationKey");

  return (
    <>
      <div>
        <Text
          fontSize="1.25em"
          lineHeight="1.75em"
          contentEditable
          onBlur={event => setValue(event.currentTarget.textContent)}
        >
          {translationKey}
        </Text>
      </div>
      <CompletionChecklist
        completionChecklist={[
          {
            id: "1",
            complete: true,
            text: "Slide 1",
          },
          {
            id: "2",
            complete: false,
            text: "Slide 2",
          },
        ]}
        renderText={text => text}
      />
    </>
  );
};
