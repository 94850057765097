import React from "react";
import {Form} from "antd";
import {useFocusedNode} from "../../model/editor";
import {DisabledAttribute} from "../disabled-attribute";
import {RequiredAttribute} from "../required-attribute";
import {isCheckboxField} from "../../utils";

export const CheckboxFieldForm = () => {
  const node = useFocusedNode();

  if (!node) {
    return null;
  }

  if (!isCheckboxField(node)) {
    return null;
  }

  return (
    <Form key={node.id}>
      <DisabledAttribute />
      <RequiredAttribute />
    </Form>
  );
};
