import React from "react";
import {Form, Switch} from "antd";

import {SourceNodes} from "../../model/schemaTypes";
import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
  useUnsetFocusedNodePropertyValue,
} from "../../model/editor";

export const DisabledAttribute = () => {
  const node = useFocusedNode() as SourceNodes;
  const setDisabled = useSetFocusedNodePropertyValue("disabled");
  const unsetDisabled = useUnsetFocusedNodePropertyValue("disabled");

  return (
    <Form.Item name="disabled" label="Disabled">
      <Switch
        checked={node?.disabled}
        onChange={enabled => (enabled ? setDisabled(enabled) : unsetDisabled())}
      />
    </Form.Item>
  );
};
