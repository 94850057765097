import React from "react";
import {Form, Input} from "antd";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {isFormControl} from "../../utils";

export const LabelAttribute = () => {
  const node = useFocusedNode();
  const setTranslationKey = useSetFocusedNodePropertyValue(
    "reference.translationKey",
  );

  if (!node) {
    return null;
  }

  return isFormControl(node) ? (
    <Form.Item name="label" label="Label/Placeholder">
      <Input
        onChange={event => setTranslationKey(event.currentTarget.value)}
        placeholder="Enter text..."
      />
    </Form.Item>
  ) : (
    <>Attribute Label/Placeholder can be used only with FORM_CONTROL type</>
  );
};
