var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { H2 } from "../../text";
import { styled } from "../../theme";
import { useBlockComponentContext } from "../BlockComponentContext";
import { ADAPTABLE_TEXT_CLASSNAME } from "../block-slide";
export var BlockSubtitle = function (_a) {
    var id = _a.id, translationKey = _a.translationKey;
    var _b = useBlockComponentContext(), Markdown = _b.Markdown, Translated = _b.Translated;
    return (React.createElement(Subtitle, { className: ADAPTABLE_TEXT_CLASSNAME, id: id },
        React.createElement(Translated, { translationKey: translationKey, render: function (_a) {
                var translation = _a.translation;
                return React.createElement(Markdown, { source: translation });
            } })));
};
export var Subtitle = styled(H2)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0.625em;\n  margin-bottom: 0.625em;\n  font-size: 2em;\n  line-height: 1.5em;\n"], ["\n  margin-top: 0.625em;\n  margin-bottom: 0.625em;\n  font-size: 2em;\n  line-height: 1.5em;\n"])));
var templateObject_1;
