"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = {
    name: "date_in_past",
    errorMessage: "Date must be in past.",
    validate: function (value) {
        var selected = new Date("" + value);
        var today = new Date();
        today.setHours(0, 0, 0);
        return selected < today;
    },
};
