var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { H1, Text } from "../../text";
import { BlockField } from "../";
import { TableOfContentRenderer, previewProps } from "../../table-of-content";
var defaultProps = {
    TableWrapper: function () { return React.createElement(TableOfContentRenderer, __assign({}, previewProps)); },
    children: function () { return (React.createElement(React.Fragment, null,
        React.createElement(H1, { fontSize: "3em" }, "Table of Contents"),
        React.createElement(Text, { fontSize: "1.25em", lineHeight: "1.75em" }, "All sections need to be completed and/or read by the resident or by a representative within 72 hours of arriving at our facility. You may come back to any section at any time to make changes or finish filing in the blanks."))); },
};
export var BlockTableOfContent = function (_a) {
    var children = _a.children, TableWrapper = _a.TableWrapper;
    return (React.createElement(React.Fragment, null,
        typeof children === "function" && children(),
        Array.isArray(children) &&
            children.map(function (block) { return React.createElement(BlockField, { key: block.id, block: block }); }),
        React.createElement(TableWrapper, null)));
};
BlockTableOfContent.defaultProps = defaultProps;
