var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { Field } from "formik";
import { styled } from "../../theme";
import { Multiselect } from "../../multiselect";
import { FloatingFieldLabel } from "../atoms/FloatingFieldLabel";
import { FieldBorder } from "../atoms/FieldBorder";
import { FieldError } from "../atoms/field-error";
import { isInvalid } from "../formHelpers";
export var TagsField = function (_a) {
    var name = _a.name, label = _a.label, disabled = _a.disabled, options = _a.options, placeholder = _a.placeholder, maxSelected = _a.maxSelected, testId = _a.testId;
    return (React.createElement(Field, { name: name }, function (_a) {
        var form = _a.form, field = _a.field;
        return (React.createElement(LabelWrapper, null,
            React.createElement(FloatingFieldLabel, { visible: true }, label),
            React.createElement(AlignCenter, null,
                React.createElement(Multiselect, { name: name, testId: testId, options: options, placeholder: placeholder, selected: disabled ? options.map(function (o) { return o.value; }) : field.value, disabled: disabled, maxSelected: maxSelected, onChange: function (values) { return form.setFieldValue(field.name, values, true); } })),
            React.createElement(FieldBorder, { invalid: isInvalid(name, form) }),
            React.createElement(FieldError, { name: name })));
    }));
};
var LabelWrapper = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: inline-block;\n  padding: 6px 0;\n  margin: 8px 0;\n"], ["\n  width: 100%;\n  display: inline-block;\n  padding: 6px 0;\n  margin: 8px 0;\n"])));
var AlignCenter = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2;
