import React from "react";
import {Button, Space} from "antd";
import {useUndo, useRedo} from "recoil-undo";
import {ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";

export const UndoRedo = () => {
  const undo = useUndo();
  const redo = useRedo();

  return (
    <Space>
      <Button onClick={undo} icon={<ArrowLeftOutlined />}>
        Undo
      </Button>
      <Button onClick={redo} icon={<ArrowRightOutlined />}>
        Redo
      </Button>
    </Space>
  );
};
