import React, {FunctionComponent, ReactNode} from "react";
import {Alert} from "antd";
import {styled} from "@reside/ui";

export const AlertWindow: FunctionComponent<{
  disabled: boolean;
  message: JSX.Element;
  action?: ReactNode;
}> = ({children, action, disabled, message}) =>
  !disabled ? (
    <>{children}</>
  ) : (
    <div style={{position: "relative"}}>
      <Alert type="warning" message={message} action={action} />
      <Wrapper>{children}</Wrapper>
    </div>
  );

const Wrapper = styled.div`
  pointer-events: none;
  opacity: 0.4;
`;
