var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { toggleMark } from "prosemirror-commands";
import { Plugin } from "prosemirror-state";
import { InputRule } from "prosemirror-inputrules";
import Mark from "./Mark";
var LINK_INPUT_REGEX = /\[(.+)]\((\S+)\)/;
function isPlainURL(link, parent, index, side) {
    if (link.attrs.title || !/^\w+:/.test(link.attrs.href)) {
        return false;
    }
    var content = parent.child(index + (side < 0 ? -1 : 0));
    if (!content.isText ||
        content.text !== link.attrs.href ||
        content.marks[content.marks.length - 1] !== link) {
        return false;
    }
    if (index === (side < 0 ? 1 : parent.childCount - 1)) {
        return true;
    }
    var next = parent.child(index + (side < 0 ? -2 : 1));
    return !link.isInSet(next.marks);
}
var Link = /** @class */ (function (_super) {
    __extends(Link, _super);
    function Link() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Link.prototype, "name", {
        get: function () {
            return "link";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "schema", {
        get: function () {
            return {
                attrs: {
                    href: {
                        default: "",
                    },
                },
                inclusive: false,
                parseDOM: [
                    {
                        tag: "a[href]",
                        getAttrs: function (dom) { return ({
                            href: dom.getAttribute("href"),
                        }); },
                    },
                ],
                toDOM: function (node) { return [
                    "a",
                    __assign(__assign({}, node.attrs), { rel: "noopener noreferrer nofollow" }),
                    0,
                ]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    Link.prototype.inputRules = function (_a) {
        var _this = this;
        var type = _a.type;
        return [
            new InputRule(LINK_INPUT_REGEX, function (state, match, start, end) {
                var okay = match[0], alt = match[1], href = match[2];
                var tr = state.tr;
                if (okay) {
                    // @ts-ignore
                    tr.replaceWith(start, end, _this.editor.schema.text(alt)).addMark(start, start + alt.length, type.create({ href: href }));
                }
                return tr;
            }),
        ];
    };
    Link.prototype.commands = function (_a) {
        var type = _a.type;
        return function (_a) {
            var href = (_a === void 0 ? { href: "" } : _a).href;
            return toggleMark(type, { href: href });
        };
    };
    Link.prototype.keys = function (_a) {
        var _this = this;
        var type = _a.type;
        return {
            "Mod-k": function (state, dispatch) {
                if (state.selection.empty) {
                    _this.options.onKeyboardShortcut();
                    return true;
                }
                return toggleMark(type, { href: "" })(state, dispatch);
            },
        };
    };
    Object.defineProperty(Link.prototype, "plugins", {
        get: function () {
            var _this = this;
            return [
                new Plugin({
                    props: {
                        handleDOMEvents: {
                            mouseover: function (view, event) {
                                if (event.target instanceof HTMLAnchorElement &&
                                    !event.target.className.includes("ProseMirror-widget")) {
                                    if (_this.options.onHoverLink) {
                                        return _this.options.onHoverLink(event);
                                    }
                                }
                                return false;
                            },
                            click: function (view, event) {
                                // allow opening links in editing mode with the meta/cmd key
                                if (view.props.editable &&
                                    view.props.editable(view.state) &&
                                    !event.metaKey) {
                                    return false;
                                }
                                if (event.target instanceof HTMLAnchorElement) {
                                    var href = event.target.href ||
                                        (event.target.parentNode instanceof HTMLAnchorElement
                                            ? event.target.parentNode.href
                                            : "");
                                    var isHashtag = href.startsWith("#");
                                    if (isHashtag && _this.options.onClickHashtag) {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        _this.options.onClickHashtag(href, event);
                                        return true;
                                    }
                                    if (_this.options.onClickLink) {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        _this.options.onClickLink(href, event);
                                        return true;
                                    }
                                }
                                return false;
                            },
                        },
                    },
                }),
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "toMarkdown", {
        get: function () {
            return {
                open: function (_state, mark, parent, index) {
                    return isPlainURL(mark, parent, index, 1) ? "<" : "[";
                },
                close: function (state, mark, parent, index) {
                    return isPlainURL(mark, parent, index, -1)
                        ? ">"
                        : "](" +
                            state.esc(mark.attrs.href) +
                            (mark.attrs.title ? " " + state.quote(mark.attrs.title) : "") +
                            ")";
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Link.prototype.parseMarkdown = function () {
        return {
            mark: "link",
            getAttrs: function (tok) { return ({
                href: tok.attrGet("href"),
                title: tok.attrGet("title") || null,
            }); },
        };
    };
    return Link;
}(Mark));
export default Link;
