"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = {
    name: "required_all",
    isImplicit: true,
    errorMessage: "You should select all options above.",
    validate: function (value, requirement) {
        if (typeof value !== "string") {
            return false;
        }
        var values = value.split(":");
        return requirement
            .split(",")
            .every(function (requiredVal) { return values.includes(requiredVal); });
    },
};
