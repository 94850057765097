import React from "react";
import {DownloadOutlined} from "@ant-design/icons";
import {PdfNode} from "@reside/forms";

import {ActionButton} from "./ActionButton";
import {savePdf} from "../../../../model/pdf/pdfUtils";

export const DownloadPdfButton = ({node}: {node: PdfNode}) => (
  <ActionButton
    icon={<DownloadOutlined />}
    onClick={e => {
      e.stopPropagation();
      savePdf(node);
    }}
  />
);
