import React from "react";
import {useRecoilValue} from "recoil";
import {Form, Input, Tooltip, Switch, Skeleton, Select, List} from "antd";
import {styled} from "@reside/ui";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {isSelectableField} from "../../utils";
import {priorFieldArraysByTemplateSelector} from "../../model/answers";

/**
 * Form input to configure "query" attribute on "selectable" inputs as Radio or Select.
 */
export const QueryAttributePresenter = () => {
  const node = useFocusedNode();

  if (!node) {
    return null;
  }

  if (!isSelectableField(node)) {
    return <>Attribute "query" can be used only with Radio or Select type.</>;
  }

  return (
    <>
      <Form.Item label="Query Choices" name="query" valuePropName="checked">
        <Switch />
      </Form.Item>
      {node.reference.query && (
        <>
          <Form.Item
            name="queryPath"
            label={
              <Tooltip title="Query Path can be a name of variable injected into the form. E.g. 'physicians'.">
                <span>Query Path</span>
              </Tooltip>
            }
          >
            <OptionQueryPath />
          </Form.Item>
          <Form.Item
            name="optionLabel"
            label={
              <Tooltip title="Markdown pattern for constructing label from the individual query item">
                <span>Option Label</span>
              </Tooltip>
            }
          >
            <OptionLabelWithAutoFill />
          </Form.Item>
        </>
      )}
    </>
  );
};

const OptionQueryPath = () => {
  const node = useFocusedNode();
  const fieldArrayByTemplate = useRecoilValue(
    priorFieldArraysByTemplateSelector,
  );
  const setQueryPath = useSetFocusedNodePropertyValue("reference.query.path");
  const setReference = useSetFocusedNodePropertyValue("reference", {
    replace: true,
  });

  if (!node || !isSelectableField(node)) {
    return null;
  }

  if (!node.reference.query) {
    return null;
  }

  const queryPath = node.reference.query?.path;

  return (
    <Select
      showSearch
      value={queryPath}
      onChange={value => {
        if (value) {
          setQueryPath(value);
        } else {
          /**
           * If the field was cleared, we remove the "query" attribute.
           */
          const {query, ...reference} = node.reference;

          setReference({...reference, choices: []});
        }
      }}
    >
      {Object.entries(fieldArrayByTemplate).map(([templateType, group]) => (
        <Select.OptGroup label={templateType}>
          {group.map(field => (
            <Select.Option key={field.id} value={field.id as string}>
              {field.translationKey}
            </Select.Option>
          ))}
        </Select.OptGroup>
      ))}
    </Select>
  );
};

const OptionLabelWithAutoFill = () => {
  const node = useFocusedNode();
  const setOptionLabel = useSetFocusedNodePropertyValue(
    "reference.optionLabel.translationKey",
  );
  const fieldArraysByTemplate = useRecoilValue(
    priorFieldArraysByTemplateSelector,
  );

  if (!node || !isSelectableField(node)) {
    return null;
  }

  if (!node.reference.query) {
    return null;
  }

  const queryPath = node.reference.query?.path;
  const optionLabel = node.reference.optionLabel?.translationKey;

  const selectedQueryPathChildren = Object.values(fieldArraysByTemplate)
    .flatMap(field => field)
    .find(field => field.id === queryPath)?.children;

  if (!selectedQueryPathChildren) {
    return null;
  }

  const data: string[] = Object.keys(selectedQueryPathChildren);

  return (
    <>
      <Input.TextArea
        value={optionLabel}
        onChange={event => {
          setOptionLabel(event.target.value);
        }}
        autoSize
      />
      <ListWrapper>
        <List
          size="small"
          bordered
          dataSource={data}
          renderItem={(fieldName: string) => {
            const query = `[[query.${queryPath}.${fieldName}]]`;

            return (
              <ListItem
                onClick={() => {
                  setOptionLabel(
                    optionLabel ? `${optionLabel} ${query}` : query,
                  );
                }}
              >
                <Tooltip title={`Append ${query} to option label`}>
                  {fieldName}
                </Tooltip>
              </ListItem>
            );
          }}
        />
      </ListWrapper>
    </>
  );
};

export const QueryAttribute = () => {
  return (
    <React.Suspense fallback={<Skeleton.Input active />}>
      <QueryAttributePresenter />
    </React.Suspense>
  );
};

const ListWrapper = styled(List.Item)`
  margin: 0.5em 0;
  .ant-list-bordered {
    width: 100%;
  }
`;

const ListItem = styled(List.Item)`
  cursor: copy;
`;
