var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Fragment, useState } from "react";
import { styled } from "../theme";
import { ProgressBar } from "./atoms/ProgressBar";
import { GroupRow } from "./atoms/GroupRow";
import { SectionRow } from "./atoms/SectionRow";
import { SlideRow } from "./atoms/SlideRow";
import { TableOfContentsHeader } from "./atoms/TableOfContentsHeader";
import { ToggleCheckbox } from "./atoms/ToggleCheckbox";
export var TableOfContentRenderer = function (_a) {
    var fullTOC = _a.fullTOC, incompleteSlides = _a.incompleteSlides, showIncompleteSlidesOnly = _a.showIncompleteSlidesOnly, onToggleIncomplete = _a.onToggleIncomplete, slideIndex = _a.slideIndex, onSlideClick = _a.onSlideClick, hasExpandedBlocks = _a.hasExpandedBlocks;
    var _b = useState([]), expandedBlockIds = _b[0], setExpandedBlockIds = _b[1];
    var toggleBlock = function (id) {
        if (expandedBlockIds.includes(id)) {
            setExpandedBlockIds(function (state) { return state.filter(function (storedId) { return storedId !== id; }); });
        }
        else {
            setExpandedBlockIds(function (state) { return state.concat(id); });
        }
    };
    var getActiveSlideIndex = function (id, slideIndexes) {
        return slideIndexes.find(function (itemId) { return itemId >= id; });
    };
    var extractSlideIndexes = function (groups) {
        return groups.reduce(function (ids, group) {
            return ids.concat(group.children.map(function (section) {
                return section.children.map(function (slide) { return slide.idx; });
            }));
        }, []);
    };
    var renderTable = function (toc) {
        var slideIndexes = extractSlideIndexes(toc.groups);
        var activeSlideId = getActiveSlideIndex(slideIndex, slideIndexes);
        return toc.groups.map(function (group) {
            var isExpanded = hasExpandedBlocks || expandedBlockIds.includes(group.id);
            return (group.state.totalSlides > 0 && (React.createElement(GroupItem, { key: group.id },
                React.createElement(GroupRow, { data: group, expanded: isExpanded, onClick: toggleBlock }),
                isExpanded &&
                    group.children.map(function (section) { return (React.createElement(Fragment, { key: section.id },
                        section.children.length > 0 && (React.createElement(SectionRow, { translationKey: section.title.translationKey })),
                        section.children.map(function (slide) { return (React.createElement(Fragment, { key: slide.id },
                            React.createElement(SlideRow, { index: slide.idx, onClick: onSlideClick, translationKey: slide.title.translationKey, hasFlags: slide.state.totalFlags > 0, active: slide.idx === activeSlideId }))); }))); }))));
        });
    };
    var toc = showIncompleteSlidesOnly ? incompleteSlides : fullTOC;
    var _c = fullTOC.state, totalSlides = _c.totalSlides, completedSlides = _c.completedSlides;
    return (React.createElement(React.Fragment, null,
        React.createElement(ToggleCheckbox, { checked: showIncompleteSlidesOnly, onCheck: onToggleIncomplete }),
        React.createElement(ProgressBar, { total: totalSlides, completed: completedSlides, progress: calculateAdmissionProgress(totalSlides, completedSlides) }),
        React.createElement(TableOfContentsHeader, null),
        renderTable(toc)));
};
var calculateAdmissionProgress = function (total, completed) {
    return total === 0 ? 100 : Math.round((completed / total) * 100);
};
var GroupItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n"], ["\n  border-bottom: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.borderGray;
});
var templateObject_1;
