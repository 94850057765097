import * as yup from "yup";
import {flatten} from "flat";
import {
  GroupNodeType,
  NodeType,
  QuestionType,
  VisibilityContextType,
} from "@reside/forms";
import {get} from "object-path";

import {
  SourceNode,
  SourceNodes,
  SourceSectionGroup,
  TemplateNodes,
} from "../schemaTypes";
import {ExtendedReferences} from "../../atoms/query-builder/config";
import {getMinValueRuleValue} from "../../atoms/min-value-attribute";
import {getMaxValueRuleValue} from "../../atoms/max-value-attribute";
import {getMinLengthRuleValue} from "../../atoms/min-length-attribute";
import {getMaxLengthRuleValue} from "../../atoms/max-length-attribute";
import {getMaxFileSizeRuleValue} from "../../atoms/max-file-size-attribute";
import {getMaxItemsRuleValue} from "../../atoms/editable-field-array/MaxItemsAttribute";
import {getMinItemsRuleValue} from "../../atoms/editable-field-array/MinItemsAttribute";
import {TemplateType} from "../../constants";
import {SlideValidationErrors, ValidationErrors} from ".";

export const getValidationSchema = (
  node: any,
  templateRefs: ExtendedReferences,
  templatesArrayFields: ExtendedReferences,
  templateRefsCount: Record<string, number>,
  templateType: TemplateType,
  isDisabled = false,
  isArrayFieldScoped = false,
): Record<NodeType | GroupNodeType | "pdf", yup.ObjectSchema<any>> => ({
  [GroupNodeType.SECTION]: yup.object().shape({
    ...childrenValidation(),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [GroupNodeType.SECTION_GROUP]: yup.object().shape({
    ...childrenValidation(),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [GroupNodeType.SECTION_GROUP]: yup.object().shape({
    ...childrenValidation(),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [GroupNodeType.SECTION_GROUP]: yup.object().shape({
    ...childrenValidation(),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  pdf: yup.object().shape({
    title: yup.object().shape({
      ...translationKeyValidation(
        templateRefs,
        "PDF node label is required",
        "PDF node label is using undeclared variable.",
      ),
    }),
    src: yup.string().required("PDF node src is required"),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [GroupNodeType.SLIDE]: yup.object().shape({
    ...childrenValidation(),
    ctaButton: yup.object().when(templateType, {
      is: (templateType: TemplateType) =>
        [TemplateType.PREFLIGHT, TemplateType.RESIDENT_EXPERIENCE].includes(
          templateType,
        ),
      then: yup.object().shape({
        title: yup.object().shape({
          translationKey: yup
            .string()
            .required("Slide Button label is required"),
        }),
      }),
    }),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.CARD]: yup.object().shape({
    ...childrenValidation(
      "Card component must contain at least one child component.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.COLLAPSIBLE]: yup.object().shape({
    ...childrenValidation(
      "Collapsible component must contain at least one child component.",
      2,
    ),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.COLUMN]: yup.object().shape({
    columnStart: yup
      .number()
      .integer()
      .optional()
      .transform(columnStartValue =>
        isNaN(columnStartValue) ? undefined : columnStartValue,
      )
      .test(
        "columnStartSmalerOrEqualThanColumnEnd",
        "Column Start value cannot be greater than Column End.",
        function (value: any) {
          try {
            return isNaN(this.parent.columnEnd) || isNaN(value)
              ? true
              : value <= this.parent.columnEnd;
          } catch {
            return true;
          }
        },
      ),
    columnEnd: yup
      .number()
      .integer()
      .optional()
      .transform(columnEndValue =>
        isNaN(columnEndValue) ? undefined : columnEndValue,
      ),
    ...childrenValidation(
      "Column component must contain at least one child component.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.CONTINUE_BUTTON]: yup.object().shape({
    ...translationKeyValidation(
      templateRefs,
      "Continue Button label is required",
      "Continue Button label is using undeclared variable.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.FIELD_ARRAY]: yup.object().shape({
    ...translationKeyValidation(
      templateRefs,
      "Label is required",
      "Label is using undeclared variable.",
    ),
    ...childrenValidation(
      "Field array must contain at least one child component.",
    ),
    collapsible: yup.boolean().optional(),
    headlineLabel: yup
      .object()
      .nullable()
      .when("collapsible", {
        is: (collapsible: boolean) => collapsible,
        then: yup
          .object()
          .typeError("Headline label must be defined.")
          .shape({
            translationKey: yup
              .string()
              .required("Headline label must be defined.")
              .test(
                "headingLabelUseDeclaredVariable",
                "Heading label is using undeclared variable.",
                (value, testContext) => {
                  const {options} = testContext;
                  const id = (options as any)?.from[1]?.value?.id;
                  return validateHeadingLabelVariables(
                    value,
                    id,
                    templatesArrayFields,
                  );
                },
              ),
          }),
      }),

    rules: yup
      .array()
      .test(
        "minItemsLessOrEqualToMaxItems",
        "Min items value cannot be greater than Max items value.",
        rules => {
          try {
            return getMinItemsRuleValue(rules) <= getMaxItemsRuleValue(rules);
          } catch {
            return true;
          }
        },
      )
      .test(
        "minItemsIsPositive",
        "Min items value has to be greater or equal to 0.",
        rules => {
          try {
            return getMinItemsRuleValue(rules) >= 0;
          } catch {
            return true;
          }
        },
      )
      .test(
        "maxItemsIsPositive",
        "Max items value has to be greater or equal to 0.",
        rules => {
          try {
            return getMaxItemsRuleValue(rules) >= 0;
          } catch {
            return true;
          }
        },
      ),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.FORM_CONTROL]: FormControlSchemas(
    node,
    templateRefs,
    templatesArrayFields,
    templateRefsCount,
    isDisabled,
    isArrayFieldScoped,
  )[node.reference?.type as QuestionType],
  [NodeType.FRAGMENT]: yup.object().shape({
    ...childrenValidation(
      "Fragment must contain at least one child component.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.LIST]: yup.object().shape({
    items: yup
      .array()
      .of(
        yup.object().shape({
          translationKey: yup
            .string()
            .required("Item label must be defined.")
            .test(
              "itemLabelUseDeclaredVariable",
              "Item label is using undeclared variable.",
              translationKeyValue =>
                validateReferenceCheck(translationKeyValue, templateRefs),
            ),
        }),
      )
      .required("List must contain at least one item.")
      .min(1, "List must contain at least one item."),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.PARAGRAPH]: yup.object().shape({
    ...translationKeyValidation(
      templateRefs,
      "Paragraph must contain text.",
      "Paragraph is using undeclared variable.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.SUBTITLE]: yup.object().shape({
    ...translationKeyValidation(
      templateRefs,
      "Subtitle must contain text.",
      "Subtitle is using undeclared variable.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.TITLE]: yup.object().shape({
    ...translationKeyValidation(
      templateRefs,
      "Title must contain text.",
      "Title is using undeclared variable.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(templateRefs),
  }),
  [NodeType.COMPLETION_CHECKLIST]: yup.object().shape({}),
  [NodeType.MANDATORY_VIDEO]: yup.object().shape({}),
  [NodeType.TABLE_OF_CONTENT]: yup.object().shape({}),
  [NodeType.IMAGE]: yup.object().shape({}),
});

const FormControlSchemas = (
  node: any,
  extendedReferences: ExtendedReferences,
  templatesArrayFields: ExtendedReferences,
  templateRefsCount: Record<string, number>,
  isDisabled: boolean,
  isArrayFieldScoped: boolean,
): Record<QuestionType, yup.ObjectSchema<any>> => ({
  [QuestionType.RADIO]: yup.object().shape({
    ...referenceWithQueryValidation(
      extendedReferences,
      templatesArrayFields,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
      "Radio field requires at least one option.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
  }),
  [QuestionType.CHECKBOX]: yup.object().shape({
    ...referenceWithQueryValidation(
      extendedReferences,
      templatesArrayFields,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
      "Checkbox field requires at least one option.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
  }),
  [QuestionType.SELECT]: yup.object().shape({
    ...referenceWithQueryValidation(
      extendedReferences,
      templatesArrayFields,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
      "Select field requires at least one option.",
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
  }),
  [QuestionType.TIMEZONE_SELECT]: yup.object().shape({
    ...referenceValidation(
      extendedReferences,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
  }),
  [QuestionType.STATE_SELECT]: yup.object().shape({
    ...referenceValidation(
      extendedReferences,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
  }),
  [QuestionType.TEXT]: yup.object().shape({
    rules: yup
      .array()
      .test(
        "minLengthIsLessOrEqualToMaxLength",
        "Min length cannot be greater than max length.",
        minLengthIsLessOrEqualToMaxLength,
      )
      .test(
        "minLengthIsPositive",
        "Min length value has to be greater or equal to 0.",
        minLengthIsPositive,
      )
      .test(
        "maxLengthIsPositive",
        "Max length value has to be greater or equal to 0.",
        maxLengthIsPositive,
      )
      .test("containsSSNRule", "SSN does not contain ssn rule.", rules => {
        if (node.format === "ssn") {
          if (!rules) {
            return false;
          }
          return rules.includes("ssn");
        }
        return true;
      }),
    ...referenceValidation(
      extendedReferences,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
  }),
  [QuestionType.TEXTAREA]: yup.object().shape({
    rules: yup
      .array()
      .test(
        "minLengthIsLessOrEqualToMaxLength",
        "Min length cannot be greater than max length.",
        minLengthIsLessOrEqualToMaxLength,
      )
      .test(
        "minLengthIsPositive",
        "Min length value has to be greater or equal to 0.",
        minLengthIsPositive,
      )
      .test(
        "maxLengthIsPositive",
        "Max length value has to be greater or equal to 0.",
        maxLengthIsPositive,
      ),
    ...referenceValidation(
      extendedReferences,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
  }),
  [QuestionType.CURRENCY]: yup.object().shape({
    rules: yup
      .array()
      .test(
        "minValueIsLessOrEqualToMaxValue",
        "Min value cannot be greater than max value.",
        rules => {
          try {
            return getMinValueRuleValue(rules) <= getMaxValueRuleValue(rules);
          } catch {
            return true;
          }
        },
      )
      .test(
        "minValueIsPositive",
        "Min value value has to be greater or equal to 0.",
        rules => {
          try {
            return getMinValueRuleValue(rules) >= 0;
          } catch {
            return true;
          }
        },
      )
      .test(
        "maxValueIsPositive",
        "Max value value has to be greater or equal to 0.",
        rules => {
          try {
            return getMaxValueRuleValue(rules) >= 0;
          } catch {
            return true;
          }
        },
      ),
    ...referenceValidation(
      extendedReferences,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
  }),
  [QuestionType.DATE]: yup.object().shape({
    ...referenceValidation(
      extendedReferences,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
    rules: yup
      .array()
      .test("containsDateRule", "Date does not contain date rule.", rules => {
        if (!rules) {
          return false;
        }
        return rules?.includes("date");
      }),
  }),
  [QuestionType.SIGNATURE]: yup.object().shape({
    ...referenceValidation(
      extendedReferences,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
  }),
  [QuestionType.FILE]: yup.object().shape({
    reference: yup.object().shape({
      ...translationKeyValidation(
        extendedReferences,
        "Label is required.",
        "Label is using undeclared variable.",
      ),
      acceptedMediaTypes: yup
        .array()
        .test(
          "areValidMediaTypes",
          "Format is not valid",
          acceptedMediaTypesValue => {
            const formatTypes = ["image/jpeg", "image/png", "application/pdf"];
            //TODO: adjust formatTypes list -> https://jira.ableneo.com/browse/RESIDE-10312
            if (acceptedMediaTypesValue) {
              return (
                acceptedMediaTypesValue.filter(
                  type => !formatTypes.includes(type),
                ).length === 0
              );
            } else {
              return true;
            }
          },
        )
        .notRequired(),
    }),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
    rules: yup
      .array()
      .test(
        "maxFileSizeIsPositive",
        "Max File size should be greater than 0.",
        maxFileSizeIsPositive,
      ),
  }),
  [QuestionType.IMAGE]: yup.object().shape({
    ...referenceValidation(
      extendedReferences,
      templateRefsCount,
      isDisabled,
      isArrayFieldScoped,
      node.reference?.preFlighted,
    ),
    ...visibilityContextValidation,
    ...visibleValidation(extendedReferences),
    rules: yup
      .array()
      .test(
        "maxFileSizeIsPositive",
        "Max File size should be greater than 0.",
        maxFileSizeIsPositive,
      ),
  }),

  //TODO remove, not used anymore
  [QuestionType.LIST]: yup.object().shape({}),
  //TODO remove, not used anymore
  [QuestionType.OBJECT]: yup.object().shape({}),
  [QuestionType.DATETIME]: yup.object().shape({}),
});

const visibleValidation = (templateRefs: ExtendedReferences) => ({
  visible: yup
    .object()
    .test(
      "isValidVisibleJson",
      "Visible attribute is not valid json",
      visibleValue => isValidJson(visibleValue),
    )
    .test(
      "visiblityUseDeclaredVariable",
      "Visibility condition using undeclared variable.",
      visibleValue => {
        if (!visibleValue) {
          return true;
        }

        const flattenVisibility = flatten(visibleValue) as any;
        const referencedVariables = Object.keys(flattenVisibility)
          .filter(key => key.includes(".var"))
          .map(key => flattenVisibility[key]);

        return referencedVariables.every(item => templateRefs[item]);
      },
    ),
});

const validateVisiblityContext = (arr: any[] | undefined) => {
  if (!arr) {
    return true;
  }
  return arr?.every(contextType =>
    Object.values(VisibilityContextType).includes(contextType),
  );
};

const visibilityContextValidation = {
  visibilityContext: yup
    .array()
    .test(
      "isValidVisibilityContext",
      "Incorrect value for visiblity context",
      validateVisiblityContext,
    ),
};

const childrenValidation = (
  message = "At least one child is required",
  minValue = 1,
) => ({
  children: yup.array().required(message).min(minValue, message),
});

const translationKeyValidation = (
  extendedReferences: ExtendedReferences,
  requiredMessage: string,
  referenceCheckMessage: string,
) => ({
  translationKey: yup
    .string()
    .required(requiredMessage)
    .test(
      "translationKeyUseDeclaredVariables",
      referenceCheckMessage,
      translationKeyValue =>
        validateReferenceCheck(translationKeyValue, extendedReferences),
    ),
});

const translationKeyOptionalValidation = (
  extendedReferences: ExtendedReferences,
  referenceCheckMessage: string,
) => ({
  translationKey: yup
    .string()
    .test(
      "translationKeyUseDeclaredVariables",
      referenceCheckMessage,
      translationKeyValue =>
        validateReferenceCheck(translationKeyValue, extendedReferences),
    ),
});

const queryChoicesValidation = (
  extendedReferences: ExtendedReferences,
  templatesArrayFields: ExtendedReferences,
  optionsRequiredMessage: string,
) => ({
  choices: yup.array().when("query", {
    is: (query: object) => !query,
    then: yup.array().min(1, optionsRequiredMessage),
    otherwise: yup
      .array()
      .of(
        yup.object().shape({
          translationKey: yup
            .string()
            .required("Option label must be defined.")
            .test(
              "optionLabelUseDeclaredVariable",
              "Option label is using undeclared variable.",
              translationKeyValue =>
                validateReferenceCheck(translationKeyValue, extendedReferences),
            ),
        }),
      )
      .min(1, optionsRequiredMessage),
  }),
  query: yup.object().when("choices", {
    is: (choices: []) => !choices,
    then: yup.object().shape({
      path: yup
        .string()
        .required("Query Path or Options must be defined.")
        .test(
          "queryPathUseDeclaredVariable",
          "Query path is using undeclared variable.",
          path => {
            if (!path) {
              return true;
            }
            return templatesArrayFields[path] ? true : false;
          },
        ),
    }),
  }),
  optionLabel: yup.object().when(["choices", "query"], {
    is: (choices: [], query: any) => !choices && query && query.path,
    then: yup.object().shape({
      translationKey: yup
        .string()
        .required("Option label must be defined.")
        .test(
          "optionLabelUseDeclaredVariable",
          "Option label is using undeclared variable.",
          value => validateOptionLabelVariables(value, templatesArrayFields),
        ),
    }),
    otherwise: yup.object().shape({
      translationKey: yup.string(),
    }),
  }),
});

const referenceWithQueryValidation = (
  extendedReferences: ExtendedReferences,
  templatesArrayFields: ExtendedReferences,
  templateRefsCount: Record<string, number>,
  isDisabled: boolean,
  isArrayFieldScoped: boolean,
  isPreflighted: boolean,
  optionsRequiredMessage: string,
) => ({
  reference: yup.object().shape(
    {
      ...translationKeyOptionalValidation(
        extendedReferences,
        "Label is using undeclared variable.",
      ),
      ...queryChoicesValidation(
        extendedReferences,
        templatesArrayFields,
        optionsRequiredMessage,
      ),
      id: yup
        .string()
        .required()
        .test("referenceIdIsUnique", "Reference id is not unique.", idValue => {
          if (!idValue) {
            return false;
          } else if (isDisabled) {
            return true;
          } else if (isArrayFieldScoped) {
            return true;
          } else if (isPreflighted) {
            return true;
          } else {
            return templateRefsCount[idValue] === 1;
          }
        }),
    },
    [["query", "choices"]],
  ),
});

const referenceValidation = (
  extendedReferences: ExtendedReferences,
  templateRefsCount: Record<string, number>,
  isDisabled: boolean,
  isArrayFieldScoped: boolean,
  isPreflighted: boolean,
) => ({
  reference: yup.object().shape({
    ...translationKeyOptionalValidation(
      extendedReferences,
      "Label is using undeclared variable.",
    ),
    id: yup
      .string()
      .required()
      .test("referenceIdIsUnique", "Reference id is not unique.", idValue => {
        if (!idValue) {
          return false;
        } else if (isDisabled) {
          return true;
        } else if (isArrayFieldScoped) {
          return true;
        } else if (isPreflighted) {
          return true;
        } else {
          return templateRefsCount[idValue] === 1;
        }
      }),
  }),
});

const isValidJson = (value: any) => {
  if (!value) {
    return true;
  } else {
    try {
      JSON.parse(JSON.stringify(value));
    } catch {
      return false;
    }
    return true;
  }
};

export const validateNode = (
  node: SourceNodes | TemplateNodes,
  extendedReferences: ExtendedReferences,
  templatesArrayFields: ExtendedReferences,
  templateRefsCount: Record<string, number>,
  templateType: TemplateType,
  isArrayFieldScoped = false,
): ValidationErrors => {
  if (!node.type) {
    return [];
  }

  const validationSchema = getValidationSchema(
    node,
    extendedReferences,
    templatesArrayFields,
    templateRefsCount,
    templateType,
    (node as any).disabled,
    isArrayFieldScoped,
  )[node.type as NodeType];

  try {
    validationSchema.validateSync(node, {abortEarly: false});
  } catch (error) {
    return error.errors;
  }

  return [];
};

export const validateNodes = (
  nodes: TemplateNodes[],
  extendedReferences: ExtendedReferences,
  templatesArrayFields: ExtendedReferences,
  templateRefsCount: Record<string, number>,
  templateType: TemplateType,
) => {
  const errors: Record<string, ValidationErrors> = {};
  const slideErrors: Record<string, SlideValidationErrors> = {};
  let isValid = true;

  const validateAllNodes = (
    nodes: TemplateNodes[],
    slideId?: string,
    isArrayFieldScoped?: boolean,
  ) => {
    nodes.forEach((node: any) => {
      const arrayFieldScoped =
        node.type === NodeType.FIELD_ARRAY ? true : isArrayFieldScoped;
      const nodeErrors = validateNode(
        node,
        extendedReferences,
        templatesArrayFields,
        templateRefsCount,
        templateType,
        arrayFieldScoped,
      );
      const currentSlideId =
        node.type === GroupNodeType.SLIDE ? node.id : slideId;
      if (nodeErrors.length > 0) {
        isValid = false;
        errors[node.id] = nodeErrors;
        slideErrors[currentSlideId] = slideErrors[currentSlideId]
          ? [
              ...slideErrors[currentSlideId],
              ...nodeErrors?.map(() => ({
                errors: nodeErrors,
                node: node,
              })),
            ]
          : [
              ...nodeErrors?.map(() => ({
                errors: nodeErrors,
                node: node,
              })),
            ];
      }
      if (node.children) {
        validateAllNodes(node.children, currentSlideId, arrayFieldScoped);
      } else if (node.items) {
        validateAllNodes(node.items, currentSlideId, arrayFieldScoped);
      } else if (node.reference?.choices) {
        validateAllNodes(
          node.reference.choices,
          currentSlideId,
          arrayFieldScoped,
        );
      }
    });
  };

  validateAllNodes(nodes);
  return {errors, slideErrors, isValid};
};

export const getErrorActiveSlideWithFocusedNode = (
  activeSlidePath: readonly number[],
  activeSlide: SourceNode<any>,
  currentFocusNode: SourceNode<any> | undefined,
  slideErrors: Record<string, SlideValidationErrors>,
  template: readonly SourceSectionGroup[],
) => {
  if (
    Object.keys(slideErrors).find(
      slideErrorId => slideErrorId === activeSlide.id,
    )
  ) {
    const hasFocusNodeError =
      currentFocusNode &&
      slideErrors[activeSlide.id].find(
        (item: any) => item.node.id === currentFocusNode.id,
      );
    return {
      errorActiveSlidePath: activeSlidePath,
      errorActiveNode: hasFocusNodeError
        ? (currentFocusNode as any)
        : slideErrors[activeSlide.id][0].node,
    };
  } else {
    let errorActiveSlidePath = activeSlidePath;
    let errorActiveNode = currentFocusNode;
    let newPathFound = false;

    template.forEach((sourceSectionGroup, sourceSectionGroupIndex) => {
      sourceSectionGroup.children.forEach(
        (sourceSection, sourceSectionIndex) => {
          sourceSection.children.forEach((slide, slideIndex) => {
            if (slideErrors[slide.id] && !newPathFound) {
              errorActiveSlidePath = [
                sourceSectionGroupIndex,
                sourceSectionIndex,
                slideIndex,
              ];
              errorActiveNode = slideErrors[slide.id][0].node;
              newPathFound = true;
            }
          });
        },
      );
    });
    return {
      errorActiveSlidePath,
      errorActiveNode,
    };
  }
};

const validateHeadingLabelVariables = (
  headingLabel: string | undefined,
  arrayFieldId: string,
  templatesArrayFields: ExtendedReferences,
) => {
  if (!headingLabel) {
    return true;
  }
  const result = headingLabel
    ?.match(/\[\[answers.*?\]\]/g)
    ?.map(style => style.replace("[[answers", "").replace("]]", ""));

  if (!result) {
    return false;
  }

  const currentArrayFields = templatesArrayFields[arrayFieldId];

  if (!currentArrayFields) {
    return false;
  }

  const optionPath = result.map(path => path.replace(".", "children."));

  return optionPath.every(path => get(currentArrayFields, path));
};

const validateReferenceCheck = (
  translationKey: string | undefined,
  extendedReferences: ExtendedReferences,
) => {
  const result = translationKey
    ?.match(/\[\[answers.*?\]\]/g)
    ?.map(style =>
      style.replace("[[answers.", "").replace("]]", "").split("."),
    );

  if (!result) {
    return true;
  }

  return result.every(keyPath => {
    if (keyPath.length === 1) {
      const [reference] = keyPath;
      return extendedReferences[reference];
    } else {
      const [reference, childReference] = keyPath;
      const queryPath = extendedReferences[reference]?.query?.path;
      return (
        queryPath &&
        (extendedReferences[queryPath] as any)?.children[childReference]
      );
    }
  });
};

const validateOptionLabelVariables = (
  optionLabel: string | undefined,
  templatesArrayFields: ExtendedReferences,
) => {
  const result = optionLabel
    ?.match(/\[\[query.*?\]\]/g)
    ?.map(style => style.replace("[[query.", "").replace("]]", ""));

  if (!result) {
    return true;
  }

  const optionPath = result.map(path => path.replace(".", ".children."));

  return optionPath.every(path => get(templatesArrayFields, path));
};

const minLengthIsLessOrEqualToMaxLength = (rules: any[] | undefined) => {
  try {
    return getMinLengthRuleValue(rules) <= getMaxLengthRuleValue(rules);
  } catch {
    return true;
  }
};

const maxLengthIsPositive = (rules: any[] | undefined) => {
  try {
    return getMaxLengthRuleValue(rules) >= 0;
  } catch {
    return true;
  }
};

const minLengthIsPositive = (rules: any[] | undefined) => {
  try {
    return getMinLengthRuleValue(rules) >= 0;
  } catch {
    return true;
  }
};

const maxFileSizeIsPositive = (rules: any[] | undefined) => {
  try {
    return getMaxFileSizeRuleValue(rules) > 0;
  } catch {
    return true;
  }
};
