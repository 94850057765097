import React from "react";
import {selector, useRecoilValue} from "recoil";
import {
  BlockTableOfContent,
  previewProps,
  TableOfContent,
  TableOfContentRenderer,
} from "@reside/ui";
import {atomizedTemplateAtom, deatomizeNodes} from "../../model/template";
import {SourceSectionGroup} from "../../model/schemaTypes";

export const tocSelector = selector<TableOfContent>({
  key: "toc",
  get: ({get}) => {
    const atomizedTemplate = get(atomizedTemplateAtom);

    const template = deatomizeNodes(
      atomizedTemplate,
      get,
    ) as SourceSectionGroup[];

    let idx = 0;

    // The light version of `buildToc` located in reside app
    return {
      groups: template.map(({id, type, children, title}) => ({
        id,
        type,
        title,
        totalSlides: children.reduce(
          (acc, {children}) => acc + children.length,
          0,
        ),
        children: children.map(
          ({id: sectionId, type, children, title: sectionTitle}) => ({
            id: sectionId,
            type,
            title: sectionTitle,
            children: children.map(({id, type, title}) => ({
              id,
              idx: idx++,
              type,
              sectionId,
              sectionTitle,
              title,
              state: {
                fullscreen: false,
                totalFlags: 0,
                last: false,
                complete: false,
                visible: true,
              },
            })),
          }),
        ),
        completedSlides: 0,
        flaggedSlides: 0,
        state: {
          totalSlides: children.length,
        },
      })),
      state: {
        totalSlides: idx,
        completedSlides: 0,
      },
    } as any;
  },
});

const TableWrapper = () => {
  const toc = useRecoilValue(tocSelector);

  return (
    <>
      {toc && (
        <TableOfContentRenderer
          {...previewProps}
          fullTOC={toc}
          hasExpandedBlocks
        />
      )}
    </>
  );
};

export const EditableTableOfContent = () => (
  <BlockTableOfContent TableWrapper={TableWrapper} />
);
