var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from "react";
import { styled } from "../../../theme";
import { Text } from "../../../text";
import { Checkbox } from "../checkbox";
import { ItemLabel } from "../item-label";
export var CheckboxItem = memo(function (_a) {
    var label = _a.label, disabled = _a.disabled, children = _a.children, checkboxProps = __rest(_a, ["label", "disabled", "children"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ItemLabel, { tabIndex: disabled ? -1 : 0, disabled: disabled },
            React.createElement(Checkbox, __assign({ disabled: disabled }, checkboxProps)),
            React.createElement(TextLabel, null, label)),
        children));
});
var TextLabel = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 1.25em;\n  line-height: 1.75em;\n  margin-top: -0.2em;\n  padding-left: 1.5em;\n  pointer-events: none;\n\n  a {\n    pointer-events: auto;\n  }\n"], ["\n  font-size: 1.25em;\n  line-height: 1.75em;\n  margin-top: -0.2em;\n  padding-left: 1.5em;\n  pointer-events: none;\n\n  a {\n    pointer-events: auto;\n  }\n"])));
var templateObject_1;
