var FULL_DATE = "MM/dd/yyyy";
var FULL_TIME = "hh:mm:ss aaa";
export var DATE_FORMAT = {
    DAY: "MMMM d, yyyy",
    SHORT_DAY: "MMM d, yyyy",
    DAY_IN_MONTH: "MMMM d",
    SHORT_DAY_IN_MONTH: "MMM d",
    ISO_DATE: "yyyy-MM-dd",
    MONTH_NAME: "MMMM",
    FULL_DATE: FULL_DATE,
    FULL_TIME: FULL_TIME,
    FULL_DATE_TIME: FULL_DATE + " " + FULL_TIME,
    DAY_SHORT_MONTH: "d MMM",
    DAY_SHORT_MONTH_YEAR: "d MMM yy",
};
export var LOCAL_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
