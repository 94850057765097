import React from "react";
import {Popover} from "antd";
import {EyeOutlined} from "@ant-design/icons";

import {useFocusedNodeVisible} from "../../model/query-builder";

export const DebugVisibilityJson = () => {
  const {visible} = useFocusedNodeVisible();

  if (!visible) {
    return null;
  }

  return (
    <Popover
      content={
        <pre>
          <code>
            {JSON.stringify(
              {
                visible,
              },
              null,
              2,
            )}
          </code>
        </pre>
      }
      title="Visibility jsonLogic"
    >
      <EyeOutlined />
    </Popover>
  );
};
