import {useCallback} from "react";
import {useLocalStorage} from "react-use";
import {atom, selector, useResetRecoilState, useSetRecoilState} from "recoil";
import {useQuery} from "react-query";
import {message} from "antd";

import {availableTemplatesAtom, useResetPersistedTemplate} from "../template";
import {isRepoInitializedAtom, modifiedFilesAtom} from "../repository";
import {setGitAuthConfig} from "../../services/GitService";
import {
  initializeOctokit,
  getUserProfile,
  GithubUser,
} from "../../services/GithubService";
import {useResetEditorState} from "../editor";

export type Credentials = {
  username: "token";
  password: string;
};

export const userProfileAtom = atom<GithubUser | undefined>({
  key: "userProfile",
  default: undefined,
});

export const isLoggedInSelector = selector<boolean>({
  key: "isLoggedIn",
  get: ({get}) => !!get(userProfileAtom),
});

export const usePersistedCredentials = () =>
  useLocalStorage<Credentials>("credentials");

export const useLoginQuery = ({
  credentials,
  key = "login",
  onError = (error: any) => message.error(error.message),
}: {
  credentials: Credentials | undefined;
  key?: string;
  onError?: (error: any) => void;
}) => {
  const setUserProfile = useSetRecoilState(userProfileAtom);
  const [, setPersistedCredentials] = usePersistedCredentials();

  return useQuery(
    [key, credentials],
    async ({queryKey: [, credentials]}: any) => {
      initializeOctokit(credentials.password);

      const user = await getUserProfile();

      /**
       * We configure the git before setting the profile to atom,
       * so once user is logged in the git is prepared to clone/pull repo.
       */
      setGitAuthConfig({
        onAuth: () => ({
          ...credentials,
          username: "token",
        }),
      });

      return user;
    },
    {
      enabled: !!credentials,
      onSuccess: user => {
        setPersistedCredentials(credentials);
        setUserProfile(user);
      },
      onError,
    },
  );
};

export const useOnLogout = () => {
  const [, , removePersistedCredentials] = usePersistedCredentials();
  const resetPersistedTemplate = useResetPersistedTemplate();
  const resetEditorState = useResetEditorState();
  const resetUserProfile = useResetRecoilState(userProfileAtom);
  const resetAvailableTemplates = useResetRecoilState(availableTemplatesAtom);
  const resetIsRepoInitialized = useResetRecoilState(isRepoInitializedAtom);
  const resetModifiedFiles = useResetRecoilState(modifiedFilesAtom);

  return useCallback(() => {
    removePersistedCredentials();
    resetPersistedTemplate();
    resetEditorState();
    resetUserProfile();
    resetAvailableTemplates();
    resetIsRepoInitialized();
    resetModifiedFiles();
  }, [
    removePersistedCredentials,
    resetPersistedTemplate,
    resetEditorState,
    resetUserProfile,
    resetAvailableTemplates,
    resetIsRepoInitialized,
    resetModifiedFiles,
  ]);
};
