import React from "react";
import {Droppable} from "react-beautiful-dnd";
import {styled} from "@reside/ui";
import {RecoilState} from "recoil";

import {TemplateNodes} from "../../../../model/schemaTypes";
import {FocusContainer} from "../../../focus-container";
import {NodeDragItem} from "../node-drag-item";

type Props = Readonly<{
  droppableId: string;
  type: string;
  parentAtom: RecoilState<TemplateNodes>;
  atoms: RecoilState<TemplateNodes>[];
  indexPath: ReadonlyArray<number>;
  depthLevel: number;
  isInsideCommonComponent: boolean;
  isActiveSlideChild: boolean;
  isDropDisabled?: boolean;
}>;

export const NodeDropList = ({
  droppableId,
  type,
  atoms,
  parentAtom,
  indexPath,
  depthLevel,
  isInsideCommonComponent,
  isActiveSlideChild,
  isDropDisabled,
}: Props) => (
  <Droppable
    isDropDisabled={isInsideCommonComponent || isDropDisabled}
    droppableId={droppableId}
    type={type}
  >
    {(dropProvided, dropSnapshot) => (
      <Container
        ref={dropProvided.innerRef}
        isDraggingOver={dropSnapshot.isDraggingOver}
        {...dropProvided.droppableProps}
      >
        {atoms.length > 0 ? (
          atoms.map((atom, index) => (
            <FocusContainer key={atom.key} atom={atom}>
              {({isFocused}) => (
                <NodeDragItem
                  key={atom.key}
                  isFocused={isFocused}
                  atom={atom}
                  parentAtom={parentAtom}
                  index={index}
                  depthLevel={depthLevel}
                  indexPath={[...indexPath, index]}
                  isInsideCommonComponent={isInsideCommonComponent}
                  isActiveSlideChild={isActiveSlideChild}
                />
              )}
            </FocusContainer>
          ))
        ) : (
          <div style={{minHeight: "1"}} /> // NOTE:  placeholder for Drop zone, when there are no children
        )}
        {dropProvided.placeholder}
      </Container>
    )}
  </Droppable>
);

NodeDropList.defaultProps = {
  /**
   * Root is template/document list.
   */
  type: "template",
  /**
   * Arbitrary id.
   */
  droppableId: "template",
  /**
   * Depth level for offsetting children from left.
   */
  depthLevel: 1,
  /**
   * We start as empty path for the root list.
   */
  indexPath: [],
  /**
   * The root cannot be itself common.
   */
  isInsideCommonComponent: false,
  isActiveSlideChild: false,
};

const Container = styled.div<{isDraggingOver?: boolean}>`
  background-color: ${({isDraggingOver}) =>
    isDraggingOver ? "#f0f2f5" : "#fafafa"};
  user-select: none;
  transition: background-color 0.1s ease;
`;
