import React from "react";
import {Form} from "antd";
import {CurrencyField} from "@reside/ui";

import {LabelAttribute} from "../label-attribute";
import {DisabledAttribute} from "../disabled-attribute";
import {RequiredAttribute} from "../required-attribute";
import {ComputationAttribute} from "../computation-attribute";
import {
  getInitialMaxValueFormValue,
  MaxValueAttribute,
} from "../max-value-attribute";
import {
  getInitialMinValueFormValue,
  MinValueAttribute,
} from "../min-value-attribute";
import {AtomProp, useFocusedNode} from "../../model/editor";
import {isCurrencyNode} from "../../utils";
import {DisableClick} from "../disable-click";

export const EditableCurrencyField = ({atom, ...props}: AtomProp & any) => (
  <DisableClick>
    <CurrencyField {...props} />
  </DisableClick>
);

/**
 * FormControl type-specific form to adjust attributes.
 */
export const CurrencyFieldForm = () => {
  const node = useFocusedNode();

  if (!node || !isCurrencyNode(node)) {
    return null;
  }

  return (
    <Form
      key={node.id}
      initialValues={{
        label: node?.reference?.translationKey,
        computation: node?.reference?.computation,
        ...getInitialMinValueFormValue(node),
        ...getInitialMaxValueFormValue(node),
      }}
    >
      <LabelAttribute />
      <MaxValueAttribute />
      <MinValueAttribute />
      <DisabledAttribute />
      <RequiredAttribute />
      <ComputationAttribute />
    </Form>
  );
};
