import React from "react";
import {RecoilState} from "recoil";
import {Form, FormInstance, Input, Switch, Tooltip} from "antd";
import {DownOutlined} from "@ant-design/icons";

import {FieldArrayNode} from "@reside/forms";
import {Button, ButtonProps, Card, FlexRow, styled} from "@reside/ui";

import {
  AtomProp,
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {ChildrenDroppable} from "../children-droppable";
import {DroppableField} from "../droppable-field";
import {useDragAndDrop} from "../../hooks/useDragAndDrop";
import {RequiredAttribute} from "../required-attribute";
import {isFieldArray} from "../../utils";
import {Override, SourceNode, TemplateNodes} from "../../model/schemaTypes";
import {
  getInitialMinItemsFormValue,
  MinItemsAttribute,
} from "./MinItemsAttribute";
import {
  getInitialMaxItemsFormValue,
  MaxItemsAttribute,
} from "./MaxItemsAttribute";

export const EditableFieldArray = ({
  node: {children, headlineLabel, collapsible, translationKey, id},
  index,
  atom: parentAtom,
}: {
  index: number;
  node: Override<FieldArrayNode, {children: RecoilState<TemplateNodes>[]}>;
} & AtomProp) => {
  const {dropRef} = useDragAndDrop({index, parentAtom});
  const hasChildren = children?.length > 0;

  return (
    <div ref={dropRef} style={{minHeight: "150px"}}>
      <Card style={{backgroundColor: "inherit"}}>
        {hasChildren ? (
          <>
            <Container>
              <Body>
                <Header>
                  {collapsible ? (
                    <>
                      <ItemLabel>
                        <DownOutlined />
                        {headlineLabel?.translationKey}
                      </ItemLabel>
                      <AlignRight>
                        <RemoveButton translationKey={translationKey} />
                      </AlignRight>
                    </>
                  ) : (
                    <>
                      <ItemLabel>{translationKey} #1</ItemLabel>
                      <AlignRight>
                        <RemoveButton
                          translationKey={translationKey}
                          disabled
                        />
                      </AlignRight>
                    </>
                  )}
                </Header>
                {children.map((atom, index) => (
                  <DroppableField
                    index={index}
                    key={atom.key}
                    atom={atom}
                    parentAtom={parentAtom}
                    isArrayFieldScoped={true}
                  />
                ))}
              </Body>
            </Container>
            <AddButton translationKey={translationKey} />
          </>
        ) : (
          <>
            <ChildrenDroppable help="The items will be visible once you drop here any items." />
            <AddButton translationKey={translationKey} />
          </>
        )}
      </Card>
    </div>
  );
};

const Container = styled.div`
  margin-bottom: 1em;
  margin-top: 1em;
  border: 1px solid ${({theme}) => theme.color.gray10};
`;

const Body = styled.div`
  padding: 1em 20px;
`;

const Header = styled(FlexRow)`
  align-items: center;
`;

const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ItemLabel = styled.div`
  text-transform: uppercase;
`;

const AddButton = ({
  translationKey,
  ...props
}: Pick<FieldArrayNode, "translationKey"> & Partial<ButtonProps>) => {
  return (
    <Button {...props} outline color="primary" disabled>
      Add {translationKey}
    </Button>
  );
};

const RemoveButton = ({
  translationKey,
  ...props
}: Pick<FieldArrayNode, "translationKey"> & Partial<ButtonProps>) => {
  return (
    <Button {...props} outline color="danger" disabled>
      Remove {translationKey}
    </Button>
  );
};

export const FieldArrayForm = () => {
  const node = useFocusedNode() as SourceNode<FieldArrayNode>;
  const [form] = Form.useForm();

  return (
    <Form
      form={form}
      key={node.id}
      initialValues={{
        collapsible: node?.collapsible,
        emptyMessage: node?.emptyMessage?.translationKey,
        headlineLabel: node?.headlineLabel?.translationKey,
        label: node?.translationKey,
        ...getInitialMinItemsFormValue(node as any),
        ...getInitialMaxItemsFormValue(node as any),
      }}
    >
      <RequiredAttribute />
      <LabelAttribute />
      <EmptyMessageAttribute />
      <CollapsibleAttribute form={form} />
      <MinItemsAttribute />
      <MaxItemsAttribute />
    </Form>
  );
};

const CollapsibleAttribute = ({form}: {form: FormInstance<any>}) => {
  const node = useFocusedNode();
  const setCollapsible = useSetFocusedNodePropertyValue("collapsible");
  const setHeadlineLabel = useSetFocusedNodePropertyValue("headlineLabel", {
    replace: true,
  });

  if (!node) {
    return null;
  }

  return isFieldArray(node) ? (
    <>
      <Form.Item
        label={
          <Tooltip title="Define if FieldArray items will be collapsible">
            <span>Collapsible</span>
          </Tooltip>
        }
      >
        <Switch
          checked={node.collapsible}
          onChange={enabled => {
            setCollapsible(enabled);
            if (!enabled) {
              setHeadlineLabel(null);
              form.setFieldsValue({headlineLabel: ""});
            }
          }}
        />
      </Form.Item>
      {node.collapsible && <HeadlineAttribute />}
    </>
  ) : (
    <>Attribute "collapsible" can be used only with FIELD_ARRAY type</>
  );
};

const HeadlineAttribute = () => {
  const node = useFocusedNode();
  const setHeadlineLabel = useSetFocusedNodePropertyValue(
    "headlineLabel.translationKey",
  );

  if (!node) {
    return null;
  }

  return isFieldArray(node) ? (
    <Form.Item
      name="headlineLabel"
      label={
        <Tooltip title="Label which will be displayed above every collapsible FieldArray item">
          <span>Headline label</span>
        </Tooltip>
      }
    >
      <Input onChange={event => setHeadlineLabel(event.currentTarget.value)} />
    </Form.Item>
  ) : (
    <>Attribute Headline can be used only with FIELD_ARRAY type</>
  );
};

const EmptyMessageAttribute = () => {
  const node = useFocusedNode();
  const setEmptyMessage = useSetFocusedNodePropertyValue(
    "emptyMessage.translationKey",
  );

  if (!node) {
    return null;
  }

  return isFieldArray(node) ? (
    <Form.Item
      name="emptyMessage"
      label={
        <Tooltip title="Empty message which will be displayed when there are no items in FieldArray">
          <span>Empty message</span>
        </Tooltip>
      }
    >
      <Input onChange={event => setEmptyMessage(event.currentTarget.value)} />
    </Form.Item>
  ) : (
    <>Attribute Label/Placeholder can be used only with FIELD_ARRAY type</>
  );
};

const LabelAttribute = () => {
  const node = useFocusedNode();
  const setTranslationKey = useSetFocusedNodePropertyValue("translationKey");

  if (!node) {
    return null;
  }

  return isFieldArray(node) ? (
    <Form.Item
      rules={[{required: true}]}
      name="label"
      label={
        <Tooltip title="Label/Placeholder for every item in FieldArray">
          <span>Label/Placeholder</span>
        </Tooltip>
      }
    >
      <Input onChange={event => setTranslationKey(event.currentTarget.value)} />
    </Form.Item>
  ) : (
    <>Attribute Label/Placeholder can be used only with FIELD_ARRAY type</>
  );
};
