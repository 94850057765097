import React from "react";
import {Button} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {useRecoilValue} from "recoil";

import {
  isTemplateDirtyAtom,
  useResetPersistedTemplate,
} from "../../model/template";
import {useResetEditorState} from "../../model/editor";

export const HomeButton = () => {
  const isTemplateDirty = useRecoilValue(isTemplateDirtyAtom);
  const resetTemplatePersisted = useResetPersistedTemplate();
  const resetEditorState = useResetEditorState();

  return (
    <Button
      icon={<HomeOutlined />}
      onClick={() => {
        /**
         * When leaving home & we have some unsaved changes, require confirmation for clearing them.
         */
        if (
          !isTemplateDirty ||
          window.confirm(
            "Leaving will discard unsaved changes. Are you sure you want to discard unsaved changes?",
          )
        ) {
          resetTemplatePersisted();
          resetEditorState();
        }
      }}
    >
      Home
    </Button>
  );
};
