var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { toggleMark } from "prosemirror-commands";
import markInputRule from "../lib/markInputRule";
import Mark from "./Mark";
var Strikethrough = /** @class */ (function (_super) {
    __extends(Strikethrough, _super);
    function Strikethrough() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Strikethrough.prototype, "name", {
        get: function () {
            return "strikethrough";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Strikethrough.prototype, "schema", {
        get: function () {
            return {
                parseDOM: [
                    {
                        tag: "s",
                    },
                    {
                        tag: "del",
                    },
                    {
                        tag: "strike",
                    },
                ],
                toDOM: function () { return ["del", 0]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    Strikethrough.prototype.keys = function (_a) {
        var type = _a.type;
        return {
            "Mod-d": toggleMark(type),
        };
    };
    Strikethrough.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [markInputRule(/~([^~]+)~$/, type)];
    };
    Object.defineProperty(Strikethrough.prototype, "toMarkdown", {
        get: function () {
            return {
                open: "~~",
                close: "~~",
                mixable: true,
                expelEnclosingWhitespace: true,
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Strikethrough.prototype, "markdownToken", {
        get: function () {
            return "s";
        },
        enumerable: true,
        configurable: true
    });
    Strikethrough.prototype.parseMarkdown = function () {
        return { mark: "strikethrough" };
    };
    return Strikethrough;
}(Mark));
export default Strikethrough;
