"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var flatten_1 = require("../../flatten");
exports.Validator = {
    name: "max_computation",
    isImplicit: true,
    errorMessage: "Max computation value exceeded :max_computation",
    validate: function (value, requirement) {
        var _a = requirement.split(","), maxComputationField = _a[0], maxComputationValue = _a[1];
        // TODO: write a test and refactor
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        var val = flatten_1.flatten(this.validator.input)[maxComputationField];
        return Number(val) <= Number(maxComputationValue);
    },
};
