var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { toggleMark } from "prosemirror-commands";
import markInputRule from "../lib/markInputRule";
import Mark from "./Mark";
var Bold = /** @class */ (function (_super) {
    __extends(Bold, _super);
    function Bold() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Bold.prototype, "name", {
        get: function () {
            return "strong";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Bold.prototype, "schema", {
        get: function () {
            return {
                parseDOM: [
                    { tag: "b" },
                    { tag: "strong" },
                    { style: "font-style", getAttrs: function (value) { return value === "bold"; } },
                ],
                toDOM: function () { return ["strong"]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    Bold.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [markInputRule(/(?:\*\*)([^*]+)(?:\*\*)$/, type)];
    };
    Bold.prototype.keys = function (_a) {
        var type = _a.type;
        return {
            "Mod-b": toggleMark(type),
            "Mod-B": toggleMark(type),
        };
    };
    Object.defineProperty(Bold.prototype, "toMarkdown", {
        get: function () {
            return {
                open: "**",
                close: "**",
                mixable: true,
                expelEnclosingWhitespace: true,
            };
        },
        enumerable: true,
        configurable: true
    });
    Bold.prototype.parseMarkdown = function () {
        return { mark: "strong" };
    };
    return Bold;
}(Mark));
export default Bold;
