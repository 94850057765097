import { isMobile } from "../is-mobile";
export var isDateTypeSupported = function () {
    var el = document.createElement("input");
    var value = "a";
    el.setAttribute("type", "date");
    el.setAttribute("value", value);
    return el.type !== "text" || el.value !== value;
};
var isFirefox = function () { return navigator.userAgent.includes("Firefox"); };
/**
 * System native datepicker only when "type=date" is supported on mobile or when disabled
 */
export var hasNativeDatepicker = function () {
    return process.env.NODE_ENV === "test"
        ? false
        : isDateTypeSupported() && (isMobile || isFirefox());
};
