"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.messages = {
    accepted: "Please Review and Confirm.",
    after: "This field must be after :after.",
    after_or_equal: "This field must be equal or after :after_or_equal.",
    alpha: "This field must contain only alphabetic characters.",
    alpha_dash: "This field may only contain alpha-numeric characters, as well as dashes and underscores.",
    alpha_num: "This field must be alphanumeric.",
    before: "This field must be before :before.",
    before_or_equal: "This field must be equal or before :before_or_equal.",
    between: "This field must be between :min and :max.",
    confirmed: "This field confirmation does not match.",
    date: "Entered value is not a valid date",
    email: "This field format is invalid. Correct format is name@domain.tld.",
    def: "This field attribute has errors.",
    digits: "This field must be :digits digits.",
    different: "This field and :different must be different.",
    in: "The selected :attribute is invalid.",
    integer: "This field must be an integer.",
    hex: "This field should have hexadecimal format",
    min: {
        numeric: "This field must be at least :min.",
        string: "This field must be at least :min characters.",
    },
    max: {
        numeric: "This field may not be greater than :max.",
        string: "This field may not be greater than :max characters.",
    },
    max_length: "This field may not be greater than :max_length characters.",
    not_in: "The selected :attribute is invalid.",
    numeric: "This field must be a number.",
    present: "This field must be present (but can be empty).",
    radio: "You should select one of the options.",
    required: "This field is required.",
    required_if: "This field is required.",
    required_unless: "This field is required.",
    required_with: "This field is required.",
    required_with_all: "This field is required.",
    required_without: "This field is required.",
    required_without_all: "This field is required.",
    same: "This field and :same fields must match.",
    size: {
        numeric: "This field must be :size.",
        string: "This field must be :size characters.",
    },
    string: "This field must be a string.",
    url: "This field format is invalid.",
    regex: "This field format is invalid.",
    phone: "This phone field contains invalid phone number. Correct format is XXX-XXX-XXXX.",
    attributes: {},
};
