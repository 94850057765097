import React, {useState} from "react";
import {SelectField, SelectFieldProps, styled} from "@reside/ui";
import {Form} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {red} from "@ant-design/colors";

import {LabelAttribute} from "../label-attribute";
import {DisabledAttribute} from "../disabled-attribute";
import {RequiredAttribute} from "../required-attribute";
import {
  AtomizedFormControlSourceNode,
  AtomProp,
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {QueryAttribute} from "../query-attribute";
import {DisableClick} from "../disable-click";
import {FormControlSourceNode} from "../../model/schemaTypes";
import {GroupFieldOption} from "../group-field-option";
import {useChoices} from "../../model/nodes";

export const EditableSelectField = ({
  atom,
  node,
  customizableChoices,
  ...props
}: {
  node: AtomizedFormControlSourceNode;
  customizableChoices?: boolean;
} & AtomProp &
  SelectFieldProps<any>) => {
  const isUsingQueryPath = node.reference.query?.path;
  const optionLabel = node.reference.optionLabel?.translationKey;
  const {choices, addChoice, removeChoice} = useChoices(atom as any);

  if (isUsingQueryPath) {
    return (
      <SelectField {...props} placeholder={`Select: ${optionLabel}`} disabled />
    );
  }

  return (
    <>
      <DisableClick>
        <SelectField {...props} />
      </DisableClick>
      {!node.reference.query && customizableChoices && (
        <>
          {((choices as any) as ReadonlyArray<any>).map((choice, index) => (
            <GroupFieldOption
              key={index}
              index={index}
              atom={node.reference.choices![index] as any}
              parentAtom={atom}
              option={choice as any}
              reference={node.reference}
              onRemove={() => removeChoice(index)}
            />
          ))}
          <IconWrapper>
            <PlusOutlined style={{color: red.primary}} onClick={addChoice} />
          </IconWrapper>
        </>
      )}
    </>
  );
};

/**
 * FormControl type-specific form to adjust attributes.
 */
export const SelectFieldForm = () => {
  const node = useFocusedNode() as FormControlSourceNode;
  const setReference = useSetFocusedNodePropertyValue("reference", {
    replace: true,
  });

  /**
   * Memorize choices when replacing by query path, as the user might want to return back to static choices while editing...
   */
  const [choices, setChoices] = useState();

  return (
    <Form
      key={node.id}
      initialValues={{
        label: node?.reference?.translationKey,
        query: !!node.reference.query,
        queryPath: node?.reference.query?.path,
        optionLabel: node?.reference.optionLabel?.translationKey,
      }}
      onValuesChange={({query}) => {
        if (query !== undefined) {
          if (query) {
            const {choices, ...reference} = node.reference;

            setChoices(choices as any);
            setReference({...reference, query: {path: ""}});
          } else {
            const {query, optionLabel, ...reference} = node.reference;

            setReference({...reference, choices});
          }
        }
      }}
    >
      <LabelAttribute />
      <DisabledAttribute />
      <RequiredAttribute />
      <QueryAttribute />
    </Form>
  );
};

const IconWrapper = styled.div`
  padding: 10px;
`;
