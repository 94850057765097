import {
  DropTargetMonitor,
  useDrag,
  useDrop,
  DragSourceMonitor,
} from "react-dnd";
import * as immutable from "object-path-immutable";
import {RecoilState, useRecoilValue, useSetRecoilState} from "recoil";

import {DragType} from "../constants";
import {atomizeNodes, useTemplateAtomState} from "../model/template";
import {moveItem, insert, createBlock, createSourceImport} from "../utils";
import {componentLibraryByTypeSelector} from "../model/library";
import {SourceNodes, TemplateNodes} from "../model/schemaTypes";
import {focusedAtomAtom} from "../model/editor";

export type DragMoveItem = Readonly<{
  type: DragType.MOVE;
  index: number;
  parentAtom: RecoilState<TemplateNodes>;
  parentNode: TemplateNodes;
  setParentNode: (node: TemplateNodes) => void;
}>;

export type DragCreateItem = Readonly<{
  type: DragType.CREATE;
  index: number;
}>;

export type DragAddLibraryComponentItem = Readonly<{
  type: DragType.ADD_LIBRARY_COMPONENT;
  id: string;
}>;

type Props = Readonly<{
  index: number;
  /**
   * The atom where the block is one of it's children.
   */
  parentAtom: RecoilState<TemplateNodes>;
  canDrag?: boolean;
}>;

export const useDragAndDrop = ({index, parentAtom, canDrag}: Props) => {
  const [parentNode, setParentNode] = useTemplateAtomState(parentAtom);
  const setFocusedAtom = useSetRecoilState(focusedAtomAtom);
  const libraryComponents = useRecoilValue(componentLibraryByTypeSelector);

  const [, dropRef] = useDrop({
    accept: [DragType.CREATE, DragType.MOVE, DragType.ADD_LIBRARY_COMPONENT],
    drop: (
      item: DragMoveItem | DragCreateItem | DragAddLibraryComponentItem,
      monitor: DropTargetMonitor,
    ) => {
      if (monitor.didDrop()) {
        return;
      }

      if (item.type === DragType.CREATE) {
        const createdNodes = createBlock(item.index);
        const createdAtoms = atomizeNodes(createdNodes);

        setParentNode({
          ...parentNode,
          children: insert(
            parentNode.children ?? [],
            index,
            ...createdAtoms,
          ) as any,
        });
        setFocusedAtom(createdAtoms[0]);
      } else if (item.type === DragType.MOVE) {
        // dropped on the same parent; we reorder the items
        if (parentAtom === item.parentAtom) {
          const movedChildren = moveItem(
            parentNode.children,
            item.index,
            index,
          );

          setParentNode({...parentNode, children: movedChildren as any});
        }
        // dropped on different parent; we delete from source and insert to target
        else {
          const sourceAtom = item.parentNode.children[item.index];

          item.setParentNode({
            ...item.parentNode,
            children: immutable.del(item.parentNode.children, `${item.index}`),
          });

          setParentNode({
            ...parentNode,
            children: parentNode.children
              ? insert(parentNode.children, index, sourceAtom)
              : [sourceAtom],
          });
        }
      } else if (item.type === DragType.ADD_LIBRARY_COMPONENT) {
        if (!item.id) {
          return;
        }

        const libraryComponent = libraryComponents.find(
          libraryComponent => libraryComponent.id === item.id,
        ) as SourceNodes & {filePath: string};

        const createdAtoms = atomizeNodes([
          {
            ...libraryComponent,
            importedFrom: createSourceImport(libraryComponent.filePath),
          },
        ]);

        setParentNode({
          ...parentNode,
          children: insert(
            parentNode.children ?? [],
            index,
            ...createdAtoms,
          ) as any,
        });
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      isOverCurrent: monitor.isOver({shallow: true}),
    }),
  });

  const [{isDragging}, dragRef] = useDrag({
    item: {
      type: DragType.MOVE,
      index,
      parentAtom,
      parentNode,
      setParentNode,
    },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag,
  });

  return {dropRef, dragRef, isDragging};
};
