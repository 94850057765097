import React from "react";
import {Form, InputNumber} from "antd";
import {MAX_LENGTH_RULE_NAME} from "@reside/forms/dist/validator/validators/maxLengthValidator";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {getRuleValue, isTextOrTextAreaNode, updateRules} from "../../utils";
import {FormControlSourceNode} from "../../model/schemaTypes";

export const getMaxLengthRuleValue = getRuleValue(
  MAX_LENGTH_RULE_NAME,
  parseInt,
);

export const getInitialMaxLengthFormValue = (node: FormControlSourceNode) => {
  try {
    return {
      [MAX_LENGTH_RULE_NAME]: getMaxLengthRuleValue(node.rules),
    };
  } catch {
    return {};
  }
};

export const MaxLengthAttribute = () => {
  const node = useFocusedNode();
  const setValidationRules = useSetFocusedNodePropertyValue("rules");

  if (!node) {
    return null;
  }

  return isTextOrTextAreaNode(node) ? (
    <Form.Item
      name={MAX_LENGTH_RULE_NAME}
      label="Max length"
      rules={[{type: "number", min: 0}]}
    >
      <InputNumber
        min={0}
        onChange={value =>
          setValidationRules(
            updateRules(
              [...(node.rules ?? [])],
              `${value ?? ""}`,
              MAX_LENGTH_RULE_NAME,
            ),
          )
        }
      />
    </Form.Item>
  ) : (
    <>
      Attribute "${MAX_LENGTH_RULE_NAME}" can be used only with
      FORM_CONTROL-TEXT/TEXTAREA
    </>
  );
};
