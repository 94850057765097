var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { Collapse } from "react-collapse";
import { styled, theme } from "../theme";
import { FlexRow } from "../box";
export var isTest = process.env.NODE_ENV === "test";
export var Drawer = function (_a) {
    var children = _a.children, isOpened = _a.isOpened, collapsible = _a.collapsible, disableAnimation = _a.disableAnimation, header = _a.header, onToggle = _a.onToggle, testId = _a.testId;
    return (React.createElement(Container, { className: "drawer-container", disableAnimation: disableAnimation, tabIndex: 0, onKeyDown: collapsible
            ? function (event) {
                // NOTE: Test environment does not handle the current focus element (activeElement)
                var drawerIsFocused = isTest || event.currentTarget === document.activeElement;
                if (drawerIsFocused) {
                    if (event.keyCode === 32 /* Space */) {
                        onToggle(!isOpened);
                    }
                    else if (event.key === "ArrowDown" && !isOpened) {
                        onToggle(true);
                    }
                    else if (event.key === "ArrowUp" && isOpened) {
                        onToggle(false);
                    }
                }
            }
            : undefined, "data-test-id": testId },
        React.createElement(HeaderRow, { collapsible: collapsible, onClick: collapsible
                ? function () {
                    onToggle(!isOpened);
                }
                : undefined }, header),
        React.createElement(Collapse, { isOpened: isOpened }, children)));
};
Drawer.defaultProps = {
    isOpened: false,
    collapsible: true,
    disableAnimation: false,
    onToggle: function () { },
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1em;\n  border: 1px solid ", ";\n\n  ", "\n\n  .ReactCollapse--content {\n    border-top: 1px solid ", ";\n  }\n\n  &:focus {\n    box-shadow: 0px 0px 2px ", ";\n  }\n"], ["\n  margin-bottom: 1em;\n  border: 1px solid ", ";\n\n  ",
    "\n\n  .ReactCollapse--content {\n    border-top: 1px solid ", ";\n  }\n\n  &:focus {\n    box-shadow: 0px 0px 2px ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.gray10;
}, function (_a) {
    var disableAnimation = _a.disableAnimation;
    return disableAnimation && ".ReactCollapse--collapse { height: auto !important; }";
}, function (_a) {
    var theme = _a.theme;
    return theme.color.gray10;
}, function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
var HeaderRow = styled(FlexRow)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 0.6em 1.2em;\n  user-select: none;\n  align-items: center;\n\n  ", "\n"], ["\n  padding: 0.6em 1.2em;\n  user-select: none;\n  align-items: center;\n\n  ",
    "\n"])), function (_a) {
    var collapsible = _a.collapsible;
    return collapsible &&
        "\n      background: " + theme.color.white + ";\n\n      &:hover {\n        cursor: pointer;\n      }\n  ";
});
var templateObject_1, templateObject_2;
