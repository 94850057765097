/* eslint-disable @typescript-eslint/no-use-before-define */
// https://raw.githubusercontent.com/ProseMirror/prosemirror-markdown/master/src/to_markdown.js
// forked for table support
// ::- A specification for serializing a ProseMirror document as
// Markdown/CommonMark text.
var MarkdownSerializer = /** @class */ (function () {
    // :: (Object<(state: MarkdownSerializerState, node: Node, parent: Node, index: number)>, Object)
    // Construct a serializer with the given configuration. The `nodes`
    // object should map node names in a given schema to function that
    // take a serializer state and such a node, and serialize the node.
    //
    // The `marks` object should hold objects with `open` and `close`
    // properties, which hold the strings that should appear before and
    // after a piece of text marked that way, either directly or as a
    // function that takes a serializer state and a mark, and returns a
    // string. `open` and `close` can also be functions, which will be
    // called as
    //
    //     (state: MarkdownSerializerState, mark: Mark,
    //      parent: Fragment, index: number) → string
    //
    // Where `parent` and `index` allow you to inspect the mark's
    // context to see which nodes it applies to.
    //
    // Mark information objects can also have a `mixable` property
    // which, when `true`, indicates that the order in which the mark's
    // opening and closing syntax appears relative to other mixable
    // marks can be varied. (For example, you can say `**a *b***` and
    // `*a **b***`, but not `` `a *b*` ``.)
    //
    // To disable character escaping in a mark, you can give it an
    // `escape` property of `false`. Such a mark has to have the highest
    // precedence (must always be the innermost mark).
    //
    // The `expelEnclosingWhitespace` mark property causes the
    // serializer to move enclosing whitespace from inside the marks to
    // outside the marks. This is necessary for emphasis marks as
    // CommonMark does not permit enclosing whitespace inside emphasis
    // marks, see: http://spec.commonmark.org/0.26/#example-330
    function MarkdownSerializer(nodes, marks) {
        // :: Object<(MarkdownSerializerState, Node)> The node serializer
        // functions for this serializer.
        this.nodes = nodes;
        // :: Object The mark serializer info.
        this.marks = marks;
    }
    // :: (Node, ?Object) → string
    // Serialize the content of the given node to
    // [CommonMark](http://commonmark.org/).
    MarkdownSerializer.prototype.serialize = function (content, options) {
        var state = new MarkdownSerializerState(this.nodes, this.marks, options);
        state.renderContent(content);
        return state.out;
    };
    return MarkdownSerializer;
}());
export { MarkdownSerializer };
// ::- This is an object used to track state and expose
// methods related to markdown serialization. Instances are passed to
// node and mark serialization methods (see `toMarkdown`).
var MarkdownSerializerState = /** @class */ (function () {
    function MarkdownSerializerState(nodes, marks, options) {
        this.nodes = nodes;
        this.marks = marks;
        this.delim = this.out = "";
        this.closed = false;
        this.inTightList = false;
        // :: Object
        // The options passed to the serializer.
        //   tightLists:: ?bool
        //   Whether to render lists in a tight style. This can be overridden
        //   on a node level by specifying a tight attribute on the node.
        //   Defaults to false.
        this.options = options || {};
        if (typeof this.options.tightLists === "undefined")
            this.options.tightLists = true;
    }
    MarkdownSerializerState.prototype.flushClose = function (size) {
        if (this.closed) {
            if (!this.atBlank())
                this.out += "\n";
            if (size === null || size === undefined)
                size = 2;
            if (size > 1) {
                var delimMin = this.delim;
                var trim = /\s+$/.exec(delimMin);
                if (trim)
                    delimMin = delimMin.slice(0, delimMin.length - trim[0].length);
                for (var i = 1; i < size; i++)
                    this.out += delimMin + "\n";
            }
            this.closed = false;
        }
    };
    // :: (string, ?string, Node, ())
    // Render a block, prefixing each line with `delim`, and the first
    // line in `firstDelim`. `node` should be the node that is closed at
    // the end of the block, and `f` is a function that renders the
    // content of the block.
    MarkdownSerializerState.prototype.wrapBlock = function (delim, firstDelim, node, f) {
        var old = this.delim;
        this.write(firstDelim || delim);
        this.delim += delim;
        f();
        this.delim = old;
        this.closeBlock(node);
    };
    MarkdownSerializerState.prototype.atBlank = function () {
        return /(^|\n)$/.test(this.out);
    };
    // :: ()
    // Ensure the current content ends with a newline.
    MarkdownSerializerState.prototype.ensureNewLine = function () {
        if (!this.atBlank())
            this.out += "\n";
    };
    // :: (?string)
    // Prepare the state for writing output (closing closed paragraphs,
    // adding delimiters, and so on), and then optionally add content
    // (unescaped) to the output.
    MarkdownSerializerState.prototype.write = function (content) {
        this.flushClose();
        if (this.delim && this.atBlank())
            this.out += this.delim;
        if (content)
            this.out += content;
    };
    // :: (Node)
    // Close the block for the given node.
    MarkdownSerializerState.prototype.closeBlock = function (node) {
        this.closed = node;
    };
    // :: (string, ?bool)
    // Add the given text to the document. When escape is not `false`,
    // it will be escaped.
    MarkdownSerializerState.prototype.text = function (text, escape) {
        var lines = text.split("\n");
        for (var i = 0; i < lines.length; i++) {
            var startOfLine = this.atBlank() || this.closed;
            this.write();
            this.out += escape !== false ? this.esc(lines[i], startOfLine) : lines[i];
            if (i !== lines.length - 1)
                this.out += "\n";
        }
    };
    // :: (Node)
    // Render the given node as a block.
    MarkdownSerializerState.prototype.render = function (node, parent, index) {
        if (typeof parent === "number")
            throw new Error("!");
        this.nodes[node.type.name](this, node, parent, index);
    };
    // :: (Node)
    // Render the contents of `parent` as block nodes.
    MarkdownSerializerState.prototype.renderContent = function (parent) {
        var _this = this;
        parent.forEach(function (node, _, i) { return _this.render(node, parent, i); });
    };
    // :: (Node)
    // Render the contents of `parent` as inline content.
    MarkdownSerializerState.prototype.renderInline = function (parent) {
        var _this = this;
        var active = [];
        var trailing = "";
        var progress = function (node, _, index) {
            var marks = node ? node.marks : [];
            // Remove marks from `hard_break` that are the last node inside
            // that mark to prevent parser edge cases with new lines just
            // before closing marks.
            // (FIXME it'd be nice if we had a schema-agnostic way to
            // identify nodes that serialize as hard breaks)
            if (node && node.type.name === "hard_break")
                marks = marks.filter(function (m) {
                    if (index + 1 === parent.childCount)
                        return false;
                    var next = parent.child(index + 1);
                    return (m.isInSet(next.marks) && (!next.isText || /\S/.test(next.text)));
                });
            var leading = trailing;
            trailing = "";
            // If whitespace has to be expelled from the node, adjust
            // leading and trailing accordingly.
            if (node &&
                node.isText &&
                marks.some(function (mark) {
                    var info = _this.marks[mark.type.name];
                    return info && info.expelEnclosingWhitespace;
                })) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
                var _a = /^(\s*)(.*?)(\s*)$/m.exec(node.text), _1 = _a[0], lead = _a[1], inner_1 = _a[2], trail = _a[3];
                leading += lead;
                trailing = trail;
                if (lead || trail) {
                    node = inner_1 ? node.withText(inner_1) : null;
                    if (!node)
                        marks = active;
                }
            }
            var inner = marks.length && marks[marks.length - 1], noEsc = inner && _this.marks[inner.type.name].escape === false;
            var len = marks.length - (noEsc ? 1 : 0);
            // Try to reorder 'mixable' marks, such as em and strong, which
            // in Markdown may be opened and closed in different order, so
            // that order of the marks for the token matches the order in
            // active.
            outer: for (var i = 0; i < len; i++) {
                var mark = marks[i];
                if (!_this.marks[mark.type.name].mixable)
                    break;
                for (var j = 0; j < active.length; j++) {
                    var other = active[j];
                    if (!_this.marks[other.type.name].mixable)
                        break;
                    if (mark.eq(other)) {
                        if (i > j)
                            marks = marks
                                .slice(0, j)
                                .concat(mark)
                                .concat(marks.slice(j, i))
                                .concat(marks.slice(i + 1, len));
                        else if (j > i)
                            marks = marks
                                .slice(0, i)
                                .concat(marks.slice(i + 1, j))
                                .concat(mark)
                                .concat(marks.slice(j, len));
                        continue outer;
                    }
                }
            }
            // Find the prefix of the mark set that didn't change
            var keep = 0;
            while (keep < Math.min(active.length, len) &&
                marks[keep].eq(active[keep]))
                ++keep;
            // Close the marks that need to be closed
            while (keep < active.length)
                _this.text(_this.markString(active.pop(), false, parent, index), false);
            // Output any previously expelled trailing whitespace outside the marks
            if (leading)
                _this.text(leading);
            // Open the marks that need to be opened
            if (node) {
                while (active.length < len) {
                    var add = marks[active.length];
                    active.push(add);
                    _this.text(_this.markString(add, true, parent, index), false);
                }
                // Render the node. Special case code marks, since their content
                // may not be escaped.
                if (noEsc && node.isText)
                    _this.text(_this.markString(inner, true, parent, index) +
                        node.text +
                        _this.markString(inner, false, parent, index + 1), false);
                else
                    _this.render(node, parent, index);
            }
        };
        parent.forEach(progress);
        progress(null, null, parent.childCount);
    };
    // :: (Node, string, (number) → string)
    // Render a node's content as a list. `delim` should be the extra
    // indentation added to all lines except the first in an item,
    // `firstDelim` is a function going from an item index to a
    // delimiter for the first line of the item.
    MarkdownSerializerState.prototype.renderList = function (node, delim, firstDelim) {
        var _this = this;
        if (this.closed && this.closed.type === node.type)
            this.flushClose(3);
        else if (this.inTightList)
            this.flushClose(1);
        var isTight = typeof node.attrs.tight !== "undefined"
            ? node.attrs.tight
            : this.options.tightLists;
        var prevTight = this.inTightList;
        var prevList = this.inList;
        this.inList = true;
        this.inTightList = isTight;
        node.forEach(function (child, _, i) {
            if (i && isTight)
                _this.flushClose(1);
            _this.wrapBlock(delim, firstDelim(i), node, function () {
                return _this.render(child, node, i);
            });
        });
        this.inList = prevList;
        this.inTightList = prevTight;
    };
    MarkdownSerializerState.prototype.renderTable = function (node) {
        var _this = this;
        this.flushClose(1);
        var headerBuffer = "";
        var prevTable = this.inTable;
        this.inTable = true;
        // rows
        node.forEach(function (row, _, i) {
            if (headerBuffer) {
                _this.out += headerBuffer + "|\n";
                headerBuffer = undefined;
            }
            // cols
            row.forEach(function (cell, _, j) {
                _this.out += j === 0 ? "| " : " | ";
                cell.forEach(function (para) {
                    // just padding the output so that empty cells take up the same space
                    // as headings.
                    // TODO: Ideally we'd calc the longest cell length and use that
                    // to pad all the others.
                    if (para.textContent === "") {
                        _this.out += "  ";
                    }
                    else {
                        _this.closed = false;
                        _this.render(para, row, j);
                    }
                });
                if (i === 0) {
                    if (cell.attrs.alignment === "center") {
                        headerBuffer += "|:---:";
                    }
                    else if (cell.attrs.alignment === "left") {
                        headerBuffer += "|:---";
                    }
                    else if (cell.attrs.alignment === "right") {
                        headerBuffer += "|---:";
                    }
                    else {
                        headerBuffer += "|----";
                    }
                }
            });
            _this.out += " |\n";
        });
        this.inTable = prevTable;
    };
    // :: (string, ?bool) → string
    // Escape the given string so that it can safely appear in Markdown
    // content. If `startOfLine` is true, also escape characters that
    // has special meaning only at the start of the line.
    MarkdownSerializerState.prototype.esc = function (str, startOfLine) {
        if (!str) {
            return str;
        }
        str = str.replace(/[`*\\~[\]]/g, "\\$&");
        if (startOfLine) {
            str = str.replace(/^[:#\-*+]/, "\\$&").replace(/^(\d+)\./, "$1\\.");
        }
        if (this.inTable) {
            str = str.replace(/\|/gi, "\\$&");
        }
        return str;
    };
    MarkdownSerializerState.prototype.quote = function (str) {
        var wrap = str.indexOf('"') === -1 ? '""' : str.indexOf("'") === -1 ? "''" : "()";
        return wrap[0] + str + wrap[1];
    };
    // :: (string, number) → string
    // Repeat the given string `n` times.
    MarkdownSerializerState.prototype.repeat = function (str, n) {
        var out = "";
        for (var i = 0; i < n; i++)
            out += str;
        return out;
    };
    // : (Mark, bool, string?) → string
    // Get the markdown string for a given opening or closing mark.
    MarkdownSerializerState.prototype.markString = function (mark, open, parent, index) {
        var info = this.marks[mark.type.name];
        var value = open ? info.open : info.close;
        return typeof value === "string" ? value : value(this, mark, parent, index);
    };
    // :: (string) → { leading: ?string, trailing: ?string }
    // Get leading and trailing whitespace from a string. Values of
    // leading or trailing property of the return object will be undefined
    // if there is no match.
    MarkdownSerializerState.prototype.getEnclosingWhitespace = function (text) {
        return {
            leading: (text.match(/^(\s+)/) || [])[0],
            trailing: (text.match(/(\s+)$/) || [])[0],
        };
    };
    return MarkdownSerializerState;
}());
export { MarkdownSerializerState };
