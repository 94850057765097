import React from "react";
import {Input, Form} from "antd";
import {MATH_MAX_CALL_REGEX} from "@reside/ui";

import {
  useSetFocusedNodePropertyValue,
  useFocusedNode,
} from "../../model/editor";
import {isCurrencyNode} from "../../utils";

export const ComputationAttribute = () => {
  const node = useFocusedNode();
  const setComputation = useSetFocusedNodePropertyValue(
    "reference.computation",
  );

  if (!node) {
    return null;
  }

  const validate = (rule: any, value: string, callback: any) => {
    if (value) {
      // we use substitutions to replace complex expression and easily define validation regex
      const expressionToTest = value
        .replace(/\[\[\w+\.\w+\]\]/gi, "3")
        .replace(/new Date\(\S+\)/gi, "1")
        .replace(/Math.\w+/gi, "2+")
        .replace(/\?/gi, "-")
        .replace(/'[0-9-]*'/gi, "0")
        .replace(/===/gi, "-")
        .replace(/!==/gi, "-");

      if (!MATH_MAX_CALL_REGEX.test(expressionToTest)) {
        callback("Invalid format");
      }
    }
  };

  return isCurrencyNode(node) ? (
    <Form.Item
      label="Computation"
      name="computation"
      rules={[
        {
          validator: validate,
        },
      ]}
    >
      <Input
        onChange={event => {
          event.target.value === ""
            ? setComputation(undefined)
            : setComputation(event.target.value);
        }}
      />
    </Form.Item>
  ) : (
    <>
      Attribute "computation" can be used only with FORM_CONTROL-CURRENCY type
    </>
  );
};
