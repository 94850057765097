import React from "react";
import { Field } from "formik";
import { format as formatDate } from "date-fns-tz";
import { DATE_FORMAT } from "../../date-picker/date";
import { FieldError } from "../atoms/field-error";
import { Signature } from "../atoms/signature";
export var SignatureField = function (_a) {
    var name = _a.name, signDateRef = _a.signDateRef, disabled = _a.disabled, label = _a.label, onChange = _a.onChange, onError = _a.onError, transformUrl = _a.transformUrl;
    return (React.createElement(Field, { name: name }, function (_a) {
        var form = _a.form, field = _a.field;
        return (React.createElement(React.Fragment, null,
            React.createElement(Signature, { transformUrl: transformUrl, value: field.value, disabled: disabled, onChange: function (value) {
                    if (onChange) {
                        return onChange(value, form);
                    }
                    form.setFieldValue(name, value);
                    signDateRef &&
                        form.setFieldValue(signDateRef, formatDate(new Date(), DATE_FORMAT.ISO_DATE));
                }, onError: onError, label: label, testId: field.name }),
            React.createElement(FieldError, { name: name })));
    }));
};
SignatureField.defaultProps = {
    transformUrl: function (url) { return url; },
};
