import React, {memo} from "react";
import {getFieldName, NodeType, QuestionType} from "@reside/forms";
import {RecoilState} from "recoil";
import {STATES} from "@reside/ui";
import {TIME_ZONES} from "@reside/ui/dist/form-blocks/block-form-control";

import {FormControlSourceNode, TemplateNodes} from "../model/schemaTypes";
import {EditableCard} from "./editable-card";
import {EditableCollapsible} from "./editable-collapsible";
import {EditableCompletionChecklist} from "./editable-completion-checklist";
import {EditableContinueButton} from "./editable-continue-button";
import {EditableFragmentComponent} from "./editable-fragment-component";
import {EditableImage} from "./editable-image";
import {EditableParagraph} from "./editable-paragraph";
import {EditableSubtitle} from "./editable-subtitle";
import {EditableTitle} from "./editable-title";
import {EditableSSNField} from "./editable-ssn-field";
import {EditableTextField} from "./editable-text-field";
import {EditableTextareaField} from "./editable-textarea-field";
import {EditableCurrencyField} from "./editable-currency-field";
import {EditableDateField} from "./editable-date-field";
import {EditableSelectField} from "./editable-select-field";
import {EditableSignatureField} from "./editable-signature-field";
import {EditableImageField} from "./editable-image-field";
import {EditableFileField} from "./editable-file-field";
import {EditableCheckboxGroupField} from "./editable-checkbox-group-field";
import {EditableRadioGroupField} from "./editable-radio-group-field";
import {EditableColumn} from "./editable-column";
import {EditableList} from "./editable-list";
import {EditableTableOfContent} from "./editable-table-of-content";
import {EditableFieldArray} from "./editable-field-array";

type Props = Readonly<{
  index: number;
  atom: RecoilState<TemplateNodes>;
  node: TemplateNodes;
  isArrayFieldScoped: boolean;
  isFocused?: boolean;
}>;

export const NodeByType = memo(
  ({index, atom, node, isFocused, isArrayFieldScoped}: Props) => {
    switch (node.type) {
      // Note: Keep the types sorted alphabetically
      case NodeType.CARD:
        return (
          <EditableCard
            atom={atom}
            index={index}
            node={node}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        );
      case NodeType.COLLAPSIBLE:
        return (
          <EditableCollapsible
            atom={atom}
            index={index}
            node={node}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        );
      case NodeType.COMPLETION_CHECKLIST:
        return <EditableCompletionChecklist atom={atom} node={node} />;
      case NodeType.CONTINUE_BUTTON:
        return <EditableContinueButton atom={atom} node={node} />;
      case NodeType.COLUMN:
        return (
          <EditableColumn
            atom={atom}
            node={node}
            index={index}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        );
      case NodeType.FORM_CONTROL:
        return (
          <FormControlNode
            atom={atom}
            index={index}
            node={node}
            isFocused={isFocused}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        );
      case NodeType.FRAGMENT:
        return (
          <EditableFragmentComponent
            atom={atom}
            index={index}
            node={node}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        );
      case NodeType.IMAGE:
        return <EditableImage atom={atom} node={node} />;
      case NodeType.LIST:
        return (
          <EditableList
            atom={atom}
            node={node as any}
            isFocused={isFocused}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        );
      case NodeType.PARAGRAPH:
        return (
          <EditableParagraph atom={atom} node={node} isFocused={isFocused} />
        );
      // case NodeType.MANDATORY_VIDEO:
      //   return <EditableVideo  atom={atom} index={index} node={node}  />;
      case NodeType.SUBTITLE:
        return (
          <EditableSubtitle atom={atom} node={node} isFocused={isFocused} />
        );
      case NodeType.TABLE_OF_CONTENT:
        return <EditableTableOfContent />;
      case NodeType.TITLE:
        return <EditableTitle atom={atom} node={node} isFocused={isFocused} />;
      case NodeType.FIELD_ARRAY:
        return (
          <EditableFieldArray atom={atom} node={node as any} index={index} />
        );
      default:
        return <>TODO ${node.type}</>;
    }
  },
);

const FormControlNode = ({
  node,
  atom,
  index,
  isFocused,
  isArrayFieldScoped,
}: {
  index: number;
  atom: RecoilState<TemplateNodes>;
  node: FormControlSourceNode;
  isFocused?: boolean;
  isArrayFieldScoped: boolean;
}) => {
  const {reference, keyboardType, format} = node;

  const fieldProps = {
    id: node.id,
    name: getFieldName(reference),
    label: reference.translationKey!,
    placeholder: reference.translationKey!,
    disabled: node.disabled,
  };

  switch (node.reference.type) {
    case QuestionType.TEXT: {
      if (format === "ssn") {
        return <EditableSSNField {...fieldProps} atom={atom} />;
      }

      return (
        <EditableTextField
          {...fieldProps}
          format={format}
          type={keyboardType}
          atom={atom}
        />
      );
    }
    case QuestionType.TEXTAREA:
      return <EditableTextareaField {...fieldProps} atom={atom} />;
    case QuestionType.CURRENCY:
      return (
        <EditableCurrencyField
          {...fieldProps}
          currency={reference.currency}
          atom={atom}
        />
      );
    case QuestionType.CHECKBOX:
      return (
        <EditableCheckboxGroupField
          {...fieldProps}
          atom={atom}
          node={node}
          index={index}
          isFocused={isFocused}
          isArrayFieldScoped={isArrayFieldScoped}
        />
      );
    case QuestionType.DATE:
      return (
        <EditableDateField
          {...fieldProps}
          range={{before: [100, "year"], after: [10, "year"]}}
          atom={atom}
        />
      );
    case QuestionType.RADIO:
      return (
        <EditableRadioGroupField
          {...fieldProps}
          atom={atom}
          node={node}
          isFocused={isFocused}
          isArrayFieldScoped={isArrayFieldScoped}
        />
      );
    case QuestionType.SELECT:
      return (
        <EditableSelectField
          {...fieldProps}
          options={reference.choices ?? ([] as any)}
          getOptionLabel={option => option.translationKey}
          atom={atom}
          node={node}
          customizableChoices
        />
      );
    case QuestionType.STATE_SELECT:
      return (
        <EditableSelectField
          {...fieldProps}
          options={STATES}
          getOptionValue={option => option.id}
          atom={atom}
          node={node}
        />
      );
    case QuestionType.TIMEZONE_SELECT:
      return (
        <EditableSelectField
          {...fieldProps}
          options={TIME_ZONES}
          atom={atom}
          node={node}
        />
      );
    case QuestionType.SIGNATURE:
      return (
        <EditableSignatureField
          id={node.id}
          disabled={node.disabled!}
          atom={atom}
        />
      );
    case QuestionType.IMAGE:
      return <EditableImageField {...fieldProps} atom={atom} />;
    case QuestionType.FILE:
      return (
        <EditableFileField
          {...fieldProps}
          acceptedMediaTypes={reference.acceptedMediaTypes}
          atom={atom}
        />
      );
    default:
      return <>TODO ${reference.type}</>;
  }
};
