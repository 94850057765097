import React, {useCallback} from "react";
import {Radio} from "antd";
import {useRecoilValue} from "recoil";
import {styled} from "@reside/ui";
import {RadioChangeEvent} from "antd/lib/radio";

import {
  templateTypeAtom,
  templateNameAtom,
  useSwitchTemplate,
} from "../../model/template";
import {getTemplateTypeName, templateTypesList} from "../../constants";

export const TemplateTypeSwitch = () => {
  const templateName = useRecoilValue(templateNameAtom);
  const templateType = useRecoilValue(templateTypeAtom);
  const switchTemplate = useSwitchTemplate();

  const handleTypeChange = useCallback(
    async (event: RadioChangeEvent) => {
      const {value: templateType} = event.target;

      if (templateName) {
        switchTemplate(templateName, templateType);
      }
    },
    [switchTemplate, templateName],
  );

  return (
    <>
      <StyledRadioButtonGroup
        buttonStyle="solid"
        value={templateType}
        onChange={handleTypeChange}
      >
        {templateTypesList.map(templateType => (
          <StyledRadioButton key={templateType} value={templateType}>
            {getTemplateTypeName(templateType)}
          </StyledRadioButton>
        ))}
      </StyledRadioButtonGroup>
    </>
  );
};

const StyledRadioButtonGroup = styled(Radio.Group)`
  display: flex;
`;

const StyledRadioButton = styled(Radio.Button)`
  height: auto;
  line-height: unset;
  width: 70px;
  padding: 0 10px;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
