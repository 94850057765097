import React from "react";
import {Tooltip} from "antd";
import {FunctionOutlined} from "@ant-design/icons";
import {styled} from "@reside/ui";

import {SourcePdfNode} from "../../../../model/schemaTypes";
import {AtomProp} from "../../../../model/editor";
import {ActionButton} from "./ActionButton";
import {usePdfMapperModalContext} from "../pdf-mapper-modal/PdfMapperModalContext";

export const EditPdfMappersButton = ({
  node,
  atom,
}: {node: SourcePdfNode} & AtomProp<SourcePdfNode>) => {
  const {setEditedPdfAtom} = usePdfMapperModalContext();

  return (
    <Tooltip title={node.mappers ? "Edit mappers" : "Add mappers"}>
      <EditMappersButton
        shape="round"
        icon={<FunctionOutlined />}
        onClick={event => {
          event.stopPropagation();
          setEditedPdfAtom(atom);
        }}
      >
        {node.mappers ? "Edit" : "Add"}
      </EditMappersButton>
    </Tooltip>
  );
};

const EditMappersButton = styled(ActionButton)`
  right: 90px;
`;
