import React from "react";
import { ThemeProvider as EmotionThemeProvider, useTheme, } from "@emotion/react";
import { theme } from "./theme";
export var ThemeConsumer = function (_a) {
    var children = _a.children;
    return children(useTheme());
};
export var ThemeProvider = function (_a) {
    var children = _a.children;
    return (React.createElement(EmotionThemeProvider, { theme: theme }, children));
};
