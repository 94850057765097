import React, {forwardRef} from "react";
import {Button, ButtonProps} from "antd";
import {styled} from "@reside/ui";

/**
 * Forward ref is useless - just prevents warning from tooltip passing ref...
 */
export const ActionButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton shape="circle" {...props} />
));

export const StyledButton = styled(Button)`
  position: absolute;
  display: none;
  top: 4px;
  right: 10px;
  border: none;
`;
