import { FastField, Field, getIn } from "formik";
import { flatten } from "@reside/forms/dist/flatten";
import * as immutable from "object-path-immutable";
export var getFormFieldComponent = function (name, options) {
    if (!Array.isArray(options)) {
        return isFastFieldCapable(name) ? FastField : Field;
    }
    return isFastFieldCapable(name) && !options.some(function (option) { return option.children; })
        ? FastField
        : Field;
};
/**
 * Fast field can not be used when field is dependant on other field values
 */
export var isFastFieldCapable = function (name) { return !isArrayField(name); };
var isArrayField = function (name) { return name.includes("."); };
export var isInvalid = function (fieldName, form) {
    return getFormFieldError(fieldName, form) && isFormFieldTouched(fieldName, form);
};
export var getFormFieldError = function (fieldName, form) {
    return immutable.get(form.errors, fieldName) ||
        getIn(form.errors, fieldName) ||
        form.errors[fieldName];
};
var isFormFieldTouched = function (fieldName, form) {
    // nested fields that are visible only if json logic is true are not registered and formik can not properly mark them as touched
    return form.submitCount > 0 ||
        immutable.get(form.touched, fieldName) ||
        getIn(form.touched, fieldName) ||
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        flatten(form.touched)[fieldName];
};
