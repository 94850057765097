var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { findParentNode, findSelectedNodeOfType } from "prosemirror-utils";
var isNodeActive = function (type, attrs) {
    if (attrs === void 0) { attrs = {}; }
    return function (state) {
        var node = findSelectedNodeOfType(type)(state.selection) ||
            findParentNode(function (node) { return node.type === type; })(state.selection);
        if (!Object.keys(attrs).length || !node) {
            return !!node;
        }
        return node.node.hasMarkup(type, __assign(__assign({}, node.node.attrs), attrs));
    };
};
export default isNodeActive;
