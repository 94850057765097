import React, {FunctionComponent} from "react";
import {Skeleton, message, Spin} from "antd";

import {useLoginQuery, usePersistedCredentials} from "../../model/auth";
import {PageLayout} from "../../atoms/page-layout";
import {SlideCard} from "../../atoms/slide-card";
import {Content} from "../../atoms/content";

export const ReloginPage: FunctionComponent = ({children}) => {
  const [credentials, , resetCredentials] = usePersistedCredentials();

  const {isFetching} = useLoginQuery({
    key: "relogin",
    credentials,
    onError: () => {
      message.error("Failed to relogin. Please log in again.");
      resetCredentials();
    },
  });

  return isFetching ? (
    <PageLayout
      leftSider={
        <Content>
          <Skeleton active />
        </Content>
      }
    >
      <SlideCard>
        <Skeleton active />
        <div style={{textAlign: "center", padding: 40}}>
          <Spin size="large" />
        </div>
        <Skeleton active />
      </SlideCard>
    </PageLayout>
  ) : (
    <>{children}</>
  );
};

export const ReloginPageLayout = () => (
  <PageLayout
    leftSider={
      <Content>
        <Skeleton active />
      </Content>
    }
  >
    <SlideCard>
      <Skeleton active />
      <div style={{textAlign: "center", padding: 40}}>
        <Spin size="large" />
      </div>
      <Skeleton active />
    </SlideCard>
  </PageLayout>
);
