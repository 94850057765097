import React from "react";
import {v4} from "uuid";
import * as immutable from "object-path-immutable";
import {Form} from "antd";
import {RecoilState, useRecoilValue} from "recoil";
import {red} from "@ant-design/colors";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {ADAPTABLE_TEXT_CLASSNAME, List, Paragraph, styled} from "@reside/ui";
import {ListNode} from "@reside/forms";

import {atomizeNodes} from "../../model/template";
import {Override, TemplateNodes, SourceNode} from "../../model/schemaTypes";
import {
  AtomizedBranchNode,
  AtomProp,
  useFocusedNode,
  useSetAtomPropertyValue,
} from "../../model/editor";
import {DroppableField} from "../droppable-field";
import {ChildrenDroppable} from "../children-droppable";

import {ListStyleTypeAttribute} from "../list-style-type-attribute";
import {useDragAndDrop} from "../../hooks/useDragAndDrop";
import {clearWhiteSpace} from "../../utils";
import {useRemoveValidationErrors} from "../../model/validation";

export const EditableList = ({
  isFocused,
  node,
  atom,
  isArrayFieldScoped,
}: {isFocused?: boolean; isArrayFieldScoped: boolean} & AtomProp & {
    node: Override<ListNode, {items: RecoilState<TemplateNodes>[]}>;
  }) => {
  const {listStyleType, items} = node;
  const setItems = useSetAtomPropertyValue(atom, "items", {replace: true});

  const addListItem = () => {
    setItems([
      ...(items ?? []),
      ...atomizeNodes([
        {
          id: v4(),
          translationKey: "",
        } as any,
      ]),
    ]);
  };

  const handleDelete = (index: number) =>
    setItems(immutable.del(items, String(index)));

  return (
    <>
      <StyledList
        Label={Label}
        listStyleType={listStyleType}
        items={items.map((atom, index) => {
          return (
            <ItemChildren
              key={atom.key}
              atom={atom}
              isFocused={isFocused}
              onDelete={() => handleDelete(index)}
              isArrayFieldScoped={isArrayFieldScoped}
            />
          );
        })}
      />
      <PlusOutlined style={{color: red.primary}} onClick={addListItem} />
    </>
  );
};

const StyledList = styled(List)`
  li {
    padding: 0;
  }
`;

const Label = styled((props: any) => (
  <List.defaultProps.Label as="div" {...props} />
))`
  display: inline-table;
  width: 95%;
`;

const ItemChildren = ({
  atom: parentAtom,
  isFocused,
  onDelete,
  isArrayFieldScoped,
}: {
  isFocused?: boolean;
  onDelete: () => void;
  isArrayFieldScoped: boolean;
} & AtomProp) => {
  const item = useRecoilValue(parentAtom) as {
    translationKey: string;
    id: string;
  } & AtomizedBranchNode;
  const setLabel = useSetAtomPropertyValue(parentAtom, "translationKey");

  const {dropRef: dropChildrenRef} = useDragAndDrop({
    parentAtom,
    index: 0,
  });
  const removeValidations = useRemoveValidationErrors(item as any);

  return (
    <Wrapper>
      <IconsWrapper>
        <DeleteOutlined
          style={{color: red.primary}}
          onClick={() => {
            onDelete();
            removeValidations();
          }}
        />
      </IconsWrapper>

      <ItemLabel value={item.translationKey} onChange={setLabel} />

      {item.children?.length ? (
        item.children.map((atom, index) => (
          <DroppableField
            index={index}
            key={atom.key}
            atom={atom}
            parentAtom={parentAtom}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        ))
      ) : isFocused ? (
        <div ref={dropChildrenRef}>
          <ChildrenDroppable help="The items will be visible once you drop here any items." />
        </div>
      ) : null}
    </Wrapper>
  );
};

const ItemLabel = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) => (
  <InlineParagraph
    className={ADAPTABLE_TEXT_CLASSNAME}
    contentEditable
    onBlur={event => {
      const value = clearWhiteSpace(event.currentTarget.textContent ?? "");
      onChange(value);
      event.currentTarget.textContent = value;
    }}
    placeholder="Enter text..."
    onClick={event => {
      event.stopPropagation();
    }}
  >
    {value}
  </InlineParagraph>
);

const InlineParagraph = styled(Paragraph)`
  width: 100%;
  display: block;

  &[placeholder]:empty:before {
    content: attr(placeholder);
    color: #555;
    cursor: text;
    pointer-events: none;
    opacity: 0.4;
    filter: saturate(25%);
  }

  &:focus {
    cursor: text;
    outline: none;
  }
`;

const Wrapper = styled.span`
  display: block;
`;

const IconsWrapper = styled.span`
  position: absolute;
  left: 5px;
`;

/**
 * FormControl type-specific form to adjust attributes.
 */
export const ListFieldForm = () => {
  const node = useFocusedNode() as SourceNode<ListNode>;

  return (
    <Form
      key={node.id}
      initialValues={{
        listStyleType: node?.listStyleType ?? "",
      }}
    >
      <ListStyleTypeAttribute />
    </Form>
  );
};
