"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = {
    name: "roomrates",
    isImplicit: true,
    errorMessage: "None of the above fields can be empty.",
    validate: function (value) {
        var errors = [];
        value.length > 0 &&
            value.forEach(function (object) {
                Object.values(object)
                    .slice(0, 2)
                    .forEach(function (property) {
                    (property && property !== "$") || errors.push(property);
                });
            });
        return errors.length === 0;
    },
};
