var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Component } from "react";
import { css } from "@emotion/css";
import { Field } from "formik";
import { MinimalPlayer } from "../../video";
import { Caption } from "./Caption";
import { LanguageControls } from "./LanguageControls";
var BlockMandatoryVideo = /** @class */ (function (_super) {
    __extends(BlockMandatoryVideo, _super);
    function BlockMandatoryVideo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            activeMutationIndex: 0,
            videoElKey: Math.random().toString(),
        };
        _this.getActiveLanguageMutation = function () {
            return _this.props.children[_this.state.activeMutationIndex];
        };
        _this.getVideoElementKey = function (mutation) {
            return _this.state.videoElKey + "-" + mutation.language.code;
        };
        _this.handleMutationChange = function (activeMutationIndex) {
            return _this.setState({ activeMutationIndex: activeMutationIndex });
        };
        _this.resetVideoPlayer = function () {
            return _this.setState({ videoElKey: Math.random().toString() });
        };
        _this.getSource = function (lang, asset) {
            var key = {
                en: {
                    poster: "arbitrationPosterEn",
                    video: "arbitrationVideoEn",
                },
                es: {
                    poster: "arbitrationPosterEs",
                    video: "arbitrationVideoEs",
                },
            }[lang][asset];
            return _this.props.sources[key];
        };
        return _this;
    }
    BlockMandatoryVideo.prototype.createFormAnswerSetter = function (formik) {
        var _this = this;
        return function () {
            formik.form.setFieldTouched(formik.field.name, true);
            formik.form.setFieldValue(formik.field.name, "yes");
            _this.resetVideoPlayer();
        };
    };
    BlockMandatoryVideo.prototype.render = function () {
        var _this = this;
        var _a = this.props, reference = _a.reference, children = _a.children;
        var activeMutation = this.getActiveLanguageMutation();
        if (!activeMutation) {
            return null;
        }
        var width = Math.min(window.innerWidth, activeMutation.video.width);
        var height = Math.min((activeMutation.video.height / activeMutation.video.width) * width, activeMutation.video.height);
        return (React.createElement("div", { className: cls.blockContainer, style: { width: width }, "data-test-id": reference.id },
            React.createElement(LanguageControls, { activeMutationIndex: this.state.activeMutationIndex, mutations: children, onChange: this.handleMutationChange }),
            React.createElement("div", { className: cls.videoFrame },
                React.createElement(Field, { name: reference.id }, function (formik) { return (React.createElement(MinimalPlayer, { key: _this.getVideoElementKey(activeMutation), controls: false, playsInline: true, source: _this.getSource(activeMutation.language.code, "video"), poster: _this.getSource(activeMutation.language.code, "poster"), width: width, height: height, onEnd: _this.createFormAnswerSetter(formik) })); })),
            React.createElement(Caption, { fieldName: reference.id, translationKey: activeMutation.caption.translationKey })));
    };
    return BlockMandatoryVideo;
}(Component));
export { BlockMandatoryVideo };
var cls = {
    blockContainer: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    box-sizing: content-box;\n  "], ["\n    box-sizing: content-box;\n  "]))),
    videoFrame: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    background: #ff0000;\n    text-align: center;\n  "], ["\n    background: #ff0000;\n    text-align: center;\n  "]))),
};
var templateObject_1, templateObject_2;
