var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { wrappingInputRule } from "prosemirror-inputrules";
import toggleList from "../commands/toggleList";
import Node from "./Node";
var CheckboxList = /** @class */ (function (_super) {
    __extends(CheckboxList, _super);
    function CheckboxList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CheckboxList.prototype, "name", {
        get: function () {
            return "checkbox_list";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckboxList.prototype, "schema", {
        get: function () {
            var _this = this;
            return {
                group: "block",
                content: "checkbox_item+",
                toDOM: function () { return ["ul", { class: _this.name }, 0]; },
                parseDOM: [
                    {
                        tag: "[class=\"" + this.name + "\"]",
                    },
                ],
            };
        },
        enumerable: true,
        configurable: true
    });
    CheckboxList.prototype.keys = function (_a) {
        var type = _a.type, schema = _a.schema;
        return {
            "Shift-Ctrl-7": toggleList(type, schema.nodes.checkbox_item),
        };
    };
    CheckboxList.prototype.commands = function (_a) {
        var type = _a.type, schema = _a.schema;
        return function () { return toggleList(type, schema.nodes.checkbox_item); };
    };
    CheckboxList.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [wrappingInputRule(/^-?\s*(\[ \])\s$/i, type)];
    };
    CheckboxList.prototype.toMarkdown = function (state, node) {
        state.renderList(node, "  ", function () { return "- "; });
    };
    CheckboxList.prototype.parseMarkdown = function () {
        return { block: "checkbox_list" };
    };
    return CheckboxList;
}(Node));
export default CheckboxList;
