var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Plugin, PluginKey } from "prosemirror-state";
import Extension from "../lib/Extension";
var TrailingNode = /** @class */ (function (_super) {
    __extends(TrailingNode, _super);
    function TrailingNode() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TrailingNode.prototype, "name", {
        get: function () {
            return "trailing_node";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrailingNode.prototype, "defaultOptions", {
        get: function () {
            return {
                node: "paragraph",
                notAfter: ["paragraph", "heading"],
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TrailingNode.prototype, "plugins", {
        get: function () {
            var _this = this;
            var _a;
            var plugin = new PluginKey(this.name);
            if (!((_a = this.editor) === null || _a === void 0 ? void 0 : _a.schema)) {
                return [];
            }
            var disabledNodes = Object.entries(this.editor.schema.nodes)
                .map(function (_a) {
                var value = _a[1];
                return value;
            })
                .filter(function (node) { return _this.options.notAfter.includes(node.name); });
            return [
                new Plugin({
                    key: plugin,
                    view: function () { return ({
                        update: function (view) {
                            var state = view.state;
                            var insertNodeAtEnd = plugin.getState(state);
                            if (!insertNodeAtEnd) {
                                return;
                            }
                            var doc = state.doc, schema = state.schema, tr = state.tr;
                            var type = schema.nodes[_this.options.node];
                            var transaction = tr.insert(doc.content.size, type.create());
                            view.dispatch(transaction);
                        },
                    }); },
                    state: {
                        init: function (_, state) {
                            var lastNode = state.tr.doc.lastChild;
                            return lastNode ? !disabledNodes.includes(lastNode.type) : false;
                        },
                        apply: function (tr, value) {
                            if (!tr.docChanged) {
                                return value;
                            }
                            var lastNode = tr.doc.lastChild;
                            return lastNode ? !disabledNodes.includes(lastNode.type) : false;
                        },
                    },
                }),
            ];
        },
        enumerable: true,
        configurable: true
    });
    return TrailingNode;
}(Extension));
export default TrailingNode;
