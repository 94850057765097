import {selector} from "recoil";
import {AnswerType} from "@reside/markdown-editor";

import {getTemplateTypeName} from "../../constants";
import {TreeDataItem} from "../../atoms/markdown-editor";
import {
  ExtendedReference,
  TreeSelectFields,
} from "../../atoms/query-builder/config";
import {FormControlSourceNode, TemplateNodes} from "../schemaTypes";
import {
  currentTemplateTreeSelectFieldsSelector,
  priorFieldArraysSelector,
} from "../answers";
import {answerIdToTitleCase} from "../answers/answersUtils";
import {isCurrencyNode, isTextOrTextAreaNode} from "../../utils";
import {QuestionType} from "@reside/forms";

type Answers = Record<string, AnswerType>;

/**
 * We create special value attributes for the tree select, because the search works on the value field.
 * We include the human readable title for search and the id to distinct multiple fields of the same title!
 */
const getSearchableAnswerValue = ({id, title}: {id: string; title?: string}) =>
  `${title}-${id}`;

const getReferenceTitle = (reference: ExtendedReference, index: number) =>
  answerIdToTitleCase(reference.id!) ||
  reference.translationKey ||
  `${reference.type} #${index + 1}`;

const getNodeTitle = (node: any, index: number) =>
  node.reference
    ? getReferenceTitle(node.reference, index)
    : node.translationKey ||
      node.title?.translationKey ||
      `${node.type} #${index + 1}`;

const getNodeId = (node: FormControlSourceNode) =>
  node.reference?.id || node.id;

export const getAnswersAndTreeDataForNode = ({
  node,
  answers,
  fieldArrays = {},
}: {
  node: any;
  answers: Answers;
  fieldArrays?: Record<string, ExtendedReference>;
}): {
  answers: Answers;
  treeData: TreeDataItem[];
} => {
  /**
   * Prevent duplicates of the same field.
   */
  if (answers[getNodeId(node)]) {
    return {answers, treeData: []};
  }

  if (node.reference?.query) {
    const {path} = node.reference.query;

    const fieldArray = fieldArrays[path];

    if (fieldArray) {
      return Object.values(fieldArray.children || {})
        .filter(
          ({type}) =>
            type === QuestionType.TEXT ||
            type === QuestionType.TEXTAREA ||
            type === QuestionType.CURRENCY,
        )
        .reduce(
          (
            {
              answers,
              treeData,
            }: {
              answers: Answers;
              treeData: TreeDataItem[];
            },
            reference,
            index,
          ) => {
            const id = `answers.${node.reference.id}.${reference.id}`;

            const value = getSearchableAnswerValue({
              title: answerIdToTitleCase(reference.id!) || id,
              id: `${node.reference.id}-${index}`,
            });

            return {
              answers: {
                ...answers,
                [id]: {
                  id,
                  type: reference.type,
                  value,
                  label: `${answerIdToTitleCase(node.reference.id!)} / ${
                    answerIdToTitleCase(reference.id!) ||
                    reference.translationKey
                  }`,
                },
              },
              treeData: [
                ...treeData,
                {
                  id,
                  title: getReferenceTitle(reference, index),
                  value,
                  selectable: true,
                },
              ],
            };
          },
          {answers, treeData: []},
        );
    }

    return {answers, treeData: []};
  }

  return [node.children, node.items, node.reference?.choices]
    .filter(Boolean)
    .flatMap(children => children)
    .reduce(
      (
        {
          answers,
          treeData,
        }: {
          answers: Answers;
          treeData: TreeDataItem[];
        },
        currentNode,
        index,
      ) => {
        if (answers[getNodeId(currentNode)]) {
          return {
            answers,
            treeData,
          };
        }

        const {
          answers: nodeChildrenAnswers,
          treeData: nodeChildrenTreeData,
        } = getAnswersAndTreeDataForNode({
          node: currentNode,
          answers,
          fieldArrays,
        });

        // TODO: this should become "isFormField" as Date, Select and other types will be supported
        const isTextualNode =
          isTextOrTextAreaNode(currentNode) || isCurrencyNode(currentNode);

        const nodeId = getNodeId(currentNode);
        const id = `answers.${nodeId}`;
        const title = getNodeTitle(currentNode, index);

        const hasChildren = nodeChildrenTreeData.length > 0;

        if (hasChildren || isTextualNode) {
          if (!currentNode.reference) {
            return {
              answers: nodeChildrenAnswers,
              treeData: [
                ...treeData,
                /**
                 * This is an unselectable branch node.
                 */
                {
                  id: currentNode.id,
                  title,
                  value: getSearchableAnswerValue({
                    id: currentNode.id,
                    title,
                  }),
                  selectable: false,
                  children: nodeChildrenTreeData,
                },
              ],
            };
          }

          /**
           * The value is link between answer token in markdown and treeData item in tree select.
           * @important
           */
          const value = getSearchableAnswerValue({
            title: answerIdToTitleCase(currentNode?.reference.id) || id,
            id: currentNode.id, // Note: do not mistake it for reference id.
          });

          return {
            answers: {
              ...nodeChildrenAnswers,
              [id]: {
                type: currentNode.reference.type,
                id,
                value,
                label:
                  answerIdToTitleCase(currentNode?.reference.id) ||
                  currentNode.reference.translationKey,
              },
            },
            treeData: [
              ...treeData,
              /**
               * This is answer item.
               */
              {
                id,
                title,
                value,
                selectable: isTextualNode,
                ...(hasChildren ? {children: nodeChildrenTreeData} : {}),
              },
            ],
          };
        }

        return {
          answers,
          treeData,
        };
      },
      {answers, treeData: []},
    );
};

export const getAnswersAndTreeDataPerTemplate = ({
  nodes,
  answers,
  fieldArrays = {},
}: {
  nodes: ReadonlyArray<TemplateNodes>;
  answers: Answers;
  fieldArrays?: Record<string, ExtendedReference>;
}) =>
  nodes.reduce(
    (
      {
        answers,
        treeData,
      }: {
        answers: Answers;
        treeData: TreeDataItem[];
      },
      node: TemplateNodes,
      index,
    ) => {
      const {id} = node;
      const title = getNodeTitle(node, index);
      const {
        answers: childrenAnswers,
        treeData: childrenTreeData,
      } = getAnswersAndTreeDataForNode({
        node,
        answers,
        fieldArrays,
      });

      return {
        treeData: [
          ...treeData,
          {
            id,
            title,
            value: getSearchableAnswerValue({id, title}),
            selectable: false,
            children: childrenTreeData,
          },
        ],
        answers: {...answers, ...childrenAnswers},
      };
    },
    {answers, treeData: []},
  );

export const getAnswersAndTreeData = (
  treeSelectFields: TreeSelectFields,
  fieldArrays: Record<string, ExtendedReference> = {},
) =>
  treeSelectFields.reduce(
    (
      {
        answers,
        treeData,
      }: {
        answers: Answers;
        treeData: TreeDataItem[];
      },
      {templateType, data},
    ) => {
      const id = templateType;
      const title = getTemplateTypeName(templateType);
      const {
        treeData: childrenTreeData,
        answers: childrenAnswers,
      } = getAnswersAndTreeDataPerTemplate({
        nodes: data,
        answers,
        fieldArrays,
      });
      const td = {
        treeData: [
          ...treeData,
          {
            id,
            title,
            value: getSearchableAnswerValue({id, title}),
            selectable: false,
            children: childrenTreeData,
          },
        ]}

      return {
        treeData: filterSelectable(td.treeData),
        answers: {...answers, ...childrenAnswers},
      };
    },
    {answers: {}, treeData: []},
  );


export const filterSelectable = (data: TreeDataItem[]): TreeDataItem[] => {
  return data.map(item => {
    if (item.children && item.children.length > 0) {
      const children: any = filterSelectable(item.children)
      if (children.length > 0) {
        return {...item, children}
      } else {
        return null
      }
    } else if (item.selectable) {
      return item
    } else {
      return null
    }
  })
    .filter(value => value !== null)
    .map(value => value as TreeDataItem)
}

export const answerTreeDataSelector = selector<{
  answers: Answers;
  treeData: TreeDataItem[];
}>({
  key: "answerTreeDataSelector",
  get: ({get}) => {
    const treeSelectFields = get(currentTemplateTreeSelectFieldsSelector);

    const fieldArrays = get(priorFieldArraysSelector);

    return getAnswersAndTreeData(treeSelectFields, fieldArrays);
  },
});
