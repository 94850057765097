import React from "react";
import {Button, Form, Input} from "antd";
import {useRecoilState, useSetRecoilState} from "recoil";

import {createNewTemplate, readTemplate} from "../../services/FileService";
import {normalizeTemplateName} from "../../utils";
import {
  availableTemplatesAtom,
  isTemplateDirtyAtom,
  persistedTemplateSelector,
  templateNameAtom,
} from "../../model/template";
import {useSetPriorTemplates} from "../../model/answers";

type CreateTemplateFormValues = Readonly<{
  templateName: string;
}>;

export const CreateTemplateForm = () => {
  const [availableTemplates, setAvailableTemplates] = useRecoilState(
    availableTemplatesAtom,
  );
  const setTemplateName = useSetRecoilState(templateNameAtom);
  const setTemplatePersisted = useSetRecoilState(persistedTemplateSelector);
  const setTemplateDirty = useSetRecoilState(isTemplateDirtyAtom);
  const setPriorTemplates = useSetPriorTemplates();

  const onFinish = async ({templateName}: CreateTemplateFormValues) => {
    await createNewTemplate(templateName);

    setAvailableTemplates([...availableTemplates, templateName].sort());

    const template = await readTemplate(templateName);
    setTemplateName(templateName);
    setTemplatePersisted(template);
    setTemplateDirty(true);
    await setPriorTemplates(templateName);
  };

  const validator = async (rule: any, value: string) => {
    if (availableTemplates.includes(value)) {
      throw new Error("Template name already used, choose unique name");
    }
  };

  return (
    <Form<CreateTemplateFormValues> onFinish={onFinish}>
      <Form.Item
        name="templateName"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        label="Template name"
        rules={[
          {
            required: true,
            message: "Please enter the template name",
          },
          {
            validator,
          },
        ]}
        normalize={value => normalizeTemplateName(value || "")}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{offset: 8, span: 16}}>
        <Button type="primary" htmlType="submit">
          Create Template
        </Button>
      </Form.Item>
    </Form>
  );
};
