import {isString, startCase, camelCase} from "lodash";
import {v4} from "uuid";

import {
  FieldArrayNode,
  GroupNodeType,
  NodeType,
  QuestionChoice,
  QuestionReference,
  QuestionType,
} from "@reside/forms";

import {
  FormControlSourceNode,
  SourceNodes,
  TemplateNodes,
  SourceSectionGroup,
  SourceSlide,
  SourceSection,
  SourceNode,
  SourceImport,
  GroupNode,
  SourcePdfNode,
} from "./model/schemaTypes";
import {blocks} from "./atoms/block-palette/constants";

export const isFormControl = (
  node: TemplateNodes,
): node is FormControlSourceNode => node.type === NodeType.FORM_CONTROL;

export const isFieldArray = (
  node: TemplateNodes,
): node is SourceNode<FieldArrayNode> => node.type === NodeType.FIELD_ARRAY;

export const isUploadField = (
  node: TemplateNodes,
): node is FormControlSourceNode =>
  isFormControl(node) &&
  (node.reference.type === QuestionType.FILE ||
    node.reference.type === QuestionType.IMAGE);

export const isFileField = (
  node: TemplateNodes,
): node is FormControlSourceNode =>
  isFormControl(node) && node.reference.type === QuestionType.FILE;

export const isDateField = (
  node: TemplateNodes,
): node is FormControlSourceNode =>
  isFormControl(node) && node.reference.type === QuestionType.DATE;

export const isSelectableField = (
  node: TemplateNodes,
): node is FormControlSourceNode =>
  isFormControl(node) &&
  (node.reference.type === QuestionType.SELECT ||
    node.reference.type === QuestionType.RADIO);

export const isCheckboxField = (
  node: TemplateNodes,
): node is FormControlSourceNode =>
  isFormControl(node) && node.reference.type === QuestionType.CHECKBOX;

export const isCurrencyNode = (
  node: TemplateNodes,
): node is FormControlSourceNode =>
  isFormControl(node) && node.reference.type === QuestionType.CURRENCY;

export const isTextOrTextAreaNode = (
  node: TemplateNodes,
): node is FormControlSourceNode =>
  isFormControl(node) &&
  (node.reference.type === QuestionType.TEXT ||
    node.reference.type === QuestionType.TEXTAREA);

export const isPdfNode = (node: any): node is SourcePdfNode =>
  node.type === "pdf";

export const isSlideNode = (node: TemplateNodes): node is SourceSlide =>
  node.type === GroupNodeType.SLIDE;

export const isSectionNode = (node: TemplateNodes): node is SourceSection =>
  node.type === GroupNodeType.SECTION;

export const isSectionGroupNode = (
  node: TemplateNodes,
): node is SourceSectionGroup => node.type === GroupNodeType.SECTION_GROUP;

export const isGroupNode = (node: TemplateNodes): node is GroupNode =>
  [
    GroupNodeType.SLIDE,
    GroupNodeType.SECTION,
    GroupNodeType.SECTION_GROUP,
  ].includes(node.type as any);

/**
 * Create rule parser which will pick the value of validation rule.
 * @param ruleName
 * @param transformValue - mapper to format the string
 */
export const getRuleValue = <V>(
  ruleName: string,
  transformValue: (value: string) => V,
) => (rules: ReadonlyArray<string | object> = []) => {
  const value = rules
    .filter(isString) // TODO: handle object rules
    .find(name => name.startsWith(ruleName))
    ?.split(":")
    .pop();

  if (!isString(value)) {
    throw new Error(`The rule '${ruleName}' is not present among the rules.`);
  }

  return transformValue(value);
};

export const updateRules = (
  rules: ReadonlyArray<string | object>,
  value: string,
  ruleExpression: string,
) => {
  // TODO: handle object rules
  const stringRules = rules.filter(isString);

  return value === ""
    ? stringRules.filter(rule => !rule.startsWith(ruleExpression))
    : stringRules.length === 0 ||
      !stringRules.find(rule => rule.startsWith(ruleExpression))
    ? [...rules, `${ruleExpression}:${value}`]
    : [
        ...stringRules.map(rule => {
          if (rule.startsWith(ruleExpression)) {
            return `${ruleExpression}:${value}`;
          }
          return rule;
        }),
      ];
};

export const moveItem = <T>(
  list: ReadonlyArray<T>,
  fromIndex: number,
  toIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);

  return result;
};

export const insert = <T>(
  list: ReadonlyArray<T>,
  index: number,
  ...elements: T[]
) => {
  const result = Array.from(list);
  result.splice(index, 0, ...elements);

  return result;
};

export const keyToPath = (key: string) =>
  key.split(".").map(x => parseInt(x, 10));

export const pathToKey = (path: ReadonlyArray<number>) => path.join(".");

export const createBlock = (index: number) => {
  const {create} = blocks[index];

  const node = create() as SourceNodes;

  const arg = (Array.isArray(node) ? node : [node]) as ReadonlyArray<
    SourceNodes
  >;

  return arg;
};

export const isCommonImportedComponent = (node: TemplateNodes) =>
  Boolean(node.importedFrom && node.importedFrom.inherit !== true);

export const createEmptySlide = (): SourceSlide => ({
  id: v4(),
  type: GroupNodeType.SLIDE,
  title: {
    translationKey: "New Slide",
  },
  ctaButton: {
    id: v4(),
    title: {
      translationKey: "Accept",
    },
  },
  children: [
    {
      id: v4(),
      type: NodeType.TITLE,
      translationKey: "Slide Title",
    },
  ],
});

export const createEmptySection = (): SourceSection => ({
  id: v4(),
  type: GroupNodeType.SECTION,
  title: {
    translationKey: "New Section",
  },
  children: [createEmptySlide()],
});

export const createEmptySectionGroup = (): SourceSectionGroup => ({
  id: v4(),
  type: GroupNodeType.SECTION_GROUP,
  title: {
    translationKey: "New Section Group",
  },
  children: [createEmptySection()],
});

export const createSourceImport = (filePath: string): SourceImport => ({
  type: "import",
  src: filePath,
});

export type SimpleTemplateNodes = ReadonlyArray<TemplateNodes> &
  ReadonlyArray<{
    children?: ReadonlyArray<TemplateNodes> & SimpleTemplateNodes;
    reference?: QuestionReference;
    choices?: ReadonlyArray<QuestionChoice>;
  }>;

export const normalizeTemplateName = (name: string) =>
  name.toUpperCase().replace(/ /g, "_");

export const clearWhiteSpace = (text: string) =>
  text.replace(/^\s+|\s+$/gm, "");

export const readEnvVariable = (name: string) => {
  const variable = process.env[`REACT_APP_${name}`];

  if (!variable) {
    throw new Error(`Missing ${name} env variable`);
  }

  return variable;
};

export const toTitleCase = (str: string) => startCase(camelCase(str));
