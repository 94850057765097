import React from "react";
import {Query, Builder, BuilderProps} from "react-awesome-query-builder";
import "react-awesome-query-builder/lib/css/styles.css";
import "react-awesome-query-builder/lib/css/compact_styles.css"; //optional, for more compact styles

import {styled} from "@reside/ui";
import {isDev} from "../../constants";
import {getConfig} from "./config";
import {useFocusedNodeTree} from "../../model/query-builder";
import {VisibilityContextForm} from "../visibility-context-form";
import {useCurrentTemplateTreeSelectFieldsSelector} from "../../model/answers";
import {DebugVisibilityJson} from "./DebugVisibilityJson";

const renderBuilder = (props: BuilderProps) => (
  <Wrapper>
    <Builder {...props} />
  </Wrapper>
);

export const QueryBuilder = () => {
  const currentTemplateTreeSelect = useCurrentTemplateTreeSelectFieldsSelector();
  const config = getConfig(currentTemplateTreeSelect);

  const {setTree, tree} = useFocusedNodeTree({
    config,
  });

  return (
    <QueryBuilderWrapper>
      <VisibilityContextForm />

      {tree.size > 2 ? (
        <span>
          This component will be visible when:{" "}
          {isDev && <DebugVisibilityJson />}
        </span>
      ) : null}
      <Query
        {...config}
        value={tree}
        onChange={setTree}
        renderBuilder={renderBuilder}
      />
    </QueryBuilderWrapper>
  );
};

const QueryBuilderWrapper = styled.div`
  background: #f0f2f5;
  border: 1px solid #a8b6ca;
  padding: 0 8px;
`;

const Wrapper = styled.div`
  background: #f0f2f5;
  padding: 5px 0;

  .group-or-rule-container {
    padding: 0;
  }

  .group-or-rule {
    border-radius: 0;
  }

  .group {
    background-color: transparent;
    border: 1px solid #a8b6ca;
    border-radius: 0;
  }

  & > .group-or-rule-container > .group:first-of-type {
    border: none;
  }

  .rule {
    background-color: transparent;
  }
`;
