export default function backspaceToParagraph(type) {
    //@ts-ignore
    return function (state, dispatch) {
        var _a = state.selection, $from = _a.$from, from = _a.from, to = _a.to, empty = _a.empty;
        // if the selection has anything in it then use standard delete behavior
        if (!empty)
            return null;
        // check we're in a matching node
        if ($from.parent.type !== type)
            return null;
        // check if we're at the beginning of the heading
        var $pos = state.doc.resolve(from - 1);
        if ($pos.parent === $from.parent)
            return null;
        // okay, replace it with a paragraph
        dispatch(state.tr
            //@ts-ignore
            .setBlockType(from, to, type.schema.nodes.paragraph)
            .scrollIntoView());
        return true;
    };
}
