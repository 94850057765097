import React from "react";

import {MinusCircleOutlined} from "@ant-design/icons";
import {useResetRecoilState} from "recoil";

import {focusedAtomAtom} from "../../model/editor";

export const UnfocusNodeButton = () => {
  const resetFocusedAtom = useResetRecoilState(focusedAtomAtom);

  return (
    <MinusCircleOutlined
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        resetFocusedAtom();
      }}
    />
  );
};
