import React from "react";
import {Button, Form, Input} from "antd";

import {Credentials} from "../../model/auth";

export const LoginForm = ({
  isLoading,
  onSubmit,
}: {
  isLoading: boolean;
  onSubmit: (credentials: Credentials) => void;
}) => (
  <Form<Credentials>
    onFinish={onSubmit}
    labelCol={{span: 8}}
    wrapperCol={{span: 8}}
  >
    <Form.Item
      name="password"
      label="Personal access token"
      rules={[
        {
          required: true,
          message: "Please enter the access token",
        },
      ]}
    >
      <Input.Password />
    </Form.Item>
    <Button size="large" type="primary" htmlType="submit" loading={isLoading}>
      Login
    </Button>
  </Form>
);
