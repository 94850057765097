import {RecoilState, useRecoilValue, waitForAll} from "recoil";
import {v4} from "uuid";
import * as immutable from "object-path-immutable";

import {
  AtomizedFormControlSourceNode,
  useSetAtomPropertyValue,
} from "../editor";
import {atomizeNodes} from "../template";

/**
 * HOOKS
 */
export const useChoices = (
  atom: RecoilState<AtomizedFormControlSourceNode>,
) => {
  const {reference} = useRecoilValue(atom);
  const setChoices = useSetAtomPropertyValue(atom as any, "reference.choices");

  const addChoice = () => {
    setChoices([
      ...(reference.choices ?? []),
      ...atomizeNodes([
        {
          id: v4(),
          translationKey: "",
        } as any,
      ]),
    ]);
  };

  const removeChoice = (index: number) => {
    reference.choices?.length === 1
      ? removeChoices()
      : setChoices(immutable.del(reference.choices, `${index}`));
  };

  const removeChoices = () => setChoices(undefined);

  /**
   * Resolve the choices atoms to regular objects...
   */
  const choices = useRecoilValue(waitForAll(reference.choices ?? ([] as any)));

  return {choices, addChoice, removeChoice, removeChoices};
};
