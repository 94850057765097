import React from "react";

import {TitleNode} from "@reside/forms";
import {ADAPTABLE_TEXT_CLASSNAME, styled, Title} from "@reside/ui";
import {debounce} from "lodash";

import {AtomProp, useSetAtomPropertyValue} from "../../model/editor";
import {BuilderMarkdownEditor} from "../../pages/editor/atoms/BuilderMarkdownEditor";
import {Override} from "../../model/schemaTypes";

type Props = Readonly<{
  isFocused?: boolean;
  node: Override<TitleNode, {id: string}>;
}> &
  AtomProp;

export const EditableTitle = ({
  node: {id, translationKey},
  atom,
  isFocused,
}: Props) => {
  const setValue = useSetAtomPropertyValue(atom, "translationKey");

  const handleChange = debounce(value => {
    const text = value();
    setValue(text);
  }, 250);

  return (
    <BuilderMarkdownEditor
      id={id}
      defaultValue={translationKey}
      onChange={handleChange}
      hasTopMenu={isFocused}
    >
      {({Menu, Editor}) => (
        <>
          <Menu />
          <StyledTitle className={ADAPTABLE_TEXT_CLASSNAME}>
            <Editor />
          </StyledTitle>
        </>
      )}
    </BuilderMarkdownEditor>
  );
};

const StyledTitle = styled(Title)`
  padding: 0 7px;
  & .ProseMirror {
    &:focus {
      cursor: text;
    }
  }
`;
