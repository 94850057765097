import React from "react";
import { Field } from "formik";
import { DatePickerInput } from "../../date-picker";
import { FlexRow } from "../../box";
import { FieldBorder } from "../atoms/FieldBorder";
import { FieldError } from "../atoms/field-error";
import { FloatingFieldLabel } from "../atoms/FloatingFieldLabel";
import { LockIcon } from "../atoms";
import { Label } from "../atoms/Label";
import { isInvalid } from "../formHelpers";
export var DateField = function (_a) {
    var name = _a.name, label = _a.label, range = _a.range, disabled = _a.disabled, placeholder = _a.placeholder, FormField = _a.FormField;
    return (React.createElement(FormField, { name: name }, function (_a) {
        var form = _a.form, field = _a.field;
        var setFieldTouched = form.setFieldTouched, setFieldValue = form.setFieldValue;
        var onValueChange = function (value) {
            setFieldTouched(name, true, true);
            if (value)
                setFieldValue(name, value, true);
        };
        return (React.createElement(React.Fragment, null,
            React.createElement(Label, null,
                React.createElement(FloatingFieldLabel, { visible: true }, label),
                React.createElement(FlexRow, null,
                    React.createElement(DatePickerInput, { testId: name, value: field.value, range: range, inputProps: {
                            name: name,
                            disabled: disabled,
                            placeholder: placeholder,
                        }, onChange: onValueChange }),
                    disabled && React.createElement(LockIcon, null)),
                React.createElement(FieldBorder, { invalid: isInvalid(name, form) })),
            React.createElement(FieldError, { name: name })));
    }));
};
DateField.defaultProps = {
    FormField: Field,
    range: {
        before: [100, "year"],
        after: [10, "year"],
    },
};
