var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { styled } from "../theme";
import { Text } from "../text";
export var List = function (_a) {
    var ordered = _a.ordered, items = _a.items, listStyleType = _a.listStyleType, className = _a.className, ListItem = _a.ListItem, Label = _a.Label;
    // with deprecated ordered, provide listStyleType and make list ordered using <ol> element
    var Wrapper = ordered || listStyleType ? OrderedList : UnorderedList;
    return (React.createElement(Wrapper, { className: className, listStyleType: listStyleType }, items.map(function (item, index) { return (React.createElement(ListItem, { key: index },
        React.createElement(Label, null, item))); })));
};
var ListItem = styled.li(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 1.25em;\n  line-height: 1.8em;\n  font-weight: ", ";\n  padding-bottom: 1em;\n\n  & & {\n    font-size: 1em;\n  }\n"], ["\n  font-size: 1.25em;\n  line-height: 1.8em;\n  font-weight: ", ";\n  padding-bottom: 1em;\n\n  & & {\n    font-size: 1em;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.bold;
});
var Label = styled(function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Text, __assign({ fontWeight: "normal" }, props), children));
})(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
List.defaultProps = {
    ordered: false,
    ListItem: ListItem,
    Label: Label,
};
var OrderedList = styled.ol(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding-left: 2em;\n  list-style-position: inside;\n  list-style-type: ", ";\n"], ["\n  padding-left: 2em;\n  list-style-position: inside;\n  list-style-type: ", ";\n"])), function (_a) {
    var listStyleType = _a.listStyleType;
    return listStyleType;
});
OrderedList.defaultProps = {
    listStyleType: "decimal",
};
var UnorderedList = styled.ul(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding-left: 2em;\n  list-style-position: inside;\n  list-style-type: ", ";\n"], ["\n  padding-left: 2em;\n  list-style-position: inside;\n  list-style-type: ", ";\n"])), function (_a) {
    var listStyleType = _a.listStyleType;
    return listStyleType;
});
UnorderedList.defaultProps = {
    listStyleType: "disc",
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
