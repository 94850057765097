import React, {FunctionComponent} from "react";
import {css} from "@emotion/react";
import {red, orange, blue} from "@ant-design/colors";
import {EyeOutlined} from "@ant-design/icons";
import {ContextuallyVisible, VisibilityContextType} from "@reside/forms";
import {styled} from "@reside/ui";

export const VisibilityIndicator: FunctionComponent<{
  hasVisibilityCondition: boolean;
  visibilityContext: ContextuallyVisible["visibilityContext"];
}> = ({hasVisibilityCondition, visibilityContext, children}) => {
  const hasPdfContext = visibilityContext?.includes(VisibilityContextType.PDF);
  const hasResidentExperienceContext = visibilityContext?.includes(
    VisibilityContextType.RE,
  );

  const color =
    hasPdfContext && hasResidentExperienceContext
      ? orange.primary!
      : hasPdfContext
      ? red.primary!
      : blue[9];

  const isVisible = !!(hasVisibilityCondition || visibilityContext);

  return (
    <LeftBar isVisible={isVisible} color={color}>
      {isVisible && <EyeIcon color={color} />}
      {children}
    </LeftBar>
  );
};

const LeftBar = styled.div<{
  isVisible: boolean;
  color: string;
}>`
  ${({isVisible, color}) =>
    isVisible &&
    css`
      border-left: 4px solid ${color};
      margin-left: -21px;
      padding-left: 17px;
    `};
`;

const EyeIcon = styled(EyeOutlined)<{
  color: string;
}>`
  color: ${({color}) => color};
  left: -15px;
  position: absolute;
`;
