import { wrapInList, liftListItem } from "prosemirror-schema-list";
import { findParentNode } from "prosemirror-utils";
import isList from "../queries/isList";
export default function toggleList(listType, itemType) {
    return function (state, dispatch) {
        var schema = state.schema, selection = state.selection;
        var $from = selection.$from, $to = selection.$to;
        var range = $from.blockRange($to);
        if (!range) {
            return false;
        }
        var parentList = findParentNode(function (node) { return isList(node, schema); })(selection);
        if (range.depth >= 1 && parentList && range.depth - parentList.depth <= 1) {
            if (parentList.node.type === listType) {
                return liftListItem(itemType)(state, dispatch);
            }
            if (isList(parentList.node, schema) &&
                listType.validContent(parentList.node.content)) {
                var tr = state.tr;
                tr.setNodeMarkup(parentList.pos, listType);
                if (dispatch) {
                    dispatch(tr);
                }
                return false;
            }
        }
        return wrapInList(listType)(state, dispatch);
    };
}
