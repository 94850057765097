import React from "react";
import { useFormikContext } from "formik";
import { Button } from "../../button";
import { useBlockComponentContext } from "../";
export var BlockContinueButton = function (_a) {
    var translationKey = _a.translationKey;
    var Translated = useBlockComponentContext().Translated;
    var isSubmitting = useFormikContext().isSubmitting;
    return (React.createElement(Button, { size: "large", color: "primary", type: "submit", disabled: isSubmitting },
        React.createElement(Translated, { translationKey: translationKey })));
};
