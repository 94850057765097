var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import { useMeasure } from "react-use";
import SignatureCanvas from "react-signature-canvas";
import Alert from "react-s-alert";
import { css } from "@emotion/react";
import { styled } from "../../../theme";
import { Text } from "../../../text";
import { Button } from "../../../button";
import { FlexColumn, FlexRow } from "../../../box";
import { isUrl, isDataUrl } from "../../../urlUtils";
import { FieldLabel } from "../FieldLabel";
import { convertImgToBase64URL } from "./utils";
export var Signature = function (_a) {
    var value = _a.value, label = _a.label, disabled = _a.disabled, testId = _a.testId, defaultWidth = _a.defaultWidth, defaultHeight = _a.defaultHeight, transformUrl = _a.transformUrl, onChange = _a.onChange, onError = _a.onError;
    var isEmpty = !value;
    var _b = useState(false), hasError = _b[0], setHasError = _b[1];
    var _c = useState(new Date().toISOString()), retryAt = _c[0], setRetryAt = _c[1];
    var _d = useState(false), isTouched = _d[0], setIsTouched = _d[1];
    var signatureCanvasRef = useRef();
    var _e = useMeasure(), ref = _e[0], width = _e[1].width;
    // Range 0..defaultWidth where value 0 is before measurement on rendered component
    var responsiveWidth = Math.min(Math.max(0, width), defaultWidth);
    useEffect(function () {
        var _a;
        var initializeCanvas = function () { return __awaiter(void 0, void 0, void 0, function () {
            var dataURL, error_1, message;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!isDataUrl(value)) return [3 /*break*/, 1];
                        dataURL = value;
                        return [3 /*break*/, 5];
                    case 1:
                        if (!isUrl(value)) return [3 /*break*/, 5];
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, convertImgToBase64URL(transformUrl(value))];
                    case 3:
                        dataURL = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _b.sent();
                        setHasError(true);
                        message = "Unable to load signature, please try to reload.";
                        Alert.error(message);
                        if (onError) {
                            onError(error_1, { value: value, message: message });
                        }
                        return [3 /*break*/, 5];
                    case 5:
                        if (dataURL) {
                            (_a = signatureCanvasRef.current) === null || _a === void 0 ? void 0 : _a.fromDataURL(dataURL, {
                                width: responsiveWidth,
                                height: defaultHeight,
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        if (
        // initialize only after width measurement takes place
        responsiveWidth > 0 &&
            value !== ((_a = signatureCanvasRef.current) === null || _a === void 0 ? void 0 : _a.toDataURL())) {
            initializeCanvas();
        }
    }, [value, retryAt, responsiveWidth, defaultHeight, transformUrl, onError]);
    return (React.createElement(SignatureContainer, { disabled: disabled || hasError, ref: ref, key: width },
        React.createElement(Header, null,
            React.createElement(FieldLabel, null,
                label,
                React.createElement("input", { type: "text", hidden: true, value: value, disabled: disabled || hasError, onChange: function (event) {
                        // Input just for controlling the Signature value from test environment
                        onChange(event.currentTarget.value);
                    } })),
            React.createElement(ButtonLayoutWrapper, null,
                React.createElement(DisplayWrapper, { isVisible: hasError },
                    React.createElement(Button, { outline: true, color: "danger", size: "small", icon: "arrows-cw", onClick: function () { return setRetryAt(new Date().toISOString()); } }, "Reload")),
                React.createElement(DisplayWrapper, { isVisible: !disabled && !hasError && (!isEmpty || isTouched) },
                    React.createElement(Button, { outline: true, color: "danger", size: "small", icon: "close", onClick: function () {
                            signatureCanvasRef.current.clear();
                            setIsTouched(false);
                            onChange("");
                        } }, "Clear")))),
        React.createElement(SignatureCanvasContainer, { "data-test-id": testId, filled: !isEmpty || isTouched, disabled: disabled || hasError },
            hasError && (React.createElement(PlaceholderMessage, null,
                React.createElement(Text, { color: "deepOrange100", fontSize: 4 }, "Unable to load signature, please try to reload."))),
            isEmpty && !isTouched && React.createElement(EmptySignatureMessage, null),
            React.createElement(SignatureCanvas, { ref: signatureCanvasRef, minWidth: 2, maxWidth: 3, dotSize: 3, velocityFilterWeight: 0.025, penColor: "#000", canvasProps: {
                    width: responsiveWidth,
                    height: defaultHeight,
                }, onBegin: function () { return setIsTouched(true); }, onEnd: function () {
                    var _a;
                    setIsTouched(false);
                    onChange((_a = signatureCanvasRef.current.toDataURL()) !== null && _a !== void 0 ? _a : "");
                }, clearOnResize: false }))));
};
Signature.defaultProps = {
    value: "",
    disabled: false,
    defaultWidth: 580,
    defaultHeight: 330,
};
export var EmptySignatureMessage = function () { return (React.createElement(PlaceholderMessage, null,
    React.createElement(SignatureGlyph, { src: require("./assets/placeholder-signature.svg"), alt: "Signature Placeholder" }),
    React.createElement(Text, { fontSize: 4 }, "Please use your finger to sign here."))); };
export var SignatureContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 580px;\n  margin: 0 auto;\n  margin-bottom: 15px;\n\n  ", "\n"], ["\n  width: 100%;\n  max-width: 580px;\n  margin: 0 auto;\n  margin-bottom: 15px;\n\n  ",
    "\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      cursor: not-allowed;\n    "], ["\n      cursor: not-allowed;\n    "])));
});
export var SignatureCanvasContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  background-color: rgba(43, 183, 217, 0.05);\n  transition: all 0.5s ease-in;\n  ", "\n  ", "\n"], ["\n  cursor: pointer;\n  position: relative;\n  border-radius: 8px;\n  border: 1px solid ", ";\n  background-color: rgba(43, 183, 217, 0.05);\n  transition: all 0.5s ease-in;\n  ", "\n  ",
    "\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
}, function (_a) {
    var filled = _a.filled;
    return filled && "background-color: #fff;";
}, function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "\n      pointer-events: none;\n      opacity: 0.6;\n      filter: saturate(25%);\n  ";
});
var Header = styled(FlexRow)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 20px;\n  min-height: 30px; // Added space for ClearButton to not move when hidden\n\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n\n  ", " {\n    flex-wrap: nowrap;\n    flex-direction: row;\n  }\n"], ["\n  margin-bottom: 20px;\n  min-height: 30px; // Added space for ClearButton to not move when hidden\n\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: column;\n\n  ", " {\n    flex-wrap: nowrap;\n    flex-direction: row;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.media.md;
});
var ButtonLayoutWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-self: flex-end;\n  ", " {\n    align-self: inherit;\n  }\n"], ["\n  display: flex;\n  align-self: flex-end;\n  ", " {\n    align-self: inherit;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.media.md;
});
var DisplayWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: none;\n  ", "\n"], ["\n  display: none;\n  ",
    "\n"])), function (_a) {
    var isVisible = _a.isVisible;
    return isVisible && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      display: block;\n    "], ["\n      display: block;\n    "])));
});
var SignatureGlyph = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: block;\n  max-width: 300px;\n  margin: 0 auto 20px;\n"], ["\n  display: block;\n  max-width: 300px;\n  margin: 0 auto 20px;\n"])));
var PlaceholderMessage = styled(FlexColumn)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  line-height: 1.75em;\n  font-size: 1.25em;\n  color: ", ";\n  pointer-events: none;\n  user-select: none;\n"], ["\n  justify-content: center;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  line-height: 1.75em;\n  font-size: 1.25em;\n  color: ", ";\n  pointer-events: none;\n  user-select: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.primary;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
