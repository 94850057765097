var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { keymap } from "prosemirror-keymap";
import { MarkdownParser } from "prosemirror-markdown";
import { MarkdownSerializer } from "./markdown/serializer";
import makeRules from "./markdown/rules";
var ExtensionManager = /** @class */ (function () {
    function ExtensionManager(extensions, editor) {
        if (extensions === void 0) { extensions = []; }
        if (editor) {
            extensions.forEach(function (extension) {
                extension.bindEditor(editor);
            });
        }
        this.extensions = extensions;
        this.embeds = editor ? editor.props.embeds : undefined;
    }
    Object.defineProperty(ExtensionManager.prototype, "nodes", {
        get: function () {
            return this.extensions.filter(function (extension) { return extension.type === "node"; }).reduce(function (nodes, node) {
                var _a;
                return (__assign(__assign({}, nodes), (_a = {}, _a[node.name] = node.schema, _a)));
            }, {});
        },
        enumerable: true,
        configurable: true
    });
    ExtensionManager.prototype.serializer = function () {
        var nodes = this.extensions.filter(function (extension) { return extension.type === "node"; }).reduce(function (nodes, extension) {
            var _a;
            return (__assign(__assign({}, nodes), (_a = {}, _a[extension.name] = extension.toMarkdown, _a)));
        }, {});
        var marks = this.extensions.filter(function (extension) { return extension.type === "mark"; }).reduce(function (marks, extension) {
            var _a;
            return (__assign(__assign({}, marks), (_a = {}, _a[extension.name] = extension.toMarkdown, _a)));
        }, {});
        return new MarkdownSerializer(nodes, marks);
    };
    ExtensionManager.prototype.parser = function (_a) {
        var schema = _a.schema, answers = _a.answers;
        var tokens = this.extensions.filter(function (extension) { return extension.type === "mark" || extension.type === "node"; }).reduce(function (nodes, extension) {
            var _a;
            var md = extension.parseMarkdown();
            if (!md)
                return nodes;
            return __assign(__assign({}, nodes), (_a = {}, _a[extension.markdownToken || extension.name] = md, _a));
        }, {});
        return new MarkdownParser(schema, makeRules({ embeds: this.embeds, answers: answers }), tokens);
    };
    Object.defineProperty(ExtensionManager.prototype, "marks", {
        get: function () {
            return this.extensions.filter(function (extension) { return extension.type === "mark"; }).reduce(function (marks, _a) {
                var _b;
                var name = _a.name, schema = _a.schema;
                return (__assign(__assign({}, marks), (_b = {}, _b[name] = schema, _b)));
            }, {});
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtensionManager.prototype, "plugins", {
        get: function () {
            return this.extensions
                .filter(function (extension) { return extension.plugins; })
                .reduce(
            // @ts-ignore
            function (allPlugins, _a) {
                var plugins = _a.plugins;
                return __spreadArrays(allPlugins, plugins);
            }, []);
        },
        enumerable: true,
        configurable: true
    });
    ExtensionManager.prototype.keymaps = function (_a) {
        var schema = _a.schema;
        var extensionKeymaps = this.extensions
            .filter(function (extension) { return ["extension"].includes(extension.type); })
            .filter(function (extension) { return extension.keys; })
            .map(function (extension) { return extension.keys({ schema: schema }); });
        var nodeMarkKeymaps = this.extensions
            .filter(function (extension) { return ["node", "mark"].includes(extension.type); })
            .filter(function (extension) { return extension.keys; })
            .map(function (extension) {
            return extension.keys({
                type: schema[extension.type + "s"][extension.name],
                schema: schema,
            });
        });
        return __spreadArrays(extensionKeymaps, nodeMarkKeymaps).map(function (keys) { return keymap(keys); });
    };
    ExtensionManager.prototype.inputRules = function (_a) {
        var schema = _a.schema;
        var extensionInputRules = this.extensions
            .filter(function (extension) { return ["extension"].includes(extension.type); })
            .filter(function (extension) { return extension.inputRules; })
            .map(function (extension) { return extension.inputRules({ schema: schema }); });
        var nodeMarkInputRules = this.extensions
            .filter(function (extension) { return ["node", "mark"].includes(extension.type); })
            .filter(function (extension) { return extension.inputRules; })
            .map(function (extension) {
            return extension.inputRules({
                type: schema[extension.type + "s"][extension.name],
                schema: schema,
            });
        });
        return __spreadArrays(extensionInputRules, nodeMarkInputRules).reduce(function (allInputRules, inputRules) { return __spreadArrays(allInputRules, inputRules); }, []);
    };
    ExtensionManager.prototype.commands = function (_a) {
        var schema = _a.schema, view = _a.view;
        return this.extensions
            .filter(function (extension) { return extension.commands; })
            .reduce(function (allCommands, extension) {
            var name = extension.name, type = extension.type;
            var commands = {};
            var value = extension.commands(__assign({ schema: schema }, (["node", "mark"].includes(type)
                ? {
                    type: schema[type + "s"][name],
                }
                : {})));
            var apply = function (callback, attrs) {
                if (!view.editable) {
                    return false;
                }
                view.focus();
                return callback(attrs)(view.state, view.dispatch, view);
            };
            var handle = function (_name, _value) {
                if (Array.isArray(_value)) {
                    commands[_name] = function (attrs) {
                        return _value.forEach(function (callback) { return apply(callback, attrs); });
                    };
                }
                else if (typeof _value === "function") {
                    commands[_name] = function (attrs) { return apply(_value, attrs); };
                }
            };
            if (typeof value === "object") {
                Object.entries(value).forEach(function (_a) {
                    var commandName = _a[0], commandValue = _a[1];
                    handle(commandName, commandValue);
                });
            }
            else {
                handle(name, value);
            }
            return __assign(__assign({}, allCommands), commands);
        }, {});
    };
    return ExtensionManager;
}());
export default ExtensionManager;
