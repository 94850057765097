var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { wrappingInputRule } from "prosemirror-inputrules";
import toggleWrap from "../commands/toggleWrap";
import { WarningIcon, InfoIcon, StarredIcon } from "outline-icons";
import React from "react";
import ReactDOM from "react-dom";
import Node from "./Node";
var Notice = /** @class */ (function (_super) {
    __extends(Notice, _super);
    function Notice() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleStyleChange = function (event) {
            var _a;
            if (!((_a = _this.editor) === null || _a === void 0 ? void 0 : _a.view)) {
                return;
            }
            var view = _this.editor.view;
            var tr = view.state.tr;
            var element = event.target;
            var _b = element.getBoundingClientRect(), top = _b.top, left = _b.left;
            var result = view.posAtCoords({ top: top, left: left });
            if (result) {
                var transaction = tr.setNodeMarkup(result.inside, undefined, {
                    style: element.value,
                });
                view.dispatch(transaction);
            }
        };
        return _this;
    }
    Object.defineProperty(Notice.prototype, "styleOptions", {
        get: function () {
            return Object.entries({
                info: this.options.dictionary.info,
                warning: this.options.dictionary.warning,
                tip: this.options.dictionary.tip,
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Notice.prototype, "name", {
        get: function () {
            return "container_notice";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Notice.prototype, "schema", {
        get: function () {
            var _this = this;
            return {
                attrs: {
                    style: {
                        default: "info",
                    },
                },
                content: "block+",
                group: "block",
                defining: true,
                draggable: true,
                parseDOM: [
                    {
                        tag: "div.notice-block",
                        preserveWhitespace: "full",
                        contentElement: "div:last-child",
                        getAttrs: function (dom) { return ({
                            style: dom.className.includes("tip")
                                ? "tip"
                                : dom.className.includes("warning")
                                    ? "warning"
                                    : undefined,
                        }); },
                    },
                ],
                toDOM: function (node) {
                    var select = document.createElement("select");
                    select.addEventListener("change", _this.handleStyleChange);
                    _this.styleOptions.forEach(function (_a) {
                        var key = _a[0], label = _a[1];
                        var option = document.createElement("option");
                        option.value = key;
                        option.innerText = label;
                        option.selected = node.attrs.style === key;
                        select.appendChild(option);
                    });
                    var component;
                    if (node.attrs.style === "tip") {
                        component = React.createElement(StarredIcon, { color: "currentColor" });
                    }
                    else if (node.attrs.style === "warning") {
                        component = React.createElement(WarningIcon, { color: "currentColor" });
                    }
                    else {
                        component = React.createElement(InfoIcon, { color: "currentColor" });
                    }
                    var icon = document.createElement("div");
                    icon.className = "icon";
                    ReactDOM.render(component, icon);
                    return [
                        "div",
                        { class: "notice-block " + node.attrs.style },
                        icon,
                        ["div", { contentEditable: false }, select],
                        ["div", 0],
                    ];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Notice.prototype.commands = function (_a) {
        var type = _a.type;
        return function (attrs) { return toggleWrap(type, attrs); };
    };
    Notice.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [wrappingInputRule(/^:::$/, type)];
    };
    Notice.prototype.toMarkdown = function (state, node) {
        state.write("\n:::" + (node.attrs.style || "info") + "\n");
        state.renderContent(node);
        state.ensureNewLine();
        state.write(":::");
        state.closeBlock(node);
    };
    Notice.prototype.parseMarkdown = function () {
        return {
            block: "container_notice",
            getAttrs: function (tok) { return ({ style: tok.info }); },
        };
    };
    return Notice;
}(Node));
export default Notice;
