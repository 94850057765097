import React from "react";
import {useRecoilValue} from "recoil";

import {isTemplateLoadedSelector} from "../model/template";
import {
  isRepoInitializedAtom,
  isRepositoryClonedSelector,
} from "../model/repository";
import {isLoggedInSelector} from "../model/auth";
import {Editor} from "../pages/editor";
import {LoginPage} from "../pages/login-page";
import {WelcomePage} from "../pages/welcome-page";
import {CloneRepositoryPage} from "../pages/clone-repository-page";
import {SyncRepositoryPage} from "../pages/sync-repository-page";
// import {RepositorySettingsGuardPage} from "../pages/guard-repository-settings-page";

export const Router = () => {
  const isLoggedIn = useRecoilValue(isLoggedInSelector);
  const isRepositoryCloned = useRecoilValue(isRepositoryClonedSelector);
  const isRepoInitialized = useRecoilValue(isRepoInitializedAtom);
  const isTemplateLoaded = useRecoilValue(isTemplateLoadedSelector);

  return !isLoggedIn ? (
    <LoginPage />
  ) : !isRepositoryCloned ? (
    <CloneRepositoryPage />
  ) : !isRepoInitialized ? (
    <SyncRepositoryPage />
  ) : !isTemplateLoaded ? (
    <WelcomePage />
  ) : (
    <Editor />
  );
};
