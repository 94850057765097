import * as Sentry from "@sentry/browser";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({dsn: process.env.REACT_APP_SENTRY_DSN});
}

export const logErrorToSentry = process.env.REACT_APP_SENTRY_DSN
  ? (error: any, info: any = {}) => {
      Sentry.withScope(scope => {
        Object.keys(info).forEach(key => {
          scope.setExtra(key, info[key]);
        });
        Sentry.captureException(error);
      });
    }
  : (error: any, info: any) => {
      // eslint-disable-next-line no-console
      console.log(error, info);
    };
