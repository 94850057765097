import React, {Component, ErrorInfo} from "react";
import {Alert, Card, Space} from "antd";
import {styled, FlexColumn} from "@reside/ui";

import {CustomizationSidebar} from "../../atoms/customization-sidebar";
import {TemplatePanel} from "../../atoms/template-panel";
import {UndoRedo} from "../../atoms/undo-redo";
import {DocumentDragDropContext} from "./atoms/document-drag-drop-context";
import {TemplatePicker} from "../../atoms/template-picker";
import {ActiveSlideCard} from "./atoms/ActiveSlideCard";
import {ComponentTabs} from "./atoms/ComponentTabs";
import {PageLayout} from "../../atoms/page-layout";
import {HomeButton} from "../../atoms/home-button";
import {SaveButton} from "../../atoms/save-button";
import {logErrorToSentry} from "../../utils/sentry";

export const Editor = () => (
  <DocumentDragDropContext>
    <PageLayout
      leftSider={
        <Sidebar>
          <Card
            title={<HomeButton />}
            extra={
              <Space>
                <UndoRedo />
                <SaveButton />
              </Space>
            }
          >
            <TemplatePicker />
          </Card>
          <ComponentTabs />
        </Sidebar>
      }
      rightSider={
        <Sidebar>
          <CustomizationSidebar />
          <TemplatePanel />
        </Sidebar>
      }
    >
      <ActiveSlideCard />
    </PageLayout>
  </DocumentDragDropContext>
);

type SlideErrorState = Readonly<{
  hasError: boolean;
}>;

export class SlideErrorBoundary extends Component<{}, SlideErrorState> {
  state: SlideErrorState = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logErrorToSentry(error, errorInfo);
  }

  render() {
    return this.state.hasError ? (
      <>
        <Alert
          type="error"
          message="Failed to render JSON"
          description="The slide children cannot be rendered inside the dynamic form."
        />
      </>
    ) : (
      this.props.children
    );
  }
}

const Sidebar = styled(FlexColumn)`
  padding: 0;
  height: 100vh;
`;
