var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled, theme } from "../../theme";
import { Icon } from "../../icon";
import { Text as BaseText } from "../../text";
export var LockIcon = function (_a) {
    var className = _a.className, label = _a.label;
    return (React.createElement(Container, { className: className },
        React.createElement(Icon, { name: "lock", color: theme.color.darkBlue60, size: 20, title: label }),
        React.createElement(Text, null, label)));
};
LockIcon.defaultProps = {
    label: "Locked",
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex-direction: row;\n  align-items: center;\n  cursor: not-allowed;\n\n  @media (min-width: ", "px) {\n    min-width: 90px;\n  }\n"], ["\n  flex-direction: row;\n  align-items: center;\n  cursor: not-allowed;\n\n  @media (min-width: ", "px) {\n    min-width: 90px;\n  }\n"])), theme.breakpoint.md);
var Text = styled(BaseText)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", "px;\n  padding-left: ", "px;\n  color: ", ";\n\n  display: none;\n  @media (min-width: ", "px) {\n    display: inline;\n  }\n"], ["\n  font-size: ", "px;\n  padding-left: ", "px;\n  color: ", ";\n\n  display: none;\n  @media (min-width: ", "px) {\n    display: inline;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fontSize[3];
}, function (_a) {
    var theme = _a.theme;
    return theme.space[1];
}, function (_a) {
    var theme = _a.theme;
    return theme.color.darkBlue60;
}, theme.breakpoint.md);
var templateObject_1, templateObject_2;
