"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var validator_1 = require("./validator");
var templateUtils_1 = require("./templateUtils");
var templateTypes_1 = require("./templateTypes");
var BlockFieldArray_helpers_1 = require("./BlockFieldArray.helpers");
var json_logic_1 = require("./json-logic");
/**
 * Reduces list of fields to Rules record mapping field name to list of validation rules.
 */
exports.getValidationRules = function (fields, values) {
    return fields.reduce(function (rules, field) {
        var _a;
        var blockName = templateUtils_1.getBlockName(field);
        return __assign(__assign({}, rules), (_a = {}, _a[blockName] = field.rules
            .map(function (rule) {
            return typeof rule === "object"
                ? blockName.match(/([\w]+)\.([0-9]+)\.([\w]+)/)
                    ? exports.parseJsonLogicRuleForBlockField(rule, values, blockName)
                    : parseJsonLogicRule(rule, values)
                : rule;
        })
            .filter(function (rule) { return rule !== null; }), _a));
    }, {});
};
//
/**
 * Reduces list of fields to ErrorMessages record mapping rule&field name to error message.
 */
exports.getValidationMessages = function (fields) {
    return fields.reduce(function (errorMessages, block) {
        var _a;
        var fieldName = templateUtils_1.getBlockName(block);
        return __assign(__assign({}, errorMessages), Object.keys((_a = block.validationMessages) !== null && _a !== void 0 ? _a : {}).reduce(function (messages, rule) {
            messages[rule + "." + fieldName] = block.validationMessages[rule];
            return messages;
        }, {}));
    }, {});
};
/**
 * Create validation function together with rules & messages for a list of blocks.
 * @param params
 * @param params.children
 * @param params.checkMandatory
 * @param params.answers
 * // TODO: validationIsMandatory is poorly designed attribute for MandatoryVideo node & should be removed
 */
exports.getValidationConfig = function (_a) {
    var children = _a.children, _b = _a.checkMandatory, checkMandatory = _b === void 0 ? true : _b, answers = _a.answers;
    var fields = exports.extractFormFields(children, undefined, answers).filter(function (field) { var _a; return ((_a = field === null || field === void 0 ? void 0 : field.rules) === null || _a === void 0 ? void 0 : _a.length) && !field.state.disabled && field.state.visible; });
    var validationRules = exports.getValidationRules(fields, answers);
    var validationMessages = exports.getValidationMessages(fields);
    var validate = function (values, rules) {
        var errors = validator_1.validator.validateAll(values, rules ? __assign(__assign({}, validationRules), rules) : validationRules, validationMessages);
        return exports.getErrorsForVisibleBlocks(errors, children, values);
    };
    return {
        validate: validate,
        validationRules: validationRules,
        validationMessages: validationMessages,
        validationIsMandatory: checkMandatory && fields.some(function (block) { return block.validationIsMandatory; }),
    };
};
/**
 * Extracts list of all form fields from Blocks JSON tree.
 */
exports.extractFormFields = function (children, prefix, answers) {
    if (prefix === void 0) { prefix = ""; }
    if (answers === void 0) { answers = {}; }
    return children === null || children === void 0 ? void 0 : children.flatMap(function (block) {
        var _a, _b, _c, _d;
        return block.type === templateTypes_1.NodeType.FIELD_ARRAY &&
            ((_a = answers[templateUtils_1.getBlockName(block)]) === null || _a === void 0 ? void 0 : _a.length) > 0
            ? (_d = (_c = (_b = answers[templateUtils_1.getBlockName(block)]) === null || _b === void 0 ? void 0 : _b.map(function (_, index) { return __spreadArrays(addCurrentBlock(block, prefix), descendToChoiceChildren(block, prefix, answers), descendToBlockChildren(block, block.id + "." + index + ".", answers)); })) === null || _c === void 0 ? void 0 : _c.flatMap(function (block) { return block; })) === null || _d === void 0 ? void 0 : _d.filter(function (block, index, thisArg) {
                return thisArg.findIndex(function (t) { return templateUtils_1.getBlockName(t) === templateUtils_1.getBlockName(block); }) === index;
            }) : __spreadArrays(addCurrentBlock(block, prefix), descendToChoiceChildren(block, prefix, answers), descendToBlockChildren(block, "", answers));
    });
};
/**
 * Add current block if it's a form block
 * @param block
 * @param prefix
 */
var addCurrentBlock = function (block, prefix) {
    return block.reference
        ? [
            __assign(__assign({}, block), { reference: __assign(__assign({}, block.reference), { id: "" + prefix + block.reference.id }) }),
        ]
        : isFormBlock(block)
            ? [block]
            : [];
};
/**
 * Descend to choice children if possible
 * @param block
 * @param prefix
 * @param answers
 */
var descendToChoiceChildren = function (block, prefix, answers) {
    var _a;
    return block.reference
        ? ((_a = block.reference.choices) !== null && _a !== void 0 ? _a : [])
            .filter(function (choice) { return choice.children; })
            .flatMap(function (choice) {
            return exports.extractFormFields(choice.children, prefix, answers);
        })
        : [];
};
/**
 * Descend to block children if possible
 * @param block
 * @param prefix
 * @param answers
 */
var descendToBlockChildren = function (block, prefix, answers) {
    return block.children ? exports.extractFormFields(block.children, prefix, answers) : [];
};
/**
 * Similar to 'extractFormFields' without extracting the fields from FieldArray children.
 */
exports.extractSimpleFormFields = function (children) {
    return children.flatMap(function (block) {
        var _a;
        return __spreadArrays((isFormBlock(block) ? [block] : []), (block.reference
            ? ((_a = block.reference.choices) !== null && _a !== void 0 ? _a : [])
                .filter(function (choice) { return choice.children; })
                .flatMap(function (choice) { return exports.extractSimpleFormFields(choice.children); })
            : []), (block.children && block.type !== templateTypes_1.NodeType.FIELD_ARRAY
            ? exports.extractSimpleFormFields(block.children)
            : []));
    });
};
/**
 * Picks values for form fields, if value is not defined, supplies defaults.
 */
exports.extractInitialValuesForFields = function (fields, values) {
    return fields.reduce(function (initialValues, field) {
        var _a;
        var fieldName = templateUtils_1.getBlockName(field);
        if (!fieldName) {
            return initialValues;
        }
        if (field.type === templateTypes_1.NodeType.FIELD_ARRAY ||
            ((_a = field.reference) === null || _a === void 0 ? void 0 : _a.type) === templateTypes_1.QuestionType.CHECKBOX) {
            initialValues[fieldName] = values[fieldName] || [];
        }
        else {
            initialValues[fieldName] = values[fieldName] || "";
        }
        return initialValues;
    }, {});
};
exports.extractInitialFormValuesForBlock = function (_a) {
    var children = _a.children, _b = _a.values, values = _b === void 0 ? {} : _b;
    var fields = exports.extractSimpleFormFields(children).filter(isFormBlock);
    return exports.extractInitialValuesForFields(fields, values);
};
/**
 * Return true only when block is field array or form control.
 */
var isFormBlock = function (block) {
    return block.type === templateTypes_1.NodeType.FIELD_ARRAY || block.type === templateTypes_1.NodeType.FORM_CONTROL;
};
/**
 * From the set of errors pick only those which are for visible form fields.
 * Purpose of this helper is to remove false-positive errors for FieldArray rules
 * as some as some conditional fields might be visible in one item and hidden in another...
 * @param errors - all form errors
 * @param blocks - current tree of blocks
 * @param values - current form values
 */
exports.getErrorsForVisibleBlocks = function (errors, blocks, values) {
    if (values === void 0) { values = {}; }
    var fields = exports.extractSimpleFormFields(blocks);
    var fieldArrayFields = fields.flatMap(function (field) {
        return field.type === templateTypes_1.NodeType.FIELD_ARRAY
            ? exports.extractSimpleFormFields(BlockFieldArray_helpers_1.explodeFieldArray(field, values[templateUtils_1.getFieldName(field)], values))
            : [];
    });
    return __spreadArrays(fields, fieldArrayFields).filter(function (block) { return block.state.visible; })
        .reduce(function (visibleErrors, block) {
        var fieldName = templateUtils_1.getBlockName(block);
        if (errors[fieldName]) {
            visibleErrors[fieldName] = errors[fieldName];
        }
        return visibleErrors;
    }, {});
};
var parseJsonLogicRule = function (rule, values) {
    var ruleName = Object.keys(rule)[0];
    return json_logic_1.jsonLogic.apply(rule[ruleName], values) ? ruleName : null;
};
exports.parseJsonLogicRuleForBlockField = function (rule, values, fieldName) {
    var ruleName = Object.keys(rule)[0];
    return json_logic_1.jsonLogic.apply(JSON.parse(JSON.stringify(rule[ruleName]).replace(/\.\*\./g, "." + fieldName.split(".")[1] + ".")), values)
        ? ruleName
        : null;
};
