import React from "react";
import {Form, Switch, Tooltip} from "antd";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {isFieldArray, isFormControl} from "../../utils";
import {FormControlSourceNode} from "../../model/schemaTypes";

export const RequiredAttribute = () => {
  const node = useFocusedNode() as FormControlSourceNode;
  const setValidationRules = useSetFocusedNodePropertyValue("rules");

  if (!node) {
    return null;
  }

  const {rules = []} = node;

  return isFormControl(node) || isFieldArray(node) ? (
    <Form.Item
      label={
        <Tooltip title="Validation rule if the question must be answered.">
          <span>Required</span>
        </Tooltip>
      }
    >
      <Switch
        checked={rules.includes("required")}
        onChange={enabled =>
          setValidationRules(
            enabled
              ? [...rules, "required"]
              : rules.filter((rule: any) => rule !== "required"),
          )
        }
      />
    </Form.Item>
  ) : (
    <>Attribute "required" can be used only with FORM_CONTROL type</>
  );
};
