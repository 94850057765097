import {useCallback} from "react";
import {
  atom,
  RecoilState,
  selector,
  selectorFamily,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {get, isEqual, set, unset} from "lodash";
import deepMerge from "deepmerge";
import {message} from "antd";
import {FormControlNode, QuestionReference} from "@reside/forms";

import {constantCommonComponentsDirName} from "../../constants";
import {logError} from "../../logging";
import {
  readLibraryComponents,
  readTemplateNames,
} from "../../services/FileService";
import {listModifiedFiles} from "../../services/GitService";
import {isRepoInitializedAtom, modifiedFilesAtom} from "../repository";
import {libraryComponentsAtom} from "../library";
import {
  atomizedTemplateAtom,
  availableTemplatesAtom,
  useTemplateAtomState,
} from "../template";
import {Override, TemplateNodes} from "../schemaTypes";

/**
 * TYPES
 */
export type AtomProp<T = TemplateNodes> = {
  atom: RecoilState<T>;
};

export type AtomizedBranchNode = Readonly<{
  children: ReadonlyArray<RecoilState<TemplateNodes>>;
}>;

export type AtomizedFormControlSourceNode = Override<
  FormControlNode,
  Readonly<{
    id: string;
    reference: QuestionReference<AtomizedBranchNode>;
  }>
>;

/**
 * ATOMS
 */
export const activeSlidePathAtom = atom<ReadonlyArray<number>>({
  key: "activeSlidePath",
  default: [0, 0, 0],
});

const defaultNodeAtom = atom({
  key: "defaultFocusedAtom",
  default: {} as TemplateNodes,
});

export const focusedAtomAtom = atom<RecoilState<TemplateNodes> | undefined>({
  key: "focusedAtom",
  default: undefined,
});

/**
 * SELECTORS
 */
// Bypass the conditional hook
export const focusedAtomSelector = selector({
  key: "focusedAtomSelector",
  get: ({get}) => {
    const atom = get(focusedAtomAtom);

    if (atom) {
      return get(atom);
    }
  },
});

const isAtomFocusedSelector = selectorFamily({
  key: "isAtomFocused",
  get: (atomId: string) => ({get}) => {
    const focusedAtom = get(focusedAtomSelector);

    if (!focusedAtom) {
      return false;
    }

    return atomId === focusedAtom.id;
  },
});

/**
 * HACK: the slide atom is returned in array, otherwise it would be resolved by the useRecoilValue hook, which is not desired as we want the actual atom.
 */
export const activeSlideAtomSelector = selector<RecoilState<TemplateNodes>[]>({
  key: "activeSlideAtom",
  get: ({get}) => {
    const activeSlidePath = get(activeSlidePathAtom);
    const atomizedTemplate = get(atomizedTemplateAtom);

    // When we don't have template opened, return empty array
    if (atomizedTemplate.length === 0) {
      return [];
    }

    return activeSlidePath.reduce((atoms, key, index, path) => {
      if (index === path.length - 1) {
        /**
         * When we have last key, we pick the slide, and return it as array of atoms to fit the interface.
         */
        return [atoms[key]];
      }

      const node = get(atoms[key]);

      return node.children;
    }, atomizedTemplate);
  },
});

export const isSlidePathActiveSelector = selectorFamily({
  key: "isSlidePathActive",
  get: (slidePath: ReadonlyArray<number>) => ({get}) => {
    const activeSlidePath = get(activeSlidePathAtom);

    return slidePath.length === 3 && isEqual(slidePath, activeSlidePath);
  },
});

/**
 * HOOKS
 */
export const useFocusedNode = () => useRecoilValue(focusedAtomSelector);

export const useIsAtomFocused = (atom: RecoilState<TemplateNodes>) => {
  const node = useRecoilValue(atom);

  return useRecoilValue(isAtomFocusedSelector(node.id));
};

/**
 * See HACK comment above.
 */
export const useActiveSlideAtom = () => {
  const [activeSlideAtom] = useRecoilValue(activeSlideAtomSelector);
  return activeSlideAtom;
};

export const useResetEditorState = () => {
  const resetActiveSlidePath = useResetRecoilState(activeSlidePathAtom);
  const resetFocusedAtom = useResetRecoilState(focusedAtomAtom);

  return () => {
    resetActiveSlidePath();
    resetFocusedAtom();
  };
};

export const useSetAtomPropertyValue = <T = TemplateNodes>(
  atom: RecoilState<T>,
  propertyPath: string,
  {
    replace = false,
    replaceArray = true,
  }: {
    /**
     * Whether to replace the value at the propertyPath, or merge it.
     */
    replace?: boolean;
    /**
     * If using merge, whether to replace array or merge it.
     */
    replaceArray?: boolean;
  } = {},
) => {
  const [node, setNode] = useTemplateAtomState(atom);

  return (value: any) => {
    if (isEqual(get(node, propertyPath), value)) {
      /**
       * Do nothing when the value is unchanged.
       */
      return;
    }
    const patch = set({}, propertyPath, value);
    const updated = replace
      ? {...node, ...patch}
      : deepMerge(
          node as any,
          patch,
          replaceArray
            ? {
                arrayMerge: (destinationArray, sourceArray) => sourceArray,
              }
            : undefined,
        );

    // @ts-ignore
    setNode(updated as any);
  };
};

export const useUnsetAtomPropertyValue = <T>(
  atom: RecoilState<T>,
  propertyPath: string,
) => {
  const [node, setNode] = useTemplateAtomState(atom);

  return () => {
    const clone = {...node};

    unset(clone, propertyPath);

    setNode(clone as any);
  };
};

export const useUnsetFocusedNodePropertyValue = (propertyPath: string) => {
  const focusedAtom = useRecoilValue(focusedAtomAtom);
  const [focusedNode, setFocusedNode] = useTemplateAtomState(
    focusedAtom ?? defaultNodeAtom, // TODO: find better way for conditional hook
  );

  return () => {
    const clone = {...focusedNode};

    unset(clone, propertyPath);

    // @ts-ignore
    setFocusedNode(clone as any);
  };
};

export const useSetFocusedNodePropertyValue = (
  propertyPath: string,
  {
    replace = false,
    replaceArray = true,
  }: {
    /**
     * Whether to replace the value at the propertyPath, or merge it.
     */
    replace?: boolean;
    /**
     * If using merge, whether to replace array or merge it.
     */
    replaceArray?: boolean;
  } = {},
) => {
  const focusedAtom = useRecoilValue(focusedAtomAtom);
  const [focusedNode, setFocusedNode] = useTemplateAtomState(
    focusedAtom ?? defaultNodeAtom, // TODO: find better way for conditional hook
  );

  return (value: any) => {
    if (isEqual(get(focusedNode, propertyPath), value)) {
      /**
       * Do nothing when the value is unchanged.
       */
      return;
    }

    const patch = set({}, propertyPath, value);
    const updated = replace
      ? {...focusedNode, ...patch}
      : deepMerge(
          focusedNode as any,
          patch,
          replaceArray
            ? {
                arrayMerge: (destinationArray, sourceArray) => sourceArray,
              }
            : undefined,
        );

    // @ts-ignore
    setFocusedNode(updated as any);
  };
};

export const useInitializeRepo = () => {
  const setIsRepoInitialized = useSetRecoilState(isRepoInitializedAtom);
  const setAvailableTemplates = useSetRecoilState(availableTemplatesAtom);
  const setLibraryComponents = useSetRecoilState(libraryComponentsAtom);
  const setModifiedFiles = useSetRecoilState(modifiedFilesAtom);

  return useCallback(async () => {
    try {
      setAvailableTemplates(await readTemplateNames());

      const [commonComponents, constantCommonComponents] = await Promise.all([
        await readLibraryComponents(),
        await readLibraryComponents(constantCommonComponentsDirName),
      ]);

      const mergedCommonComponents = commonComponents.map(
        (currentItem, index) => {
          return {
            ...currentItem,
            components: [
              ...currentItem.components,
              ...constantCommonComponents[index].components,
            ],
          };
        },
      );

      setModifiedFiles(await listModifiedFiles());
      setLibraryComponents(mergedCommonComponents);
      setIsRepoInitialized(true);
    } catch (error) {
      logError({error});
      message.error("Failed to read templates.");
    }
  }, [
    setAvailableTemplates,
    setIsRepoInitialized,
    setLibraryComponents,
    setModifiedFiles,
  ]);
};
