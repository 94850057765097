import React from "react";
import {useRecoilValue} from "recoil";
import {Button, styled} from "@reside/ui";

import {EditableText} from "../editable-text";
import {AtomProp, useSetAtomPropertyValue} from "../../model/editor";
import {SourceSlide} from "../../model/schemaTypes";

export const EditableCtaButton = ({atom}: AtomProp<SourceSlide>) => {
  const activeSlide = useRecoilValue(atom);

  const setCtaButtonTitle = useSetAtomPropertyValue(
    atom,
    "ctaButton.title.translationKey",
  );

  return (
    <StaticButton
      size="large"
      color={activeSlide.darkBackground ? "white" : "primary"}
      onClick={event => event.preventDefault()}
    >
      <EditableText
        value={activeSlide.ctaButton?.title.translationKey ?? ""}
        emptyValue="Continue"
        onChange={setCtaButtonTitle}
      />
    </StaticButton>
  );
};

const StaticButton = styled(Button)`
  outline: none;
  pointer-events: none;
`;
