import React from "react";
import { SelectField } from "../select-field";
export var StatesField = function (_a) {
    var label = _a.label, name = _a.name;
    return (React.createElement(SelectField, { getOptionLabel: function (option) { return option.label; }, getOptionValue: function (option) { return option.id; }, label: label, name: name, options: STATES }));
};
StatesField.defaultProps = {
    label: "State",
    name: "state",
};
export var STATES = [
    { label: "Alabama", id: "84efe283-45a0-4e82-9af7-4899bce7c1b6" },
    { label: "Alaska", id: "db6a2ff7-f5c3-4f53-9dce-b36144de8d5c" },
    { label: "American Samoa", id: "10f62aff-77fc-408a-adeb-df4445c4e71f" },
    { label: "Arizona", id: "635922ef-692c-4656-9058-f6047319af3f" },
    { label: "Arkansas", id: "8b5cf4c2-692f-4135-a797-0674452d348b" },
    { label: "California", id: "77d483ab-91a3-4d72-a235-b8a61dd9ad5b" },
    { label: "Colorado", id: "157ad14e-3372-47c6-ad2d-95fba8bfbe0f" },
    { label: "Connecticut", id: "f9d2700b-8b15-4261-bfa1-7402419f8162" },
    { label: "Delaware", id: "1c855233-6f96-456b-80d4-f2c781b7028d" },
    { label: "District of Columbia", id: "d3206c50-cc45-458b-b6fc-bacee64495d8" },
    { label: "Florida", id: "224735d0-021c-4dd5-bcab-044369abc891" },
    { label: "Georgia", id: "829b05aa-d067-427d-8182-e72eda7b0835" },
    { label: "Guam", id: "135d1f22-29cf-4d12-91ae-cee9fd1f4202" },
    { label: "Hawaii", id: "9544908e-c460-4fec-8c18-eb67a10c2845" },
    { label: "Idaho", id: "04abbb26-595b-4111-8096-b2ad3c535145" },
    { label: "Illinois", id: "dc8cae26-3cae-4304-b013-7132e574ac17" },
    { label: "Indiana", id: "9dce70f0-f161-4e79-a619-425aed4c0c29" },
    { label: "Iowa", id: "51324d49-e0ab-418c-9bfd-e05f751f19a5" },
    { label: "Kansas", id: "4f0d71fb-08d1-48ec-8e98-3cfd2b8eb13b" },
    { label: "Kentucky", id: "0ca0e135-b3d9-496b-abb7-3bf59a0e0af3" },
    { label: "Louisiana", id: "5c75b759-6baa-4859-a00b-a862e41cd4fc" },
    { label: "Maine", id: "19e1899f-966c-49a4-b288-c34b72da4c85" },
    { label: "Maryland", id: "545816db-4b0e-4eaa-ba4b-b682b61b9769" },
    { label: "Massachusetts", id: "1ea0e8ad-3f51-4cdf-b8e0-e31bca400298" },
    { label: "Michigan", id: "f511706f-579b-4fed-a0fb-d2308520ad14" },
    { label: "Minnesota", id: "68b0a88d-d5ec-4626-a897-39aff6917b2d" },
    { label: "Mississippi", id: "825f6b6a-05f2-4c6c-af79-ae4948634905" },
    { label: "Missouri", id: "9b304245-cb30-42a1-9f21-71eaebdbe518" },
    { label: "Montana", id: "3dfec54f-e120-486f-9cd0-2ab46c788218" },
    { label: "Nebraska", id: "b6d5b41e-61bd-4e94-a1e7-f0f0f3f2a220" },
    { label: "Nevada", id: "cfa34198-2dee-4df1-8a1c-4b5558264eaf" },
    { label: "New Hampshire", id: "4b024c12-e043-4acf-9b2e-10b94687ca2f" },
    { label: "New Jersey", id: "b1d383d5-f489-478b-a62c-1bac3e60c3e2" },
    { label: "New Mexico", id: "7efafca8-6d2c-4a27-aea7-9c2fbb019b45" },
    { label: "New York", id: "2bfdf2d9-0ffa-4f01-9bb8-6a3e8e8e4707" },
    { label: "North Carolina", id: "b1c540cb-6845-41d5-a247-a86af2c89ca9" },
    { label: "North Dakota", id: "1920342d-a386-4e4b-b07c-d98612c0ea8b" },
    {
        label: "Northern Mariana Islands",
        id: "b5e6acaa-67b1-4d6c-9dc8-1d374fcfbf6c",
    },
    { label: "Ohio", id: "a282356e-342b-40ca-96f7-a438136a5b5d" },
    { label: "Oklahoma", id: "7d767936-dcc0-480b-a4c9-fee6b519871e" },
    { label: "Oregon", id: "9f5b4a1f-1cf3-4310-a398-e8b6f517eb1c" },
    { label: "Palau", id: "441dbb1d-3499-4a6f-8c43-5da9408d1a80" },
    { label: "Pennsylvania", id: "1b1af448-55fe-42e4-9971-9806c4bd49ec" },
    { label: "Puerto Rico", id: "1e6c203f-bef2-4d69-be76-147084851ee7" },
    { label: "Rhode Island", id: "78c56b52-cbe3-4084-9fe6-7e156d899787" },
    { label: "South Carolina", id: "cc87dc5c-0b4b-48e8-b97c-ce2ab405403f" },
    { label: "South Dakota", id: "2cbc8d80-5c76-4c0c-882a-76863c80a1a4" },
    { label: "Tennessee", id: "9360e964-4665-477a-bbe9-2b72e0a9f5cd" },
    { label: "Texas", id: "989a07a1-a923-41bd-97e5-b7453a26b992" },
    { label: "Utah", id: "8ceb7ce0-66ee-47c7-89dd-4c9c171a22ab" },
    { label: "Vermont", id: "4ecc2933-c546-45ac-a51e-65cbe1f8aa17" },
    { label: "Virgin Islands", id: "251bc600-7bc3-4453-b355-b8e140751a99" },
    { label: "Virginia", id: "8ccfe66f-e87d-4e50-aa02-ab20982b4d65" },
    { label: "Washington", id: "35f19401-0c58-4649-a8a1-1740e5ef6478" },
    { label: "West Virginia", id: "b1f0912c-75ed-4fee-8f2f-240a1ed092e7" },
    { label: "Wisconsin", id: "28c1ea33-ed6a-4e30-b96c-a3a242f11432" },
    { label: "Wyoming", id: "5079d64f-60ba-46cc-ba7d-e0ace4dcc1c7" },
];
