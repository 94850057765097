import React, {useCallback, useMemo, useState} from "react";
import {Table, Button, Card, Input, Skeleton, Empty, message} from "antd";
import {ColumnsType} from "antd/lib/table/interface";
import {useRecoilState, useSetRecoilState} from "recoil";
import {FlexColumn, styled} from "@reside/ui";

import {
  availableTemplatesAtom,
  isTemplateDirtyAtom,
  persistedTemplateSelector,
  templateNameAtom,
} from "../../model/template";
import {readTemplate, cloneTemplate} from "../../services/FileService";
import {normalizeTemplateName} from "../../utils";
import {Content} from "../content";
import {useSetPriorTemplates} from "../../model/answers";

export const TemplateTable = () => {
  const [templates, setAvailableTemplates] = useRecoilState(
    availableTemplatesAtom,
  );
  const setTemplateName = useSetRecoilState(templateNameAtom);
  const setTemplatePersisted = useSetRecoilState(persistedTemplateSelector);
  const setPriorTemplates = useSetPriorTemplates();
  const setTemplateDirty = useSetRecoilState(isTemplateDirtyAtom);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredTemplates = templates.filter(item =>
    item.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  const handleTemplateSelect = useCallback(
    async (templateName: string) => {
      setTemplateName(templateName);

      const template = await readTemplate(templateName);
      setTemplatePersisted(template);
      await setPriorTemplates(templateName);
    },
    [setTemplateName, setTemplatePersisted, setPriorTemplates],
  );

  const handleCloneTemplate = useCallback(
    async (templateName: string) => {
      const newTemplateName = normalizeTemplateName(
        prompt("Enter a template name:", "") ?? "",
      );

      if (newTemplateName) {
        if (templates.includes(newTemplateName)) {
          message.error("Template name already used, choose unique name");
        } else {
          await cloneTemplate(templateName, newTemplateName);
          const template = await readTemplate(newTemplateName);

          setAvailableTemplates([...templates, newTemplateName].sort());
          setTemplateName(newTemplateName);
          setTemplatePersisted(template);
          setTemplateDirty(true);
          await setPriorTemplates(newTemplateName);
        }
      }
    },
    [
      setAvailableTemplates,
      setPriorTemplates,
      setTemplateDirty,
      setTemplateName,
      setTemplatePersisted,
      templates,
    ],
  );

  const columns: ColumnsType<{name: string}> = useMemo(
    () => [
      {}, // padding/gutter
      {
        title: "Name",
        dataIndex: "name",
        render: (templateName: string) => templateName,
      },
      {
        title: "actions",
        dataIndex: "name",
        render: (templateName: string) => (
          <div style={{textAlign: "right"}}>
            <Button
              onClick={event => {
                event.stopPropagation();
                handleCloneTemplate(templateName);
              }}
              type="primary"
            >
              Clone
            </Button>
          </div>
        ),
        fixed: "right",
      },
    ],
    [handleCloneTemplate],
  );

  return (
    <Sidebar>
      {templates.length > 0 ? (
        <>
          <TemplatesCard
            title={
              <Input.Search
                allowClear
                placeholder="Search template"
                onChange={event => setSearchQuery(event.target.value)}
              />
            }
          />
          <Scrollable>
            {filteredTemplates.length === 0 ? (
              <Empty style={{paddingTop: 40}} />
            ) : (
              <TableStyles>
                <Table
                  size="middle"
                  rowClassName="templateRow"
                  onRow={record => ({
                    onClick: () => {
                      handleTemplateSelect(record.name);
                    },
                  })}
                  dataSource={filteredTemplates.map(name => ({
                    key: name,
                    name,
                  }))}
                  columns={columns}
                  pagination={false}
                  showHeader={false}
                />
              </TableStyles>
            )}
          </Scrollable>
        </>
      ) : (
        <Content>
          <Skeleton />
        </Content>
      )}
    </Sidebar>
  );
};

const TemplatesCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`;

const Sidebar = styled(FlexColumn)`
  padding: 0;
  height: 100vh;
`;

const Scrollable = styled.div`
  overflow-y: scroll;
  height: 100vh;
`;

const TableStyles = styled.div`
  .templateRow {
    button {
      visibility: hidden;
      transition: visibility 0ms;
    }

    &:hover {
      cursor: pointer;

      button {
        visibility: visible;
      }
    }
  }
`;
