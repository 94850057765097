import React from "react";
import {Form} from "antd";

import {FormControlSourceNode} from "../../model/schemaTypes";
import {useFocusedNode} from "../../model/editor";
import {LabelAttribute} from "../label-attribute";
import {DisabledAttribute} from "../disabled-attribute";
import {RequiredAttribute} from "../required-attribute";

export const EmailFieldForm = () => {
  const node = useFocusedNode() as FormControlSourceNode;

  return (
    <Form
      key={node.id}
      initialValues={{
        label: node?.reference?.translationKey,
      }}
    >
      <LabelAttribute />
      <DisabledAttribute />
      <RequiredAttribute />
    </Form>
  );
};
