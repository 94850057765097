var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var Extension = /** @class */ (function () {
    function Extension(options) {
        if (options === void 0) { options = {}; }
        this.options = __assign(__assign({}, this.defaultOptions), options);
    }
    Extension.prototype.bindEditor = function (editor) {
        this.editor = editor;
    };
    Object.defineProperty(Extension.prototype, "type", {
        get: function () {
            return "extension";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Extension.prototype, "name", {
        get: function () {
            return "";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Extension.prototype, "plugins", {
        get: function () {
            return [];
        },
        enumerable: true,
        configurable: true
    });
    Extension.prototype.keys = function (options) {
        return {};
    };
    Extension.prototype.inputRules = function (options) {
        return [];
    };
    Extension.prototype.commands = function (options) {
        return function (attrs) { return function () { return false; }; };
    };
    Object.defineProperty(Extension.prototype, "defaultOptions", {
        get: function () {
            return {};
        },
        enumerable: true,
        configurable: true
    });
    return Extension;
}());
export default Extension;
