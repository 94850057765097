var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { forwardRef, useEffect } from "react";
import { useFormikContext } from "formik";
import { isMobile } from "../is-mobile";
var SCROLLABLE_ERROR_AREA_ID = "scrollableArea";
/**
 * Prefix error messages elements id attribute with this string in order to scroll to them.
 */
export var SCROLLABLE_ERROR_ID = "error-field-scroll-target";
export var ScrollToError = function (_a) {
    var offsetTop = _a.offsetTop, offsetBottom = _a.offsetBottom, scrollTopOffset = _a.scrollTopOffset, elementSelector = _a.elementSelector;
    var submitCount = useFormikContext().submitCount;
    useEffect(function () {
        requestAnimationFrame(function () {
            scrollToFirstErrorIfAny(offsetTop, offsetBottom, scrollTopOffset, elementSelector);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitCount]);
    return null;
};
ScrollToError.defaultProps = {
    offsetTop: 0,
    offsetBottom: 0,
    // address top menu bar offset on iPhone
    scrollTopOffset: isMobile ? 200 : 100,
};
/**
 * Use this area as container for your form where errors are expected.
 */
export var ScrollableErrorArea = forwardRef(function (props, ref) { return (React.createElement("div", __assign({ ref: ref }, props, { "data-test-id": SCROLLABLE_ERROR_AREA_ID }))); });
/**
 * Helpers
 */
var isInViewport = function (el, offsetTop, offsetBottom) {
    var rect = el.getBoundingClientRect();
    return (rect.top >= offsetTop &&
        rect.left >= 0 &&
        rect.bottom <=
            (window.innerHeight - offsetBottom ||
                document.documentElement.clientHeight - offsetBottom) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth));
};
var isNotInViewport = function (el, offsetTop, offsetBottom) { return isInViewport(el, offsetTop, offsetBottom) === false; };
var scrollToFirstErrorIfAny = function (offsetTop, offsetBottom, scrollTopOffset, elementSelector) {
    if (elementSelector === void 0) { elementSelector = "[data-test-id=" + SCROLLABLE_ERROR_AREA_ID + "]"; }
    var pagerElement = document.querySelector(elementSelector);
    // Search within the parent element children - fix for confirmation modal blocks
    var errorElements = (pagerElement !== null && pagerElement !== void 0 ? pagerElement : document).querySelectorAll("[id^=\"" + SCROLLABLE_ERROR_ID + "\"]");
    var targetErrorElement = Array.from(errorElements).find(function (element) {
        return isNotInViewport(element, offsetTop, offsetBottom);
    });
    if (pagerElement && targetErrorElement) {
        scrollParentToChild(pagerElement, targetErrorElement, scrollTopOffset);
    }
};
var scrollParentToChild = function (parentEl, childEl, scrollTopOffset) {
    parentEl.scrollTop = childEl.offsetTop - parentEl.offsetTop - scrollTopOffset;
};
