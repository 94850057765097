import React from "react";
import {Form, InputNumber} from "antd";
import {MAX_FILE_SIZE_RULE_NAME} from "@reside/forms/dist/validator/validators/maxFileSizeValidator";

import {
  useFocusedNode,
  useSetFocusedNodePropertyValue,
} from "../../model/editor";
import {updateRules, isUploadField, getRuleValue} from "../../utils";
import {FormControlSourceNode} from "../../model/schemaTypes";

export const getMaxFileSizeRuleValue = getRuleValue(
  MAX_FILE_SIZE_RULE_NAME,
  parseInt,
);

export const getInitialMaxFileSizeFormValue = (node: FormControlSourceNode) => {
  try {
    return {
      [MAX_FILE_SIZE_RULE_NAME]: getMaxFileSizeRuleValue(node.rules),
    };
  } catch {
    return {};
  }
};

export const MaxFileSizeAttribute = () => {
  const node = useFocusedNode();
  const setValidationRules = useSetFocusedNodePropertyValue("rules");

  if (!node) {
    return null;
  }

  return isUploadField(node) ? (
    <Form.Item
      name={MAX_FILE_SIZE_RULE_NAME}
      label="Max file size (MB)"
      rules={[{type: "number", min: 0}]}
    >
      <InputNumber
        onChange={value =>
          setValidationRules(
            updateRules(
              [...(node.rules ?? [])],
              `${value ?? ""}`,
              MAX_FILE_SIZE_RULE_NAME,
            ),
          )
        }
      />
    </Form.Item>
  ) : (
    <>
      Attribute "${MAX_FILE_SIZE_RULE_NAME}" can be used only with FORM_CONTROL
      IMAGE or FILE type
    </>
  );
};
