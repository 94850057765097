var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { FieldArray, useFormikContext } from "formik";
import { v4 } from "uuid";
import { getIndexedBlock } from "@reside/forms/dist/BlockFieldArray.helpers";
import { styled } from "../../theme";
import { FieldErrorText } from "../../form/atoms/field-error";
import { Button } from "../../button";
import { Text } from "../../text";
import { FlexRow } from "../../box";
import { ValidationBlockBoundary } from "../validation-block-boundary";
import { BlockField, useBlockComponentContext } from "../";
import { CollapsibleItems } from "./atoms/collapsible-items";
export var BlockFieldArray = function (_a) {
    var id = _a.id, translationKey = _a.translationKey, emptyMessage = _a.emptyMessage, collapsible = _a.collapsible, headlineLabel = _a.headlineLabel, children = _a.children, queryVariables = _a.queryVariables;
    var _b = useBlockComponentContext(), Translated = _b.Translated, translate = _b.translate;
    var _c = useFormikContext(), values = _c.values, errors = _c.errors, touched = _c.touched;
    var items = values[id] || [];
    return (React.createElement(ValidationBlockBoundary, { name: id },
        React.createElement(FieldArray, { name: id }, function (arrayHelpers) { return (React.createElement(React.Fragment, null,
            collapsible ? (React.createElement(CollapsibleItems, { items: items, id: id, translationKey: translationKey, headlineLabel: headlineLabel, onRemove: function (index) { return arrayHelpers.remove(index); } }, function (index) { return (React.createElement(Body, null, children.map(function (block) { return (React.createElement(BlockField, { key: block.id, block: getIndexedBlock(block, id, index, __assign(__assign({}, items[index]), values)), queryVariables: queryVariables })); }))); })) : (items.map(function (item, index) {
                return (React.createElement(Container, { key: index },
                    React.createElement(Body, null,
                        React.createElement(Header, null,
                            React.createElement(ItemLabel, null,
                                translate(translationKey),
                                " #",
                                index + 1),
                            React.createElement(AlignRight, null,
                                React.createElement(RemoveButton, { translationKey: translationKey, onClick: function () {
                                        arrayHelpers.remove(index);
                                    } }))),
                        children.map(function (block) { return (React.createElement(BlockField, { key: block.id, block: getIndexedBlock(block, id, index, __assign(__assign({}, item), values)), queryVariables: queryVariables })); }))));
            })),
            emptyMessage && items.length === 0 && (React.createElement(EmptyMessage, null,
                React.createElement(Translated, { translationKey: emptyMessage.translationKey }))),
            React.createElement(AddButton, { translationKey: translationKey, onClick: function () {
                    arrayHelpers.push({ id: v4() });
                } }),
            React.createElement(FieldArrayError, { touch: touched[id], error: errors[id] }))); })));
};
var FieldArrayError = function (_a) {
    var touch = _a.touch, error = _a.error;
    return touch && typeof error === "string" ? (React.createElement(FieldErrorText, null, error)) : null;
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1em;\n  border: 1px solid ", ";\n"], ["\n  margin-bottom: 1em;\n  border: 1px solid ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.gray10;
});
var Body = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 1em 20px;\n"], ["\n  padding: 1em 20px;\n"])));
var Header = styled(FlexRow)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n"], ["\n  align-items: center;\n"])));
export var AlignRight = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
export var ItemLabel = styled(function (props) { return (React.createElement(Text, __assign({ as: "div", color: "gray100" }, props))); })(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-transform: uppercase;\n"], ["\n  text-transform: uppercase;\n"])));
export var RemoveButton = function (_a) {
    var translationKey = _a.translationKey, props = __rest(_a, ["translationKey"]);
    var Translated = useBlockComponentContext().Translated;
    return (React.createElement(Button, __assign({}, props, { outline: true, color: "danger" }),
        "Remove ",
        React.createElement(Translated, { translationKey: translationKey })));
};
var AddButton = function (_a) {
    var translationKey = _a.translationKey, props = __rest(_a, ["translationKey"]);
    var Translated = useBlockComponentContext().Translated;
    return (React.createElement(Button, __assign({}, props, { outline: true, color: "primary" }),
        "Add ",
        React.createElement(Translated, { translationKey: translationKey })));
};
var EmptyMessage = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-bottom: 15px;\n"], ["\n  margin-bottom: 15px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
