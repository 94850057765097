import React from "react";
import {Skeleton, Typography, Divider} from "antd";

import {PageLayout} from "../../atoms/page-layout";
import {SlideCard} from "../../atoms/slide-card";
import {TemplateTable} from "../../atoms/template-table";
import {BranchSelect} from "../../atoms/branch-select";
import {LogoutButton} from "../../atoms/logout-button";
import {CreateTemplateForm} from "./CreateTemplateForm";

const {Title, Paragraph} = Typography;

export const WelcomePage = () => (
  <PageLayout leftSider={<TemplateTable />}>
    <SlideCard title={<BranchSelect />} extra={<LogoutButton />}>
      <Skeleton />
      <div style={{padding: 100}}>
        <div style={{textAlign: "center"}}>
          <Title level={2}>Welcome to admission builder.</Title>
          <Paragraph>
            Start by editing or cloning an existing template in the left panel.
          </Paragraph>
          <Divider>or</Divider>
          <Paragraph>Create a new template</Paragraph>
        </div>
        <div style={{maxWidth: 600, margin: "0 auto"}}>
          <CreateTemplateForm />
        </div>
      </div>
      <Skeleton />
    </SlideCard>
  </PageLayout>
);
