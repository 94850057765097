var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Node from "./Node";
import { Decoration, DecorationSet } from "prosemirror-view";
import { addColumnAfter, addColumnBefore, deleteColumn, deleteRow, deleteTable, fixTables, goToNextCell, isInTable, setCellAttr, tableEditing, toggleHeaderCell, toggleHeaderColumn, toggleHeaderRow, } from "prosemirror-tables";
import { addRowAt, createTable, getCellsInColumn, moveRow, } from "prosemirror-utils";
import { Plugin, TextSelection } from "prosemirror-state";
var Table = /** @class */ (function (_super) {
    __extends(Table, _super);
    function Table() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Table.prototype, "name", {
        get: function () {
            return "table";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Table.prototype, "schema", {
        get: function () {
            return {
                content: "tr+",
                tableRole: "table",
                isolating: true,
                group: "block",
                parseDOM: [{ tag: "table" }],
                toDOM: function () {
                    return [
                        "div",
                        { class: "scrollable-wrapper" },
                        [
                            "div",
                            { class: "scrollable" },
                            ["table", { class: "rme-table" }, ["tbody", 0]],
                        ],
                    ];
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    Table.prototype.commands = function (_a) {
        var schema = _a.schema;
        return {
            createTable: function (_a) {
                var rowsCount = _a.rowsCount, colsCount = _a.colsCount;
                return function (state, dispatch) {
                    var offset = state.tr.selection.anchor + 1;
                    var nodes = createTable(schema, rowsCount, colsCount);
                    var tr = state.tr.replaceSelectionWith(nodes).scrollIntoView();
                    var resolvedPos = tr.doc.resolve(offset);
                    tr.setSelection(TextSelection.near(resolvedPos));
                    dispatch(tr);
                };
            },
            setColumnAttr: function (_a) {
                var index = _a.index, alignment = _a.alignment;
                return function (state, dispatch) {
                    var cells = getCellsInColumn(index)(state.selection) || [];
                    var transaction = state.tr;
                    cells.forEach(function (_a) {
                        var pos = _a.pos;
                        transaction = transaction.setNodeMarkup(pos, null, {
                            alignment: alignment,
                        });
                    });
                    dispatch(transaction);
                };
            },
            addColumnBefore: function () { return addColumnBefore; },
            addColumnAfter: function () { return addColumnAfter; },
            deleteColumn: function () { return deleteColumn; },
            addRowAfter: function (_a) {
                var index = _a.index;
                return function (state, dispatch) {
                    if (index === 0) {
                        // A little hack to avoid cloning the heading row by cloning the row
                        // beneath and then moving it to the right index.
                        var tr = addRowAt(index + 2, true)(state.tr);
                        dispatch(moveRow(index + 2, index + 1)(tr));
                    }
                    else {
                        dispatch(addRowAt(index + 1, true)(state.tr));
                    }
                };
            },
            deleteRow: function () { return deleteRow; },
            deleteTable: function () { return deleteTable; },
            toggleHeaderColumn: function () { return toggleHeaderColumn; },
            toggleHeaderRow: function () { return toggleHeaderRow; },
            toggleHeaderCell: function () { return toggleHeaderCell; },
            setCellAttr: function () { return setCellAttr; },
            fixTables: function () { return fixTables; },
        };
    };
    Table.prototype.keys = function () {
        return {
            Tab: goToNextCell(1),
            "Shift-Tab": goToNextCell(-1),
            Enter: function (state, dispatch) {
                if (!isInTable(state))
                    return false;
                // TODO: Adding row at the end for now, can we find the current cell
                // row index and add the row below that?
                var cells = getCellsInColumn(0)(state.selection) || [];
                dispatch(addRowAt(cells.length, true)(state.tr));
                return true;
            },
        };
    };
    Table.prototype.toMarkdown = function (state, node) {
        state.renderTable(node);
        state.closeBlock(node);
    };
    Table.prototype.parseMarkdown = function () {
        return { block: "table" };
    };
    Object.defineProperty(Table.prototype, "plugins", {
        get: function () {
            var _this = this;
            return [
                tableEditing(),
                new Plugin({
                    props: {
                        decorations: function (state) {
                            var doc = state.doc;
                            var decorations = [];
                            var index = 0;
                            doc.descendants(function (node, pos) {
                                if (node.type.name !== _this.name)
                                    return;
                                var elements = document.getElementsByClassName("rme-table");
                                var table = elements[index];
                                if (!table)
                                    return;
                                var element = table.parentElement;
                                var shadowRight = !!(element && element.scrollWidth > element.clientWidth);
                                if (shadowRight) {
                                    decorations.push(Decoration.widget(pos + 1, function () {
                                        var shadow = document.createElement("div");
                                        shadow.className = "scrollable-shadow right";
                                        return shadow;
                                    }));
                                }
                                index++;
                            });
                            return DecorationSet.create(doc, decorations);
                        },
                    },
                }),
            ];
        },
        enumerable: true,
        configurable: true
    });
    return Table;
}(Node));
export default Table;
