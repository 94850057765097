var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "../../theme";
export var FieldLabel = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 1.25em;\n  line-height: 1.75em;\n\n  ", "\n"], ["\n  font-size: 1.25em;\n  line-height: 1.75em;\n\n  ",
    "\n"])), function (_a) {
    var disabled = _a.disabled;
    return disabled &&
        "\n    opacity: 0.6;\n    cursor: not-allowed;\n    filter: saturate(25%);\n  ";
});
FieldLabel.defaultProps = {
    disabled: false,
};
var templateObject_1;
