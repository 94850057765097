import React from "react";

import {AtomizedBranchNode, AtomProp} from "../../model/editor";
import {DroppableField} from "../droppable-field";

export const GroupFieldChildrenBlocks = ({
  atom: parentAtom,
  children,
  isArrayFieldScoped,
}: AtomProp & AtomizedBranchNode & {isArrayFieldScoped: boolean}) => (
  <>
    {children.map((atom, index) => (
      <DroppableField
        key={atom.key}
        index={index}
        atom={atom}
        parentAtom={parentAtom}
        isArrayFieldScoped={isArrayFieldScoped}
      />
    ))}
  </>
);
