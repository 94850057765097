var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { Field } from "formik";
import { StarRating } from "../../star-rating";
export var StarRatingField = function (_a) {
    var name = _a.name;
    return (React.createElement(Field, { name: name }, function (_a) {
        var field = _a.field, form = _a.form;
        return (React.createElement(StarRating, __assign({}, field, { onChange: function (value) {
                var shouldValidate = form.validateOnChange;
                form.setFieldValue(name, value, shouldValidate);
                form.setFieldTouched(name, true);
            } })));
    }));
};
