import React from "react";
import {Tag} from "antd";
import {PdfNode} from "@reside/forms";
import {useRecoilValue} from "recoil";

import {isCommonPdfSelector} from "../../../../model/pdf/PdfModel";

export const PdfSourceTemplateTag = ({node}: {node: PdfNode}) => {
  const isCommonPdf = useRecoilValue(isCommonPdfSelector(node.src));

  return isCommonPdf ? (
    <Tag color="warning">{node.src.split("/")[0]}</Tag>
  ) : null;
};
