import {v4} from "uuid";
import {saveAs} from "file-saver";
import {PdfNode} from "@reside/forms";

import {ensureDirectory, fileExists, fs} from "../../services/FileService";
import {rootDir} from "../../services/GitService";

export const savePdf = async (node: PdfNode) =>
  saveAs(
    new Blob([await readPdfFile(node)], {type: "application/pdf"}),
    getPdfFileName(node),
  );

export const pdfFileExists = (templateName: string, pdfName: string) =>
  fileExists(`pdf/${templateName}/${pdfName}`);

export const readPdfFile = (node: PdfNode) =>
  fs.readFile(`${rootDir}/pdf/${node.src}`);

export const ensurePdfDir = (templateName: string) =>
  ensureDirectory(`${rootDir}/pdf/${templateName}`);

export const writePdfFile = async (file: File, templateName: string) =>
  fs.writeFile(
    `${rootDir}/pdf/${templateName}/${file.name}`,
    new Uint8Array(await file.arrayBuffer()),
  );

export const getPdfFileName = (node: PdfNode) => node.src.split("/").pop()!;

export const createPdfNode = (
  templateName: string,
  pdfName: string,
): PdfNode => ({
  id: v4(),
  type: "pdf",
  src: `${templateName}/${pdfName}`,
  title: {
    translationKey: pdfName,
  },
});
