import React from "react";
import {useRecoilValue} from "recoil";
import {Menu, Tooltip} from "antd";
import {GroupNodeType} from "@reside/forms";
import SubMenu from "antd/lib/menu/SubMenu";

import {
  libraryGroupMenuSelector,
  sectionGroupIsCommonImportSelector,
  componentLibraryMenuByTypeSelector,
  useAddGroup,
} from "../../../../model/library";
import {NodeIcon} from "../../../node-icon";
export const ComponentLibraryMenu = () => {
  const {addSlide, addSection, addSectionGroup} = useAddGroup();

  const sectionGroupIsCommonImport = useRecoilValue(
    sectionGroupIsCommonImportSelector,
  );
  const componentLibraryMenuByType = useRecoilValue(
    componentLibraryMenuByTypeSelector,
  );

  const groupMenu = useRecoilValue(libraryGroupMenuSelector);

  const handleAddNewSlideClick = () => addSlide();

  const handleAddNewSectionClicked = () => addSection();

  const handleAddNewSectionGroupClicked = () => addSectionGroup();

  const handleInlineClicked = (nodeType: GroupNodeType, nodeId: string) => {
    const selectedComponent = componentLibraryMenuByType.find(
      component => component.id === nodeId,
    ) as any;

    if (nodeType === GroupNodeType.SECTION) {
      addSection(selectedComponent);
    } else if (nodeType === GroupNodeType.SECTION_GROUP) {
      addSectionGroup(selectedComponent);
    } else {
      addSlide(selectedComponent);
    }
  };

  return (
    <Menu selectedKeys={undefined} style={{width: 256}}>
      <Menu.ItemGroup key="Blank items" title="Blank items">
        <Menu.Item
          disabled={sectionGroupIsCommonImport}
          key={GroupNodeType.SLIDE}
          icon={<NodeIcon node={{type: GroupNodeType.SLIDE} as any} />}
          onClick={handleAddNewSlideClick}
        >
          {sectionGroupIsCommonImport ? (
            <Tooltip title="Can not insert a new slide into common group">
              Slide
            </Tooltip>
          ) : (
            <>Slide</>
          )}
        </Menu.Item>
        <Menu.Item
          disabled={sectionGroupIsCommonImport}
          key={GroupNodeType.SECTION}
          icon={<NodeIcon node={{type: GroupNodeType.SECTION} as any} />}
          onClick={handleAddNewSectionClicked}
        >
          {sectionGroupIsCommonImport ? (
            <Tooltip title="Can not insert a new section into a common group">
              Section
            </Tooltip>
          ) : (
            <>Section </>
          )}
        </Menu.Item>
        <Menu.Item
          key={GroupNodeType.SECTION_GROUP}
          icon={<NodeIcon node={{type: GroupNodeType.SECTION_GROUP} as any} />}
          onClick={handleAddNewSectionGroupClicked}
        >
          Section-group
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup key="Other" title="Library components">
        {groupMenu.map(({key, items}) => (
          <SubMenu
            key={`other-${key}`}
            icon={
              <NodeIcon
                node={{type: key.replace(/\b\w/g, l => l.toLowerCase())} as any}
              />
            }
            title={key.replace(/\b\w/g, l => l.toUpperCase())}
          >
            {items.map(({node, disabled}) => (
              <Menu.Item
                key={node.id}
                disabled={disabled}
                icon={<NodeIcon node={node} />}
                onClick={() => handleInlineClicked(node.type, node.id)}
              >
                {disabled ? (
                  <Tooltip title="This item is already used">
                    {node.title ? node?.title.translationKey : node.fileName}
                  </Tooltip>
                ) : (
                  <>{node.title ? node?.title.translationKey : node.fileName}</>
                )}
              </Menu.Item>
            ))}
          </SubMenu>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );
};
