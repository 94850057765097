var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { toggleMark } from "prosemirror-commands";
import markInputRule from "../lib/markInputRule";
import Mark from "./Mark";
function backticksFor(node, side) {
    var ticks = /`+/g;
    var match;
    var len = 0;
    if (node.isText) {
        while ((match = ticks.exec(node.text))) {
            len = Math.max(len, match[0].length);
        }
    }
    var result = len > 0 && side > 0 ? " `" : "`";
    for (var i = 0; i < len; i++) {
        result += "`";
    }
    if (len > 0 && side < 0) {
        result += " ";
    }
    return result;
}
var Code = /** @class */ (function (_super) {
    __extends(Code, _super);
    function Code() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Code.prototype, "name", {
        get: function () {
            return "code_inline";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Code.prototype, "schema", {
        get: function () {
            return {
                excludes: "_",
                parseDOM: [{ tag: "code" }],
                toDOM: function () { return ["code", { spellCheck: false }]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    Code.prototype.inputRules = function (_a) {
        var type = _a.type;
        return [markInputRule(/(?:^|[^`])(`([^`]+)`)$/, type)];
    };
    Code.prototype.keys = function (_a) {
        var type = _a.type;
        // Note: This key binding only works on non-Mac platforms
        // https://github.com/ProseMirror/prosemirror/issues/515
        return {
            "Mod`": toggleMark(type),
        };
    };
    Object.defineProperty(Code.prototype, "toMarkdown", {
        get: function () {
            return {
                open: function (_state, _mark, parent, index) {
                    return backticksFor(parent.child(index), -1);
                },
                close: function (_state, _mark, parent, index) {
                    return backticksFor(parent.child(index - 1), 1);
                },
                escape: false,
            };
        },
        enumerable: true,
        configurable: true
    });
    Code.prototype.parseMarkdown = function () {
        return { mark: "code_inline" };
    };
    return Code;
}(Mark));
export default Code;
