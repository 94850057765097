var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import Extension from "../lib/Extension";
var Placeholder = /** @class */ (function (_super) {
    __extends(Placeholder, _super);
    function Placeholder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Placeholder.prototype, "name", {
        get: function () {
            return "empty-placeholder";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Placeholder.prototype, "defaultOptions", {
        get: function () {
            return {
                emptyNodeClass: "placeholder",
                placeholder: "",
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Placeholder.prototype, "plugins", {
        get: function () {
            var _this = this;
            return [
                new Plugin({
                    props: {
                        decorations: function (state) {
                            var doc = state.doc;
                            var decorations = [];
                            var completelyEmpty = doc.textContent === "" && doc.content.size <= 2;
                            doc.descendants(function (node, pos) {
                                if (!completelyEmpty) {
                                    return;
                                }
                                if (pos !== 0 || node.type.name !== "paragraph") {
                                    return;
                                }
                                var decoration = Decoration.node(pos, pos + node.nodeSize, {
                                    class: _this.options.emptyNodeClass,
                                    "data-empty-text": _this.options.placeholder,
                                });
                                decorations.push(decoration);
                            });
                            return DecorationSet.create(doc, decorations);
                        },
                    },
                }),
            ];
        },
        enumerable: true,
        configurable: true
    });
    return Placeholder;
}(Extension));
export default Placeholder;
