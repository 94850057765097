import React from "react";
import {red} from "@ant-design/colors";
import {PlusOutlined} from "@ant-design/icons";
import {RadioItem, RadioGroupField, styled} from "@reside/ui";
import {getFieldName} from "@reside/forms";
import {RecoilState} from "recoil";

import {AtomizedFormControlSourceNode, AtomProp} from "../../model/editor";
import {useChoices} from "../../model/nodes";
import {GroupFieldOption} from "../group-field-option";
import {TemplateNodes} from "../../model/schemaTypes";
import {EditableLabelAttribute} from "../editable-label-attribute";

export const EditableRadioGroupField = ({
  isFocused,
  node,
  atom: parentAtom,
  isArrayFieldScoped,
}: {
  isFocused?: boolean;
  isArrayFieldScoped: boolean;
  node: AtomizedFormControlSourceNode;
} & AtomProp) => {
  const {reference, disabled} = node;
  const isUsingQueryPath = node.reference.query;
  const optionLabel = node.reference.optionLabel?.translationKey;

  const {choices, addChoice, removeChoice} = useChoices(parentAtom as any);

  return (
    <Wrapper>
      <RadioGroupField<RecoilState<TemplateNodes>>
        name={getFieldName(reference)}
        label={<EditableLabelAttribute atom={parentAtom} />}
        options={choices as any}
        disabled={Boolean(disabled || isUsingQueryPath)}
        renderOption={(element, option, index) => (
          <GroupFieldOption
            index={index}
            atom={reference.choices?.[index] as any}
            parentAtom={parentAtom}
            element={element}
            option={option as any}
            reference={reference}
            onRemove={() => removeChoice(index)}
            isFocused={isFocused}
            isArrayFieldScoped={isArrayFieldScoped}
          />
        )}
        isChecked={() => false}
        getOptionLabel={() => null}
        getOptionKey={(option, name) => `${name}.${option.id}`}
      />
      {isUsingQueryPath ? (
        <RadioItem disabled label={optionLabel} onChange={() => {}} />
      ) : (
        <IconWrapper>
          <PlusOutlined style={{color: red.primary}} onClick={addChoice} />
        </IconWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 7px;
`;

const IconWrapper = styled.div`
  padding: 10px;
`;
