var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Plugin, TextSelection } from "prosemirror-state";
import getMarkRange from "../queries/getMarkRange";
import Mark from "./Mark";
var Placeholder = /** @class */ (function (_super) {
    __extends(Placeholder, _super);
    function Placeholder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Placeholder.prototype, "name", {
        get: function () {
            return "placeholder";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Placeholder.prototype, "schema", {
        get: function () {
            return {
                parseDOM: [{ tag: "span.template-placeholder" }],
                toDOM: function () { return ["span", { class: "template-placeholder" }]; },
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Placeholder.prototype, "toMarkdown", {
        get: function () {
            return {
                open: "!!",
                close: "!!",
                mixable: true,
                expelEnclosingWhitespace: true,
            };
        },
        enumerable: true,
        configurable: true
    });
    Placeholder.prototype.parseMarkdown = function () {
        return { mark: "placeholder" };
    };
    Object.defineProperty(Placeholder.prototype, "plugins", {
        get: function () {
            var _this = this;
            return [
                new Plugin({
                    props: {
                        handleTextInput: function (view, from, to, text) {
                            if (_this.editor.props.template) {
                                return false;
                            }
                            var state = view.state, dispatch = view.dispatch;
                            var $from = state.doc.resolve(from);
                            var range = getMarkRange($from, state.schema.marks.placeholder);
                            if (!range)
                                return false;
                            var selectionStart = Math.min(from, range.from);
                            var selectionEnd = Math.max(to, range.to);
                            dispatch(state.tr
                                .removeMark(range.from, range.to, state.schema.marks.placeholder)
                                .insertText(text, selectionStart, selectionEnd));
                            var $to = view.state.doc.resolve(selectionStart + text.length);
                            dispatch(view.state.tr.setSelection(TextSelection.near($to)));
                            return true;
                        },
                        handleKeyDown: function (view, event) {
                            if (!view.props.editable || !view.props.editable(view.state)) {
                                return false;
                            }
                            if (_this.editor.props.template) {
                                return false;
                            }
                            if (event.key !== "ArrowLeft" &&
                                event.key !== "ArrowRight" &&
                                event.key !== "Backspace") {
                                return false;
                            }
                            var state = view.state, dispatch = view.dispatch;
                            if (event.key === "Backspace") {
                                var range = getMarkRange(state.doc.resolve(Math.max(0, state.selection.from - 1)), state.schema.marks.placeholder);
                                if (!range)
                                    return false;
                                dispatch(state.tr
                                    .removeMark(range.from, range.to, state.schema.marks.placeholder)
                                    .insertText("", range.from, range.to));
                                return true;
                            }
                            if (event.key === "ArrowLeft") {
                                var range = getMarkRange(state.doc.resolve(Math.max(0, state.selection.from - 1)), state.schema.marks.placeholder);
                                if (!range)
                                    return false;
                                var startOfMark = state.doc.resolve(range.from);
                                dispatch(state.tr.setSelection(TextSelection.near(startOfMark)));
                                return true;
                            }
                            if (event.key === "ArrowRight") {
                                var range = getMarkRange(state.selection.$from, state.schema.marks.placeholder);
                                if (!range)
                                    return false;
                                var endOfMark = state.doc.resolve(range.to);
                                dispatch(state.tr.setSelection(TextSelection.near(endOfMark)));
                                return true;
                            }
                            return false;
                        },
                        handleClick: function (view, pos, event) {
                            if (!view.props.editable || !view.props.editable(view.state)) {
                                return false;
                            }
                            if (_this.editor.props.template) {
                                return false;
                            }
                            if (event.target instanceof HTMLSpanElement &&
                                event.target.className.includes("template-placeholder")) {
                                var state = view.state, dispatch = view.dispatch;
                                var range = getMarkRange(state.selection.$from, state.schema.marks.placeholder);
                                if (!range)
                                    return false;
                                event.stopPropagation();
                                event.preventDefault();
                                var startOfMark = state.doc.resolve(range.from);
                                dispatch(state.tr.setSelection(TextSelection.near(startOfMark)));
                                return true;
                            }
                            return false;
                        },
                    },
                }),
            ];
        },
        enumerable: true,
        configurable: true
    });
    return Placeholder;
}(Mark));
export default Placeholder;
