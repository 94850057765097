"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIN_ITEMS_RULE_NAME = "min_items";
exports.Validator = {
    name: exports.MIN_ITEMS_RULE_NAME,
    errorMessage: "This array field must have at least : " + exports.MIN_ITEMS_RULE_NAME + " items.",
    validate: function (value, requirement) {
        return Array.isArray(value) && value.length >= Number(requirement);
    },
};
