var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { useField } from "formik";
import { Text } from "../../text";
import { styled } from "../../theme";
import { useBlockComponentContext } from "../BlockComponentContext";
export var Caption = function (_a) {
    var fieldName = _a.fieldName, translationKey = _a.translationKey;
    var _b = useField(fieldName), meta = _b[1];
    var hasError = meta.touched && meta.error;
    var Translated = useBlockComponentContext().Translated;
    return (React.createElement(CaptionText, { fontSize: "1.25em", color: hasError ? "deepOrange100" : undefined },
        React.createElement(Translated, { translationKey: translationKey })));
};
var CaptionText = styled(Text)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  padding: 30px 10px;\n  text-align: center;\n  margin-top: 5px;\n  margin-bottom: 10px;\n"], ["\n  display: block;\n  padding: 30px 10px;\n  text-align: center;\n  margin-top: 5px;\n  margin-bottom: 10px;\n"])));
var templateObject_1;
