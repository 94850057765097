import React from "react";
import {useRecoilValue, waitForAll} from "recoil";
import {Skeleton} from "antd";

import {priorTemplatesUpdatedAtAtom} from "../../../model/answers";
import {MarkdownEditor, Props} from "../../../atoms/markdown-editor";
import {answerTreeDataSelector} from "../../../model/markdown";

export const BuilderMarkdownEditor = (
  props: Omit<Props, "answers" | "treeData">,
) => {
  const [hasData, {treeData, answers}] = useRecoilValue(
    waitForAll([priorTemplatesUpdatedAtAtom, answerTreeDataSelector]),
  );

  if (!hasData) {
    return <Skeleton active paragraph={{rows: 1}} />;
  }

  return <MarkdownEditor {...props} answers={answers} treeData={treeData} />;
};
