var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../../theme";
import { SegmentedControl } from "../../segmented-control";
import { useBlockComponentContext } from "../BlockComponentContext";
export var LanguageControls = function (_a) {
    var activeMutationIndex = _a.activeMutationIndex, mutations = _a.mutations, onChange = _a.onChange;
    var translate = useBlockComponentContext().translate;
    return (React.createElement(Container, null,
        React.createElement(Label, null, "Choose language of video"),
        React.createElement(SegmentedControlWrapper, null,
            React.createElement(SegmentedControl, { selectedIndex: activeMutationIndex, segments: mutations.map(function (mutation) {
                    return translate(mutation.language.label.translationKey);
                }), onChange: onChange }))));
};
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n\n  ", " {\n    flex-direction: row;\n    align-items: center;\n    padding: 30px 0;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n\n  ", " {\n    flex-direction: row;\n    align-items: center;\n    padding: 30px 0;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.media.md;
});
var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 1.25em;\n  padding: 10px 0;\n"], ["\n  font-size: 1.25em;\n  padding: 10px 0;\n"])));
var SegmentedControlWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", " {\n    margin-left: 20px;\n    width: 10px;\n    height: 50px;\n  }\n"], ["\n  ", " {\n    margin-left: 20px;\n    width: 10px;\n    height: 50px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.media.md;
});
var templateObject_1, templateObject_2, templateObject_3;
