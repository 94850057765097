import {GroupNodeType, NodeType} from "@reside/forms";

export enum TextFieldType {
  SSN = "SSN",
  ZIP_CODE = "ZIP_CODE",
  PHONE = "PHONE",
  EMAIL = "EMAIL",
}

export enum DragType {
  CREATE = "CREATE ",
  MOVE = "MOVE ",
  MOVE_CHOICES = "MOVE_CHOICES",
  ADD_LIBRARY_COMPONENT = "ADD_LIBRARY_COMPONENT",
}

export enum TemplateType {
  RESIDENT_EXPERIENCE = "resident-experience",
  PREFLIGHT = "pre-flight",
  PRELUDE = "resident-experience-prelude",
  FACILITY_SETTINGS = "facility-settings",
}

const TemplateTypeName: Record<TemplateType, string> = {
  [TemplateType.RESIDENT_EXPERIENCE]: "Resident Experience",
  [TemplateType.PREFLIGHT]: "Pre-Flight",
  [TemplateType.PRELUDE]: "Prelude",
  [TemplateType.FACILITY_SETTINGS]: "Facility Settings",
};

export const getTemplateTypeName = (templateType: TemplateType) =>
  TemplateTypeName[templateType];

export const templateTypesList = [
  TemplateType.RESIDENT_EXPERIENCE,
  TemplateType.PRELUDE,
  TemplateType.PREFLIGHT,
  TemplateType.FACILITY_SETTINGS,
];

export const BOOLEAN_MAP = new Map([
  ["true", true],
  ["false", false],
]);

export const commonComponentsDirName = "common-components";
export const constantCommonComponentsDirName = "constant-common-components";
export const isDev = process.env.NODE_ENV === "development";

export const nestedDropResultTypes: string[] = [
  GroupNodeType.SLIDE,
  GroupNodeType.SECTION,
  GroupNodeType.SECTION_GROUP,
  NodeType.FRAGMENT,
  NodeType.CARD,
  NodeType.COLLAPSIBLE,
  NodeType.COLUMN,
  NodeType.FIELD_ARRAY,
];
