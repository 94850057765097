var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from "react";
import { styled } from "../../../theme";
import { SCROLLABLE_ERROR_ID } from "../../../scroll-to-error";
import { getFormFieldComponent, getFormFieldError, isInvalid, } from "../../formHelpers";
export var FieldError = function (_a) {
    var name = _a.name;
    var FormField = getFormFieldComponent(name);
    return (React.createElement(FormField, { name: name }, function (_a) {
        var form = _a.form;
        return isInvalid(name, form) ? (React.createElement(FieldErrorText, { id: SCROLLABLE_ERROR_ID + "-" + name, "data-test-id": "field-error-" + name }, getFormFieldError(name, form))) : null;
    }));
};
export var FieldErrorText = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.25em;\n  padding: 10px 5px;\n  font-weight: ", ";\n  text-align: left;\n"], ["\n  color: ", ";\n  font-size: 1.25em;\n  padding: 10px 5px;\n  font-weight: ", ";\n  text-align: left;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.color.deepOrange100;
}, function (_a) {
    var theme = _a.theme;
    return theme.fontWeight.medium;
});
var templateObject_1;
