"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MIN_LENGTH_RULE_NAME = "min_length";
exports.Validator = {
    name: exports.MIN_LENGTH_RULE_NAME,
    errorMessage: "This field must be at least :" + exports.MIN_LENGTH_RULE_NAME + " characters.",
    validate: function (value, requirement) {
        return value.toString().length >= Number(requirement);
    },
};
