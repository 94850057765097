import React from "react";
import {styled} from "@reside/ui";
import {clearWhiteSpace} from "../../utils";

export const EditableText = ({
  value,
  emptyValue,
  onChange,
}: {
  value: string;
  emptyValue: string;
  onChange: (value: string) => void;
}) => (
  <Text
    contentEditable
    onBlur={event => {
      const value =
        clearWhiteSpace(event.currentTarget.textContent ?? "") || emptyValue;
      onChange(value);
      event.currentTarget.textContent = value;
    }}
    dangerouslySetInnerHTML={{
      __html: value,
    }}
  />
);

const Text = styled.span`
  outline: none;
`;
