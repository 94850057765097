var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import shouldForwardProp from "@styled-system/should-forward-prop";
import { space, color, fontSize, fontWeight, lineHeight, } from "styled-system";
import { styled } from "../theme";
export var Text = styled("span", { shouldForwardProp: shouldForwardProp })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  text-transform: ", ";\n"], ["\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  text-transform: ", ";\n"])), space, fontSize, fontWeight, lineHeight, color, function (_a) {
    var textTransform = _a.textTransform;
    return textTransform;
});
var templateObject_1;
