import React from "react";
import {SubtitleNode} from "@reside/forms";
import {Subtitle, ADAPTABLE_TEXT_CLASSNAME, styled} from "@reside/ui";
import {debounce} from "lodash";

import {AtomProp, useSetAtomPropertyValue} from "../../model/editor";
import {BuilderMarkdownEditor} from "../../pages/editor/atoms/BuilderMarkdownEditor";

type Props = Readonly<{
  isFocused?: boolean;
  node: SubtitleNode;
}> &
  AtomProp;

export const EditableSubtitle = ({
  node: {id, translationKey},
  atom,
  isFocused,
}: Props) => {
  const setValue = useSetAtomPropertyValue(atom, "translationKey");

  const handleChange = debounce(value => {
    const text = value();
    setValue(text);
  }, 250);

  return (
    <BuilderMarkdownEditor
      id={id}
      defaultValue={translationKey}
      onChange={handleChange}
      hasTopMenu={isFocused}
    >
      {({Menu, Editor}) => (
        <>
          <Menu />
          <StyledSubtitle className={ADAPTABLE_TEXT_CLASSNAME}>
            <Editor />
          </StyledSubtitle>
        </>
      )}
    </BuilderMarkdownEditor>
  );
};

const StyledSubtitle = styled(Subtitle)`
  padding: 0 7px;
& .ProseMirror {
    &:focus {
      cursor: text;
    }
`;
