import React, { useState, } from "react";
import { Field } from "formik";
import { FlexRowCenter } from "../../box";
import { Input } from "../atoms/TextualInput";
import { FieldError } from "../atoms/field-error";
import { FloatingFieldLabel } from "../atoms/FloatingFieldLabel";
import { FieldBorder } from "../atoms/FieldBorder";
import { LockIcon } from "../atoms";
import { Label } from "../atoms/Label";
import { ClearFieldButton } from "../atoms/clear-field-button";
import { autoCapitalizeText, format as formatValue } from "./format";
import { precision, isDecimal } from "./number";
import { isInvalid } from "../formHelpers";
export var TextField = function (_a) {
    var name = _a.name, label = _a.label, type = _a.type, disabled = _a.disabled, placeholder = _a.placeholder, renderBefore = _a.renderBefore, renderAfter = _a.renderAfter, fractionalDigits = _a.fractionalDigits, uppercase = _a.uppercase, format = _a.format, onChange = _a.onChange, onFocus = _a.onFocus, onBlur = _a.onBlur, FormField = _a.FormField;
    var _b = useState(false), focused = _b[0], setFocused = _b[1];
    return (React.createElement(FormField, { name: name, type: type }, function (_a) {
        var field = _a.field, form = _a.form;
        var value = field.value;
        var setFieldValue = form.setFieldValue, setFieldTouched = form.setFieldTouched;
        var isFilled = isValueFilled(value);
        var hasError = isInvalid(name, form);
        var onReset = function () { return setFieldValue(name, ""); };
        return (React.createElement(Label, null,
            label && (React.createElement(FloatingFieldLabel, { uppercase: uppercase, visible: isFilled }, label)),
            React.createElement(FlexRowCenter, null,
                renderBefore({ value: value }),
                React.createElement(Input, { name: name, value: value !== undefined
                        ? value
                        : isFilled
                            ? processValue(fractionalDigits, value)
                            : "", placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, onBlur: function (event) {
                        setFocused(false);
                        setFieldTouched(name, true);
                        if (fractionalDigits) {
                            if (event.currentTarget.value !== "") {
                                setFieldValue(name, Number(event.currentTarget.value).toFixed(fractionalDigits));
                            }
                        }
                        onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
                    }, onChange: function (event) {
                        onChange
                            ? onChange(event)
                            : form.setFieldValue(name, formatFieldValue(event.currentTarget.value, {
                                type: type,
                                format: format,
                                fractionalDigits: fractionalDigits,
                            }), true);
                    }, onFocus: function (event) {
                        onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
                        setFocused(true);
                    }, disabled: disabled, type: type }),
                renderAfter({ value: value }),
                isFilled && !disabled && React.createElement(ClearFieldButton, { onClick: onReset }),
                disabled && React.createElement(LockIcon, null)),
            React.createElement(FieldBorder, { focused: focused, invalid: hasError }),
            React.createElement(FieldError, { name: name })));
    }));
};
TextField.defaultProps = {
    type: "text",
    renderBefore: function () { return null; },
    renderAfter: function () { return null; },
    FormField: Field,
};
export var formatFieldValue = function (value, _a) {
    var type = _a.type, format = _a.format, fractionalDigits = _a.fractionalDigits;
    return format
        ? formatValue(value, format)
        : type === "number" || fractionalDigits
            ? removeLeadingZeroFromNumber(value)
            : autoCapitalizeText(value, type);
};
export var isValueFilled = function (value) { return value || value === 0; };
export var removeLeadingZeroFromNumber = function (value) {
    var str = String(value).replace(/^0+/, "0");
    if (String(str).length === 1) {
        return str;
    }
    /* eslint-disable no-useless-escape */
    return String(str).replace(/^0+(?![.|,])/, "");
};
var shouldFormatDigits = function (fractionalDigits, value) {
    if (isDecimal(value)) {
        return fractionalDigits && precision(value) > fractionalDigits;
    }
    else {
        return false;
    }
};
var processValue = function (fractionalDigits, value) {
    if (shouldFormatDigits(fractionalDigits, value)) {
        return Number(value).toFixed(fractionalDigits);
    }
    return value;
};
