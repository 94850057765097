var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Extension from "../lib/Extension";
var Node = /** @class */ (function (_super) {
    __extends(Node, _super);
    function Node() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Node.prototype, "type", {
        get: function () {
            return "node";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "markdownToken", {
        get: function () {
            return "";
        },
        enumerable: true,
        configurable: true
    });
    Node.prototype.toMarkdown = function (state, node) {
        // eslint-disable-next-line no-console
        console.error("toMarkdown not implemented", state, node);
    };
    Node.prototype.parseMarkdown = function () {
        return;
    };
    return Node;
}(Extension));
export default Node;
