import React from "react";
import {message, Space} from "antd";
import {FileAddOutlined} from "@ant-design/icons";
import {styled} from "@reside/ui";

import {useIsResidentExperienceTemplateValue} from "../../../../model/template";
import {useAppendPdfFile} from "../../../../model/pdf/PdfModel";

const pdfType = "application/pdf";

export const PdfUploadButton = () => {
  const isResidentExperience = useIsResidentExperienceTemplateValue();

  const appendPdfFile = useAppendPdfFile();

  return isResidentExperience ? (
    <FooterButton>
      <Space>
        <FileAddOutlined />
        Upload PDF
      </Space>
      <input
        type="file"
        hidden
        accept={pdfType}
        onChange={event => {
          const file = event.currentTarget.files?.item(0);

          /**
           * Reset the input, so we can pick another PDF.
           */
          event.currentTarget.value = "";

          if (file) {
            if (file.type !== pdfType) {
              message.error("Only PDF files are allowed to attach.");
              return;
            }

            appendPdfFile(file);
          }
        }}
      />
    </FooterButton>
  ) : null;
};

const FooterButton = styled.label`
  display: block;
  cursor: pointer;
`;
